import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';

import smartdate from 'smartdate';
import DataStore from '../../stores/DataStore';
import FilterGrid from '../basic/FilterGrid';
import Loading from '../basic/Loading';
import MemberActions from '../../actions/MemberActions';
import MemberDetailsForm from '../forms/MemberDetailsForm';
import MemberPositions from './MemberPositions';
import MemberEditForm from '../forms/MemberEditForm';
import MemberResetPasswordForm from '../forms/MemberResetPasswordForm';
import CreatePasswordResetLinkForm from '../forms/CreatePasswordResetLinkForm';
import router from '../../router/router';
import Smartdate from '../basic/Smartdate';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import {
  MEMBER_PERMISSIONS,
  MEMBER_INVITATION_RESEND,
  MEMBER_INVITATION_REVOKE,
} from '../../constants/FormIds';
import Button from '../basic/Button';
import Caption from '../basic/Caption';
import Text from '../basic/Text';
import NavLink from '../basic/NavLink';
import { Site } from '../../stores/DataModels';

/* eslint-disable react/prefer-es6-class */
const Member = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    memberId: PropTypes.string,
    organization: PropTypes.object,
    routes: PropTypes.object,
  },

  mixins: [storeListenerMixin(DataStore)],

  componentWillMount() {
    this.setState({ loading: true });
    const finished = () => this.setState({ loading: false });
    MemberActions.getMember(this.props.memberId, finished, finished);
  },

  componentWillReceiveProps() {
    this.setState(this.getStateFromStores());
  },

  getStateFromStores() {
    const member = DataStore.member.get(this.props.memberId);
    const user = member && member.get('user');
    return {
      member,
      email: (user && user.get('email')) || '',
      fullName: (user && user.fullName()) || '- not specified yet -',
      invited: member ? Boolean(!user.get('last_login')) : null,
      user,
      editableProfile: false,
    };
  },

  setPermissions(data, e) {
    MemberActions.setMemberPermissions(
      this.state.member, data, MEMBER_PERMISSIONS,
    );
    e.preventDefault();
  },

  invitationResend(e) {
    MemberActions.resendInvitation(this.state.member, MEMBER_INVITATION_RESEND);
    e.preventDefault();
  },

  invitationRevoke(e) {
    MemberActions.setMemberPermissions(
      this.state.member, { is_active: false }, MEMBER_INVITATION_REVOKE,
    );
    e.preventDefault();
  },

  dateStr(date) {
    return date ? smartdate.format(date, { mode: 'date' }) : 'not specified';
  },

  groupOrderBy(groupmember) {
    return (groupmember.get('group').get('name') || '').toLowerCase();
  },

  groupDisplayFields(groupMember) {
    const group = groupMember.get('group');
    return [
      group.get('name'),
      group.get('type'),
      group.get('description'),
      this.groupRole(groupMember),
    ];
  },

  groupRole(groupMember) {
    if (groupMember.get('member').get('is_admin')) {
      return 'Org. Admin';
    }
    return groupMember.get('is_admin') ? 'Group Owner' : 'User';
  },

  groupLinks(groupMember) {
    return this.props.routes.GROUP.makeUrl(groupMember.get('group').id);
  },

  showEditMember() {
    this.setState({ editableProfile: true });
  },

  hideEditMember() {
    this.setState({ editableProfile: false });
  },

  memberIsCurrent(member) {
    const currentMember = DataStore.currentMember();
    return member.id === (currentMember && currentMember.get('id'));
  },

  renderInvitationLinkBlock() {
    return (
      <div>
        <Text classNameModifier="cub-Msg cub-Msg--info">
          Member has been created. Provide them with the username and invitation
          link so that they can log in and set a permanent password.
        </Text>
        <CreatePasswordResetLinkForm
          userId={this.state.user.id}
          buttonText="Create Invitation Link"
        />
      </div>
    );
  },

  renderInvitationBlock() {
    if (this.state.email) {
      return this.renderEmailInvitationBlock();
    }
    return this.renderInvitationLinkBlock();
  },

  renderEmailInvitationBlock() {
    const lastSentOn = this.state.user.get('invitation_last_sent_on');
    let lastSentOnText;
    let resendButtonText;
    if (lastSentOn) {
      lastSentOnText = (<Smartdate date={lastSentOn} />);
      resendButtonText = 'Resend invitation';
    } else {
      lastSentOnText = (<span>wasn&apos;t sent yet</span>);
      resendButtonText = 'Send invitation';
    }
    return (
      <div className="cub-MemberInvitation">
        <p className="cub-MemberLabel cub-MemberLabel--invitation">
          <span className="cub-MemberLabel-text">
            Invitation sent:{' '}
            <span className="cub-MemberLabel-value">{lastSentOnText}</span>{' '}
          </span>
          <NavLink
            className="cub-Link cub-Link--danger cub-Link--revoke"
            onClick={this.invitationRevoke}
          >
            <span className="cub-Link-icon" />
            Revoke invitation
          </NavLink>
        </p>
        <Button
          classNameModifier="cub-Button--resend"
          onClick={this.invitationResend}
          text={resendButtonText}
        />
      </div>
    );
  },

  renderPasswordReset() {
    const site = DataStore.currentSite();
    if (!site.passLoginEnabled()) {
      return null;
    }
    if (this.state.member.get('is_admin')) {
      return null;
    }
    const email = this.state.user.get('email');
    if (email) {
      return <MemberResetPasswordForm email={email} />;
    }
    const userId = this.state.user.get('id');
    return <CreatePasswordResetLinkForm userId={userId} />;
  },

  renderUserInfo() {
    const user = this.state.user;
    const userInfo = [];
    const site = DataStore.currentSite();
    const showPhoneField = (
      site.get('user_phone_field') !== Site.USER_PHONE_DISABLED
    );

    userInfo.push([
      <Text classNameModifier="cub-Text--memberName" key="name">
        Name: {this.state.fullName}
      </Text>,
      this.state.email && (
      <Text classNameModifier="cub-Text--memberEmail" key="email">
        Email Address: {this.state.email}
      </Text>
      ),
      <Text classNameModifier="cub-Text--username" key="username">
        Username: {user.get('username')}
      </Text>,
    ]);
    if (showPhoneField) {
      userInfo.push(
        <Text classNameModifier="cub-Text--phone" key="phone">
          Phone: {user.get('phone') || 'not specified'}
        </Text>,
      );
    }
    if (!this.state.invited) {
      userInfo.push.apply(userInfo, [
        <Text classNameModifier="cub-Text--gender" key="gender">
          Gender: {user.get('gender') || 'not specified'}
        </Text>,
        <Text classNameModifier="cub-Text--birthdate" key="birthdate">
          Birthdate: {this.dateStr(user.birthdate())}
        </Text>,
      ]);
    }
    return userInfo;
  },

  renderStatus() {
    const member = this.state.member;
    const status = member.get('is_active') ?
      'Active member' :
      'Inactive member';

    let statusBtn = null;
    if (!this.memberIsCurrent(member)) {
      const isActive = member.get('is_active');
      /* eslint-disable react/jsx-no-bind */
      statusBtn = (
        <NavLink
          key="member-status-btn"
          className={
            `cub-Link cub-Link--${isActive ?
              'deactivate cub-Link--danger' : 'activate'}`
          }
          onClick={this.setPermissions.bind(this, { is_active: !isActive })}
        >
          <span className="cub-Link-icon" />
          {isActive ? 'Deactivate' : 'Activate'}
        </NavLink>
      );
      /* eslint-enable react/jsx-no-bind */
    }
    return (
      <p
        key="member-status"
        className="cub-MemberLabel cub-MemberLabel--status"
      >
        <span className="cub-MemberLabel-text">
          Status: <span className="cub-MemberLabel-value">{status}</span>
        </span>
        {statusBtn}
      </p>
    );
  },

  renderRole() {
    const member = this.state.member;

    let role = member.get('is_admin') ? 'Org. Admin' : 'User';
    if (this.memberIsCurrent(member)) {
      role += ' (You)';
    }

    let roleBtn = null;
    if (!this.memberIsCurrent(member)) {
      const isAdmin = member.get('is_admin');
      /* eslint-disable react/jsx-no-bind */
      roleBtn = (
        <NavLink
          key="member-role-btn"
          className={
            `cub-Link cub-Link--${isAdmin ?
              'revoke cub-Link--danger' : 'grant'}`
          }
          onClick={this.setPermissions.bind(this, { is_admin: !isAdmin })}
        >
          <span className="cub-Link-icon" />
          {isAdmin ? 'Revoke' : 'Grant'} Admin rights
        </NavLink>
      );
      /* eslint-enable react/jsx-no-bind */
    }

    return (
      <p
        key="member-role"
        className="cub-MemberLabel cub-MemberLabel--role"
      >
        <span className="cub-MemberLabel-text">
          Role: <span className="cub-MemberLabel-value">{role}</span>
        </span>
        {roleBtn}
      </p>
    );
  },

  renderMemberProfile() {
    const member = this.state.member;
    if (this.memberIsCurrent(member)) return null;

    const isProfileEditable = member.get('is_profile_editable');

    if (this.state.editableProfile) {
      return (
        <MemberEditForm
          member={member}
          routes={this.props.routes}
          cancelClick={this.hideEditMember}
        />
      );
    }

    const editProfileBlock = isProfileEditable ? (
      <Button
        classNameModifier="cub-Button--editMember"
        onClick={this.showEditMember}
        text="Edit Personal Data"
      />
    ) : (
      <Text classNameModifier="cub-Msg cub-Msg--warning">
        Need to edit personal data?
        You can request that this user allow administrators to edit
        basic profile information via their My Profile page.
      </Text>
    );
    return (
      <div className="cub-Member-generalInfoDetails">
        {this.renderUserInfo()}
        {editProfileBlock}
      </div>
    );
  },

  render() {
    const member = this.state.member;

    if (!member) {
      if (this.state.loading) {
        return <Loading />;
      }

      // Looks like this member was not found
      router.navigate(this.props.routes.MEMBERS_ACTIVE);
      return <Loading />;
    }

    const groupMember = _.first(member.related.groupmember);
    if (_.isObject(groupMember) && !_.isObject(groupMember.get('group'))) {
      // waiting until at least one group is loaded
      return <Loading />;
    }

    let you = '';
    if (this.memberIsCurrent(member)) you = ' (You)';
    return (
      <div className="cub-Member">
        {this.state.loading && <Loading />}
        <div className="cub-Member-controls">
          <Caption>Access</Caption>
          {this.renderStatus()}
          {this.renderRole()}
          {this.state.invited && this.renderInvitationBlock()}
          {!this.state.invited && this.renderPasswordReset()}
        </div>

        <Caption>
          Details for {' '}
          <span className="cub-Member-name">{this.state.fullName + you}</span>
        </Caption>

        <div className="cub-Member-generalInfo">
          {this.renderMemberProfile()}
          <MemberDetailsForm member={member} />
        </div>

        <MemberPositions member={member} allowEditMembershipInfo />

        <Caption>Groups</Caption>

        <FilterGrid
          className="cub-Grid cub-Grid--memberGroups"
          cols={['Name', 'Type', 'Description', 'Role']}
          items={member.related.groupmember}
          orderBy={this.groupOrderBy}
          displayFields={this.groupDisplayFields}
          links={this.groupLinks}
        />

      </div>
    );
  },
});

export default Member;
