import PropTypes from 'prop-types';
import React from 'react';
import GenericForm from './GenericForm';

export default class VerificationRequestForm extends React.Component {
  render() {
    const org = this.props.member.get('organization');
    const fields = [
      {
        name: 'user',
        type: 'hidden',
        value: this.props.member.get('user').id,
      },
      {
        name: 'organization',
        type: 'hidden',
        value: org.id,
      },
      {
        name: 'phone',
        label: 'Work phone of your supervisor or HR department',
        type: 'text',
        value: org.get('hr_phone') || org.get('phone') || '',
        required: true,
        max_length: 100,
      },
      {
        name: 'personal_id',
        label: org.fieldLabels().personal_id,
        type: 'text',
        required: true,
        max_length: 90,
      },
      {
        name: 'notes',
        label: 'Notes/Special Circumstances',
        type: 'textarea',
        required: false,
        max_length: 500,
      },
    ];
    return (
      <GenericForm
        action="verificationrequests"
        fieldsets={[{ name: 'responsive-column', fields }]}
        onSuccess={() => {}}
        submitBtn="Get verified"
        user={this.props.member.get('user')}
        {...this.props}
      />
    );
  }
}

VerificationRequestForm.propTypes = {
  classNameModifier: PropTypes.string,
  member: PropTypes.object,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
};
