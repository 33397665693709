import AppDispatcher from '../dispatcher/AppDispatcher';
import AppStore from './AppStore';
import createStore from '../mixins/createStore';
import FormErrors from '../components/FormErrors';
import { SSO_CALLBACK } from '../constants/FormIds';
import {
  CLEAR_FORM_ERROR,
  FORM_ERROR,
  FORM_SUBMITTED,
  FORM_SUCCESS,
  FORM_RESET,
  URL_CHANGED,
} from '../constants/ActionTypes';

let forms = {};

function resetFormData(formId) {
  forms[formId] = {
    errors: new FormErrors(),
    isProcessing: false,
  };
  return forms[formId];
}

const FormStore = createStore({
  formData(formId) {
    return forms[formId] || resetFormData(formId);
  },

  errors(formId) {
    return this.formData(formId).errors;
  },

  isProcessing(formId) {
    return this.formData(formId).isProcessing;
  },

});

FormStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  let form;

  AppDispatcher.waitFor([AppStore.dispatchToken]);

  switch (action.actionType) {
    case URL_CHANGED:
      // When URL changed, we expect that all previous form errors disappear
      // except for SSO_CALLBACK because we are getting errors from url
      forms = { SSO_CALLBACK: forms[SSO_CALLBACK] };
      break;

    case CLEAR_FORM_ERROR:
      form = FormStore.formData(action.form);
      form.errors.clearFieldError(action.fieldName);
      break;

    case FORM_SUBMITTED:
      form = FormStore.formData(action.form);
      form.isProcessing = true;
      break;

    case FORM_ERROR:
      form = FormStore.formData(action.form);
      form.errors = new FormErrors(action.response);
      form.isProcessing = false;
      break;

    case FORM_SUCCESS:
    case FORM_RESET:
      resetFormData(action.form);
      FormStore.emitSuccess(action.form, action.response);
      break;

    default:
      return true;
  }

  FormStore.emitChange();
  return true;
});

export default FormStore;
