import PropTypes from 'prop-types';
import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { siteSentry } from '../../utils/Utils';
import CaptionLarge from './CaptionLarge';
import Error from './Error';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.recapchaToken = this._recapchaToken.bind(this);
    this.onSubmit = this._onSubmit.bind(this);
  }

  _recapchaToken(e) {
    if (!this.props.executeRecaptcha) {
      return;
    }
    this.props.executeRecaptcha(this.props.recaptcha).then((token) => {
      this.props.onTokenUpdate(token);
      this.onSubmit(e);
    }).catch(siteSentry.captureException);
    e.preventDefault();
  }

  _onSubmit(e) {
    if (!(this.props.disableWhenProcessing && this.props.isProcessing) &&
      typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(e);
    }

    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
  }

  render() {
    return (
      <form
        className={`cub-Form ${this.props.classNameModifier}`}
        autoComplete={this.props.autoComplete}
        ref={this.props.innerRef}
        onSubmit={this.props.recaptcha ? this.recapchaToken : this.onSubmit}
      >
        {this.props.caption &&
          <CaptionLarge isInsideForm>{this.props.caption}</CaptionLarge>}

        {this.props.error && (
          <Error classNameModifier="cub-Form-error--top">
            {this.props.error}
          </Error>
        )}

        {this.props.children}
      </form>
    );
  }
}

// Couldn't figure out a way to implement useGoogleReCaptcha without hook
export default React.forwardRef((props, ref) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <Form {...props} innerRef={ref} executeRecaptcha={executeRecaptcha} />
  );
});

Form.propTypes = {
  autoComplete: PropTypes.string,
  caption: PropTypes.string,
  children: PropTypes.any,
  classNameModifier: PropTypes.string,
  disableWhenProcessing: PropTypes.bool,
  error: PropTypes.any,
  executeRecaptcha: PropTypes.func,
  isProcessing: PropTypes.bool,
  onSubmit: PropTypes.func,
  onTokenUpdate: PropTypes.func,
  recaptcha: PropTypes.string,
  innerRef: PropTypes.string,
};

Form.defaultProps = {
  autoComplete: 'on',
  classNameModifier: '',
  innerRef: '',
};
