import AppDispatcher from '../dispatcher/AppDispatcher';
import AppStore from './AppStore';
import createStore from '../mixins/createStore';
import UserActions from '../actions/UserActions';
import {
  EMAIL_CONFIRMATION_ERROR,
  EMAIL_CONFIRMATION_SUCCESS,
  URL_CHANGED,
} from '../constants/ActionTypes';

function noToken() {
  return {
    token: null,
    status: null,
  };
}

let token = noToken();

const TokenStore = createStore({
  token() {
    return token.token;
  },

  status() {
    return token.status;
  },

});

function confirmEmail() {
  if (token.token) {
    UserActions.confirmEmail(token.token);
  }
}

TokenStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  AppDispatcher.waitFor([AppStore.dispatchToken]);

  switch (action.actionType) {
    case URL_CHANGED: {
      const route = action.route;
      const args = action.args;
      const routes = AppStore.routes();
      if (route === routes.EMAIL_CONFIRMATION) {
        token = {
          token: args[0],
          status: 'loading',
        };
        TokenStore.emitChange();
      } else if (token.token) {
        token = noToken();
        TokenStore.emitChange();
      }
      break;
    }

    case EMAIL_CONFIRMATION_SUCCESS:
      if (token.token) {
        token.status = 'confirmed';
      }
      TokenStore.emitChange();
      break;

    case EMAIL_CONFIRMATION_ERROR:
      if (token.token) {
        if (action.meta.code === 403) {
          token.status = 'already confirmed';
          TokenStore.emitChange();
        } else if (action.meta.code === 400) {
          token.status = 'bad token';
          TokenStore.emitChange();
        } else {
          window.setTimeout(confirmEmail, 5000); // retry in 5 sec
        }
      }
      break;

    default:
  }

  return true;
});

export default TokenStore;
