import React from 'react';
import createForm from '../../mixins/createForm';
import Email from '../fields/Email';
import UserActions from '../../actions/UserActions';
import { FORGOT_PASSWORD } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';
import Text from '../basic/Text';

const ForgotPasswordForm = createForm(FORGOT_PASSWORD, {
  getInitialState() {
    return {
      email: '',
    };
  },

  componentDidMount() {
    this.refs.email.focus();
  },

  submit() {
    const nextUrl = this.props.routes.PASSWORD_RESET_LINK_SENT;
    UserActions.forgotPassword({
      email: this.state.email,
    }, FORGOT_PASSWORD, nextUrl);
  },

  render() {
    return (
      <Form
        classNameModifier="cub-Form--forgotPassword"
        caption="Reset password"
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <Email
          ref="email"
          name="email"
          value={this.state.email}
          required
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('email')}
        />

        <Button
          type="submit"
          isProcessing={this.state.isProcessing}
          text="Reset Password"
        />

        <Text classNameModifier="cub-Text--forgotPassword">
          If you forgot your registration email, please contact our{' '}
          <a className="cub-Form-link" href={this.props.routes.SUPPORT.url}>
            Member Services
          </a> for help.
        </Text>
      </Form>
    );
  },
});

export default ForgotPasswordForm;
