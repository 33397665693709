import LabeledInput from './LabeledInput';

export default class PostId extends LabeledInput {}

PostId.defaultProps = {
  classNameModifier: 'cub-FormControl--postId',
  label: 'POST ID',
  maxLength: 45,
  name: 'post_id',
};
