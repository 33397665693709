import PropTypes from 'prop-types';
import React from 'react';
import router from '../../router/router';

export default class NavLink extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    // for hash-only navigation router would also scroll window to the top
    if (this.props.target === '_blank') return;
    router.navigate(e.currentTarget.href);
    e.preventDefault();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  focus() {
    const linkEl = this.linkEl;
    if (linkEl) {
      linkEl.focus();
    }
  }

  render() {
    const props = {
      ref: (el) => { this.linkEl = el; },
      href: this.props.href ||
        (this.props.route && this.props.route.makeUrl(this.props)),
      id: this.props.id,
      className: this.props.className,
      target: this.props.target,
      title: this.props.title,
      'data-purpose': this.props.dataPurpose,
      onClick: this.props.onClick || this.onClick,
      role: 'button',
    };
    return React.createElement(
      'a',
      props,
      this.props.children,
    );
  }
}

NavLink.propTypes = {
  id: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  dataPurpose: PropTypes.string,
  onClick: PropTypes.func,
  route: PropTypes.object,
  target: PropTypes.string,
  title: PropTypes.string,
};
