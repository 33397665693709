import LabeledTextarea from './LabeledTextarea';

export default class GroupDescription extends LabeledTextarea {}

GroupDescription.defaultProps = {
  classNameModifier: 'cub-FormControl--description',
  label: 'Description',
  maxLength: 500,
  name: 'description',
};
