import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import FormActions from '../../actions/FormActions';
import FormListener from '../FormListener';
import FormStore from '../../stores/FormStore';
import { parseQueryString } from '../../utils/Utils';
import NavLink from '../basic/NavLink';
import UserActions from '../../actions/UserActions';
import DataStore from '../../stores/DataStore';
import ExternalLogin from '../fields/ExternalLogin';
import { SSO_SIGN_IN, USER_LOOKUP } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';
import Text from '../basic/Text';
import UsernameOrEmail from '../fields/UsernameOrEmail';

class UserLookupForm extends FormListener {
  constructor(props) {
    super(props);
    this.formId = USER_LOOKUP;
    this.state = {
      username: this.props.placeholder || parseQueryString().username || '',
    };
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const input = this.refs.username;
    if (input) {
      input.focus();
    }
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  formStateFromStores(formState) {
    const ssoErrors = FormStore.errors(SSO_SIGN_IN);
    return {
      ssoError: ssoErrors.topError(),
    };
  }

  submit() {
    UserActions.lookup(this.state.username, USER_LOOKUP);
    FormActions.resetForm(SSO_SIGN_IN);
  }

  onFieldChange(name, value) {
    super.onFieldChange(name, value);
    FormActions.resetForm(SSO_SIGN_IN);
  }

  renderHelpText(site) {
    /* eslint-disable react/jsx-curly-brace-presence */
    if (!site) return null;

    const esoSites = [
      'ste_Gb0WOetOz4j65H7R', // eso.evals.net
      'ste_LA01DsB4KQjRMm24', // eso.firerescue1academy.com
      'ste_oN1XRuqNLIDkpqB7', // eso.ems1academy.com
    ];
    // TODO: get rid of this custom text after all users onboarded ESO-system
    if (site.idpLoginEnabled() && _.contains(esoSites, site.id)) {
      return (
        <span>
          <p>
            To access your Academy account with ESO, please click the
            {' "'}Login with ESO{'" '} button.
            Then, sign in using your ESO account credentials
            (username, password, and agency code). When you successfully
            sign in, you will be automatically logged in to your
            Academy account.
          </p>
          <p>
            Can{'\''}t remember your account credentials?
            Click the {'"'}Forgot your password?{'" '} link in the ESO window.
          </p>
          <p>
            If you don{'\''}t have an ESO account, you can sign in with
            your email and password using the following form.
          </p>
        </span>
      );
    }

    if (site.idpLoginEnabled() && !site.passLoginEnabled()) {
      return (
        <span>
          <p>
            To access this site, please click the {'"'}Login with ...{'" '}
            button. Then, sign in using your account from the related
            site/system named in the {'"'}Login with ...{'" '} button.
            When you successfully sign in, you will be automatically logged in
            to your account on this site.
          </p>
        </span>
      );
    }
    /* eslint-enable react/jsx-curly-brace-presence */
    return null;
  }

  render() {
    const site = DataStore.currentSite();
    const routes = this.props.routes;

    return (
      <Form
        classNameModifier="cub-Form--login"
        caption="Sign In"
        error={this.state.errors.topError() || this.state.ssoError}
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        {/* TODO: extract SSOLogin to upper level component */}
        <ExternalLogin />

        {this.renderHelpText(site)}

        {site && site.passLoginEnabled() && (
          <>
            <UsernameOrEmail
              ref="username"
              value={this.state.username}
              onChange={this.onInputChange}
              error={this.state.errors.fieldError('username')}
            />

            <Button
              type="submit"
              isProcessing={this.state.isProcessing}
              text="Continue"
            />

            <Text classNameModifier="cub-Text--register">
              Not registered yet?{' '}
              <NavLink route={routes.REGISTER}>Register here</NavLink>{' '}
              for your account.
            </Text>
          </>
        )}
      </Form>
    );
  }
}

UserLookupForm.propTypes = {
  routes: PropTypes.object,
  placeholder: PropTypes.string,
};

export default UserLookupForm;
