import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import Error from '../basic/Error';

export default class LabeledRadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
  }

  validate() {
    if (this.props.required && !String(this.props.value)) {
      return 'This field is required.';
    }
    return null;
  }

  render() {
    return (
      <div
        className={'cub-FormGroup cub-FormGroup--radio' +
              ` ${this.props.classNameModifier}`}
      >
        {this.props.label && (
          <legend className="cub-Label">
            {this.props.label}
            {this.props.required && (
              <span className="cub-Label-asterisk">*</span>
            )}
          </legend>
        )}
        {_.map(this.props.options, (label, value) => (
          <label
            className="cub-Label cub-Label--radio"
            key={value}
          >
            <input
              className="cub-FormControl cub-FormControl--radio"
              type="radio"
              checked={value === this.props.value}
              disabled={this.props.disabled}
              name={this.props.name}
              value={value}
              onChange={this.props.onChange}
            />
            <span className="cub-Label-text">{label}</span>
          </label>
        ))}
        <Error dataField={this.props.name}>
          {this.props.error}
        </Error>
      </div>
    );
  }
}

LabeledRadioGroup.propTypes = {
  classNameModifier: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

LabeledRadioGroup.defaultProps = {
  classNameModifier: '',
};
