import AppDispatcher from '../dispatcher/AppDispatcher';
import CubApi from '../api/CubApi';
import UserStore from '../stores/UserStore';
import { FORM_SUCCESS } from '../constants/ActionTypes';
import { GENERIC_FORM } from '../constants/FormIds';

const VerificationActions = {
  cancel(verificationRequest) {
    CubApi.del(
      `verificationrequests/${verificationRequest.id}`,
      { apiKey: UserStore.token(true) },
      VerificationActions.requestCancelled,
    );
  },

  requestCancelled(response, meta, method, url, options) {
    AppDispatcher.handleFormAction({
      actionType: FORM_SUCCESS,
      form: GENERIC_FORM,
      response,
      options,
    });
  },
};

export default VerificationActions;
