import PropTypes from 'prop-types';
import React from 'react';

export default class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = 'cub-Markdown-styleButton';

    const icon = this.props.icon;
    if (icon) {
      className += ` cub-Markdown-styleButton--${icon}`;
    }

    if (this.props.active) {
      className += ' is-active';
    }

    return (
      <span
        className={className}
        onMouseDown={this.onToggle}
        role="button"
        tabIndex={0}
      >
        {icon ? '' : this.props.label}
      </span>
    );
  }
}

StyleButton.propTypes = {
  label: PropTypes.string,
  style: PropTypes.any,
  active: PropTypes.bool,
  onToggle: PropTypes.func,
  icon: PropTypes.string,
};
