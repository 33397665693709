import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import ApiActions from '../../actions/ApiActions';
import DataStore from '../../stores/DataStore';
import Loading from '../basic/Loading';
import Member from './Member';
import MemberAddForm from '../forms/MemberAddForm';
import MemberList from './MemberList';
import NavLink from '../basic/NavLink';
import router from '../../router/router';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import CaptionLarge from '../basic/CaptionLarge';
import Text from '../basic/Text';
import OrganizationActions from '../../actions/OrganizationActions';
import { Data } from '../../stores/DataBase';

/* eslint-disable react/prefer-es6-class */
const Members = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    args: PropTypes.array,
    route: PropTypes.object,
    routes: PropTypes.object,
  },

  /* eslint-disable react/no-unused-class-component-methods,react/sort-comp */
  mixins: [storeListenerMixin(DataStore)],

  getStateFromStores() {
    const member = DataStore.currentMember();
    const updatedState = { currentMember: member };
    const selectedOrg = this.state.selectedOrg;
    let organization;
    if (selectedOrg && selectedOrg.__pending_organization) {
      const orgId = selectedOrg.__pending_organization;
      organization = Data.organization.get(orgId);
    } else {
      organization = (
        selectedOrg ||
        DataStore.selectedTrustingOrg() ||
        (member && member.get('organization'))
      );
    }
    if (organization && (!selectedOrg || selectedOrg.id !== organization.id)) {
      updatedState.selectedOrg = organization;
    }
    if (organization && member && member.get('is_admin')) {
      this.loadGroups(organization);
    }
    return updatedState;
  },
  /* eslint-enable react/no-unused-class-component-methods,react/sort-comp */

  getInitialState() {
    this.loadingScheduled = false;
    const currentMember = DataStore.currentMember();
    const selectedTrustingOrg = DataStore.selectedTrustingOrg();
    const currentOrg = currentMember && currentMember.get('organization');
    let selectedOrg = selectedTrustingOrg || currentOrg;
    selectedOrg = this.scheduleOrganizationRequest(selectedOrg);
    return {
      currentMember,
      selectedOrg,
    };
  },

  componentDidMount() {
    this.loadDeferredOrganization(this.state.selectedOrg);
  },

  componentDidUpdate(prevProps) {
    if (this.props.route !== prevProps.route) {
      this.setState((prevState) => {
        let selectedOrg = prevState.selectedOrg;
        selectedOrg = this.scheduleOrganizationRequest(selectedOrg);
        this.loadDeferredOrganization(selectedOrg);
        return { selectedOrg };
      });
    }
  },

  onOrgChange(org) {
    this.loadingScheduled = false;
    this.setState({
      selectedOrg: org,
    });
  },

  loadGroups(organization) {
    if (this.loadingScheduled) {
      return;
    }
    window.setTimeout(() => {
      ApiActions.getAll('groups', { organization: organization.id });
    }, 0);
    this.loadingScheduled = true;
  },

  scheduleOrganizationRequest(selectedOrg) {
    if (this.props.route === this.props.routes.MEMBERS_NEW_IN_ORG) {
      const orgId = this.props.args[0];
      if (!selectedOrg || orgId !== selectedOrg.id) {
        return { __pending_organization: orgId };
      }
    }
    return selectedOrg;
  },

  loadDeferredOrganization(selectedOrg) {
    if (selectedOrg && selectedOrg.__pending_organization) {
      const orgId = selectedOrg.__pending_organization;
      _.defer(() => OrganizationActions.getOrganization(orgId));
    }
  },

  render() {
    const currentMember = this.state.currentMember;
    const organization = this.state.selectedOrg;
    if (!currentMember) {
      return <Loading />;
    }
    if (!currentMember.get('is_admin')) {
      router.navigate(this.props.routes.HOME);
      return <Loading />;
    }
    if (!organization || organization.__pending_organization) {
      return <Loading />;
    }

    const routes = this.props.routes;
    let currentView;
    let header = null;

    if (DataStore.currentSite().getWidgetSetting('members', 'header')) {
      header = (
        <header className="cub-Header">
          <CaptionLarge classNameModifier="cub-Caption--large">
            Members: {organization.get('name')}
          </CaptionLarge>
          <Text>
            <NavLink
              className="cubLink cubLink--manageMembers cub-Util-marginRight"
              route={routes.MEMBERS_ACTIVE}
            >
              <i className={'cub-Icon cub-Icon--manageMembers ' +
                'cub-Util-noMarginLeft'}
              />
              Manage members
            </NavLink>
            <NavLink
              className="cub-Link cubLink--manageGroups"
              route={routes.GROUPS}
            >
              <i className={'cub-Icon cub-Icon--manageGroups ' +
                'cub-Util-noMarginLeft'}
              />
              Manage groups
            </NavLink>
          </Text>
        </header>
      );
    }

    if (this.props.route === routes.MEMBER) {
      currentView = (
        <Member
          routes={routes}
          organization={organization}
          memberId={this.props.args[0]}
        />
      );
    } else if (
      this.props.route === routes.MEMBERS_NEW ||
      this.props.route === routes.MEMBERS_NEW_IN_ORG
    ) {
      currentView = (
        <MemberAddForm
          routes={routes}
          organization={organization}
          onChange={this.onOrgChange}
        />
      );
    } else {
      currentView = (
        <MemberList
          routes={routes}
          currentMember={currentMember}
          organization={organization}
          route={this.props.route}
          onChange={this.onOrgChange}
        />
      );
    }

    return <div className="cub-Members">{header} {currentView}</div>;
  },
});

export default Members;
