import ConfigStore from '../stores/ConfigStore';
import {
  addEventListener,
  startsWith,
  logger,
  urlify,
  urlParse,
  parseHeaders,
  siteSentry,
} from '../utils/Utils';

const API_ERRORS = {
  cant_connect: {
    body: {
      error: {
        code: 'connection_error',
        description: 'Can\'t connect to the server. This could be a ' +
          'temporary network problem, please try again in a while.',
      },
    },
    meta: {
      code: 'connection_error',
      headers: {},
    },
  },
  bad_response: {
    body: {
      error: {
        code: 'bad_response',
        description: 'Bad response was received from the server (not ' +
          'a valid JSON). This could be a temporary problem, please ' +
          'try again in a while. Our engineers were already notified.',
      },
    },
    meta: {
      code: 'bad_response',
      headers: {},
    },
  },
};

// XDM transport

const _pendingRequests = [];
const _requests = {};
const _apiFrameId = `cubApiChannel${Math.floor(Math.random() * 1000000)}`;
const _apiChannelId = `cub${Math.floor(Math.random() * 1000000)}`;
let _apiChannel = null; // will be initialized after iframe load

function getXdmFrame() {
  return document.querySelector(`iframe#${_apiFrameId}`);
}

function genRequestId() {
  const rand = Math.floor(Math.random() * 1000000);
  return `r_${new Date().getTime()}_${rand}`;
}

function getApiXdmRequest(method, url, opts, apiUrl) {
  const timeout = ConfigStore.get('apiTimeout');
  let requestUrl;
  if (/^https?:\/\/|^\/\//i.test(url)) {
    requestUrl = url; // Absolute URL was given
  } else {
    requestUrl = apiUrl.path;
    requestUrl += url.indexOf('/') === 0 ? url : `/${url}`;
  }

  const apiKey = opts.apiKey || ConfigStore.get('apiKey');
  let data = urlify(opts.data || {});

  if (method.toLowerCase() === 'get') {
    requestUrl += requestUrl.indexOf('?') >= 0 ? '&' : '?';
    requestUrl += data;
    data = '';
  }

  return {
    type: 'api',
    authorization: `Bearer ${apiKey}`,
    contentType: 'application/x-www-form-urlencoded',
    method,
    url: requestUrl,
    data,
    timeout,
  };
}

function getXCookiesXdmRequest(method, opts) {
  return {
    type: 'cookies',
    method,
    value: opts.value,
  };
}

function xdmRequest(type, method, url, options, success, error) {
  const requestId = genRequestId();

  if (!_apiChannel) {
    // queue requests for now and send them later when channel is initialized
    // eslint-disable-next-line prefer-rest-params
    _pendingRequests.push(Array.prototype.slice.call(arguments));
    return;
  }

  _requests[requestId] = {
    requestId, type, method, url, options, success, error,
  };

  logger.debug({
    message: `${type}Request`,
    category: `cub.${type}`,
    data: {
      type, requestId, method, url, options,
    },
  });
  siteSentry.addBreadcrumb({
    message: `${type}Request`,
    category: `cub.${type}`,
    data: {
      type, requestId, method, url, options: siteSentry.hideSecrets(options),
    },
  });

  const apiUrl = urlParse(ConfigStore.get('apiUrl'));
  const opts = options || {};

  let message;
  switch (type) {
    case 'api':
      message = getApiXdmRequest(method, url, opts, apiUrl);
      break;
    case 'cookies':
      message = getXCookiesXdmRequest(method, opts);
      break;
    default:
      break;
  }
  message.requestId = requestId;
  _apiChannel.postMessage(JSON.stringify(message), apiUrl.netloc);
}

function apiResponse(response, reqAttrs) {
  logger.debug({
    message: `${reqAttrs.type}Response`,
    category: `cub.${reqAttrs.type}`,
    data: {
      requestId: reqAttrs.requestId,
      response,
    },
  });

  if (response.body && response.body.error) {
    const { description, params } = response.body.error;
    logger.warn(description, params || '');
  }

  let callback = () => undefined; // noop
  if (response.meta && response.meta.code === 200) {
    if (reqAttrs.success) callback = reqAttrs.success;
  } else if (reqAttrs.error) callback = reqAttrs.error;

  const { method, url, options } = reqAttrs;
  siteSentry.addBreadcrumb({
    message: `${reqAttrs.type}Response`,
    category: `cub.${reqAttrs.type}`,
    data: {
      requestId: reqAttrs.requestId,
      meta: siteSentry.hideSecrets(response.meta),
      method,
      url,
      options: siteSentry.hideSecrets(options),
    },
  });
  callback(response.body, response.meta, method, url, options);
}

function xhrApiRequest(method, url, options, success, error) {
  const requestId = genRequestId();
  const type = 'xhr';

  logger.debug({
    message: `${type}Request`,
    category: `cub.${type}`,
    data: {
      requestId, method, url, options,
    },
  });
  siteSentry.addBreadcrumb({
    message: `${type}Request`,
    category: `cub.${type}`,
    data: {
      requestId, method, url, options: siteSentry.hideSecrets(options),
    },
  });

  const reqAttrs = {
    requestId, type, method, url, options, success, error,
  };

  const timeout = ConfigStore.get('apiTimeout');

  let data = (options || {}).data || {};
  data = urlify(data);

  let requestUrl = url;
  if (method.toLowerCase() === 'get') {
    requestUrl += requestUrl.indexOf('?') >= 0 ? '&' : '?';
    requestUrl += data;
    data = '';
  }

  const request = new XMLHttpRequest();
  request.open(method, requestUrl, true);
  request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  if (timeout) request.timeout = timeout;

  request.onload = () => {
    const rawResponse = request.responseText;

    let parsed;
    try {
      parsed = JSON.parse(rawResponse);
    } catch (err) {
      apiResponse(API_ERRORS.bad_response, reqAttrs);
      return;
    }
    const status = request.status;
    const rawHeaders = request.getAllResponseHeaders();
    if (!status || !rawHeaders) {
      siteSentry.captureMessage(
        'Undefined "status" or "headers" for xhrRequest.', {
          level: 'error',
          extra: { request, parsed },
        },
      );
    }
    apiResponse({
      body: parsed,
      meta: { code: status, headers: parseHeaders(rawHeaders) },
    }, reqAttrs);
  };

  const onError = () => apiResponse(API_ERRORS.cant_connect, reqAttrs);
  request.onerror = onError;
  request.ontimeout = onError;

  request.send(data);
}

function genericRequest(method, url, options, success, error) {
  if (/^https?:\/\/|^\/\//i.test(url)) { // if absolute url
    const requestUrl = urlParse(url);
    const currentUrl = urlParse(document.location.href);
    if (requestUrl.netloc === currentUrl.netloc) {
      // if request goes on the same domain use simple xhr instead xdm
      xhrApiRequest(method, url, options, success, error);
      return;
    }
  }

  xdmRequest('api', method, url, options, success, error);
}

function xdmChannelInitialized() {
  _apiChannel = getXdmFrame().contentWindow;
  while (_pendingRequests.length > 0) {
    xdmRequest.apply(this, _pendingRequests.pop());
  }
}

function gotWindowMessage(e) {
  const origin = e.origin || e.originalEvent.origin;
  if (!startsWith(ConfigStore.get('apiUrl'), origin) || !e.data) {
    return;
  }
  let message = null;
  try {
    message = JSON.parse(e.data);
  } catch (err) {
    // Stupid IE9 can't send anything other than string
  }
  if (!message || message.channel !== _apiChannelId) {
    return;
  }

  logger.debug({
    message: 'gotWindowMessage (raw)',
    category: 'cub.xdm',
    data: { message },
  });
  siteSentry.addBreadcrumb({
    message: 'gotWindowMessage (raw)',
    category: 'cub.xdm',
    data: { event: message.event },
  });

  if (message.event === 'ack') {
    xdmChannelInitialized();
  } else if (message.requestId) {
    const reqAttrs = _requests[message.requestId];
    if (!reqAttrs) {
      siteSentry.captureMessage('reqAttrs for xdmResponse not found', {
        level: 'error',
        extra: { message, _requests },
      });
      return;
    }

    if (message.event === 'connectionError') {
      apiResponse(API_ERRORS.cant_connect, reqAttrs);
    } else if (message.event === 'cookies') {
      if (reqAttrs.success) reqAttrs.success(message.value);
    } else if (message.response) {
      let parsed;
      try {
        parsed = JSON.parse(message.response);
      } catch (err) {
        parsed = API_ERRORS.bad_response;
      }
      apiResponse(parsed, reqAttrs);
    } else {
      siteSentry.captureMessage('Empty response in XDM transport.', {
        level: 'error',
        extra: { message, reqAttrs },
      });
    }
    delete _requests[message.requestId];
  } else {
    siteSentry.captureMessage('Unknown message in XDM transport.', {
      level: 'error',
      extra: { message },
    });
  }
}

function xdmInit(apiKey) {
  if (getXdmFrame()) {
    return;
  }
  const apiUrl = ConfigStore.get('apiUrl');
  const xdmUrl = `${urlParse(apiUrl).netloc}/static/channel.html`;
  const url = encodeURIComponent(window.location.href);
  let frameUrl = `${xdmUrl}?cub_xdm_e=${url}&cub_xdm_c=${_apiChannelId}`;
  if (apiKey) {
    frameUrl += `&cub_xdm_a=${apiKey}`;
  }
  // Workaround for IE. I don't create iframe element directly, because
  // stupid IE can't assign styles to it
  const tempParent = document.createElement('div');
  tempParent.innerHTML =
      `<iframe id="${_apiFrameId}" name="${_apiFrameId}" src="${frameUrl}" ` +
      'style="position: fixed; top: -2000px; left: 0px;">';
  const frame = tempParent.firstChild;
  const entry = document.getElementsByTagName('script')[0];
  entry.parentNode.insertBefore(frame, entry);
}

function isBusy() {
  return _pendingRequests.length > 0 || Object.keys(_requests).length > 0;
}

addEventListener(window, 'message', gotWindowMessage);

// API methods

const Api = {
  xdmInit,
  isBusy,
  genericRequest,

  get(url, options, success, error) {
    xdmRequest('api', 'get', url, options, success, error);
  },

  post(url, options, success, error) {
    xdmRequest('api', 'post', url, options, success, error);
  },

  // delete is a reserved word, so I called it del
  del(url, options, success, error) {
    xdmRequest('api', 'delete', url, options, success, error);
  },

  getXCookies(success) {
    xdmRequest('cookies', 'get', null, { }, success);
  },

  setXCookies(value, success) {
    xdmRequest('cookies', 'set', null, { value }, success);
  },

};

export default Api;
