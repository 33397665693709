import _ from 'underscore';
import React from 'react';
import createForm from '../../mixins/createForm';
import DataStore from '../../stores/DataStore';
import { MEMBER_BENEFITS } from '../../constants/FormIds';
import ApiActions from '../../actions/ApiActions';
import UserActions from '../../actions/UserActions';
import LabeledCheckbox from '../fields/LabeledCheckbox';
import Form from '../basic/Form';
import Button from '../basic/Button';
import Loading from '../basic/Loading';
import regflow from '../../utils/regflow';
import router from '../../router/router';
import { parseQueryString, setQueryString } from '../../utils/Utils';

const MemberBenefitsForm = createForm(MEMBER_BENEFITS, [DataStore], {
  formStateFromStores() {
    this.loadBenefits();
    const loadingComplete = (
      this.state.loadingScheduled && !DataStore.nowLoading('sitebenefits')
    );

    const subscribe = _.extend({}, this.state.subscribe);
    if (regflow.inprogress()) {
      // by default check all benefits on registration flow
      this.props.site.availableBenefits().forEach((bnf) => {
        subscribe[bnf.id] = true;
      });
    }

    return {
      loadingComplete,
      subscribe,
      benefits: this.props.site.availableBenefits(),
    };
  },

  getInitialState() {
    const flipped = {};
    const subscribe = {};

    if (regflow.inprogress()) {
      // by default check all benefits on registration flow
      this.props.site.availableBenefits().forEach((bnf) => {
        subscribe[bnf.id] = true;
      });
    } else {
      this.props.user.activeBenefits().forEach((bnf) => {
        subscribe[bnf.id] = true;
      });
    }
    return {
      benefits: this.props.site.availableBenefits(),
      subscribe,
      flipped,
      loadingScheduled: false,
      loadingComplete: false,
    };
  },

  loadBenefits() {
    window.setTimeout(() => {
      if (this.state.loadingScheduled) {
        return;
      }
      ApiActions.getAll('sitebenefits', {
        site__domain: '{referer}',
        expand: 'benefit',
      });
      this.setState({ loadingScheduled: true });
    }, 0);
  },

  subscribe(id) {
    const subscribe = _.extend({}, this.state.subscribe);
    subscribe[id] = !subscribe[id];
    this.setState({ subscribe });
  },

  onSubscriptionChange(e) {
    this.subscribe(e.target.name);
  },

  flip() {
    const flipped = _.extend({}, this.state.subscribe);
    this.setState({ flipped });
  },

  goToNextPage() {
    let nextRoute;
    if (regflow.subscriptionsAvailable(this.props)) {
      nextRoute = this.props.routes.NEWSLETTERS;
    }
    if (nextRoute) {
      router.navigate(
        setQueryString(nextRoute.url, parseQueryString()),
      );
    } else {
      if (regflow.inprogress()) {
        regflow.finish();
      }
      const redirect = router.getFirstLoginRedirect().url;
      router.navigate(parseQueryString().next || redirect);
    }
  },

  submit() {
    const subscribe = [];
    const unsubscribe = [];
    this.state.benefits.forEach((bnf) => {
      const id = bnf.id;
      if (this.state.subscribe[id]) {
        subscribe.push(id);
      } else {
        unsubscribe.push(id);
      }
    });
    UserActions.updateBenefits({
      subscribe: subscribe.join(','),
      unsubscribe: unsubscribe.join(','),
    }, MEMBER_BENEFITS);
  },

  storesSuccess(formId) {
    if (formId === MEMBER_BENEFITS) {
      this.flip();
      if (regflow.inprogress()) {
        this.goToNextPage();
      }
    }
  },

  render() {
    if (!this.state.benefits.length) {
      if (this.state.loadingScheduled) {
        if (!this.state.loadingComplete) {
          return <Loading />;
        }
        if (this.props.next) {
          router.navigate(this.props.next);
          return <Loading />;
        }
      }
      return (
        <span>
          Sorry, no benefits are available right now.
          Be sure to check back for updates.
        </span>
      );
    }

    let flippedCount = 0;
    const benefits = this.state.benefits.map((bnf) => {
      let className = 'cub-List-item Duplex Duplex--flippable Duplex--flipY';
      if (this.state.flipped[bnf.id]) {
        className += ` Duplex--delay${flippedCount}`;
        flippedCount += 1;
      }
      className += `${this.state.flipped[bnf.id] ? ' is-flipped' : ''}`;
      return (
        <li className={className} key={bnf.id}>
          <div className="Duplex-front">
            <LabeledCheckbox
              name={bnf.id}
              label="Sign me up"
              image={bnf.get('logo')}
              imageTitle={bnf.get('name')}
              imageDescription={bnf.get('summary')}
              checked={this.state.subscribe[bnf.id]}
              onChange={this.onSubscriptionChange}
            />
          </div>
          <div className="Duplex-back">
            <div className="cub-Label cub-Label--checkbox">
              <div className="cub-Label-imageBlock">
                <img className="cub-Label-image" src={bnf.get('logo')} alt="" />
              </div>
              <span className="cub-Label-title">
                {bnf.get('name')}
              </span>
              <span
                className="cub-Label-textAdditional"
                dangerouslySetInnerHTML={{ __html: bnf.get('details') }}
              />
            </div>
          </div>
        </li>
      );
    });

    const continueTxt = 'Continue registration';
    const btnLabel = regflow.inprogress() ? continueTxt : 'Redeem benefits';
    return (
      <Form
        classNameModifier="cub-Form--manageMemberBenefits"
        caption="My benefits"
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <ul className="cub-List cub-List--checklist cub-List--grid">
          {benefits}
        </ul>
        <div className="cub-FormGroup cub-FormGroup--buttons">
          <Button
            type="submit"
            isProcessing={this.state.isProcessing}
            text={btnLabel}
          />
        </div>
      </Form>
    );
  },
});

export default MemberBenefitsForm;
