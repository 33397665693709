import crypto from 'crypto';

/**
 * Inject styles on the page before any other styles,
 * so they can be easily overwritten by parent page.
 * Works for all pages, even for pages without <head>. Supports IE8.
 */

// function for getting entry point(first child) for injection
export function getInjectEntry() {
  // Inject before first <link> or <style> element on the page
  let entry = document.querySelector('link,style');
  // ..or, if there are no styles, inject before first <script>.
  // At least one script must be always present (the widget itself).
  if (!entry) {
    entry = document.querySelector('script');
  }
  return entry;
}

// inject style tag
export function injectStyle(css) {
  const entry = getInjectEntry();
  const style = document.createElement('style');
  style.type = 'text/css';
  // Split rules into separate lines for readability
  const formattedCss = css.replace(/}/g, '}\n');
  if (style.styleSheet) { // IE
    style.styleSheet.cssText = formattedCss;
  } else {
    style.appendChild(document.createTextNode(formattedCss));
  }
  entry.parentNode.insertBefore(style, entry);
  return style;
}

// inject link tag
export function injectLink(href) {
  const entry = getInjectEntry();
  const link = document.createElement('link');
  link.href = href;
  link.rel = 'stylesheet';
  entry.parentNode.insertBefore(link, entry);
  return link;
}

// inject script tag
export function injectScript(url, onLoad) {
  const entry = getInjectEntry();
  const urlHash = (
    crypto.createHash('sha256')
      .update(url)
      .digest('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '')
  );
  const scriptId = `cub-${urlHash}`;
  if (entry.parentNode.querySelector(`#${scriptId}`)) {
    return;
  }
  const script = document.createElement('script');
  script.src = url;
  script.id = scriptId;
  if (typeof onLoad === 'function') {
    script.onload = onLoad;
  }
  entry.parentNode.insertBefore(script, entry);
}
