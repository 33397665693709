import PropTypes from 'prop-types';
import React from 'react';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';
import MultiStepLogin from './MultiStepLogin';
import PasswordReset from './PasswordReset';
import PasswordResetDone from './PasswordResetDone';
import PasswordResetLinkSent from './PasswordResetLinkSent';
import IdpConfirmation from './IdpConfirmation';
import IdpConfirmationRequired from './IdpConfirmationRequired';
import SsoCallback from './SsoCallback';

export default class Login extends React.Component {
  render() {
    const args = this.props.args;
    const routes = this.props.routes;
    let view;

    switch (this.props.route) {
      case routes.LOGIN:
        view = <MultiStepLogin routes={routes} />;
        break;

      case routes.FORGOT_PASSWORD:
        view = <ForgotPasswordForm routes={routes} />;
        break;

      case routes.PASSWORD_RESET_LINK_SENT:
        view = <PasswordResetLinkSent routes={routes} />;
        break;

      case routes.PASSWORD_RESET_DONE:
        view = <PasswordResetDone routes={routes} />;
        break;

      case routes.RESET_PASSWORD:
        view = <PasswordReset routes={routes} />;
        break;

      case routes.IDP_CONFIRMATION_REQUIRED:
        view = <IdpConfirmationRequired />;
        break;

      case routes.IDP_CONFIRMATION:
        view = <IdpConfirmation args={args} routes={routes} />;
        break;

      case routes.SSO_CALLBACK:
        view = <SsoCallback args={args} routes={routes} />;
        break;

      default:
    }

    return <div className="cub-Login">{view}</div>;
  }
}

Login.propTypes = {
  args: PropTypes.array,
  route: PropTypes.object,
  routes: PropTypes.object,
};
