import PropTypes from 'prop-types';
import React from 'react';

import Button from '../basic/Button';
import Form from '../basic/Form';
import Address from '../fields/Address';
import City from '../fields/City';
import Country from '../fields/Country';
import OrganizationEmployees from '../fields/OrganizationEmployees';
import OrganizationName from '../fields/OrganizationName';
import Phone from '../fields/Phone';
import State from '../fields/State';
import Tag from '../fields/Tag';
import PostalCode from '../fields/PostalCode';

import FormState from '../../forms/FormState';

export default class OrganizationAddForm extends React.Component {
  constructor(props) {
    super(props);
    this.form = new FormState((data) => this.setState(data), () => this.state);
    const { isProcessing, errors } = this.form.getInitialState();
    this.state = {
      isProcessing,
      errors,
      name: props.name || '',
      tag: Tag.options[props.tag] ? props.tag : '',
      employees: '',
      country: null,
      state: null,
      city: '',
      address: '',
      postal_code: '',
      phone: '',
    };

    this.onCountryChange = this.onCountryChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.refs.name.focus();
  }

  onCountryChange(...args) {
    // because different countries have different states
    this.setState({ state: null });
    this.form.onInputChange(...args);
  }

  submit() {
    const { submited, succeeded, failed } = this.form;
    this.props.onSave({
      name: this.state.name,
      tags: this.state.tag,
      employees: this.state.employees,
      country: (this.state.country && this.state.country.id) || '',
      state: (this.state.state && this.state.state.id) || '',
      city: this.state.city,
      address: this.state.address,
      postal_code: this.state.postal_code,
      phone: this.state.phone,
    }, { submited, succeeded, failed });
  }

  render() {
    const country = typeof this.state.country === 'object' ?
      this.state.country :
      null;

    return (
      <Form
        classNameModifier="cub-Form--organizationAdd"
        error={this.state.errors.topError()}
        ref="form"
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <OrganizationName
          value={this.state.name}
          ref="name"
          required
          error={this.state.errors.fieldError('name')}
          onChange={this.form.onInputChange}
        />

        <Tag
          label="Organization type"
          value={this.state.tag}
          error={this.state.errors.fieldError('tags')}
          onChange={this.form.onInputChange}
        />

        <OrganizationEmployees
          value={this.state.employees}
          error={this.state.errors.fieldError('employees')}
          onChange={this.form.onInputChange}
        />

        <Country
          value={this.state.country}
          required
          error={this.state.errors.fieldError('country')}
          onChange={this.onCountryChange}
        />

        <State
          value={this.state.state}
          error={this.state.errors.fieldError('state')}
          country={country}
          placeholder={
            country ? 'Select state/province' : 'Please select country first'
          }
          onChange={this.form.onInputChange}
        />

        <City
          value={this.state.city}
          error={this.state.errors.fieldError('city')}
          onChange={this.form.onInputChange}
        />

        <Address
          value={this.state.address}
          error={this.state.errors.fieldError('address')}
          onChange={this.form.onInputChange}
        />

        <PostalCode
          value={this.state.postal_code}
          error={this.state.errors.fieldError('postal_code')}
          onChange={this.form.onInputChange}
        />

        <Phone
          value={this.state.phone}
          error={this.state.errors.fieldError('phone')}
          onChange={this.form.onInputChange}
        />

        <div className="cub-FormGroup cub-FormGroup--buttons">
          <Button
            type="submit"
            isProcessing={this.state.isProcessing}
            text="Save"
          />

          {!this.props.hideCancel && (
            <Button
              classNameModifier="cub-Button--cancel"
              text="Cancel"
              onClick={this.props.onCancel}
            />
          )}
        </div>
      </Form>
    );
  }
}

OrganizationAddForm.propTypes = {
  name: PropTypes.string,
  hideCancel: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  tag: PropTypes.string,
};
