import PropTypes from 'prop-types';
import React from 'react';
import NavLink from '../basic/NavLink';
import CaptionLarge from '../basic/CaptionLarge';
import Text from '../basic/Text';

export default class PasswordResetDone extends React.Component {
  render() {
    return (
      <div className={'cub-InfoBlock' +
          ' cub-InfoBlock--passwordResetDone isSuccess'}
      >
        <CaptionLarge>Password reset</CaptionLarge>
        <Text>Your password was reset successfully.</Text>
        <NavLink className="cub-Button" route={this.props.routes.HOME}>
          <span className="cub-Button-icon" />
          OK
        </NavLink>
      </div>
    );
  }
}

PasswordResetDone.propTypes = {
  routes: PropTypes.object,
};
