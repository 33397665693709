import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import CubApi from '../../api/CubApi';
import router from '../../router/router';
import { getFromLocalStorage, putToLocalStorage, storageSupported }
  from '../../storage/storage';
import { Organization, State as StateModel } from '../../stores/DataModels';
import ApiActions from '../../actions/ApiActions';
import NavActions from '../../actions/NavActions';
import UserActions from '../../actions/UserActions';
import Caption from '../basic/Caption';
import Country from '../fields/Country';
import CustomControl from '../fields/CustomControl';
import FormErrors from '../FormErrors';
import Button from '../basic/Button';
import Form from '../basic/Form';
import Markdown from '../fields/Markdown';
import NavLink from '../basic/NavLink';
import Text from '../basic/Text';
import LabeledCheckbox from '../fields/LabeledCheckbox';
import LabeledCheckboxGroup from '../fields/LabeledCheckboxGroup';
import LabeledCombobox from '../fields/LabeledCombobox';
import LabeledMultiselect from '../fields/LabeledMultiselect';
import LabeledRadioGroup from '../fields/LabeledRadioGroup';
import LabeledInput from '../fields/LabeledInput';
import LabeledInputGroup from '../fields/LabeledInputGroup';
import LabeledSelect from '../fields/LabeledSelect';
import LabeledTextarea from '../fields/LabeledTextarea';
import LabeledImageControl from '../fields/LabeledImageControl';
import OrganizationSubform from '../fields/OrganizationSubform';
import OrganizationSearch from '../fields/OrganizationSearch';
import OrganizationEmployees from '../fields/OrganizationEmployees';
import Position from '../fields/Position';
import RegisterMeLabeledCheckbox from '../fields/RegisterMeLabeledCheckbox';
import State from '../fields/State';
import {
  addEventListener, removeEventListener, logger, throwSafely,
  waitFor, hasOwnProperty, siteSentry, parseQueryString,
  setQueryString,
} from '../../utils/Utils';
import * as Cookies from '../../utils/Cookies';
import regflow from '../../utils/regflow';
import lytics from '../../utils/lytics';

// Describes default expands for actions.
// It could be useful for cases when generic form changes
// data related to widget, i added user expand for leads action
// because leads will update user information, so with this expand
// user data will be automatically updated.
const defaultPostExpand = {
  leads: 'user__membership__positions,' +
        'user__membership__organization,' +
        'membership__positions',
};

function ImplicitNameError(fieldType) {
  return new Error(
    `GenericForm: "${fieldType}" field MUST NOT have "name" in config. ` +
    `It has implicit "name" = "${fieldType}"`,
  );
}

function checkImplicitName(field) {
  if (field.name && (field.name !== field.type)) {
    throwSafely(ImplicitNameError(field.type));
  }
}

const REG_SKIPPED = 'REG_SKIPPED';
const REG_SUCCESS = 'REG_SUCCESS';
const REG_FAILED = 'REG_FAILED';
const REG_ALREADY = 'REG_ALREADY';

export default class GenericForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState(props);
    // _isSingleColumnLayout will be defined after componentDidMount()
    this.state._isSingleColumnLayout = null;
    this.internalStateKeys = ['_isProcessing', '_errors',
      '_isSingleColumnLayout', '_successMsgState', '_register_me',
      '_suppressionAllowed', '_wasFormSubmitted'];
    this.validate = this.validate.bind(this);
    this.submit = this.submit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.onStateOptionsLoaded = this.onStateOptionsLoaded.bind(this);
    this.onOrganizationChange = this.onOrganizationChange.bind(this);
    this.onOrganizationSearch = this.onOrganizationSearch.bind(this);
    this.showSuccessMsg = this.showSuccessMsg.bind(this);
    this.onEmailFilled = this.onEmailFilled.bind(this);
    this.disallowSuppression = this.disallowSuppression.bind(this);
    this.setValues = this.setValues.bind(this);
    this.onWindowResize = _.throttle(this.onWindowResize.bind(this), 300);
  }

  componentDidMount() {
    this.onWindowResize();
    addEventListener(window, 'resize', this.onWindowResize);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState(
        (prevSate) => this.mergeStateFromUser(prevSate, nextProps.user),
      );
    }
  }

  componentWillUnmount() {
    removeEventListener(window, 'resize', this.onWindowResize);
  }

  onInputChange(event, afterStateUpdate) {
    // can accept either native input change event,
    // or {name: <name>, value: <value>} object
    const evt = event.target || event;
    let value = (evt.type === 'checkbox' && !evt.checked) ? '' : evt.value;
    // transform Multiselect array of values to String
    if (evt.type === 'multiselect' && Array.isArray(value)) {
      value = value.join(',');
    }
    this.onFieldChange(evt.name, value, afterStateUpdate);
  }

  onFieldChange(name, value, afterStateUpdate) {
    if (this.state._errors.fieldError(name)) {
      this.state._errors.clearFieldError(name);
    }
    const change = {};
    change[name] = value;
    this.setState(change, afterStateUpdate || _.noop);
  }

  onCountryChange(...args) {
    // because different countries have different states
    if (this.state.state) {
      this.setState({ state: null });
    }
    this.onInputChange(...args);
  }

  onEmailFilled() {
    if (!this.isFormSuppressionAllowed()) {
      return;
    }
    this.setState((prevState) => ({
      _wasFormSubmitted: this.wasFormSubmittedWithEmail(prevState.email),
    }));
  }

  onStateOptionsLoaded(options) {
    const state = this.state.state;
    if (StateModel.alike(state)) {
      return;
    }
    const stateObj = _.find(options, (s) => s.name === state);
    if (stateObj) {
      this.setState({ state: stateObj });
    }
  }

  onOrganizationChange(event, afterStateUpdate, { resetPosition = true } = {}) {
    if (resetPosition) {
      this.setState({ position: null });
    }
    this.onInputChange(event, afterStateUpdate);
  }

  onOrganizationSearch(event) {
    const { name, value } = event;
    if (Organization.alike(value)) {
      const org = value;
      // set organization name as input state
      this.onFieldChange(name, org.name);
    } else {
      this.onFieldChange(name, value);
    }

    const orgSearchField = _.find(
      this.getFieldComponents(),
      (component) => component.props.fieldType === 'organization_search',
    );
    const { onSearchResult } = orgSearchField.props;
    if (_.isFunction(onSearchResult)) {
      try {
        onSearchResult(value, this.setValues);
      } catch (err) {
        throwSafely(err);
      }
    }
  }

  onSuccess(formData, response) {
    const isLogged = Boolean(this.props.user);
    const toRegister = this.state._register_me && !isLogged;
    const regMaxTimeout = this.props.registerMe.maxTimeout || 2000;
    const isFormSuppressionAllowed = this.isFormSuppressionAllowed();

    // remember email + form pair if form settings allows supression
    if (isFormSuppressionAllowed && storageSupported('localStorage')) {
      let submitedForms = getFromLocalStorage('submittedForms');
      if (!submitedForms) {
        submitedForms = {};
      }
      const key = this.props.formId;
      if (!(key in submitedForms) || !Array.isArray(submitedForms[key])) {
        submitedForms[key] = [];
      }
      if (submitedForms[key].indexOf(this.state.email) === -1) {
        submitedForms[this.props.formId].push(this.state.email);
      }
      putToLocalStorage({ submittedForms: submitedForms });
    }

    const redirectUrl = (to) => (_.isFunction(to) ? to(response) : to);
    const redirect = (url) => NavActions.urlChangeRequested(url);
    const registration = toRegister ? this.register() : Promise.resolve({
      status: REG_SKIPPED, url: null, updateUser: null,
    });

    let onSuccessResult;
    if (typeof this.props.onSuccess === 'function') {
      try {
        onSuccessResult = this.props.onSuccess(
          formData, ReactDOM.findDOMNode(this.refs.form), response,
        );
      } catch (err) {
        logger.debug({
          message: 'GenericForm: exception in onSuccess callback',
          category: 'cub.log',
          data: { props: this.props },
        });
        siteSentry.addBreadcrumb({
          message: 'GenericForm: exception in onSuccess callback',
          category: 'cub.log',
          data: { props: this.props },
        });
        siteSentry.captureException(err);
      }
    }

    const waitLytics = waitFor(this.sendLyticsEvent(), regMaxTimeout);
    const waitOnSuccess = waitFor(onSuccessResult, regMaxTimeout);

    return Promise.all([
      registration,
      waitOnSuccess,
      waitLytics,
    ]).then(([{ status, updateUser }]) => {
      if (this.props.registerMe.respectRedirect && this.props.redirect) {
        // skip EXPERIENCE or REGISTRATION pages and redirect to destination
        // if user exists - allow to login and then redirect
        const redirectOpts = _.extend(
          { requireLogin: true },
          this.props.redirect,
        );

        if (status === REG_ALREADY && redirectOpts.requireLogin) {
          const email = this.state.email;
          const nextUrl = setQueryString(
            this.props.routes.LOGIN.makeUrl(),
            _.extend(parseQueryString(), {
              username: email,
              next: redirectUrl(redirectOpts.to),
            }),
          );
          this.showSuccessMsg({
            type: 'reg-already',
            proceed: _.once(() => redirect(nextUrl)),
            ctx: { email },
          });
          return;
        }

        const nextUrl = redirectUrl(redirectOpts.to);
        const silent = router.pageReloadRequired(nextUrl);
        const proceed = _.once(() => {
          if (_.isFunction(updateUser)) updateUser(silent);
          redirect(nextUrl);
        });

        if (redirectOpts.skipSuccessMsg) {
          proceed();
          return;
        }
        this.showSuccessMsg({
          type: 'continue',
          proceed,
          ctx: { extraMsg: redirectOpts.message },
        });
        return;
      }

      if (status === REG_SKIPPED) {
        if (this.props.redirect) {
          const { to, message, skipSuccessMsg } = this.props.redirect;
          const nextUrl = redirectUrl(to);
          const proceed = _.once(() => redirect(nextUrl));
          if (skipSuccessMsg) {
            proceed();
            return;
          }
          this.showSuccessMsg({
            type: 'continue',
            proceed,
            ctx: { extraMsg: message },
          });
          return;
        }

        if (!_.isEmpty(this.props.registerMe) && !isLogged) {
          // registerMe feature was active but checkbox unchecked
          // motivate user to register if he is not loggined user
          const nextUrl = this.props.routes.REGISTER;
          this.showSuccessMsg({
            type: 'reg-motivate',
            proceed: _.once(() => redirect(nextUrl)),
          });
          return;
        }
        this.showSuccessMsg({ type: 'simple' });
        return;
      }

      if (_.isEmpty(this.props.routes)) {
        this.showSuccessMsg({ type: 'simple' });
        siteSentry.captureMessage(
          '"Register Me" feature: props.routes didn\'t load, ' +
          'falling back to simple success message.', {
            level: 'error',
            extra: { props: this.props },
          },
        );
        return;
      }

      if (status === REG_SUCCESS) {
        const nextUrl = this.props.routes.EXPERIENCE;
        const silent = router.pageReloadRequired(nextUrl);
        this.showSuccessMsg({
          type: 'reg-success',
          proceed: _.once(() => {
            regflow.start();
            updateUser(silent);
            redirect(nextUrl);
          }),
        });
      } else if (status === REG_ALREADY) {
        // The option "requireLogin" is a property of the "redirect". No
        // need to handle it here.
        const email = this.state.email;
        const nextUrl = setQueryString(
          this.props.routes.LOGIN.makeUrl(),
          _.extend(parseQueryString(), {
            username: email,
          }),
        );
        this.showSuccessMsg({
          type: 'reg-already',
          proceed: _.once(() => redirect(nextUrl)),
          ctx: { email },
        });
      } else if (status === REG_FAILED) {
        // if registration api call failed - redir to 'register' page
        const nextUrl = this.props.routes.REGISTER;
        this.showSuccessMsg({
          type: 'reg-motivate',
          proceed: _.once(() => redirect(nextUrl)),
        });
      }
    });
  }

  onError(formData, response) {
    const _errors = new FormErrors(response);
    this.setState({ _isProcessing: false, _errors });
    if (typeof this.props.onError === 'function') {
      this.props.onError(
        _errors.topError(),
        _errors.fieldErrors(),
        formData,
        ReactDOM.findDOMNode(this.refs.form),
      );
    }
  }

  /**
   * Forms will have a single column layout if:
   * 1) there is only one fieldset
   * 2) there is no predefined columnWidth values for fieldsets
   * 3) a width of the parent widget container is narrower than
   *    the "responsiveBreakpoint" property, that is actually min-width
   */
  onWindowResize() {
    const parentWidth = ReactDOM.findDOMNode(this).parentNode.offsetWidth;
    const fieldsets = this.props.fieldsets;
    const minWidth = this.props.responsiveBreakpoint;

    const _isSingleColumnLayout =
      (fieldsets && fieldsets.length <= 1 && /* 1 */
      !_.some(fieldsets, 'columnWidth')) || /* 2 */
      parentWidth < minWidth; /* 3 */
    if (this.state._isSingleColumnLayout !== _isSingleColumnLayout) {
      this.setState({ _isSingleColumnLayout });
    }
  }

  setValues(values) {
    const fields = Object.keys(_.omit(this.state, this.internalStateKeys));
    Object.keys(values).forEach((key) => {
      if (!_.contains(fields, key)) {
        throwSafely(new Error(
          `GenericForm: "${key}" was not found in "fields". ` +
          `Can't set it to "${values[key]}"`,
        ));
        return;
      }
      this.onFieldChange(key, values[key]);
    });
  }

  getPardotCookie() {
    const cookieObj = Cookies.parse(document.cookie);
    return _.pairs(cookieObj)
      // eslint-disable-next-line no-unused-vars
      .filter(([key, val]) => key.indexOf('visitor_id') === 0)
      .map(([key, val]) => Cookies.stringify(key, val, null))
      .join('; ');
  }

  getFieldComponents() {
    const fields = Object.keys(_.omit(this.state, this.internalStateKeys));
    return fields.filter(
      (field) => (
        field !== 'form' && // a reserved ref name for the whole form
        !!this.refs[field] // field is presented on the form (exclude hidden)
      ),
    ).map((field) => this.refs[field]);
  }

  isFormSuppressionAllowed(props) {
    const p = props || this.props;
    return p.formId && p.suppressDuplicates && p.redirect.to;
  }

  disallowSuppression() {
    this.setState({ _suppressionAllowed: false });
  }

  sendLyticsEvent() {
    return new Promise((resolve) => {
      const data = _.omit(_.pick(this.state, [
        'email', 'first_name', 'last_name',
        'organization', 'position', 'state',
        'organization_name', 'member_position',
        'zip', 'phone', 'city',
      ]), (value) => _.isNull(value));

      const { origin, pathname } = window.document.location;
      const payload = {
        form_id: origin + pathname,
        email: data.email || '',
        firstName: data.first_name || '',
        lastName: data.last_name || '',
        zipCode: data.zip || '',
        phone: data.phone || '',
        city: data.city || '',
        position: data.position || data.member_position || '',
      };

      if (Organization.alike(data.organization)) {
        payload.department = data.organization.name;
      } else {
        payload.department = data.organization_name || data.organization || '';
      }

      if (StateModel.alike(data.state)) {
        payload.state = data.state.name;
      } else {
        payload.state = data.state || '';
      }

      lytics.send(payload, resolve);
    });
  }

  register() {
    return new Promise((resolve) => {
      const data = _.omit(_.pick(this.state, [
        'email', 'first_name', 'last_name',
        'middle_name', 'organization', 'position',
      ]), (value) => _.isNull(value));

      if (hasOwnProperty(data, 'organization')) {
        const org = data.organization;
        if (Organization.alike(org)) {
          data.organization = org.id;
        }
      }

      const registerMe = this.props.registerMe;
      // TODO: Rename this prop to "skipSubscribe".
      if (hasOwnProperty(registerMe, 'skip_subscribe')) {
        _.extend(data, {
          skip_subscribe: registerMe.skip_subscribe,
        });
      }

      const params = {
        data: _.extend({ expand: UserActions.USER_EXPAND }, data),
      };
      CubApi.post(
        'user/register-without-password',
        params,
        (response, meta) => { // success
          // can't update user right here because it can lead to page reload
          const updateUser = (silent) => UserActions.userUpdatedWithoutForm(
            response, meta, silent,
          );
          resolve({ status: REG_SUCCESS, updateUser });
        },
        (response) => { // fail
          const errors = new FormErrors(response);
          const emailError = errors.fieldError('email');
          if (emailError && emailError.indexOf('already') !== -1) {
            resolve({ status: REG_ALREADY });
            return;
          }
          siteSentry.captureMessage(
            '"Register Me" feature: registration API call failed', {
              level: 'error',
              extra: { response },
            },
          );
          resolve({ status: REG_FAILED });
        },
      );
    });
  }

  showSuccessMsg({ type, proceed, ctx } = {}) {
    this.setState((prevState) => {
      const msgState = _.clone(prevState._successMsgState);
      return {
        _successMsgState: _.extend(msgState, {
          show: true,
          type: type || 'simple',
          proceed: proceed || _.noop,
          ctx: ctx || {},
        }),
      };
    });
  }

  wasFormSubmittedWithEmail(email) {
    if (!storageSupported('localStorage')) {
      return false;
    }
    const submittedForms = getFromLocalStorage('submittedForms');
    if (!submittedForms) {
      return false;
    }
    const key = this.props.formId;
    return (key in submittedForms) && submittedForms[key].indexOf(email) !== -1;
  }

  initialState(props) {
    const state = {
      _isProcessing: false,
      _errors: new FormErrors(),
      _successMsgState: { show: false },
    };
    props.fieldsets.forEach((fieldset) => {
      if (fieldset && Array.isArray(fieldset.fields)) {
        fieldset.fields.forEach((field) => {
          if (field.type === 'checkbox') {
            state[field.name] = field.checked ? field.value : '';
          } else if (field.type === 'checkboxgroup') {
            state[field.name] = Array.isArray(field.value) ? field.value : [];
          } else if (field.type === 'inputgroup') {
            state[field.name] = _.isObject(field.value) ? field.value : {};
          } else if (field.type === 'multiselect') {
            // transform Multiselect array of values to String
            state[field.name] = (field.value && Array.isArray(field.value)) ?
              _.pluck(field.value, field.valueField).join(',') : '';

          // fields 'organization', 'position', 'country' and 'state'
          // MUST NOT have explicit names in fieldset config so
          // we can't use field.name for this fields
          } else if (field.type === 'organization') {
            state.organization = field.value || '';
            checkImplicitName(field);
          } else if (field.type === 'position') {
            state.position = field.value || '';
            checkImplicitName(field);
          } else if (field.type === 'country') {
            state.country = field.value || '';
            checkImplicitName(field);
          } else if (field.type === 'state') {
            state.state = field.value || '';
            checkImplicitName(field);
          } else {
            state[field.name] = field.value || '';
          }
        });
      }
    });
    const { checked, enforced } = props.registerMe;
    state._register_me = (enforced || checked) ? 'on' : '';
    state._suppressionAllowed = this.isFormSuppressionAllowed(props);
    return props.user ? this.mergeStateFromUser(state, props.user) : state;
  }

  mergeStateFromUser(state, user) {
    const membership = user.getLastMembership();
    const newState = _.extend({}, state);
    const organization = membership ? membership.get('organization') : null;
    const position = membership ? membership.getLastPosition() : null;
    const isSuppressionAllowed = state._suppressionAllowed || false;
    const profileAttrs = _.extend({
      email: user.get('email'),
      organization: (organization || {}).attributes || null,
      position: position ? position.get('position') : '',
    }, user.profileAttrs());
    Object.keys(profileAttrs).forEach((k) => {
      if (hasOwnProperty(newState, k) && profileAttrs[k] && !newState[k]) {
        if (((k === 'organization') && _.isNull(newState[k])) ||
          ((k === 'position') && _.isNull(newState[k]))) {
          // Do not override if explicitly set to null
        } else {
          newState[k] = profileAttrs[k];
        }
      }
    });
    if (isSuppressionAllowed && newState.email) {
      // eslint-disable-next-line no-underscore-dangle
      newState._wasFormSubmitted = this.wasFormSubmittedWithEmail(
        newState.email,
      );
    } else {
      // eslint-disable-next-line no-underscore-dangle
      newState._wasFormSubmitted = false;
    }
    return newState;
  }

  formData() {
    const data = _.omit(this.state, (value) => _.isNull(value));
    // FIXME? checked by field.name not field.type
    if (hasOwnProperty(data, 'country')) {
      data.country = (data.country && data.country.name) || data.country || '';
    }
    // FIXME? checked by field.name
    if (hasOwnProperty(data, 'state')) {
      data.state = (data.state && data.state.name) || data.state || '';
    }
    // FIXME? checked by field.name
    if (hasOwnProperty(data, 'organization')) {
      const org = data.organization;
      if (Organization.alike(org)) {
        data.organization = org.id;
      }
    }
    const arrayData = {};
    const objData = {};
    const omitKeys = this.internalStateKeys.slice();
    Object.keys(data).forEach((k) => {
      if (Array.isArray(data[k])) {
        arrayData[`${k}[]`] = data[k];
        omitKeys.push(k);
      } else if (_.isObject(data[k]) && omitKeys.indexOf(k) <= 0) {
        _.defaults(objData, data[k]);
        omitKeys.push(k);
      }
    });
    return _.extend(
      _.omit(...[data].concat(omitKeys)),
      arrayData,
      objData,
    );
  }

  validate() {
    const errors = {};
    this.getFieldComponents().forEach((fieldComponent) => {
      // Some fields may have no refs and no validation (i.e. hidden field)
      const validateField = fieldComponent.validate;
      let errorMessage = null;
      if (typeof validateField === 'function') {
        errorMessage = validateField();
        if (errorMessage) {
          errors[fieldComponent.props.name] = errorMessage;
        }
      }
    });
    if (_.isEmpty(errors)) {
      return null;
    }
    this.onError(this.formData(), {
      error: {
        code: 400,
        description: 'The following parameters are invalid: ' +
          `${Object.keys(errors).join(', ')}`,
        params: errors,
      },
    });
    return 'ERROR';
  }

  submit(e) {
    if (e) {
      e.preventDefault();
    }
    const errorMessage = this.validate();
    if (errorMessage) {
      return;
    }
    this.setState({ _isProcessing: true, _errors: new FormErrors() });
    this.submitSubforms(
      () => this.submitData(),
      () => this.setState({ _isProcessing: false }),
    );
  }

  submitSubforms(success, error) {
    const organizationField = _.find(
      this.getFieldComponents(),
      (component) => component.props.fieldType === 'organization',
    );

    if (organizationField) {
      const { name } = organizationField.props;
      const value = this.state[name];
      if (_.isNull(value)) {
        if (organizationField.isEmpty()) {
          this.onOrganizationChange(
            { name, value: '' }, success, { resetPosition: false },
          );
        } else {
          organizationField.submit((organization) => {
            this.onOrganizationChange(
              { name, value: organization },
              success,
              { resetPosition: false },
            );
          },
          error);
        }
      } else if (Organization.alike(value)) {
        if (!organizationField.isEmpty()) {
          // Update an existing organization with required fields
          const orgData = organizationField.updateFormData();
          this.setState(orgData, success);
        } else {
          success();
        }
      } else {
        logger.warn("'value' must be Organization model or " +
              'null (for subform submit)');
      }
    } else {
      success();
    }
  }

  submitData() {
    const formData = _.extend(
      { expand: defaultPostExpand[this.props.action] || '' },
      this.formData(),
    );
    const cookie = this.getPardotCookie();
    if (cookie) {
      formData.cookie = cookie;
    }

    ApiActions.genericPost(
      this.props.action,
      formData,
      this.onSuccess.bind(this, formData),
      this.onError.bind(this, formData),
    );
    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(formData, ReactDOM.findDOMNode(this.refs.form));
    }
  }

  fieldElement(field) {
    const classNameModifier = `cub-FormControl--${field.name}`;
    switch (field.type) {
      case 'email':
        return (
          <LabeledInput
            classNameModifier={classNameModifier}
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            error={this.state._errors.fieldError(field.name)}
            value={this.state[field.name]}
            onChange={this.onInputChange}
            onBlur={this.onEmailFilled}
            required={field.required}
            placeholder={field.placeholder}
            maxLength={field.max_length}
          />
        );
      case 'text':
        return (
          <LabeledInput
            classNameModifier={classNameModifier}
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            error={this.state._errors.fieldError(field.name)}
            value={this.state[field.name]}
            onChange={this.onInputChange}
            required={field.required}
            placeholder={field.placeholder}
            maxLength={field.max_length}
          />
        );

      case 'textarea':
        return (
          <LabeledTextarea
            classNameModifier={classNameModifier}
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            error={this.state._errors.fieldError(field.name)}
            value={this.state[field.name]}
            onChange={this.onInputChange}
            required={field.required}
            placeholder={field.placeholder}
            maxLength={field.max_length}
          />
        );

      case 'select':
        return (
          <LabeledSelect
            classNameModifier={classNameModifier}
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            options={_.object(field.options)}
            error={this.state._errors.fieldError(field.name)}
            value={this.state[field.name]}
            onChange={this.onInputChange}
            required={field.required}
          />
        );

      case 'combobox':
        return (
          <LabeledCombobox
            classNameModifier={classNameModifier}
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            data={field.options}
            error={this.state._errors.fieldError(field.name)}
            value={this.state[field.name]}
            onChange={this.onInputChange}
            required={field.required}
            placeholder={field.placeholder}
            suggest
            filter="startsWith"
          />
        );

      case 'multiselect':
        return (
          <LabeledMultiselect
            classNameModifier={classNameModifier}
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            data={field.options}
            valueField={field.valueField}
            textField={field.textField}
            error={this.state._errors.fieldError(field.name)}
            value={field.value}
            onChange={this.onInputChange}
            required={field.required}
            placeholder={field.placeholder}
            filter="startsWith"
          />
        );

      case 'checkbox':
        // TODO: support required and error attributes
        return (
          <LabeledCheckbox
            classNameModifier={classNameModifier}
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            value={field.value}
            checked={Boolean(this.state[field.name])}
            onChange={this.onInputChange}
            error={this.state._errors.fieldError(field.name)}
            required={field.required}
            imageDescription={field.image_description || false}
          />
        );

      case 'checkboxgroup':
        return (
          <LabeledCheckboxGroup
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            options={_.object(field.options)}
            columns={field.columns}
            error={this.state._errors.fieldError(field.name)}
            value={this.state[field.name]}
            onChange={this.onInputChange}
            required={field.required}
          />
        );

      case 'radiogroup':
        return (
          <LabeledRadioGroup
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            options={_.object(field.options)}
            error={this.state._errors.fieldError(field.name)}
            value={this.state[field.name]}
            onChange={this.onInputChange}
            required={field.required}
          />
        );

      case 'inputgroup':
        return (
          <LabeledInputGroup
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            options={_.object(field.options)}
            error={this.state._errors.fieldError(field.name)}
            value={this.state[field.name]}
            onChange={this.onInputChange}
            required={field.required}
          />
        );

      case 'hidden':
        return (
          <input
            key={field.name}
            type="hidden"
            name={field.name}
            value={this.state[field.name]}
          />
        );

      case 'country': { // <- curly braces for variable scoping
        const fieldName = 'country';
        return (
          <Country
            key={fieldName}
            name={fieldName}
            ref={fieldName}
            label={field.label}
            value={this.state[fieldName]}
            error={this.state._errors.fieldError(fieldName)}
            onChange={this.onCountryChange}
            required={field.required}
            restrictToKnown={field.restrictToKnown}
          />
        );
      }

      case 'state': { // <- curly braces for variable scoping
        const fieldName = 'state';
        const country = hasOwnProperty(this.state, 'country') ?
          this.state.country :
          field.country || 'United States';
        return (
          <State
            key={fieldName}
            name={fieldName}
            ref={fieldName}
            label={field.label}
            country={country}
            value={this.state[fieldName]}
            error={this.state._errors.fieldError(fieldName)}
            onChange={this.onInputChange}
            onOptionsLoaded={this.onStateOptionsLoaded}
            required={field.required}
            restrictToKnown={field.restrictToKnown}
          />
        );
      }

      case 'organization_size':
        return (
          <OrganizationEmployees
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            value={this.state[field.name]}
            error={this.state._errors.fieldError(field.name)}
            onChange={this.onInputChange}
            required={field.required}
          />
        );

      case 'organization_search':
        return (
          <OrganizationSearch
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            value={this.state[field.name]}
            error={this.state._errors.fieldError(field.name)}
            onChange={this.onOrganizationSearch}
            required={field.required}
            placeholder={field.placeholder}
            fieldType={field.type}
            onSearchResult={field.onSearchResult}
            nothingFoundMsg={field.nothingFoundMsg}
          />
        );

      case 'organization': { // <- curly braces for variable scoping
        const fieldName = 'organization';
        return (
          <OrganizationSubform
            key={fieldName}
            name={fieldName}
            ref={fieldName}
            label={field.label}
            value={this.state[fieldName]}
            error={this.state._errors.fieldError(fieldName)}
            onChange={this.onOrganizationChange}
            required={field.required}
            placeholder={field.placeholder}
            fieldType={field.type}
            requirePostalCode={field.requirePostalCode}
          />
        );
      }

      case 'position': { // <- curly braces for variable scoping
        const fieldName = 'position';

        let orgTags = null;
        // FIXME? checked by field.name
        if (Organization.alike(this.state.organization)) {
          const org = this.state.organization;
          if (org.tags.length) {
            orgTags = org.tags;
          }
        }

        const siteTags = this.props.site ? this.props.site.get('tags') : null;
        const tags = orgTags || field.tags || siteTags;
        return (
          <Position
            key={fieldName}
            name={fieldName}
            ref={fieldName}
            label={field.label}
            tags={tags}
            value={this.state[fieldName]}
            error={this.state._errors.fieldError(fieldName)}
            onChange={this.onInputChange}
            required={field.required}
            placeholder={field.placeholder}
          />
        );
      }

      case 'custom_control':
        return (
          <CustomControl
            classNameModifier={classNameModifier}
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            error={this.state._errors.fieldError(field.name)}
            value={this.state[field.name]}
            onChange={this.onInputChange}
            required={field.required}
            placeholder={field.placeholder}
            maxLength={field.max_length}
            onLoad={field.onload}
            onUnload={field.onunload}
          />
        );

      case 'markdown':
        return (
          <Markdown
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            error={this.state._errors.fieldError(field.name)}
            value={this.state[field.name]}
            onChange={this.onInputChange}
            required={field.required}
            placeholder={field.placeholder}
            maxLength={field.max_length}
          />
        );

      case 'image':
        return (
          <LabeledImageControl
            key={field.name}
            name={field.name}
            ref={field.name}
            label={field.label}
            error={this.state._errors.fieldError(field.name)}
            value={this.state[field.name]}
            onChange={this.onInputChange}
            required={field.required}
            size={field.size}
          />
        );

      default:
        return null;
    }
  }

  renderDuplicatedDlg(email, url) {
    return (
      <div className="cub-Row">
        <div className="cub-Row-column cub-Row-column--100 cub-Util-textCenter">
          <Caption isInsideForm>
            Oh! Looks like you already entered your info!
          </Caption>
          {url && (
            <Text>
              Maybe you just need the link to the course? Here you go:{' '}
              <a
                className="cub-Link"
                href={url}
                rel="noreferrer"
                target="_blank"
                title={url}
              >
                link
              </a>
            </Text>
          )}
          <Text>
            If you needed to contact us for another reason, you can do so here:
            {' '}
            <a className="cub-Form-link" href={this.props.routes.SUPPORT.url}>
              {this.props.routes.SUPPORT.url}
            </a>
          </Text>

          <div className="cub-FormGroup cub-FormGroup--buttons">
            <Button
              classNameModifier="cub-Button--back"
              onClick={this.disallowSuppression}
              text="Back to form editing"
            />
            {url && (
              <NavLink
                className="cub-Button cub-Button--web"
                href={url}
                target="_blank"
                title={url}
              >
                <span className="cub-Button-icon" />
                <span className="cub-Button-text">Visit the link</span>
              </NavLink>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderSuccessMsg() {
    const msgState = this.state._successMsgState;
    let successMsg;
    if (this.props.successMsgHTML) {
      const html = this.props.successMsgHTML;
      successMsg = (
        <div
          className="cub-Container"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    } else {
      successMsg = (
        <Text>
          {this.props.successMsgText}
        </Text>
      );
    }
    return (
      <div className="cub-Row">
        <div className="cub-Row-column cub-Row-column--100 cub-Util-textCenter">
          {successMsg}
          {(msgState.type === 'reg-success') && (
            <div>
              <Text>
                Your free membership was created, click &quot;Continue&quot; to
                complete the filling of the profile.
              </Text>
              <div className="cub-FormGroup cub-FormGroup--buttons">
                <Button
                  onClick={msgState.proceed}
                  text="Continue"
                />
              </div>
            </div>
          )}
          {(msgState.type === 'reg-motivate') && (
            <div>
              <Text>
                To create your free membership click &quot;Continue&quot;.
              </Text>
              <div className="cub-FormGroup cub-FormGroup--buttons">
                <Button
                  onClick={msgState.proceed}
                  text="Continue"
                />
              </div>
            </div>
          )}
          {(msgState.type === 'reg-already') && (
            <div>
              <Text>
                We have found in our
                records that an account for {msgState.ctx.email} already exists.

                Please click &quot;Continue&quot; to login.
              </Text>
              <div className="cub-FormGroup cub-FormGroup--buttons">
                <Button
                  onClick={msgState.proceed}
                  text="Continue"
                />
              </div>
            </div>
          )}
          {(msgState.type === 'continue') && (
            <div>
              <Text>
                {msgState.ctx.extraMsg ||
                  'To proceed, please, click "Continue".'}
              </Text>
              <div className="cub-FormGroup cub-FormGroup--buttons">
                <Button
                  onClick={msgState.proceed}
                  text="Continue"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const formProps = {
      classNameModifier: this.props.classNameModifier,
      error: this.state._errors.topError(),
      ref: 'form',
      onSubmit: this.submit,
      isProcessing: this.state._isProcessing,
      disableWhenProcessing: true,
    };
    const site = this.props.site;
    const suppressionAllowed = this.state._suppressionAllowed;
    const wasFormSubmited = this.state._wasFormSubmitted;

    if (this.state._isSingleColumnLayout === null) {
      // During first render we can't determine how many columns to render,
      // because I didn't found a way how to get a reference to parent DOM node
      // of React component before its 1st render. I need this reference to
      // measure width of parent element and calculate the number of columns.
      // So I return nothing in the first render, and wait for componentDidMount
      // to execute, to be able to get a reference to parent DOM node, measure
      // width and calculate number of columns.
      return <Form {...formProps} />;
    }

    if (this.state._successMsgState.show) {
      return (
        <Form {...formProps}>
          {this.renderSuccessMsg()}
        </Form>
      );
    }

    if (!formProps.isProcessing && suppressionAllowed && wasFormSubmited) {
      return (
        <Form {...formProps}>
          {this.renderDuplicatedDlg(this.state.email, this.props.redirect.to)}
        </Form>
      );
    }
    if (this.state.form && this.state.form.startsWith('lfm_') &&
      this.props.fieldsets.some((forms) => forms.fields.some((field) => (
        field.name === 'token' && field.type === 'hidden'
      )))
    ) {
      formProps.recaptcha = this.state.form;
    }
    if (formProps.recaptcha) {
      formProps.onTokenUpdate = (token) => this.setState({ token });
    }
    const fieldsets = this.props.fieldsets.map((fieldset, fsIndex) => {
      if (!fieldset || !Array.isArray(fieldset.fields)) {
        logger.warn(`Bad fieldset: ${fieldset}`);
        return null;
      }
      const width = fieldset.columnWidth || 50;
      let className = 'cub-Row-column ';
      className += this.state._isSingleColumnLayout ?
        'cub-Row-column--100 ' :
        `cub-Row-column--${width} `;
      const legend = fieldset.legend && (
        <p className="cub-Label cub-Label--legend">
          {fieldset.legend}
        </p>
      );
      // Check if at least one element has a width,
      // mark whole Fieldset as multi-column.
      const isMultiColumn = _.find(
        fieldset.fields,
        (field) => field.width && field.width > 0,
      );
      // Wrap fields into the Grid if it's needed.
      const fields = isMultiColumn ?
        (
          <div className="cub-Row">
            {fieldset.fields.map((field) => {
              let fieldClassName =
                `cub-Row-column cub-Row-column--${field.width || 100}`;
              // Collapse Grid columns via CSS with "hidden" fields.
              fieldClassName +=
                (field.type === 'hidden') ? ' cub-Row-column--collapsed' : '';
              return (
                <div className={fieldClassName}>
                  {this.fieldElement(field)}
                </div>
              );
            }, this)}
          </div>
        ) :
        // In another case, just output the fields without Grid wrapper.
        fieldset.fields.map(this.fieldElement, this);

      if (fieldset.className) {
        className += fieldset.className;
      }

      return (
        <div key={fsIndex} className={className}>
          {legend}
          {fields}
        </div>
      );
    });

    const registerMeOpts = _.extend({
      hideForLogged: true,
    }, this.props.registerMe);
    const isLogged = Boolean(this.props.user);
    const showRegisterMe = !_.isEmpty(this.props.registerMe) &&
                           !(isLogged && registerMeOpts.hideForLogged);
    const enforced = this.props.registerMe.enforced;

    const tos = !_.isEmpty(this.props.routes) ? (
      <NavLink route={this.props.routes.TERMS_OF_SERVICE} target="_blank">
        Terms of Service
      </NavLink>
    ) : <span>Terms of Service</span>;

    let text;
    if (!enforced) {
      text = (
        <Text>
          By checking above, you agree to the {tos}.
        </Text>
      );
    } else {
      text = (
        <Text>
          By submitting this form, you agree to the {tos} and
          will be signed up to the site.
        </Text>
      );
    }
    return (
      <Form {...formProps}>
        <div className="cub-Row">
          {fieldsets}
          {this.props.submitBtn && (
            <div className={'cub-Row-column ' +
              `cub-Row-column--${this.props.submitColumnWidth}` +
              ' cub-Util-textCenter'}
            >
              <div className="cub-FormGroup">
                <Button
                  className={formProps.recaptcha ? 'g-recaptcha' : ''}
                  isProcessing={this.state._isProcessing}
                  text={this.props.submitBtn}
                  type="submit"
                />
              </div>
            </div>
          )}
          {showRegisterMe && (
            <div className={'cub-Row-column cub-Row-column--100 ' +
              'cub-Util-textCenter'}
            >
              {!enforced && (
                <RegisterMeLabeledCheckbox
                  key="_register_me"
                  ref="_register_me"
                  name="_register_me"
                  label={registerMeOpts.label}
                  siteName={site ? site.get('friendly_name') : ''}
                  isLogged={isLogged}
                  checked={Boolean(this.state._register_me)}
                  onChange={this.onInputChange}
                />
              )}
              {text}
            </div>
          )}
        </div>
      </Form>
    );
  }
}

GenericForm.propTypes = {
  action: PropTypes.string,
  classNameModifier: PropTypes.string,
  fieldsets: PropTypes.array,
  responsiveBreakpoint: PropTypes.number,
  member: PropTypes.object,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  successMsgText: PropTypes.string,
  successMsgHTML: PropTypes.string,
  formId: PropTypes.string,
  redirect: PropTypes.shape({
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    message: PropTypes.string,
    skipSuccessMsg: PropTypes.bool,
    requireLogin: PropTypes.bool,
  }),
  registerMe: PropTypes.shape({
    checked: PropTypes.bool,
    enforced: PropTypes.bool,
    hideForLogged: PropTypes.bool,
    maxTimeout: PropTypes.number,
    respectRedirect: PropTypes.bool,
    skip_subscribe: PropTypes.bool,
  }),
  site: PropTypes.object,
  routes: PropTypes.object,
  submitBtn: PropTypes.string,
  submitColumnWidth: PropTypes.number,
  token: PropTypes.string,
  user: PropTypes.object,
  suppressDuplicates: PropTypes.bool,
};

GenericForm.defaultProps = {
  classNameModifier: 'cub-Form--generic',
  registerMe: {
    checked: false,
    enforced: false,
  },
  responsiveBreakpoint: 700,
  submitBtn: 'Submit',
  submitColumnWidth: 100,
  successMsgText: 'Thank you! Your request was successfully submitted.',
  suppressDuplicates: false,
};
