import React from 'react';
import createForm from '../../mixins/createForm';
import GroupActions from '../../actions/GroupActions';
import GroupDescription from '../fields/GroupDescription';
import GroupName from '../fields/GroupName';
import GroupType from '../fields/GroupType';
import Loading from '../basic/Loading';
import router from '../../router/router';
import { GROUP_ADD } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';
import OrganizationSearch from '../fields/OrganizationSearch';
import UserActions from '../../actions/UserActions';
import DataStore from '../../stores/DataStore';

const GroupAddForm = createForm(GROUP_ADD, {
  getInitialState() {
    return {
      name: '',
      type: 'Group',
      description: '',
      currentMember: this.props.currentMember,
      selectedOrg: this.props.organization,
    };
  },

  componentDidMount() {
    if (this.refs.name) {
      this.refs.name.focus();
    }
  },

  onOrgChange(event) {
    const org = event.value;
    if (org.related == null) {
      return;
    }
    this.setState({ selectedOrg: org });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(org);
    }
    UserActions.selectTrustingOrg(org);
  },

  submit() {
    GroupActions.newGroup({
      name: this.state.name,
      type: this.state.type,
      description: this.state.description,
      organization: this.state.selectedOrg.id,
    }, GROUP_ADD);
  },

  render() {
    if (!this.state.currentMember.get('is_admin')) {
      router.navigate(this.props.routes.GROUPS);
      return <Loading />;
    }

    const user = DataStore.currentUser();
    const showOrgControl = user.isTrustedAdmin();
    // TODO: Uncomment the following line and remove two preceding lines when
    //  risk pool admins no longer need to be org admins (HS-2905).
    // const showOrgControl = this.props.currentMember.get('is_trusted_admin');

    return (
      <Form
        classNameModifier="cub-Form--groupAdd"
        error={this.state.errors.topError()}
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <GroupName
          ref="name"
          value={this.state.name}
          required
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('name')}
        />

        <GroupType
          value={this.state.type}
          required
          data={this.state.selectedOrg.groupTypes()}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('type')}
        />

        <GroupDescription
          value={this.state.description}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('description')}
        />

        {showOrgControl && (
          <OrganizationSearch
            label="Add to organization"
            value={this.state.selectedOrg.toFullJson()}
            onChange={this.onOrgChange}
            searchManagedTrusting
            required
          />
        )}

        <Button
          classNameModifier="cub-Button--add"
          type="submit"
          isProcessing={this.state.isProcessing}
          text="Add"
        />
      </Form>
    );
  },
});

export default GroupAddForm;
