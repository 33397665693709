import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import createForm from '../../mixins/createForm';
import FormActions from '../../actions/FormActions';
import MemberActions from '../../actions/MemberActions';
import ConfigStore from '../../stores/ConfigStore';
import { MemberPosition } from '../../stores/DataModels';
import Month from '../fields/Month';
import Position from '../fields/Position';
import Unit from '../fields/Unit';
import Year from '../fields/Year';
import { MEMBER_POSITION } from '../../constants/FormIds';
import DataStore from '../../stores/DataStore';
import Button from '../basic/Button';
import LabeledCheckbox from '../fields/LabeledCheckbox';
import Form from '../basic/Form';
import Error from '../basic/Error';
import FormErrors from '../FormErrors';
import ExperienceForm from './ExperienceForm';

const MemberPositionForm = createForm(MEMBER_POSITION, {
  propTypes: {
    edit: PropTypes.bool,
    member: PropTypes.object, // for 'add new' mode
    memberPosition: PropTypes.object, // for 'edit existing' mode
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.edit && !this.state.edit) {
      this.setState({ edit: true });
      this.setFocus = true;
    }
  },

  getInitialState() {
    this.setFocus = this.props.edit;
    return this.propsPositionState(this.props.edit);
  },

  propsPositionState(edit) {
    const memberPosition = this.props.memberPosition ||
        new MemberPosition({ member: this.props.member });
    // days of date interval are ignored in this version and
    // will be overwritten with blank values after save.
    const state = {
      memberPosition,
      edit,
      position: memberPosition.get('position') || '',
      unit: memberPosition.get('unit') || '',
      year_from: memberPosition.get('year_from') || '',
      month_from: memberPosition.get('month_from') || '',
      day_from: '',
      year_to: memberPosition.get('year_to') || '',
      month_to: memberPosition.get('month_to') || '',
      day_to: '',
    };
    state.till_present = !(state.year_to || state.month_to);
    return state;
  },

  componentDidMount() {
    if (this.setFocus) {
      this.focus();
    }
  },

  componentDidUpdate() {
    if (this.setFocus) {
      this.focus();
    }
  },

  focus() {
    this.refs.position.focus();
    this.setFocus = false;
  },

  validate() {
    const errors = {};
    Object.keys(this.refs).forEach((key) => {
      const field = this.refs[key];
      // Some fields may have no refs and no validation (i.e. hidden field)
      const validateField = field && field.validate;
      if (typeof validateField !== 'function') {
        return;
      }
      const errorMessage = validateField();
      if (errorMessage) {
        errors[key] = errorMessage;
      }
    });
    const formErrors = new FormErrors({
      error: {
        code: 400,
        description: 'The following parameters are invalid: ' +
          `${Object.keys(errors).join(', ')}`,
        params: errors,
      },
    });
    this.setState({ errors: formErrors });
    return !_.isEmpty(errors) ? 'ERROR' : null;
  },

  submit() {
    const errorMessage = this.validate();
    if (errorMessage) {
      return;
    }
    MemberActions.saveMemberPosition(this.state.memberPosition, {
      position: this.state.position,
      unit: this.state.unit,
      year_from: this.state.year_from,
      month_from: this.state.month_from,
      day_from: this.state.day_from,
      year_to: this.state.year_to,
      month_to: this.state.month_to,
      day_to: this.state.day_to,
    }, MEMBER_POSITION);
  },

  onAdd(e) {
    e.preventDefault();
    this.setState({ edit: true });
    this.setFocus = true;
  },

  onEdit(e) {
    e.preventDefault();
    const currentMember = DataStore.currentMember();
    const formMember = this.state.memberPosition.get('member');
    const text = 'Are you sure you want to change your position info with the' +
      ' understanding that it may affect your official organization records?';
    if (
      formMember &&
      formMember.get('is_active') &&
      !formMember.get('is_admin') &&
      !(currentMember && currentMember.get('is_admin'))
    ) {
      if (!window.confirm(text)) { // eslint-disable-line no-alert
        return;
      }
    }
    this.setState({ edit: true });
    this.setFocus = true;
  },

  onDelete(e) {
    e.preventDefault();
    MemberActions.deleteMemberPosition(this.state.memberPosition);
  },

  onCancel(e) {
    e.preventDefault();
    this.setState(this.propsPositionState(false));
    FormActions.formCancelled(MEMBER_POSITION, this.props);
  },

  formStateFromStores(newState) {
    const err = newState.errors;
    return {
      sinceError: err.fieldError('year_from') ||
                  err.fieldError('month_from') || null,
      tillError: err.fieldError('year_to') ||
                 err.fieldError('month_to') || null,
    };
  },

  storesSuccess(formId, response) {
    if (formId === MEMBER_POSITION) {
      if (this.state.memberPosition.id) {
        if (response.id === this.state.memberPosition.id) {
          this.setState({ edit: false }); // saved existing
        }
      } else {
        const memberId = response.member.id || response.member;
        if (memberId === this.state.memberPosition.get('member').id) {
          this.setState(this.propsPositionState(false)); // added new
        }
      }
    }
  },

  onPresentClick() {
    this.setState({ till_present: !this.state.till_present });
  },

  till() {
    let till;
    const tillClassName = 'cub-MemberPosition-period ' +
      'cub-MemberPosition-period--till';
    if (this.state.till_present) {
      till = (
        <fieldset className="cub-Row-column cub-Row-column--50">
          <div className={tillClassName}>
            <label className="cub-Label">End date:</label>
            <LabeledCheckbox
              label="present"
              name="present"
              id="cub-present"
              value="true"
              onChange={this.onPresentClick}
              checked
            />
          </div>
        </fieldset>
      );
    } else {
      till = (
        <fieldset className="cub-Row-column cub-Row-column--50">
          <div className={tillClassName}>
            <label className="cub-Label" htmlFor="cub-month_to">
              End date:
            </label>
            <div className="cub-Row">
              <div className="cub-Row-column cub-Row-column--70">
                <div className="cub-FormGroup cub-FormGroup--select">
                  <Month
                    classNameModifier="cub-FormControl--monthTo"
                    name="month_to"
                    id="cub-month_to"
                    value={this.state.month_to}
                    onChange={this.onInputChange}
                  />
                </div>
              </div>
              <div className="cub-Row-column cub-Row-column--30">
                <div className="cub-FormGroup cub-FormGroup--input">
                  <Year
                    classNameModifier="cub-FormControl--yearTo"
                    name="year_to"
                    value={this.state.year_to}
                    onChange={this.onInputChange}
                  />
                </div>
              </div>
            </div>
            <Error>{this.state.tillError}</Error>
          </div>
        </fieldset>
      );
    }
    return till;
  },

  periodStr() {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];
    const state = this.state;
    let period = '';
    if (state.year_from) {
      if (state.month_from) {
        period = `${months[+state.month_from - 1]} ${state.year_from}`;
      } else {
        period = state.year_from;
      }
    } else {
      period = 'unknown';
    }
    period += ' - ';
    if (state.year_to) {
      if (state.month_to) {
        period += `${months[+state.month_to - 1]} ${state.year_to}`;
      } else {
        period += state.year_to;
      }
    } else {
      period += 'present';
    }
    return period;
  },

  render() {
    if (!this.state.memberPosition.id && !this.state.edit) {
      return (
        <div>
          <Button
            classNameModifier="cub-Button--add"
            onClick={this.onAdd}
            text="Add Position"
          />
        </div>
      );
    }

    const member = this.state.memberPosition.get('member');
    const organization = member.get('organization');
    const labels = organization.fieldLabels();
    let formClass = 'cub-Form--memberPosition';
    let topError = null;
    let position;
    let unit;
    let period;
    let positionInfoClassName = 'cub-MemberPosition-info ';
    let btns = null;

    if (this.state.edit) {
      formClass += ' is-editing';
      topError = this.state.errors.topError();
      position = (
        <Position
          ref="position"
          value={this.state.position}
          required
          tags={organization.get('tags')}
          label="Title/Position"
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('position')}
        />
      );
      const unitChoices = (
        ConfigStore.get('memberPositionUnitChoices') ||
        member.get('organization').units()
      );
      const restrictToKnown = Boolean(
        ConfigStore.get('memberPositionUnitChoices'),
      );
      const site = DataStore.currentSite();
      const unitRequired = (
        site.get('experience_step') === ExperienceForm.UNIT_REQUIRED
      );
      unit = (
        <Unit
          ref="unit"
          value={this.state.unit}
          label={labels.unit}
          data={unitChoices}
          required={unitRequired}
          restrictToKnown={restrictToKnown}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('unit')}
        />
      );
      period = (
        <div className="cub-MemberPosition-periodContainer">
          <div className="cub-Row">
            <fieldset className="cub-Row-column cub-Row-column--50">
              <div className={'cub-MemberPosition-period ' +
                'cub-MemberPosition-period--since'}
              >
                <label className="cub-Label" htmlFor="cub-month_from">
                  Start date:
                </label>
                <div className="cub-Row">
                  <div className="cub-Row-column cub-Row-column--70">
                    <div className="cub-FormGroup cub-FormGroup--select">
                      <Month
                        classNameModifier="cub-FormControl--monthFrom"
                        name="month_from"
                        id="cub-month_from"
                        value={this.state.month_from}
                        onChange={this.onInputChange}
                      />
                    </div>
                  </div>
                  <div className="cub-Row-column cub-Row-column--30">
                    <div className="cub-FormGroup cub-FormGroup--input">
                      <Year
                        classNameModifier="cub-FormControl--yearFrom"
                        name="year_from"
                        value={this.state.year_from}
                        onChange={this.onInputChange}
                      />
                    </div>
                  </div>
                </div>
                <Error>{this.state.sinceError}</Error>
              </div>
            </fieldset>
            {this.till()}
          </div>
        </div>
      );
      btns = (
        <div className="cub-FormGroup cub-FormGroup--buttons">
          <Button
            type="submit"
            isProcessing={this.state.isProcessing}
            text="Save"
          />

          <Button
            classNameModifier="cub-Button--cancel"
            onClick={this.onCancel}
            text="Cancel"
          />

          {this.state.memberPosition.id && (
            <Button
              classNameModifier="cub-Button--delete"
              onClick={this.onDelete}
              text="Remove"
            />
          )}
        </div>
      );
    } else {
      position = (
        <p className="cub-MemberPosition-positionInfo">
          {this.state.position}
        </p>
      );
      unit = (
        <p className="cub-MemberPosition-unitInfo">
          {this.state.unit}
        </p>
      );
      period = (
        <p className="cub-MemberPosition-periodInfo">
          {this.periodStr()}
        </p>
      );
      positionInfoClassName = 'cub-Row-column';
      if (this.props.edit !== null) {
        btns = (
          <div className="cub-Row-column">
            <div className="cub-FormGroup cub-FormGroup--buttons">
              <Button
                classNameModifier="cub-Button--edit"
                onClick={this.onEdit}
                text="Edit"
              />
            </div>
          </div>
        );
      }
    }

    return (
      <Form
        classNameModifier={formClass}
        autoComplete="off"
        error={topError}
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <div className={
          this.state.edit ? '' : 'cub-Row cub-Util-flexJustifyBetween'
        }
        >
          <div className={positionInfoClassName}>
            {position}
            {unit}
            {period}
          </div>
          {btns}
        </div>
      </Form>
    );
  },
});

export default MemberPositionForm;
