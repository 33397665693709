import _ from 'underscore';
import createReactClass from 'create-react-class';
import formMixin from './formMixin';
import FormStore from '../stores/FormStore';
import storeListenerMixin from './storeListenerMixin';

/**
 * Create Form view-controller.
 *
 * @param {string} formId - ID of form, required for events dispatching
 * @param {array} stores - optional, array of stores to listen
 * @param {object} cls - class definition
 * @return {object}
 */
export default function createForm(formId, stores, cls) {
  if (!Array.isArray(stores)) {
    cls = stores;
    stores = [];
  }
  stores.push(FormStore);
  const mixins = [
    storeListenerMixin.apply(storeListenerMixin, stores),
    formMixin(formId),
  ];
  return createReactClass(_.extend({ mixins }, cls));
}
