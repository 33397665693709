import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import createForm from '../../mixins/createForm';
import ApiActions from '../../actions/ApiActions';
import MemberActions from '../../actions/MemberActions';
import UserStore from '../../stores/UserStore';
import OrganizationAddForm from '../forms/OrganizationAddForm';
import OrganizationSearch from '../fields/OrganizationSearch';
import { MEMBERSHIP_ADD, GENERIC_FORM } from '../../constants/FormIds';
import Button from '../basic/Button';

const MembershipAddForm = createForm(MEMBERSHIP_ADD, {
  propTypes: {
    hideCancel: PropTypes.bool,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    tag: PropTypes.string,
    site: PropTypes.object,
    user: PropTypes.object,
  },

  getInitialState() {
    return {
      addingNewOrganization: false,
      organization: null, // can be a string or Organization object
    };
  },

  componentDidMount() {
    if (!this.state.addingNewOrganization) {
      this.refs.organization.focus();
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.addingNewOrganization && prevState.addingNewOrganization) {
      this.refs.organization.focus();
    }
  },

  onOrganizationSave(data, { submited, succeeded, failed }) {
    submited();
    ApiActions.simplePost(
      'organizations',
      data,
      (response) => {
        MemberActions.newMember({
          organization: response.id,
          user: UserStore.currentUserId(),
        }, GENERIC_FORM, false, (newMemberResponse) => {
          succeeded();
          MemberActions.gotMember(newMemberResponse);
          if (typeof this.props.onSave === 'function') {
            this.props.onSave(GENERIC_FORM, newMemberResponse);
          }
        }, (newMemberResponse) => failed(newMemberResponse));
      },
      (response) => failed(response),
    );
  },

  onOrganizationAdd(organizationName) {
    const newState = { addingNewOrganization: true };
    if (organizationName) {
      newState.organization = organizationName;
    }
    this.setState(newState);
  },

  onOrganizationChange({ value }) {
    const organization = value;
    this.setState({ organization });
  },

  onSubmit() {
    const orgId = this.state.organization && this.state.organization.id;
    if (orgId) {
      MemberActions.newMember({
        user: this.props.user.id,
        organization: orgId,
      }, MEMBERSHIP_ADD, true);
    }
  },

  storesSuccess(formId, response) {
    if ((formId === MEMBERSHIP_ADD) &&
        typeof this.props.onSave === 'function') {
      this.props.onSave(formId, response);
    }
  },

  onCancel() {
    if (this.state.addingNewOrganization) {
      this.setState({ addingNewOrganization: false });
    } else if (typeof this.props.onCancel === 'function') {
      this.props.onCancel();
    }
  },

  render() {
    let addComponent;
    let hideCancel = this.props.hideCancel;
    const canAddOrganizations = (
      !hideCancel || !this.props.site.get('limit_search_to_trusting_orgs')
    );

    if (this.state.addingNewOrganization) {
      // Create new organization mode
      let orgName = '';
      if (typeof this.state.organization === 'string') {
        orgName = this.state.organization;
      }
      addComponent = (
        <OrganizationAddForm
          hideCancel={this.hideCancel}
          name={orgName}
          tag={this.props.tag}
          onCancel={this.onCancel}
          onSave={this.onOrganizationSave}
        />
      );
      hideCancel = false; // in this mode Cancel reverts to organization search
    } else {
      // Select existing organization mode
      let next = null;
      let existsMembership = null;
      if (this.state.organization && this.state.organization.id) {
        if (this.props.user) {
          existsMembership = _.find(
            this.props.user.related.member,
            (m) => m.get('organization').id === this.state.organization.id,
          );
        }
        if (!existsMembership) {
          next = (
            <Button
              type="submit"
              isProcessing={this.state.isProcessing}
              onClick={!this.state.isProcessing && this.onSubmit}
              text="Save"
            />
          );
        }
      }

      addComponent = (
        <div className="cub-AddOrganization-content">
          {existsMembership && (
            <div className="cub-Msg cub-Msg--success">
              The selected organization has already been added to your profile.
            </div>
          )}

          <OrganizationSearch
            ref="organization"
            tag={this.props.tag}
            label="Start typing to find your organization:"
            value={this.state.organization}
            onAdd={canAddOrganizations && this.onOrganizationAdd}
            onChange={this.onOrganizationChange}
            nothingFoundMsg={!canAddOrganizations ?
              'Such organization is not found' : undefined}
          />

          <div className="cub-FormGroup cub-FormGroup--buttons">
            {next}

            {!hideCancel && (
            <Button
              classNameModifier="cub-Button--cancel"
              text="Cancel"
              onClick={this.onCancel}
            />
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="cub-AddOrganization">
        {addComponent}
      </div>
    );
  },
});

export default MembershipAddForm;
