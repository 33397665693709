import FormListener from '../components/FormListener';

export default function formMixin(formId, skipClearingFormErrorFor) {
  const mixin = {
    formId,
    skipClearingFormErrorFor: new Set(skipClearingFormErrorFor),
  };
  Object.getOwnPropertyNames(FormListener.prototype).forEach((name) => {
    mixin[name] = FormListener.prototype[name];
  });
  delete mixin.constructor;
  delete mixin.formStateFromStores; // Must be defined in the component.
  return mixin;
}
