import FormActions from './FormActions';

const CouponActions = {
  verifyCoupon(formId, couponCode, order, plan, success, error) {
    const data = { coupon_code: couponCode };
    if (order) {
      data.order = order.id;
    }
    if (plan) {
      data.plan = plan.id;
    }
    FormActions.submitUserForm(
      formId,
      'coupons/verify',
      data,
      success,
      error,
    );
  },
};

export default CouponActions;
