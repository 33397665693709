import AppDispatcher from '../dispatcher/AppDispatcher';
import AppStore from './AppStore';
import createStore from '../mixins/createStore';
import {
  PASSWORD_RESET_TOKEN_CHECKED,
  PASSWORD_RESET_TOKEN_ERROR,
  URL_CHANGED,
} from '../constants/ActionTypes';

function noToken() {
  return {
    token: null,
    valid: null,
    passwordChangeRequired: null,
  };
}

let token = noToken();

const TokenStore = createStore({
  token() {
    return token.token;
  },

  isValid() {
    return token.valid;
  },

  passwordChangeRequired() {
    return token.passwordChangeRequired;
  },

  lastLogin() {
    return token.lastLogin;
  },

  firstName() {
    return token.firstName;
  },

  lastName() {
    return token.lastName;
  },

  phone() {
    return token.phone;
  },

});

TokenStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const response = action.response;

  AppDispatcher.waitFor([AppStore.dispatchToken]);

  switch (action.actionType) {
    case URL_CHANGED: {
      const route = action.route;
      const args = action.args;
      const routes = AppStore.routes();
      if (route === routes.RESET_PASSWORD) {
        token = {
          token: args[0],
          valid: null,
        };
        TokenStore.emitChange();
      } else if (token.token) {
        token = noToken();
        TokenStore.emitChange();
      }
      break;
    }

    case PASSWORD_RESET_TOKEN_CHECKED:
      if (token.token) {
        token.valid = true;
        token.passwordChangeRequired = response.password_change_required;
        token.lastLogin = response.last_login;
        token.firstName = response.first_name;
        token.lastName = response.last_name;
        token.phone = response.phone;
        TokenStore.emitChange();
      }
      break;

    case PASSWORD_RESET_TOKEN_ERROR:
      if (token.token) {
        token.valid = false;
        TokenStore.emitChange();
      }
      break;

    default:
  }

  return true;
});

export default TokenStore;
