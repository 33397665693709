import PropTypes from 'prop-types';
import React from 'react';

export default class InputStripe extends React.Component {
  render() {
    return (
      <input
        className={'cub-FormControl cub-FormControl--input' +
          ` ${this.props.classNameModifier}`}
        data-stripe={this.props.dataStripe}
        name={this.props.name}
        id={this.props.id}
        placeholder={this.props.placeholder}
        size={this.props.size}
      />
    );
  }
}

InputStripe.propTypes = {
  classNameModifier: PropTypes.string,
  dataStripe: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  id: PropTypes.string,
};

InputStripe.defaultProps = {
  classNameModifier: 'cub-FormControl--stripeInput',
};
