import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import DataStore from '../../stores/DataStore';
import EmailConfirmationStore from '../../stores/EmailConfirmationStore';
import Loading from '../basic/Loading';
import NavLink from '../basic/NavLink';
import router from '../../router/router';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import CaptionLarge from '../basic/CaptionLarge';
import Text from '../basic/Text';

/* eslint-disable react/prefer-es6-class */
const ConfirmEmail = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    routes: PropTypes.object,
  },

  /* eslint-disable react/no-unused-class-component-methods,react/sort-comp */
  mixins: [storeListenerMixin(EmailConfirmationStore, DataStore)],

  getStateFromStores() {
    return {
      status: EmailConfirmationStore.status(),
      isAnonymous: !DataStore.currentUser(),
    };
  },
  /* eslint-enable react/no-unused-class-component-methods,react/sort-comp */

  componentDidMount() {
    if (this.state.status !== 'bad token' && !this.state.isAnonymous) {
      router.navigate(router.getFirstLoginRedirect().url);
    }
  },

  render() {
    const routes = this.props.routes;
    let state;
    switch (this.state.status) {
      case 'confirmed':
      case 'already confirmed': {
        let whatNext;
        if (this.state.isAnonymous) {
          whatNext = (
            <Text>
              You are welcome
              to <NavLink route={routes.LOGIN}>Login</NavLink> now.
            </Text>
          );
        } else {
          whatNext = (<Loading />);
        }
        state = (
          <div className={'cub-InfoBlock' +
                ' cub-InfoBlock--emailConfirmed isSuccess'}
          >
            <CaptionLarge>Email confirmation</CaptionLarge>
            <Text>
              Thank you, your email is now confirmed.
            </Text>
            {whatNext}
          </div>
        );
        break;
      }

      case 'bad token':
        state = (
          <div className="cub-InfoBlock hasBadToken">
            <Text>The confirmation link you are attempting to use is invalid.
            </Text>
            <Text>
              If you copied the link into your browser manually, please try
              again and make sure that it is copied correctly.
            </Text>
            <Text>
              If this problem persists, please contact our{' '}
              <NavLink route={routes.SUPPORT}>Customer Support</NavLink>.
            </Text>
          </div>
        );
        break;

      default:
        state = <Loading />;
    }
    return state;
  },
});

export default ConfirmEmail;
