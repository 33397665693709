import { keyMirror } from '../../utils/Utils';
import LabeledSelect from './LabeledSelect';

const options = keyMirror([
  '',
  'Corrections',
  'Education',
  'Emergency Management',
  'EMS',
  'Finance',
  'Fire',
  'Government',
  'Law Enforcement',
  'Military',
  'Vendor',
  'Private Security',
  'Non-Sworn Law Enforcement',
]);
options[''] = ' - not specified - ';

export default class Tag extends LabeledSelect { }

Tag.options = options;

Tag.defaultProps = {
  name: 'tag',
  options,
};
