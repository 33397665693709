import PropTypes from 'prop-types';
import React from 'react';
import Error from '../basic/Error';
import ImageControl from './ImageControl';
import ConfigStore from '../../stores/ConfigStore';

export default class LabeledImageControl extends React.Component {
  constructor(props) {
    super(props);

    const state = {
      value: this.props.value,
    };
    this.state = state;
    this.validate = this.validate.bind(this);
    this.onImageUploaded = this.onImageUploaded.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.value !== prevState.value) {
      if (this.props.onChange) {
        this.props.onChange({
          target: {
            name: this.props.name,
            value: this.state.value,
          },
        });
      }
    }
  }

  onImageUploaded(url) {
    this.setState({ value: url });
  }

  onRemove() {
    this.setState({ value: null });
  }

  validate() {
    if (this.props.required && !this.state.value) {
      return 'This field is required.';
    }
    return null;
  }

  render() {
    return (
      <div className="cub-FormGroup cub-FormGroup--image">
        <label className="cub-Label cub-Label--image">
          {this.props.label}
          {this.props.required && <span className="cub-Label-asterisk">*</span>}
        </label>
        <ImageControl
          apiEndpoint="file/upload"
          altText="logo"
          errorClassNameModifier=""
          imageClassNameModifier="cub-Util-heightAuto"
          onRemove={this.onRemove}
          onS3UploadDone={this.onImageUploaded}
          src={this.state.value}
          srcDefault={this.props.valueDefault ||
            ConfigStore.static('img/logo_default.jpg')}
          size={this.props.size}
        />
        <Error data-field={this.props.name}>
          {this.props.error}
        </Error>
      </div>
    );
  }
}

LabeledImageControl.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  valueDefault: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
};
