import _ from 'underscore';
import Backbone from 'backbone';
import { dateFromISOString } from '../utils/Utils';

const Data = {
  toJSON({ skipNonCacheable = false } = {}) {
    const result = {};
    Object.keys(this).forEach((key) => {
      const collection = this[key];
      if (collection instanceof CubCollection) {
        result[key] = collection.toJSON({ skipNonCacheable });
      }
    }, this);
    return result;
  },

  reset(data) {
    data = data || {};
    Object.keys(this).forEach((key) => {
      const collection = this[key];
      if (collection instanceof CubCollection) {
        let models = [];
        if (Object.prototype.hasOwnProperty.call(data, key) &&
          _.isArray(data[key])
        ) {
          models = data[key];
        }
        models.forEach((item) => collection.getOrCreate(item));
      }
    }, this);
  },

  loadedAfter(models, date) {
    if (models instanceof CubModel) return models.loadedAfter(date);
    if (models instanceof CubCollection) {
      models = models.models;
    }
    if (!Array.isArray(models) || !models.length) {
      return false;
    }
    return models.every((item) => item.loadedAfter(date));
  },

  loadedInLastSeconds(models, seconds) {
    const date = new Date();
    date.setSeconds(date.getSeconds() - seconds);
    return this.loadedAfter(models, date);
  },
};

const objectByIdPrefix = {};

function objId(obj) {
  return _.isObject(obj) ? obj.id : obj;
}

function objCollection(obj) {
  let collection;
  if (obj instanceof CubModel) {
    collection = obj.collection;
  } else if (_.isObject(obj) && obj.object) {
    collection = Data[obj.object];
  } else {
    const objIdStr = String(objId(obj) || '');
    let prefixMatch = objIdStr.match(/^(\w{3})_(\w{16})$/);
    if (!prefixMatch) {
      // Original Stripe service products like 'prod_BUsy9S6vXini1K'
      prefixMatch = objIdStr.match(/^(\w{4})_(\w{14})$/);
    }
    collection = prefixMatch && Data[objectByIdPrefix[prefixMatch[1]]];
  }
  return collection;
}

const CubCollection = Backbone.Collection.extend({
  getOrCreate(obj, options) {
    options = options || { silent: true };
    options.skipIds = options.skipIds || {};
    options.checkStale = options.checkStale || [];
    options.parse = true;
    options.merge = true;
    if (!_.isObject(obj)) {
      obj = { id: obj };
    }
    const skipped = options.skipIds[obj.id];
    let created;
    if (skipped) {
      created = skipped.set(skipped.parse(obj, options), options);
    } else {
      created = this.add(obj, options);
    }
    return created;
  },

  filterRelated(models, stale) {
    if (!Array.isArray(models)) {
      models = [models];
    }
    const objType = this.model.prototype.object;
    const relatedArrays = [];
    const checkStale = (m) => m.stale === stale;
    let related;
    for (let i = 0, l = models.length; i < l; i += 1) {
      related = models[i].related[objType];
      if (typeof stale === 'boolean') {
        related = related.filter(checkStale);
      }
      if (!related.length) {
        return related;
      }
      relatedArrays.push(related);
    }
    return _.intersection(...relatedArrays);
  },

  staleModels() {
    return this.filter((m) => m.stale);
  },

  markRelatedAsStale(models) {
    const related = this.filterRelated(models);
    related.forEach((item) => { item.stale = true; });
    return related;
  },

  markAllAsStale() {
    this.models.forEach((model) => { model.stale = true; });
    return this.models;
  },

  toJSON(options = {}) {
    let models = this.models;
    if (options.skipNonCacheable) {
      models = this.filter((model) => !model.nonCacheable);
    }
    return _.map(models, (model) => model.toJSON(options));
  },
});

const CubModel = Backbone.Model.extend({
  cascadeDelete: [],

  collections: [],

  constructor(...args) {
    // eslint-disable-next-line no-unused-vars
    const [_attrs, options] = args;
    this.nonCacheable = Boolean((options || {}).nonCacheable);
    this.stale = false;
    this.related = {};
    Object.keys(Data).forEach((key) => {
      if (Data[key] instanceof CubCollection) {
        this.related[key] = [];
      }
    });
    Backbone.Model.apply(this, args);
  },

  set(key, val, options) {
    if (typeof key === 'object') options = val;
    if (this.nonCacheable) {
      // non-cacheable model can become cacheable but not vice versa
      this.nonCacheable = Boolean((options || {}).nonCacheable);
    }
    return Backbone.Model.prototype.set.apply(this, [key, val, options]);
  },

  setRelation(model, options) {
    // Set forwards relation
    const attrs = {};
    attrs[this.object] = this;
    model.set(attrs, _.extend({}, options, { silent: true }));
    // Set backwards relation
    const related = this.related[Object.getPrototypeOf(model).object];
    if (related.indexOf(model) === -1) {
      related.push(model);
    }
  },

  removeRelation(model, options) {
    // Replace forwards relation with object id
    if (model.get(this.object) === this) {
      const attrs = {};
      attrs[this.object] = this.id;
      model.set(attrs, _.extend({}, options, { silent: true }));
    }
    // Remove backwards relation
    const related = this.related[Object.getPrototypeOf(model).object];
    const index = related.indexOf(model);
    if (index !== -1) {
      related.splice(index, 1);
    }
  },

  parse(response, options) {
    const attrs = {};
    let collection;
    let value;
    response = response || {};
    options = options || {};

    // immediately save object id and write it to skipIds to prevent
    // creation of duplicated models in recursively expanded responses
    this.id = response.id;
    options.skipIds = options.skipIds || {};
    options.skipIds[this.id] = this;
    // after update, some objects may become stale. We return suspects list
    // in options.checkStale, and this is responsibility of caller to check
    // all models in this list and clean those which are stale.
    options.checkStale = options.checkStale || [];

    this.stale = false;
    if (options.loaded) {
      attrs.loaded = new Date();
    }

    Object.keys(response).forEach((key) => {
      value = response[key];
      if (Array.isArray(value)) {
        collection = Data[this.collections[key]];
        if (collection) {
          Array.prototype.push.apply(
            options.checkStale,
            collection.markRelatedAsStale(this),
          );
          value.forEach((el) => {
            if (!_.isObject(el)) {
              el = { id: el };
            }
            el = collection.getOrCreate(el, options);
            this.setRelation(el, options);
          }, this);
        } else {
          attrs[key] = value;
        }
        return;
      }
      if (!(value instanceof CubModel) &&
          (
            Object.prototype.hasOwnProperty.call(Data, key) ||
            (value && value.object)
          )
      ) {
        collection = objCollection(value);
        if (collection) {
          value = collection.getOrCreate(value, options);
          value.setRelation(this, options);
        }
      }
      attrs[key] = dateFromISOString(value) || value;
    });
    return attrs;
  },

  toJSON() {
    const attrs = this.attributes;
    const result = {};
    Object.keys(attrs).forEach((key) => {
      const value = attrs[key];
      if (value instanceof CubModel) {
        result[key] = value.id;
      } else {
        result[key] = value;
      }
    });
    return result;
  },

  loadedAfter(date) {
    const loaded = this.get('loaded');
    return loaded instanceof Date && loaded > date;
  },

  loadedInLastSeconds(seconds) {
    const date = new Date();
    date.setSeconds(date.getSeconds() - seconds);
    return this.loadedAfter(date);
  },
});

function updateObjects(objects, options) {
  options = options || {};
  options.checkStale = options.checkStale || [];
  if (!Array.isArray(objects)) {
    objects = [objects];
  }
  objects.forEach((obj) => {
    const collection = objCollection(obj);
    if (collection) {
      collection.getOrCreate(obj, options);
    }
  });
  options.checkStale.forEach((model) => {
    if (options.skipIds[model.id]) {
      model.stale = false;
    } else if (model.stale) {
      model.collection.remove(model, options);
    }
  });
}

function deleteObjects(objects, options) {
  options = options || { silent: false };
  options.skipIds = options.skipIds || {};
  if (!Array.isArray(objects)) {
    objects = [objects];
  }
  objects.forEach((obj) => {
    if (!options.skipIds[objId(obj)]) {
      const collection = objCollection(obj);
      if (collection) {
        collection.remove(obj, options);
      }
    }
  });
}

function onObjRemove(model, collection, options) {
  options = options || {};
  options.skipIds = options.skipIds || {};
  // prevent infinite loop if there are circular cascade delete dependencies
  options.skipIds[model.id] = true;
  const attrs = model.attributes;
  Object.keys(attrs).forEach((key) => {
    const value = attrs[key];
    if (value instanceof CubModel) {
      value.removeRelation(model, options);
    }
  });
  model.cascadeDelete.forEach((objType) => {
    const related = model.related[objType];
    model.related[objType] = [];
    deleteObjects(related, options);
  });
}

function init(models) {
  models.forEach((m) => {
    const collection = new (CubCollection.extend({ model: m }))();
    Data[m.prototype.object] = collection;
    collection.on('remove', onObjRemove);
    const prefixes = m.prototype.prefixes || [m.prototype.prefix];
    prefixes.forEach((prefix) => {
      objectByIdPrefix[prefix] = m.prototype.object;
    });
  });
}

export {
  CubModel,
  CubCollection,
  Data,
  deleteObjects,
  init,
  objCollection,
  updateObjects,
};
