import createReactClass from 'create-react-class';
import React from 'react';
import AppStore from '../stores/AppStore';
import Groups from './views/Groups';
import Login from './views/Login';
import Members from './views/Members';
import Orders from './views/Orders';
import OrderInvoice from './views/OrderInvoice';
import Profile from './views/Profile';
import storeListenerMixin from '../mixins/storeListenerMixin';
import Registration from './views/Registration';
import TermsOfService from './views/TermsOfService';

/* eslint-disable react/prefer-es6-class */
const Application = createReactClass({
  /* eslint-disable react/no-unused-class-component-methods */
  mixins: [storeListenerMixin(AppStore)],

  getStateFromStores() {
    const currentRoute = AppStore.currentRoute();
    return {
      initialized: AppStore.initialized(),
      routes: AppStore.routes(),
      route: currentRoute.route,
      args: currentRoute.args,
    };
  },
  /* eslint-enable react/no-unused-class-component-methods */

  render() {
    if (!this.state.initialized || !this.state.route) {
      return <div />;
    }
    const routes = this.state.routes;
    let view;
    switch (this.state.route) {
      case routes.TERMS_OF_SERVICE:
        view = <TermsOfService />;
        break;

      case routes.LOGIN:
      case routes.FORGOT_PASSWORD:
      case routes.PASSWORD_RESET_LINK_SENT:
      case routes.PASSWORD_RESET_DONE:
      case routes.RESET_PASSWORD:
      case routes.IDP_CONFIRMATION:
      case routes.IDP_CONFIRMATION_REQUIRED:
      case routes.SSO_CALLBACK:
        view = (
          <Login
            routes={routes}
            route={this.state.route}
            args={this.state.args}
          />
        );
        break;

      case routes.REGISTER:
      case routes.REGISTER_FOR_A_PLAN:
      case routes.REGISTER_FOR_A_PRODUCT:
      case routes.EMAIL_CONFIRMATION:
        view = (
          <Registration
            routes={routes}
            route={this.state.route}
            args={this.state.args}
          />
        );
        break;

      case routes.USER_PROFILE:
      case routes.EXPERIENCE:
      case routes.CHANGE_EMAIL:
      case routes.EMAIL_CHANGE_DONE:
      case routes.CHANGE_PASSWORD:
      case routes.PASSWORD_CHANGE_DONE:
      case routes.CHANGE_USERNAME:
      case routes.USERNAME_CHANGE_DONE:
      case routes.NEWSLETTERS:
      case routes.MEMBER_BENEFITS:
      case routes.PREMIUM_BENEFITS:
      case routes.VERIFICATION_REQUESTS:
      case routes.REGISTRATION_VERIFICATION:
      case routes.USER_BILLING:
      case routes.USER_BILLING_PLAN:
      case routes.SET_EMAIL:
      case routes.CONFIRM_MEMBERSHIP:
        view = (
          <Profile
            routes={routes}
            route={this.state.route}
            args={this.state.args}
          />
        );
        break;
      case routes.ORDER_INVOICE:
        view = (
          <OrderInvoice
            routes={routes}
            route={this.state.route}
            args={this.state.args}
          />
        );
        break;
      case routes.ORDER_PAYMENT:
      case routes.ORDER_INVOICE_PAYMENT:
        view = (
          <Orders
            routes={routes}
            route={this.state.route}
            args={this.state.args}
          />
        );
        break;

      case routes.MEMBERS_ACTIVE:
      case routes.MEMBERS_INVITED:
      case routes.MEMBERS_INACTIVE:
      case routes.MEMBERS_NEW:
      case routes.MEMBERS_NEW_IN_ORG:
      case routes.MEMBER:
        view = (
          <Members
            routes={routes}
            route={this.state.route}
            args={this.state.args}
          />
        );
        break;

      case routes.GROUPS:
      case routes.GROUPS_NEW:
      case routes.GROUP:
      case routes.GROUP_ADD_MEMBERS:
        view = (
          <Groups
            routes={routes}
            route={this.state.route}
            args={this.state.args}
          />
        );
        break;

      default:
        return <div />;
    }
    return (
      <div className="cub-App">{view}</div>
    );
  },
});

export default Application;
