import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/promise';
import 'core-js/es/number';
import 'raf/polyfill';
import _ from 'underscore';
import React from 'react';
import ReactDOM from 'react-dom';
import smartdate from 'smartdate';
import { jwtDecode } from 'jwt-decode';
import Api from './api/CubApi';
import Application from './components/Application';
import AppStore from './stores/AppStore';
import ConfigStore from './stores/ConfigStore';
import DataStore from './stores/DataStore';
import Forms from './forms/Forms';
import Menu from './components/Menu';
import SiteActions from './actions/SiteActions';
import styles from '../css/main.scss';
import UserStore from './stores/UserStore';
import router from './router/router';
import { injectStyle, injectLink } from './utils/injector';
import {
  absoluteURL,
  Deferred,
  getWidgetPath,
  logger,
  siteSentry,
} from './utils/Utils';
import ga from './utils/ga';
import lytics from './utils/lytics';
import ostat from './utils/ostat';
import iubenda from './utils/iubenda';

// Public API
const cub = {
  version: WIDGET_VERSION,

  api: Api,

  helpers: { Promise, Deferred },

  analytics: { ga: ga.push },

  setup(params) {
    ConfigStore.config(params);
    return this;
  },

  debug(on = true) {
    if (on) {
      logger.setLevel('debug');
      cub.__DEBUG = {
        DataStore,
        AppStore,
        router,
        _,
        logger,
        lytics,
        siteSentry,
        jwtDecode,
        absoluteURL,
      };
    } else {
      logger.setLevel('warn');
      delete cub.__DEBUG;
    }
  },

  start(params) {
    if (params) {
      cub.setup(params);
    }

    /* eslint camelcase: "off" */
    __webpack_public_path__ = getWidgetPath();

    iubenda.init();
    ga.setup();
    lytics.setup();
    const initLyticsOnce = _.once(lytics.initAndLoad);
    DataStore.addChangeListener(() => {
      const site = DataStore.currentSite();
      const cid = site ? site.get('lytics_jstag_cid') : null;
      if (cid) initLyticsOnce(cid);
    });

    Api.xdmInit(ConfigStore.get('apiKey'));
    ostat.init();

    injectStyle(styles);
    injectLink(ConfigStore.static('css/FontAwesome.css'));

    SiteActions.getCurrentSite();
    UserStore.init();
    DataStore.restoreFromCache();
    AppStore.init();
    UserStore.refreshUser();

    smartdate.init({
      className: ConfigStore.get('smartdateClass'),
      mode: 'past',
    });

    const appSelector = ConfigStore.get('appSelector');
    if (appSelector) {
      const appContainer = document.querySelector(appSelector);
      if (appContainer) {
        ReactDOM.render(React.createElement(Application, null), appContainer);
      }
    }

    const menuSelector = ConfigStore.get('menuSelector');
    if (menuSelector) {
      const menuContainer = document.querySelector(menuSelector);
      if (menuContainer) {
        ReactDOM.render(React.createElement(Menu, null), menuContainer);
      }
    }

    Forms.init();
    return this;
  },
};

(function waitForCubAsyncInit() {
  if (typeof window.cubAsyncInit === 'function') {
    window.cubAsyncInit(cub);
  } else {
    setTimeout(waitForCubAsyncInit, 50);
  }
}());

export default cub;
