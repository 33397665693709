import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import NavLink from './NavLink';

export default class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeItem: this.props.active };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active) {
      this.setState({ activeItem: nextProps.active });
    }
  }

  onClick(id) {
    const newState = { activeItem: id };
    if (typeof this.props.onTabClick === 'function') {
      this.props.onTabClick(newState.activeItem);
    }
    this.setState(newState);
  }

  render() {
    const tabsClassName = `cub-Tabs ${this.props.classNameModifier}`;

    return (
      <ul className={tabsClassName}>
        {_.map(this.props.options, (item, i) => {
          const tabItemText = (
            <span className="cub-Tabs-itemLinkText">{item.title}</span>
          );
          const tabItemCount = (typeof item.count === 'number') && (
            <span className="cub-Tabs-itemLinkCount">
              {`(${item.count})`}
            </span>
          );
          const onClick = () => this.onClick(item.id);
          const tabItem = (item.route) ?
            (
              <NavLink
                className="cub-Tabs-itemLink"
                id={item.id}
                href={item.href}
                route={item.route}
                onClick={onClick}
              >
                {tabItemText} {tabItemCount}
              </NavLink>
            ) :
            (
              <span
                className="cub-Tabs-itemLink"
                id={item.id}
                onClick={onClick}
                role="button"
                tabIndex="0"
              >
                {tabItemText}
              </span>
            );

          let className = `cub-Tabs-item cub-Tabs-item--${item.name}`;
          if (item.id === this.state.activeItem) {
            className += ' is-active';
          }

          return (
            <li key={i} className={className}>
              {tabItem}
            </li>
          );
        })}
      </ul>
    );
  }
}

Tabs.propTypes = {
  classNameModifier: PropTypes.string,
  active: PropTypes.string,
  onTabClick: PropTypes.func,
  options: PropTypes.array,
};

Tabs.defaultProps = {
  classNameModifier: '',
};
