import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { injectScript } from '../../utils/injector';
import Loading from '../basic/Loading';
import Form from '../basic/Form';
import Text from '../basic/Text';
import InputStripe from '../fields/InputStripe';

export default class BankCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { stripeLoaded: false };
    this.onStripeLoaded = this.onStripeLoaded.bind(this);
    this.onStripeResponse = this.onStripeResponse.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    // Load Stripe.js
    if (window.Stripe && window.Stripe.version && window.Stripe.version >= 2) {
      // Surprise! Stripe is already here. Welp.. ok
      this.onStripeLoaded();
      return;
    }
    injectScript('https://js.stripe.com/v2/', this.onStripeLoaded);
  }

  onStripeLoaded() {
    window.Stripe.setPublishableKey(this.props.site.get('stripe_pk'));
    this.setState({ stripeLoaded: true });
  }

  onSubmit(e) {
    let submitAllowed = true;
    if (typeof this.props.onSubmit === 'function') {
      submitAllowed = this.props.onSubmit();
    }
    if (submitAllowed) {
      window.Stripe.createToken(
        ReactDOM.findDOMNode(this.refs.ccForm),
        this.onStripeResponse,
      );
    }
    e.preventDefault();
  }

  onStripeResponse(status, response) {
    if (status === 200) {
      if (typeof this.props.onCardCapture === 'function') {
        this.props.onCardCapture(response.id);
      }
    } else if (typeof this.props.onError === 'function') {
      this.props.onError(response.error.message);
    }
  }

  render() {
    const stripePk = this.props.site.get('stripe_pk');
    if (!stripePk) {
      return (
        <Text classNameModifier="cub-Text--bankCardMsg">
          Stripe Account is not configured for this site.
          <br />
          Payments are not available.
        </Text>
      );
    }

    if (!this.state.stripeLoaded) {
      // If we're going to input a card, then Stripe.js is required..
      return <Loading />;
    }

    return (
      <Form
        classNameModifier="cub-Form--bankCard"
        error={this.props.error}
        ref="ccForm"
        onSubmit={this.onSubmit}
      >
        <div className="cub-FormGroup cub-FormGroup--input">
          <label
            className={'cub-Label cub-Label--input ' +
              'cub-Label--bankCardNumber'}
            htmlFor="cub-bank_card_number"
          >
            Card number
          </label>
          <InputStripe
            classNameModifier="cub-FormControl--bankCardNumber"
            dataStripe="number"
            name="bank_card_number"
            id="cub-bank_card_number"
            size="c"
          />
        </div>

        <div className="cub-FormGroup cub-FormGroup--inputGroup">
          <div className="cub-Row">
            <div className="cub-Row-column cub-Row-column--60">
              <label
                className={'cub-Label cub-Label--input ' +
                  'cub-Label--bankCardExpiration'}
                htmlFor="cub-bank_card_exp_month"
              >
                Expiration
              </label>
              <div className="cub-Row">
                <div className="cub-Row-column cub-Row-column--50">
                  <div className="cub-FormGroup cub-FormGroup--input">
                    <InputStripe
                      classNameModifier="cub-FormControl--bankCardExpMonth"
                      dataStripe="exp_month"
                      name="bank_card_exp_month"
                      id="cub-bank_card_exp_month"
                      placeholder="MM"
                      size="2"
                    />
                  </div>
                </div>
                <div className="cub-Row-column cub-Row-column--50">
                  <div className="cub-FormGroup cub-FormGroup--input">
                    <InputStripe
                      classNameModifier="cub-FormControl--bankCardExpYear"
                      dataStripe="exp_year"
                      name="bank_card_exp_year"
                      placeholder="YY"
                      size="2"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="cub-Row-column cub-Row-column--40">
              <div className="cub-FormGroup cub-FormGroup--input">
                <label
                  className={'cub-Label cub-Label--input ' +
                    'cub-Label--bankCardCVC'}
                  htmlFor="cub-bank_card_cvc"
                >
                  CVC
                </label>
                <InputStripe
                  classNameModifier="cub-FormControl--bankCardCVC"
                  dataStripe="cvc"
                  name="bank_card_cvc"
                  id="cub-bank_card_cvc"
                  size="4"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cub-PaymentSummary">
          {this.props.children}
        </div>
      </Form>
    );
  }
}

BankCardForm.propTypes = {
  children: PropTypes.any,
  error: PropTypes.string,
  onCardCapture: PropTypes.func,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  site: PropTypes.object,
};
