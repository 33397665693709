import _ from 'underscore';
import iubenda from './iubenda';
import { logger, siteSentry, throwSafely } from './Utils';

// after setup() we can safely call send(payload) even
// before initAndLoad(cid) - events will be queued
function setup() {
  try {
    if (typeof window.jstag === 'object') {
      logger.warn('lytics: tag is already setup!');
      return;
    }

    logger.debug('lytics: jstag setup');
    // script below was taken from Lytics docs
    // asynchronous code from Lytics docs, creates jstag object and loads api
    /* eslint-disable */
    window.jstag = (function() {
      function t(t) {
        return function() {
          return t.apply(this, arguments), this
        }
      }
      function n() {
        var n = ["ready"].concat(c.call(arguments));
        return t(function() {
          n.push(c.call(arguments)), this._q.push(n)
        })
      }
      var i = {
        _q: [],
        _c: {},
        ts: (new Date).getTime(),
        ver: "2.0.0"
      },
      c = Array.prototype.slice;

      i.init = function(t) {
        return i._c = t, t.synchronous || i.loadtagmgr(t), this
      };

      i.loadtagmgr = function(t) {
        var n = document.createElement("script");
        n.type = "text/javascript", n.async = !0, n.src = t.url + "/api/tag/" + t.cid + "/lio.js";
        var i = document.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(n, i)
      };

      i.ready = n();
      i.send = n("send");
      i.mock = n("mock");
      i.identify = n("identify");
      i.pageView = n("pageView");
      i.bind = t(function(t) {
        i._q.push([t, c.call(arguments, 1)])
      });
      i.block = t(function() {
        i._c.blockload = !0
      });
      i.unblock = t(function() {
        i._c.blockload = !1
      });

      return i;
    })();
    /* eslint-enable */
  } catch (err) {
    throwSafely(err);
  }
}

function initAndLoad(cid) {
  try {
    if (typeof window.jstag !== 'object') {
      siteSentry.captureMessage('lytics: tag is not setup!');
      return;
    }

    logger.debug(`lytics: jstag init and load (cid=${cid})`);
    iubenda.hasApprovedAdsPromise.then((hasApprovedAds) => {
      if (!hasApprovedAds) {
        return;
      }
      window.jstag.init({
        cid,
        url: '//c.lytics.io',
        min: true,
        loadid: false,
      });
    });
  } catch (err) {
    throwSafely(err);
  }
}

function send(payload, callback) {
  try {
    if (typeof window.jstag !== 'object') {
      siteSentry.captureMessage('lytics: tag is not setup!');
      return;
    }

    logger.debug('lytics: sending event', payload);
    window.jstag.send(payload, callback || _.noop);
  } catch (err) {
    throwSafely(err);
  }
}

export default {
  setup,
  initAndLoad,
  send,
};
