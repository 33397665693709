import { logger, siteSentry, throwSafely } from './Utils';

function delay(time, value) {
  return new Promise((resolve) => {
    setTimeout(resolve, time, value);
  });
}

function getGoogleAnalyticsClient() {
  if (
    window.GoogleAnalyticsObject &&
    typeof window[window.GoogleAnalyticsObject] === 'function'
  ) {
    return window[window.GoogleAnalyticsObject];
  }
  return null;
}

function waitForGoogleAnalyticsClient(timeout = 30000) {
  const now = new Date();
  const ga = getGoogleAnalyticsClient();
  if (ga) {
    return Promise.resolve(ga);
  }
  if (timeout <= 0) {
    return Promise.reject(new Error('Google Analytics is not available'));
  }
  return delay(Math.min(timeout, 1000)).then(() => {
    const afterDelay = new Date();
    const newTimeout = timeout - (afterDelay.getTime() - now.getTime());
    return waitForGoogleAnalyticsClient(newTimeout);
  });
}

let promisedGA;

function setup() {
  try {
    promisedGA = waitForGoogleAnalyticsClient().catch((err) => {
      logger.warn(err.message);
    });
  } catch (err) {
    throwSafely(err);
  }
}

function push(...command) {
  try {
    if (typeof promisedGA !== 'object') {
      siteSentry.captureMessage('ga: command queue is not setup!');
      return;
    }
    promisedGA.then((ga) => {
      if (!ga) {
        return;
      }
      logger.debug('ga: sending a command', command);
      ga(...command);
    });
  } catch (err) {
    throwSafely(err);
  }
}

export default {
  setup,
  push,
};
