import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import CaptionLarge from '../basic/CaptionLarge';
import MemberVerificationRequests from './MemberVerificationRequests';
import NavLink from '../basic/NavLink';
import { parseQueryString, setQueryString } from '../../utils/Utils';
import regflow from '../../utils/regflow';
import router from '../../router/router';

export default class VerificationRequests extends React.Component {
  render() {
    let nextRoute = this.props.next || router.getFirstLoginRedirect();
    if (nextRoute === this.props.routes.PREMIUM_BENEFITS) {
      if (!regflow.benefitsAvailable(this.props) &&
        regflow.subscriptionsAvailable(this.props)) {
        nextRoute = this.props.routes.NEWSLETTERS;
      }
    }
    const members = this.props.user.membershipForVerification(this.props.site);
    let continueBtnTitle = '';
    continueBtnTitle =
      (_.find(members, (m) => m.verificationRequests().length > 0)) ?
        'Continue' :
        // Set a value to Button Title only if hideSkipButton is false.
        // If not, the Skip Button would be hidden.
        !this.props.hideSkipButton && 'Skip';
    return (
      <div className="cub-VerificationRequests">
        <CaptionLarge>Verification requests</CaptionLarge>
        <div className="cub-VerificationRequests-content">
          {members.map(
            (m) => <MemberVerificationRequests key={m.id} member={m} />,
          )}
          {continueBtnTitle && (
            <NavLink
              className="cub-Button cub-Button--continue"
              href={setQueryString(nextRoute.url, parseQueryString())}
            >
              <span className="cub-Button-icon" />
              <span className="cub-Button-text">{continueBtnTitle}</span>
            </NavLink>
          )}
        </div>
      </div>
    );
  }
}

VerificationRequests.propTypes = {
  next: PropTypes.object,
  routes: PropTypes.object,
  site: PropTypes.object,
  user: PropTypes.object,
  hideSkipButton: PropTypes.bool,
};

VerificationRequests.defaultProps = {
  hideSkipButton: false,
};
