import PropTypes from 'prop-types';
import React from 'react';
import OrderActions from '../../actions/OrderActions';
import FormStore from '../../stores/FormStore';
import InvoiceTokenStore from '../../stores/OrderInvoiceStore';
import UserStore from '../../stores/UserStore';
import { setQueryString } from '../../utils/Utils';
import Loading from '../basic/Loading';
import Caption from '../basic/Caption';
import OrderInvoiceLoginView from './OrderInvoiceLoginView';
import OrderInvoiceCreateAccount from './OrderInvoiceCreateAccount';
import OrderInvoiceError from './OrderInvoiceError';
import createForm from '../../mixins/createForm';
import {
  ACCEPT_INVOICE_FORM_ID,
  CHECK_TOKEN_FORM_ID,
} from '../../constants/FormIds';

import router from '../../router/router';

/* eslint-disable react/prefer-es6-class */
const OrderInvoice = createForm(CHECK_TOKEN_FORM_ID, [InvoiceTokenStore], {
  propTypes: {
    args: PropTypes.array,
    route: PropTypes.object,
    routes: PropTypes.object,
  },

  getInitialState() {
    return {
      autoLoginStatus: null,
      invoiceTokenStatus: null,
      acceptingInvoice: false,
      loading: true,
      registrationError: {},
      invoiceChecked: false,
      acceptInvoiceSuccess: false,
    };
  },

  getErrors(formState) {
    if (!formState.errors) {
      return false;
    }
    return formState.errors._errors;
  },

  formStateFromStores(formState) {
    const formId = ACCEPT_INVOICE_FORM_ID;
    const acceptInvoiceState = {
      errors: FormStore.errors(formId),
      isProcessing: FormStore.isProcessing(formId),
    };
    let invoiceTokenStatus = null;

    if (this.getErrors(acceptInvoiceState)) {
      invoiceTokenStatus = false;
    }

    if (this.state.acceptInvoiceSuccess) {
      invoiceTokenStatus = true;
    }

    const autoLoginStatus = InvoiceTokenStore.autoLoginTokenStatus();
    const acceptingInvoice = this.state.acceptingInvoice && (
      invoiceTokenStatus === null
    );
    const invoiceAccepted = invoiceTokenStatus === true;
    const loggedIn = !!UserStore.currentUserId();
    let invoiceChecked = this.state.invoiceChecked || false;
    let loading = !this.isReady() || acceptingInvoice || invoiceAccepted;

    const errors = this.getErrors(formState);

    if (errors && !this.state.isProcessing) {
      // invoice is checkd if we have errors and it's not processing
      // or if we have valid response from checkToken
      invoiceChecked = true;
    }

    if (!InvoiceTokenStore.autoLoginToken() && !loggedIn) {
      if (!invoiceChecked) {
        loading = true;
      }
    }

    const newState = {
      orderId: this.orderId(),
      registrationError: InvoiceTokenStore.registrationError(),
      invoiceToken: InvoiceTokenStore.token(),
      loggedIn,
      loading,
      autoLoginStatus,
      acceptingInvoice,
      invoiceTokenStatus,
      invoiceChecked,
      acceptInvoiceState,
    };
    const needAcceptInvoice = !acceptingInvoice && invoiceTokenStatus === null;
    if (newState.loggedIn && this.isReady() && needAcceptInvoice) {
      window.setTimeout(
        () => {
          OrderActions.acceptInvoice(newState.orderId, newState.invoiceToken);
        },
        100,
      );
      newState.acceptingInvoice = true;
      newState.loading = true;
    }

    return newState;
  },

  isReady() {
    return InvoiceTokenStore.token() && this.orderId();
  },

  orderId() {
    return this.props.args[0];
  },

  storesSuccess(formId, response) {
    if (formId === CHECK_TOKEN_FORM_ID) {
      // invoice checked ssuccessfully
      this.setState({ invoiceChecked: true });
    }

    if (formId === ACCEPT_INVOICE_FORM_ID) {
      this.setState({ acceptInvoiceSuccess: true });
      window.setTimeout(() => router.navigate(response.order_preview_url), 100);
    }
  },

  render() {
    const routes = this.props.routes;
    const next = routes.ORDER_INVOICE.makeUrl(
      this.state.orderId,
      this.state.invoiceToken,
    );
    const loginUrl = setQueryString(routes.LOGIN.url, { next });
    const supportTeamLink = (
      <a className="cub-Form-link" href={this.props.routes.SUPPORT.url}>
        support team
      </a>
    );

    let content = '';
    const acceptingErrors = this.getErrors(this.state.acceptInvoiceState);

    if (this.state.loading) {
      content = <Loading />;
    } else if (InvoiceTokenStore.autoLoginToken()) {
      content = (
        <OrderInvoiceLoginView
          loggedIn={this.state.loggedIn}
          autoLoginStatus={this.state.autoLoginStatus}
          loginUrl={loginUrl}
          orderId={this.orderId()}
          supportTeamLink={supportTeamLink}
        />
      );
    } else if (acceptingErrors) {
      content = (
        <OrderInvoiceError
          errors={acceptingErrors}
          orderId={this.orderId()}
          supportTeamLink={supportTeamLink}
        />
      );
    } else {
      content = (
        <OrderInvoiceCreateAccount
          formErrors={this.state.errors}
          orderId={this.orderId()}
          invoiceToken={this.state.invoiceToken}
          registrationError={this.state.registrationError}
          loginUrl={loginUrl}
          supportTeamLink={supportTeamLink}
          invoiceTokenStatus={this.state.invoiceTokenStatus}
        />
      );
    }
    return (
      <div className="cub-InfoBlock">
        <Caption>Order invoice: <small>{this.state.orderId}</small></Caption>
        {content}
      </div>
    );
  },
});

export default OrderInvoice;
