import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import DataStore from '../../stores/DataStore';
import FilterGrid from '../basic/FilterGrid';
import GroupActions from '../../actions/GroupActions';
import Loading from '../basic/Loading';
import NavLink from '../basic/NavLink';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import Button from '../basic/Button';
import Text from '../basic/Text';
import OrganizationSearch from '../fields/OrganizationSearch';
import UserActions from '../../actions/UserActions';

/* eslint-disable react/prefer-es6-class */
const GroupList = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    currentMember: PropTypes.object,
    onChange: PropTypes.func,
    organization: PropTypes.object,
    routes: PropTypes.object,
  },

  /* eslint-disable react/no-unused-class-component-methods,react/sort-comp */
  mixins: [storeListenerMixin(DataStore)],

  getStateFromStores() {
    const org = DataStore.selectedTrustingOrg() || this.props.organization;
    const member = this.props.currentMember;
    return {
      groups: member.manageableGroups(org),
      isAdmin: member.get('is_admin'),
      loadingComplete: !DataStore.nowLoadingRelatedTo(org),
      selectedOrg: org,
    };
  },
  /* eslint-enable react/no-unused-class-component-methods,react/sort-comp */

  onDelete(group, e) {
    const msg = 'Click OK to proceed with deleting ' +
        `group "${group.get('name')}"`;
    if (window.confirm(msg)) { // eslint-disable-line no-alert
      GroupActions.deleteGroup(group);
    }
    e.preventDefault();
  },

  onOrgChange(event) {
    const org = event.value;
    if (org.related == null) {
      return;
    }
    const member = this.props.currentMember;
    this.setState({
      groups: member.manageableGroups(org),
      isAdmin: member.get('is_admin'),
      loadingComplete: !DataStore.nowLoadingRelatedTo(org),
      selectedOrg: org,
    });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(org);
    }
    UserActions.selectTrustingOrg(org);
  },

  displayFields(group) {
    const { joined, invited } = group.get('active_members');

    let count = joined + invited;
    if (_.isNaN(count)) count = '..';
    return [
      `${group.get('name')} (${count})`,
      group.get('type'),
      group.get('description'),
    ];
  },

  links(group) {
    return this.props.routes.GROUP.makeUrl(group.id);
  },

  btns(group) {
    /* eslint-disable react/jsx-no-bind */
    return (
      <Button
        classNameModifier="cub-Button--delete"
        onClick={this.onDelete.bind(this, group)}
        text="Delete"
      />
    );
    /* eslint-enable react/jsx-no-bind */
  },

  render() {
    let groupAdd = null;
    let loading = null;

    if (!this.state.loadingComplete) {
      loading = <Loading />;
    }

    groupAdd = this.state.isAdmin ?
      (
        <NavLink
          className="cub-Button cub-Button--add"
          route={this.props.routes.GROUPS_NEW}
        >
          <span className="cub-Button-icon" />
          Add group
        </NavLink>
      ) :
      (
        <div className="cub-Msg cub-Msg--warning">
          <Text>
            <span
              className="cub-Icon cub-Icon--attention cub-Util-noMarginLeft"
            />
            You don&#39;t have permissions to manage groups of selected{' '}
            organization.
          </Text>
        </div>
      );

    const user = DataStore.currentUser();
    const showOrgControl = user.isTrustedAdmin();
    // TODO: Uncomment the following line and remove two preceding lines when
    //  risk pool admins no longer need to be org admins (HS-2905).
    // const showOrgControl = this.props.currentMember.get('is_trusted_admin');

    return (
      <div className="cub-GridList cub-GridList--groups">
        <div className="cub-GridList-controls">
          <div className="cub-Row cub-Util-flexJustifyBetween">
            <div className="cub-Row-column cub-Row-column--60">
              {showOrgControl && (
                <OrganizationSearch
                  label={null}
                  value={this.state.selectedOrg.toFullJson()}
                  onChange={this.onOrgChange}
                  searchManagedTrusting
                />
              )}
            </div>
            <div className="cub-Row-column">
              {groupAdd}
            </div>
          </div>
        </div>
        {loading}
        <FilterGrid
          className="cub-Grid cub-Grid--groups"
          cols={['Name', 'Type', 'Description']}
          items={this.state.groups}
          orderBy="name"
          displayFields={this.displayFields}
          links={this.links}
          btns={this.state.isAdmin ? this.btns : null}
          emptyMessage="No groups found"
        />
      </div>
    );
  },
});

export default GroupList;
