import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import { OrganizationProp } from '../../constants/PropTypes';
import { Organization } from '../../stores/DataModels';
import ApiActions from '../../actions/ApiActions';
import DataStore from '../../stores/DataStore';
import LabeledCombobox from './LabeledCombobox';
import OrganizationItem from './OrganizationItem';
import Tag from './Tag';

const maxSearchResults = 100;

const debouncedOrganizationSearch = _.debounce((filter, callback) => {
  const {
    input, tag, trustedOrganization, managedByTrusted, shouldHandleChunk,
  } = filter;
  const filterWithDefaults = {
    q: input || '',
    tags: tag || '',
    trusted_organization: (
      _.isObject(trustedOrganization) ?
        trustedOrganization.id :
        trustedOrganization || ''
    ),
    managed_by_trusted: managedByTrusted || '',
    search_in_other_tags: true,
    offset: 0,
    count: maxSearchResults,
    expand: 'country,state',
  };
  // ApiActions.getChunk parses models and puts them in the DataStore.
  const get = shouldHandleChunk ? ApiActions.getChunk : ApiActions.get;
  get('organizations', filterWithDefaults, callback);
}, 300);

export default class OrganizationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tag: this.validTag(props.tag),
      search: '',
      options: [],
      value: null || props.value,
    };

    if (Organization.validPrefix(props.value)) {
      this.state.value = null;
    }
    this.searchCache = {};
    Object.keys(Tag.options).forEach((tag) => {
      this.searchCache[tag] = {};
    });
    this.onChange = this.onChange.bind(this);
    this.onTagChange = this.onTagChange.bind(this);
    this.setOptions = this.setOptions.bind(this);
    this.optionsLoaded = this.optionsLoaded.bind(this);
    this.fireAdd = this.fireAdd.bind(this);
    this.fireChange = this.fireChange.bind(this);
    this.focus = this.focus.bind(this);
    this.filter = this.filter.bind(this);
    this.validate = this.validate.bind(this);
    this.itemComponent = this.itemComponent.bind(this);
    this.noHighlight = [
      'addNew', 'divider', 'loading',
      'moreResults',
    ];
    this.noClick = ['divider', 'loading', 'moreResults'];
  }

  componentWillMount() {
    this.setOptions(this.state.search, this.state.tag);
  }

  componentWillReceiveProps(nextProps) {
    // if tagsSelection has been disabled from outside,
    // or tag has been changed from outside, then switch to tag from new props.
    if ((nextProps.tag !== this.props.tag) ||
        (!nextProps.tagsSelection && this.props.tagsSelection)) {
      const tag = this.validTag(nextProps.tag);
      if (tag !== this.state.tag) {
        this.setState({ tag });
        this.onTagChange({ target: { value: tag } });
      }
    }
    if (this.state.value !== nextProps.value) {
      if (Organization.validPrefix(nextProps.value)) return;
      // Stores have changed and it triggered the change in the props. This
      // must not reset the current value.
      if (_.isObject(nextProps.value)) return;
      this.setState({ value: nextProps.value });
    }
  }

  onChange(event) {
    let value = event.target.value;
    const name = event.target.name;
    if (typeof value === 'string') {
      this.setOptions(value, this.state.tag);
      this.setState({ search: value, value });
      this.fireChange({ name, value });
    } else if (value.id === 'addNew') {
      this.fireAdd(this.state.search);
    } else if (['divider', 'loading', 'moreResults'].indexOf(value.id) < 0) {
      const orgName = value.name;
      const orgId = value.id;
      this.setState((prevState) => ({
        value: prevState.loading ? orgName : orgId,
      }));
      if (this.props.searchManagedTrusting) {
        // Parsed model is expected by the parent.
        value = DataStore.organization.get(value.id);
      }
      this.fireChange({ name, value });
    }
  }

  onTagChange(event) {
    this.setOptions(this.state.search, event.target.value);
    this.refs.combobox.focus();
  }

  setOptions(search, tag) {
    const newState = { search, tag, loading: false };
    const searchCache = this.searchCache[tag];
    let cached = searchCache[search];
    if (cached) {
      newState.options = cached;
      this.setState(newState);
      return;
    }

    const filter = {
      input: search,
      tag,
      shouldHandleChunk: this.props.searchManagedTrusting,
    };
    if (this.props.searchManagedTrusting) {
      // TODO: Uncomment the following lines when risk pool admins no longer
      //  need to be org admins (HS-2905).
      // const currentMember = DataStore.currentMember();
      // filter.trustedOrganization = currentMember.get('organization');
      filter.managedByTrusted = true;
    }
    debouncedOrganizationSearch(filter, this.optionsLoaded);
    newState.loading = true;
    // When we don't have cache for current search string yet,
    // try to find cache for shorter string and filter results from it.
    const orgsFilter =
        (org) => org.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    for (let l = search.length - 1; l >= 0; l--) {
      cached = searchCache[search.slice(0, l)];
      if (cached && cached.length) {
        newState.options = cached.filter(orgsFilter);
        break;
      }
    }
    if (!cached) {
      // No search results for shorter string
      newState.options = [];
    }
    this.setState(newState);
  }

  validate() {
    if (this.props.required && !this.props.value) {
      return 'This field is required.';
    }
    return null;
  }

  validTag(tag) {
    return Tag.options[tag] ? tag : '';
  }

  optionsLoaded(response, meta, method, url, options) {
    const opts = (options && options.data) || {};
    const search = opts.q || '';
    const tag = opts.tags || '';
    let searchResults = response;
    if (this.props.searchManagedTrusting) {
      searchResults = this.prependTrustedOrg(response, search);
    } else {
      searchResults = this.appendUnapproved(response, search, tag);
    }
    this.searchCache[tag][search] = searchResults;
    // Ignore results that don't match current search
    if (search === this.state.search && tag === this.state.tag) {
      this.setOptions(search, tag);
    }
  }

  prependTrustedOrg(response, search) {
    const currentMember = DataStore.currentMember();
    if (!currentMember) {
      return response;
    }

    const site = DataStore.currentSite();
    const trustedOrgId = site.get('limit_search_to_trusting_orgs');
    if (!trustedOrgId) {
      return response;
    }
    const trustedOrg = DataStore.organization.get(trustedOrgId);
    if (!trustedOrg) {
      return response;
    }
    // Assume current organization is the trusted org.
    // TODO: Uncomment the following line and remove 9 preceding lines when risk
    //  pool admins no longer need to be org admins (HS-2905).
    // const trustedOrg = currentMember.get('organization');

    const orgName = trustedOrg.get('name') || '';
    if (search && orgName.toLowerCase().indexOf(search.toLowerCase()) < 0) {
      return response;
    }
    return [trustedOrg.toFullJson(), ...response];
  }

  appendUnapproved(response, search, tag) {
    // Hack to append known unapproved orgs to the search results.
    const user = DataStore.currentUser();
    if (!user) {
      return response;
    }
    const currentOrgs = user.searchInCurrentOrgs(search);
    const wTag = currentOrgs.filter((o) => o.get('tags').indexOf(tag) !== -1);
    const woTag = currentOrgs.filter((o) => o.get('tags').indexOf(tag) === -1);
    let result = [];
    if (wTag.length) {
      // Put known unapproved orgs with tags to top.
      result = wTag.map((o) => o.toFullJson()).concat(response);
    } else {
      result = response;
    }
    if (woTag.length) {
      // Put known unapproved orgs without tags to the end.
      result = result.concat(woTag.map((o) => o.toFullJson()));
    }
    return result;
  }

  isAddingEnabled() {
    return typeof this.props.onAdd === 'function';
  }

  fireAdd(value) {
    if (typeof this.props.onAdd === 'function') {
      this.props.onAdd(value);
    }
  }

  fireChange({ name, value }) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange({ name, value });
    }
  }

  focus() {
    this.refs.combobox.focus();
  }

  filter(item, value) {
    if (item.id === 'moreResults') {
      const searchCache = this.searchCache[this.state.tag];
      return (searchCache[value] || []).length >= maxSearchResults;
    }
    return true;
  }

  itemComponent(props) {
    const id = props.item.id;
    const highlight = this.noHighlight.indexOf(id) < 0 ? this.state.search : '';
    return (
      <OrganizationItem
        className={highlight ? '' : `cub-Option-${id}`}
        noClick={this.noClick.indexOf(id) >= 0}
        highlight={highlight}
        {...props}
      />
    );
  }

  render() {
    const tag = this.state.tag;
    let mainResults;
    let otherResults;
    const otherResultsIndex = tag ?
      _.findIndex(this.state.options, (org) => org.tags.indexOf(tag) === -1) :
      -1;
    if (otherResultsIndex === -1) {
      mainResults = this.state.options;
      otherResults = [];
    } else {
      mainResults = this.state.options.slice(0, otherResultsIndex);
      otherResults = this.state.options.slice(otherResultsIndex);
    }

    const addNew = (msg) => ({
      name: msg || '... Your organization not listed? Click here to add it',
      id: 'addNew',
    });
    const divider = (msg) => ({
      name: msg || `Other non-${tag} organizations:`,
      id: 'divider',
    });
    const loading = (msg) => ({
      name: msg || 'Loading...',
      id: 'loading',
    });
    const moreResults = (msg) => ({
      name: msg || 'More results available, please use more specific ' +
        'search terms to narrow down the results',
      id: 'moreResults',
    });
    let results;
    if (mainResults.length >= maxSearchResults) {
      // A lot of main results, only first 100 displayed
      results = mainResults.concat([moreResults()]);
    } else if (otherResults.length === 0) {
      // Only main results, but less than 100, all displayed
      if (this.state.loading) {
        results = mainResults.concat(loading());
      } else if (this.isAddingEnabled()) {
        results = mainResults.concat(addNew(
          !mainResults.length && 'Click to add as a new organization',
        ));
      } else {
        results = mainResults;
      }
    } else {
      // Mixed - main and other results
      const mainCategory = Tag.options[tag];
      otherResults.unshift(divider());
      if (mainResults.length || this.state.loading) {
        mainResults.unshift(divider(`Results in ${mainCategory}:`));
      }
      if (this.state.loading) {
        mainResults.push(loading());
        otherResults.push(loading());
      } else {
        if (this.isAddingEnabled()) {
          mainResults.push(addNew(
            `No ${!mainResults.length ? '' : 'more '}results for ` +
              `${mainCategory}. Click here to add as a new organization`,
          ));
        }
        if (this.state.options.length >= maxSearchResults) {
          otherResults.push(moreResults());
        } else if (this.isAddingEnabled()) {
          otherResults.push(addNew());
        }
      }
      results = mainResults.concat(otherResults);
    }

    return (
      <div className="cub-Organization-content">
        <LabeledCombobox
          classNameModifier="cub-FormControl--organization"
          label={this.props.label}
          ref="combobox"
          required={this.props.required}
          error={this.props.error}
          textField="name"
          valueField="id"
          name={this.props.name}
          data={results}
          value={this.state.value}
          placeholder={this.props.placeholder}
          filter={this.filter}
          busy={this.state.loading || this.props.loading}
          disabled={this.props.disabled}
          showNoResults
          itemComponent={this.itemComponent}
          onChange={this.onChange}
          onBlur={this.props.onBlur}
          messages={{
            emptyList: this.props.nothingFoundMsg,
          }}
        />
        {this.props.tagsSelection && (
          <Tag
            label="Org. type"
            value={this.state.tag}
            onChange={this.onTagChange}
          />
        )}
      </div>
    );
  }
}

OrganizationSearch.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    OrganizationProp,
  ]),
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  searchManagedTrusting: PropTypes.bool,
  tag: PropTypes.string,
  tagsSelection: PropTypes.bool,
  nothingFoundMsg: PropTypes.string,
};

OrganizationSearch.defaultProps = {
  label: 'Organization name',
  name: 'organization',
  tag: '',
  tagsSelection: false, // consider removing this option at all
  nothingFoundMsg: 'Enter name or choose from search results',
  searchManagedTrusting: false,
};
