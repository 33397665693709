import React from 'react';
import createForm from '../../mixins/createForm';
import DataStore from '../../stores/DataStore';
import GroupDescription from '../fields/GroupDescription';
import GroupName from '../fields/GroupName';
import GroupType from '../fields/GroupType';
import GroupActions from '../../actions/GroupActions';
import { GROUP_DETAILS } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';

const GroupDetailsForm = createForm(GROUP_DETAILS, [DataStore], {
  getInitialState() {
    return {
      name: this.props.group.get('name') || '',
      type: this.props.group.get('type') || 'Group',
      typeChoices: this.props.group.get('organization').groupTypes(),
      description: this.props.group.get('description') || '',
    };
  },

  formStateFromStores() {
    const group = this.props.group;
    return {
      name: this.state.name || group.get('name') || '',
      type: this.state.type || group.get('type') || 'Group',
      typeChoices: group.get('organization').groupTypes(),
      description: this.state.description || group.get('description') || '',
    };
  },

  submit() {
    GroupActions.updateGroup(this.props.group, {
      name: this.state.name,
      type: this.state.type,
      description: this.state.description,
    }, GROUP_DETAILS);
  },

  render() {
    return (
      <Form
        classNameModifier="cub-Form--groupDetails"
        caption="Group details"
        error={this.state.errors.topError()}
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <GroupName
          value={this.state.name}
          required
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('name')}
        />

        <GroupType
          value={this.state.type}
          required
          data={this.state.typeChoices}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('type')}
        />

        <GroupDescription
          value={this.state.description}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('description')}
        />

        <Button
          type="submit"
          isProcessing={this.state.isProcessing}
          text="Save"
        />
      </Form>
    );
  },
});

export default GroupDetailsForm;
