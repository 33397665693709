import _ from 'underscore';
import AppDispatcher from '../dispatcher/AppDispatcher';
import CubApi from '../api/CubApi';
import FormActions from './FormActions';
import MemberActions from './MemberActions';
import UserStore from '../stores/UserStore';
import { GROUP_DELETED, GROUP_UPDATED } from '../constants/ActionTypes';

const GROUP_MEMBER_EXPAND = MemberActions.MEMBER_EXPAND
  .split(',')
  .map((item) => `member__${item}`)
  .concat(['group'])
  .join(',');

const GroupActions = {
  newGroup(data, formId) {
    FormActions.submitUserForm(formId, 'groups', data);
  },

  updateGroup(group, data, formId) {
    FormActions.submitUserForm(
      formId,
      `groups/${group.id}`,
      data,
      FormActions.dispatchFormSuccess,
      GroupActions.groupError.bind(this, group),
    );
  },

  getGroup(groupId) {
    CubApi.get(`groups/${groupId}`,
      { apiKey: UserStore.token(true), data: { expand: GROUP_MEMBER_EXPAND } },
      GroupActions.groupLoaded);
  },

  groupLoaded(response) {
    AppDispatcher.handleServerAction({
      actionType: GROUP_UPDATED,
      response,
    });
  },

  deleteGroup(group) {
    CubApi.del(
      `groups/${group.id}`,
      { apiKey: UserStore.token(true) },
      GroupActions.groupDeleted.bind(GroupActions, group),
      GroupActions.groupError.bind(GroupActions, group),
    );
  },

  groupDeleted(instance, response, meta, method, url, opts) {
    AppDispatcher.handleServerAction({
      actionType: GROUP_DELETED,
      instance,
    });
    FormActions.dispatchFormSuccess(response, meta, method, url, opts);
  },

  groupError(instance, response, meta, method, url, options) {
    const errCode = parseInt(meta && meta.code, 10);
    if (errCode === 404) {
      AppDispatcher.handleServerAction({
        actionType: GROUP_DELETED,
        instance,
      });
    }
    FormActions.dispatchFormError(response, meta, method, url, options);
  },

  newGroupMember(data, formId) {
    FormActions.submitUserForm(
      formId,
      'groupmembers/',
      _.extend({ expand: GROUP_MEMBER_EXPAND }, data),
    );
  },

  setGroupMemberPermissions(instance, data, formId) {
    FormActions.submitUserForm(
      formId,
      `groupmembers/${instance.id}/permissions`,
      _.extend({ expand: GROUP_MEMBER_EXPAND }, data),
      FormActions.dispatchFormSuccess,
      GroupActions.groupMemberError.bind(this, instance),
    );
  },

  deleteGroupMember(groupMember) {
    CubApi.del(
      `groupmembers/${groupMember.id}`,
      { apiKey: UserStore.token(true) },
      GroupActions.groupMemberDeleted.bind(GroupActions, groupMember),
      GroupActions.groupMemberError.bind(GroupActions, groupMember),
    );
  },

  groupMemberDeleted(instance, response, meta, method, url, opts) {
    let memberId = instance.get('member');
    if (_.isObject(memberId)) {
      memberId = memberId.id;
    }
    MemberActions.getMember(memberId);

    let groupId = instance.get('group');
    if (_.isObject(groupId)) {
      groupId = groupId.id;
    }
    GroupActions.getGroup(groupId);
    FormActions.dispatchFormSuccess(response, meta, method, url, opts);
  },

  groupMemberError(instance, response, meta, method, url, opts) {
    const errCode = parseInt(meta && meta.code, 10);
    if (errCode === 404) {
      // Reload member data
      let memberId = instance.get('member');
      if (_.isObject(memberId)) {
        memberId = memberId.id;
      }
      MemberActions.getMember(memberId);
    }
    FormActions.dispatchFormError(response, meta, method, url, opts);
  },
};

export default GroupActions;
