import React from 'react';

export default class StoreListener extends React.Component {
  constructor(props) {
    super(props);
    this.stores = [];
    this.onStoresChange = this.onStoresChange.bind(this);
    this.onStoresSuccess = this.onStoresSuccess.bind(this);
    this.getStateFromStores = this.getStateFromStores.bind(this);
  }

  componentWillMount() {
    this.stores.forEach((store) => {
      store.addChangeListener(this.onStoresChange);
      store.addSuccessListener(this.onStoresSuccess);
    });
    this.setState(this.getStateFromStores());
    if (typeof this.storeListenerWillMount === 'function') {
      this.storeListenerWillMount();
    }
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    this.stores.forEach((store) => {
      store.removeChangeListener(this.onStoresChange);
      store.removeSuccessListener(this.onStoresSuccess);
    });
    if (typeof this.storeListenerWillUnmount === 'function') {
      this.storeListenerWillUnmount();
    }
  }

  onStoresChange() {
    if (this.mounted) {
      this.setState(this.getStateFromStores());
    }
  }

  onStoresSuccess(...args) {
    if (typeof this.storesSuccess === 'function') {
      this.storesSuccess(...args);
    }
  }

  getStateFromStores() {
    return {};
  }

  storeListenerWillUnmount() {
    // TODO: Consider removing this method in favor of overriding
    //  `componentWillUnmount` in subclasses.
  }

  storesSuccess() {
    // TODO: Remove this method in favor of overriding `onStoresSuccess` in
    //  subclasses once `storeListenerMixin` is no longer used.
  }
}
