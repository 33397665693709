import PropTypes from 'prop-types';
import React from 'react';
import Button from './Button';
import Highlight from './Highlight';
import Selection from '../../utils/Selection';

export default class Grid extends React.Component {
  /**
   * Not using state because of race condition in componentWillReceiveProps
   */
  constructor(props) {
    super(props);
    this.itemsCollection = new Selection();
    if (props.orderBy) {
      this.itemsCollection.comparator = props.orderBy;
    }
    this.itemsCollection.set(props.items);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
    this.toggleAllSelected = this.toggleAllSelected.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const items = nextProps.items;
    const previousSelectionLength = this.itemsCollection.selection.length;
    this.itemsCollection.set(items);
    this.forceUpdate();
    if (previousSelectionLength !== this.itemsCollection.selection.length) {
      if (this.props.onSelect) {
        this.props.onSelect(this.itemsCollection.selected());
      }
    }
  }

  onSearchChange(e) {
    if (this.props.onSearchChange) {
      this.props.onSearchChange(e.target.value);
    }
  }

  onSearchClear(e) {
    if (this.props.onSearchChange) {
      this.props.onSearchChange('');
    }
    e.preventDefault();
  }

  toggleSelected(item) {
    this.itemsCollection.toggleSelection(item);
    this.forceUpdate();
    if (this.props.onSelect) {
      this.props.onSelect(this.itemsCollection.selected());
    }
  }

  toggleAllSelected() {
    if (this.itemsCollection.allSelected()) {
      this.itemsCollection.deselectAll();
    } else {
      this.itemsCollection.selectAll();
    }
    this.forceUpdate();
    if (this.props.onSelect) {
      this.props.onSelect(this.itemsCollection.selected());
    }
  }

  render() {
    const rows = [];
    let btns = null;
    let cells;
    let grid = null;
    let link;
    let select = null;
    let selectAll = null;
    let searchBar = null;
    let searchResultsHeader = null;
    let loadMoreBtn = null;

    const items = this.itemsCollection.models;
    if (this.props.onSearchChange) {
      if (this.props.foundCount === 0) {
        searchResultsHeader = 'No results';
      }
      if (this.props.search) {
        if (this.props.foundCount === 0) {
          searchResultsHeader = `No results for "${this.props.search}"`;
        } else if (this.props.foundCount > 0) {
          searchResultsHeader = `Looking for "${this.props.search}": ` +
              `${this.props.foundCount} found`;
        } else {
          searchResultsHeader = `Looking for "${this.props.search}"`;
        }
      }
      searchBar = (
        <div className="cub-Search">
          <div className="cub-InputGroup">
            <input
              className={'cub-FormControl cub-FormControl--input ' +
                'cub-InputGroup-input'}
              type="text"
              value={this.props.value}
              placeholder="Search..."
              maxLength={254}
              onChange={this.onSearchChange}
            />
            <Button
              classNameModifier="cub-InputGroup-button cub-Button--noIcon"
              onClick={this.onSearchClear}
              text="&times;"
            />
          </div>
          {searchResultsHeader && (
            <p className="cub-Search-results">{searchResultsHeader}</p>
          )}
        </div>
      );
    }

    if (this.props.loadMore) {
      loadMoreBtn = (
        <Button
          classNameModifier="cub-Button--next"
          onClick={this.props.loadMore}
          text="Load more"
        />
      );
    }

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      btns = this.props.btns ? <td>{this.props.btns(item)}</td> : null;
      if (this.props.onSelect) {
        /* eslint-disable react/jsx-no-bind */
        select = (
          <td>
            <input
              className="cub-FormControl cub-FormControl--checkbox"
              type="checkbox"
              checked={this.itemsCollection.isSelected(item)}
              onChange={this.toggleSelected.bind(this, item)}
            />
          </td>
        );
        /* eslint-enable react/jsx-no-bind */
      } else {
        select = null;
      }
      const displayFields = this.props.displayFields(item);
      link = this.props.links ? this.props.links(item) : null;
      cells = [];
      for (let j = 0, n = displayFields.length; j < n; j++) {
        if (link) {
          cells.push(
            <td key={j}>
              <Highlight
                elementType="a"
                href={link}
                text={displayFields[j]}
                highlight={this.props.search}
              />
            </td>,
          );
        } else {
          cells.push(
            <Highlight
              elementType="td"
              key={j}
              text={displayFields[j]}
              highlight={this.props.search}
            />,
          );
        }
      }
      rows.push(
        <tr key={item.id}>
          {select}
          {cells}
          {btns}
        </tr>,
      );
    }

    if (this.props.onSelect) {
      selectAll = (
        <th>
          <input
            className={'cub-FormControl cub-FormControl--checkbox ' +
                'cub-FormControl--checkboxSelectAll'}
            type="checkbox"
            checked={this.itemsCollection.allSelected()}
            onChange={this.toggleAllSelected}
          />
        </th>
      );
    }

    cells = this.props.cols.map((c, k) => <th key={k}>{c}</th>);
    if (items.length) {
      grid = (
        <table className="cub-Table">
          <thead>
            <tr>
              {selectAll}
              {cells}
              {this.props.btns ? <th /> : null}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      );
    }

    return (
      <div className={this.props.className}>
        {searchBar}
        {grid}
        {loadMoreBtn}
      </div>
    );
  }
}

Grid.propTypes = {
  btns: PropTypes.any,
  className: PropTypes.string,
  cols: PropTypes.array,
  displayFields: PropTypes.func,
  items: PropTypes.array,
  loadMore: PropTypes.func,
  links: PropTypes.func,
  value: PropTypes.string,
  search: PropTypes.string,
  foundCount: PropTypes.number,
  onSelect: PropTypes.func,
  onSearchChange: PropTypes.func,
  orderBy: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
};

Grid.defaultProps = {
  items: [],
};
