import PropTypes from 'prop-types';
import React from 'react';
import LabeledInput from './LabeledInput';

export default class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  toggleVisibility() {
    this.setState((prevState) => ({ hidden: !prevState.hidden }));
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  validate() {
    return this.input.validate();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  focus() {
    this.input.focus();
  }

  render() {
    const icon = this.state.hidden ? 'view' : 'hide';
    const type = this.state.hidden ? 'password' : 'text';
    const requirementsBlock = (
      <ul className="cub-List cub-Util-marginTop">
        <li className="cub-List-item">
          At least 8 characters
        </li>
        <li className="cub-List-item">
          At least one upper case, one lower case, and one number or special
          character
        </li>
        <li className="cub-List-item">
          No repetitive or sequential characters (aaaa, 1234, qwerty)
        </li>
        <li className="cub-List-item">
          No personal data (first or last name, or email)
        </li>
      </ul>
    );
    return (
      <LabeledInput
        ref={(input) => { this.input = input; }}
        value={this.props.value}
        type={type}
        inputIcon={icon}
        error={this.props.error}
        classNameModifier={this.props.classNameModifier}
        label={this.props.label}
        maxLength={this.props.maxLength}
        name={this.props.name}
        onChange={this.props.onChange}
        onIconClick={this.toggleVisibility}
        placeholder={this.props.placeholder}
        required={this.props.required}
        description={
          this.props.showRequirements ? requirementsBlock : null
        }
      />
    );
  }
}

Password.propTypes = {
  error: PropTypes.any,
  classNameModifier: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  showRequirements: PropTypes.bool,
};

Password.defaultProps = {
  classNameModifier: 'cub-FormControl--password',
  label: 'Password',
  maxLength: 64,
  name: 'password',
  showRequirements: false,
};
