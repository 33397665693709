import _ from 'underscore';
import createReactClass from 'create-react-class';
import React from 'react';
import AppStore from '../stores/AppStore';
import ConfigStore from '../stores/ConfigStore';
import DataStore from '../stores/DataStore';
import MenuItem from './MenuItem';
import NavLink from './basic/NavLink';
import storeListenerMixin from '../mixins/storeListenerMixin';
import UserActions from '../actions/UserActions';
import Image from './basic/Image';
import {
  addEventListener,
  removeEventListener,
  setQueryString,
  siteSentry,
} from '../utils/Utils';

/* eslint-disable react/prefer-es6-class */
const Menu = createReactClass({
  /* eslint-disable react/no-unused-class-component-methods,react/sort-comp */
  mixins: [storeListenerMixin(AppStore, DataStore)],

  getStateFromStores() {
    const site = DataStore.currentSite();
    const user = DataStore.currentUser();
    const member = DataStore.currentMember();
    const customUserMenuItems = ConfigStore.get('customUserMenuItems');
    let memberships;
    let hasGroupsAccess = false;
    let hasMembersAccess = false;
    if (member) {
      memberships = user.orderedMembershipAlphabetical();
      hasMembersAccess = member.get('is_admin');
      hasGroupsAccess = hasMembersAccess || member.isGroupOwner();
    }
    return {
      memberships,
      currentRoute: AppStore.currentRoute(),
      enableBilling: site && site.activePlans().length > 0,
      enableBenefits: site && (
        site.availableBenefits().length > 0 ||
        site.get('has_segmented_benefits')
      ),
      enableSubscriptions: user && user.get('email') && site &&
        site.mailingLists().length > 0,
      fullName: user ? user.fullName() : '',
      hasGroupsAccess,
      hasMembersAccess,
      isAnonymous: !user,
      routes: AppStore.routes(),
      selectedMember: member,
      site,
      user,
      customUserMenuItems,
    };
  },

  storeListenerWillUnmount() {
    removeEventListener(document, 'click', this.clickAway, false);
  },
  /* eslint-enable react/no-unused-class-component-methods,react/sort-comp */

  getInitialState() {
    return {
      organizationMenuOpen: false,
      userMenuOpen: false,
    };
  },

  componentDidMount() {
    addEventListener(document.documentElement, 'click', this.clickAway, false);
  },

  toggleUserMenu(e) {
    this.setState((prevState) => ({ userMenuOpen: !prevState.userMenuOpen }));
    e.preventDefault();
  },

  toggleOrganizationMenu(e) {
    this.setState((prevState) => ({
      organizationMenuOpen: !prevState.organizationMenuOpen,
    }));
    e.preventDefault();
  },

  clickAway(e) {
    if (!e.target.getAttribute) {
      siteSentry.setExtras({
        Menu_clickAway_target: e.target,
        Menu_clickAway_event: e,
      });
      this.setState({ userMenuOpen: false, organizationMenuOpen: false });
      return;
    }
    const attr = e.target.getAttribute('data-purpose');
    if (attr !== 'menu-toggle') {
      this.setState({ userMenuOpen: false });
    }
    if (attr !== 'org-toggle') {
      this.setState({ organizationMenuOpen: false });
    }
  },

  organizationSwitch(organizationId, e) {
    UserActions.setCurrentOrganization(organizationId);
    this.setState({ organizationMenuOpen: false });
    e.preventDefault();
  },

  render() {
    const currentRoute = this.state.currentRoute;
    const routes = this.state.routes;
    const user = this.state.user;
    const menuOpen = this.state.userMenuOpen ||
      this.state.organizationMenuOpen;
    const menuClassNames = {
      container: 'cub-Menu',
      itemsContainer: 'cub-Menu-itemsContainer',
      items: 'cub-Menu-items',
      item: 'cub-Menu-item',
      link: 'cub-Menu-link',
      notification: 'cub-Menu-notification',
      submenuItems: 'cub-Menu-submenuItems',
      submenuItem: 'cub-Menu-submenuItem',
      submenuLink: 'cub-Menu-submenuLink',
      submenuText: 'cub-Menu-submenuText',

      modifiers: {
        login: 'cub-Menu-link--login',
        register: 'cub-Menu-link--register',

        user: 'cub-Menu-item--user',
        userMenu: 'cub-Menu-link--userMenu',
        profile: 'cub-Menu-submenuLink--profile',
        billing: 'cub-Menu-submenuLink--billing',
        subscriptions: 'cub-Menu-submenuLink--subscriptions',
        memberBenefits: 'cub-Menu-submenuLink--memberBenefits',
        changePassword: 'cub-Menu-submenuLink--changePassword',
        logout: 'cub-Menu-submenuLink--logout',
        userPhoto: 'cub-Menu-submenuLink--userPhoto',
        custom: 'cub-Menu-submenuLink--custom',

        org: 'cub-Menu-item--org',
        orgItem: 'cub-Menu-submenuItem--org',
        orgMembers: 'cub-Menu-submenuLink--orgMembers',
        orgGroups: 'cub-Menu-submenuLink--orgGroups',
        orgMenu: 'cub-Menu-link--orgMenu',
        orgSwitcher: 'cub-Menu-submenuLink--orgSwitcher',
        divider: 'cub-Menu-submenuItem--divider',
      },

      states: {
        menuOpened: 'is-menuOpened',
        hasNotifications: 'is-showNotifications',
      },
    };

    const site = this.state.site;
    if (this.state.isAnonymous) {
      const menuItems = [];
      if (site && site.loginEnabled()) {
        let loginUrl;
        let registerUrl;
        if (!_.isEmpty(routes)) {
          loginUrl = routes.LOGIN.url;
          if (currentRoute.route === routes.REGISTER_FOR_A_PLAN) {
            loginUrl = setQueryString(
              loginUrl,
              { next: routes.USER_BILLING.url },
            );
          }
          registerUrl = routes.REGISTER.url;
        }
        menuItems.push(
          <MenuItem
            key="login"
            className={
              `${menuClassNames.link} ${menuClassNames.modifiers.login}`
            }
            href={loginUrl}
            subMenuItem={false}
          >
            Sign In
          </MenuItem>,
        );
        if (site.passLoginEnabled()) {
          menuItems.push(
            <MenuItem
              key="register"
              className={
                `${menuClassNames.link} ${menuClassNames.modifiers.register}`
              }
              href={registerUrl}
              subMenuItem={false}
            >
              Register
            </MenuItem>,
          );
        }
      }
      return (
        <nav className={menuClassNames.container}>
          <ul className={menuClassNames.items}>
            {menuItems}
          </ul>
        </nav>
      );
    }

    let userMenu = null;
    const notificationsCount = user.pendingNotifications().length;
    if (this.state.userMenuOpen) {
      const userMenuItems = [];
      let notifications;
      let notificationsClass = '';
      if (notificationsCount) {
        notificationsClass = menuClassNames.states.hasNotifications;
        notifications = (
          <span className={menuClassNames.notification}>
            {notificationsCount}
          </span>
        );
      }
      userMenuItems.push(
        <MenuItem
          className={`${menuClassNames.submenuLink}` +
              ` ${menuClassNames.modifiers.profile}` +
              ` ${notificationsClass}`}
          key="profile"
          route={routes.USER_PROFILE}
        >
          My profile
          {notifications}
        </MenuItem>,
      );
      if (this.state.enableSubscriptions) {
        userMenuItems.push(
          <MenuItem
            className={`${menuClassNames.submenuLink}` +
                ` ${menuClassNames.modifiers.subscriptions}`}
            key="subscriptions"
            route={routes.NEWSLETTERS}
          >
            My newsletters
          </MenuItem>,
        );
      }
      if (this.state.enableBenefits) {
        userMenuItems.push(
          <MenuItem
            className={`${menuClassNames.submenuLink}` +
                ` ${menuClassNames.modifiers.memberBenefits}`}
            key="member-benefits"
            route={routes.MEMBER_BENEFITS}
          >
            My benefits
          </MenuItem>,
        );
      }
      if (this.state.enableBilling) {
        userMenuItems.push(
          <MenuItem
            className={`${menuClassNames.submenuLink}` +
                ` ${menuClassNames.modifiers.billing}`}
            key="billing"
            route={routes.USER_BILLING}
          >
            Billing
          </MenuItem>,
        );
      }
      if (site.passLoginEnabled()) {
        userMenuItems.push(
          <MenuItem
            className={`${menuClassNames.submenuLink}` +
                ` ${menuClassNames.modifiers.changePassword}`}
            key="change-password"
            route={routes.CHANGE_PASSWORD}
          >
            Change password
          </MenuItem>,
        );
      }
      if (_.isArray(this.state.customUserMenuItems)) {
        this.state.customUserMenuItems
          .filter((item) => item && item.name && item.title && item.href)
          .forEach((item) => {
            userMenuItems.push(
              <MenuItem
                className={`${menuClassNames.submenuLink}` +
                ` ${menuClassNames.modifiers.custom}-${item.name}`}
                key={item.name}
                href={item.href}
              >
                {item.title}
              </MenuItem>,
            );
          });
      }
      userMenu = (
        <div className={menuClassNames.itemsContainer}>
          <NavLink
            className={`${menuClassNames.submenuLink}` +
                ` ${menuClassNames.modifiers.userPhoto}`}
            route={routes.USER_PROFILE}
          >
            <Image
              classNameModifier="cub-Util-floatLeft"
              src={user.getPhoto('photo_large')}
              srcDefault={ConfigStore.static('img/user_large.png')}
              size="medium"
              altText="Me"
            />
          </NavLink>
          <ul className={menuClassNames.submenuItems}>
            {userMenuItems}
            <MenuItem
              className={`${menuClassNames.submenuLink}` +
                  ` ${menuClassNames.modifiers.logout}`}
              key="logout"
              route={routes.LOGOUT}
            >
              Logout
            </MenuItem>
          </ul>
        </div>
      );
    }

    const organizationMenuItems = [];
    let organization = null;
    if (this.state.selectedMember) {
      const selectedMemberOrg = this.state.selectedMember.get('organization');
      if (!selectedMemberOrg) {
        siteSentry.setExtras({
          Menu_render_selectedMember: this.state.selectedMember,
        });
      }
      let orgName;
      try {
        orgName = selectedMemberOrg.get('name');
      } catch (e) {
        siteSentry.captureException(e, {
          extra: {
            selectedMember: this.state.selectedMember,
            selectedMemberOrg,
          },
        });
        orgName = selectedMemberOrg.name;
      }
      if (this.state.hasMembersAccess) {
        organizationMenuItems.push(
          <MenuItem
            className={`${menuClassNames.submenuLink}` +
              ` ${menuClassNames.modifiers.orgMembers}`}
            key="users"
            route={routes.MEMBERS_ACTIVE}
          >
            Manage users
          </MenuItem>,
        );
      }
      if (this.state.hasGroupsAccess) {
        organizationMenuItems.push(
          <MenuItem
            className={`${menuClassNames.submenuLink}` +
              ` ${menuClassNames.modifiers.orgGroups}`}
            key="groups"
            route={routes.GROUPS}
          >
            Manage groups
          </MenuItem>,
        );
      }
      if (this.state.memberships.length) {
        organizationMenuItems.push(
          <li
            className={`${menuClassNames.submenuItem}` +
              ` ${menuClassNames.modifiers.divider}`}
            key="divider"
          >
            <span className={menuClassNames.submenuText}>
              Switch organization
            </span>
          </li>,
        );
        for (let i = 0, l = this.state.memberships.length; i < l; i++) {
          const org = this.state.memberships[i].get('organization');
          let submenuClassNames =
            `${menuClassNames.submenuItem} ${menuClassNames.modifiers.orgItem}`;
          let activeIcon;
          if (selectedMemberOrg.get('id') === org.get('id')) {
            submenuClassNames += ' is-active';
            activeIcon = (
              <span
                className="cub-Menu-submenuIcon cub-Menu-submenuIcon--next"
              />
            );
          }
          /* eslint-disable react/jsx-no-bind */
          organizationMenuItems.push(
            <li className={submenuClassNames} key={org.id}>
              <a
                className={`${menuClassNames.submenuLink}` +
                  ` ${menuClassNames.modifiers.orgSwitcher}`}
                href={routes.HOME.makeUrl()}
                data-purpose="org-toggle"
                onClick={this.organizationSwitch.bind(this, org.id)}
              >
                {activeIcon}
                {org.get('name')}
              </a>
            </li>,
          );
          /* eslint-enable react/jsx-no-bind */
        }
      }
      if (organizationMenuItems.length) {
        const orgLink = (
          <NavLink
            className={`${menuClassNames.link}` +
              ` ${menuClassNames.modifiers.orgMenu}`}
            dataPurpose="org-toggle"
            onClick={this.toggleOrganizationMenu}
            route={routes.HOME}
          >
            {orgName}
          </NavLink>
        );
        if (this.state.organizationMenuOpen) {
          organization = (
            <li
              className={`${menuClassNames.item}` +
                ` ${menuClassNames.modifiers.org}`}
              key="org"
            >
              {orgLink}
              <div className={menuClassNames.itemsContainer}>
                <ul className={menuClassNames.submenuItems}>
                  {organizationMenuItems}
                </ul>
              </div>
            </li>
          );
        } else {
          organization = (
            <li
              className={`${menuClassNames.item}` +
                ` ${menuClassNames.modifiers.org}`}
              key="org"
            >
              {orgLink}
            </li>
          );
        }
      } else {
        organization = (
          <li
            className={`${menuClassNames.item}` +
              ` ${menuClassNames.modifiers.org}`}
            key="org"
          >
            {orgName}
          </li>
        );
      }
    }

    return (
      <nav className={`${menuClassNames.container}` +
        ` ${menuOpen ? menuClassNames.states.menuOpened : ''}`}
      >
        <ul className={menuClassNames.items}>
          <li
            className={
              `${menuClassNames.item} ${menuClassNames.modifiers.user} ` +
                  `${notificationsCount ?
                    menuClassNames.states.hasNotifications : ''}`
            }
            key="user"
          >
            <NavLink
              className={
                `${menuClassNames.link} ${menuClassNames.modifiers.userMenu}`
              }
              dataPurpose="menu-toggle"
              route={routes.USER_PROFILE}
              onClick={this.toggleUserMenu}
            >
              <Image
                dataPurpose="menu-toggle"
                src={user.getPhoto('photo_small')}
                srcDefault={ConfigStore.static('img/user_small.png')}
                size="small"
                altText="Me"
              />
              {this.state.fullName}
            </NavLink>
            {userMenu}
          </li>
          {organization}
        </ul>
      </nav>
    );
  },
});

export default Menu;
