import PropTypes from 'prop-types';
import React from 'react';

export default class Month extends React.Component {
  render() {
    return (
      <select
        className={'cub-FormControl cub-FormControl--select' +
          ` ${this.props.classNameModifier}`}
        name={this.props.name}
        id={this.props.id}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        <option value="">Choose month...</option>
        <option value="1">January</option>
        <option value="2">February</option>
        <option value="3">March</option>
        <option value="4">April</option>
        <option value="5">May</option>
        <option value="6">June</option>
        <option value="7">July</option>
        <option value="8">August</option>
        <option value="9">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </select>
    );
  }
}

Month.propTypes = {
  classNameModifier: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

Month.defaultProps = {
  classNameModifier: 'cub-FormControl--month',
};
