import PropTypes from 'prop-types';
import React from 'react';
import LabeledInput from './LabeledInput';

export default class BirthDate extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    if (this.props.onChange) {
      // Substitute event.target with a surrogate with normalized value
      event.target = {
        name: event.target.name,
        value: this.toISO(event.target.value),
      };
      this.props.onChange(event);
    }
  }

  /**
   * Convert YYYY-MM-DD date string to MM/DD/YYYY.
   *
   * @param date - date in YYYY-MM-DD format, or empty string, or null
   * @returns {*} - date converted to MM/DD/YYYY format,
   *                or original value if conversion is not possible
   */
  fromISO(date) {
    if (typeof date === 'string') {
      const parts = date.split('-');
      if (parts.length === 3) {
        return [parts[1], parts[2], parts[0]].join('/');
      }
    }
    return date;
  }

  /**
   * Convert MM/DD/YYYY date string to YYYY-MM-DD.
   *
   * @param date - date in MM/DD/YYYY format, or empty string, or null
   * @returns {*} - date converted to YYYY-MM-DD format,
   *                or original value if conversion is not possible
   */
  toISO(date) {
    if (typeof date === 'string') {
      const parts = date.split('/');
      if (parts.length === 3) {
        return [parts[2], parts[0], parts[1]].join('-');
      }
    }
    return date;
  }

  render() {
    return (
      <LabeledInput
        classNameModifier="cub-FormControl--birthDate"
        label={this.props.label || 'Date of Birth'}
        required={this.props.required}
        name="birth_date"
        maxLength={10}
        value={this.fromISO(this.props.value)}
        placeholder="MM/DD/YYYY"
        onChange={this.onChange}
        error={this.props.error}
        disabled={this.props.disabled}
      />
    );
  }
}

BirthDate.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};
