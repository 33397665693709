import React from 'react';
import PropTypes from 'prop-types';
import Image from '../basic/Image';
import ImageUploadForm from '../forms/ImageUploadForm';

export default class ImageControl extends React.Component {
  render() {
    const props = this.props;
    const className = `cub-ImageControl ${props.classNameModifier}`;
    let removeBtnText = null;
    let uploadBtnText = 'Upload photo';
    if (props.src) {
      removeBtnText = 'Remove photo';
      uploadBtnText = 'Change photo';
    }

    return (
      <div className={className}>
        <Image
          classNameModifier={props.imageClassNameModifier}
          src={props.src}
          srcDefault={props.srcDefault}
          size={props.size}
          altText={props.altText}
        />
        <ImageUploadForm
          apiEndpoint={props.apiEndpoint}
          expand={props.expand}
          errorClassNameModifier={props.errorClassNameModifier}
          onRemove={props.onRemove}
          onRemoveDone={props.onRemoveDone}
          onRemoveStarted={props.onRemoveStarted}
          onS3UploadDone={props.onS3UploadDone}
          onUploadDone={props.onUploadDone}
          removeBtnText={removeBtnText}
          uploadBtnText={uploadBtnText}
        />
      </div>
    );
  }
}

ImageControl.propTypes = {
  classNameModifier: PropTypes.string,
  altText: PropTypes.string,
  apiEndpoint: PropTypes.string,
  errorClassNameModifier: PropTypes.string,
  expand: PropTypes.string,
  imageClassNameModifier: PropTypes.string,
  onRemove: PropTypes.func,
  onRemoveDone: PropTypes.func,
  onRemoveStarted: PropTypes.func,
  onS3UploadDone: PropTypes.func,
  onUploadDone: PropTypes.func,
  size: PropTypes.string,
  src: PropTypes.string,
  srcDefault: PropTypes.string,
};

ImageControl.defaultProps = {
  classNameModifier: '',
  altText: '',
  apiEndpoint: '',
  size: 'large',
};
