import _ from 'underscore';
import StoreListener from './StoreListener';
import FormStore from '../stores/FormStore';
import FormActions from '../actions/FormActions';

export default class FormListener extends StoreListener {
  constructor(props) {
    super(props);
    this.formId = null;
    this.skipClearingFormErrorFor = new Set();
    this.stores.push(FormStore);

    this.onInputChange = this.onInputChange.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  getStateFromStores() {
    const formState = {
      errors: FormStore.errors(this.formId),
      isProcessing: FormStore.isProcessing(this.formId),
    };
    if (typeof this.formStateFromStores === 'function') {
      _.extend(formState, this.formStateFromStores(formState));
    }
    return formState;
  }

  // eslint-disable-next-line no-unused-vars
  formStateFromStores(formState) {
    // TODO: Consider removing this method in favor of overriding
    //  `getStateFromStores` in subclasses.
    return {};
  }

  onInputChange(event) {
    // can accept either native input change event,
    // or {name: <name>, value: <value>} object
    const evt = event.target || event;
    const value = (evt.type === 'checkbox' && !evt.checked) ? '' : evt.value;
    this.onFieldChange(evt.name, value);
  }

  onFieldChange(name, value) {
    if (
      !this.skipClearingFormErrorFor.has(name) &&
      this.state.errors.fieldError(name)
    ) {
      FormActions.clearFormError(this.formId, name);
    }
    const change = {};
    change[name] = value;
    this.setState(change);
  }
}
