import LabeledInput from './LabeledInput';

export default class LastName extends LabeledInput {}

LastName.defaultProps = {
  classNameModifier: 'cub-FormControl--lastName',
  label: 'Last Name',
  maxLength: 50,
  name: 'last_name',
};
