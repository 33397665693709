import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import ApiActions from '../../actions/ApiActions';
import regflow from '../../utils/regflow';
import BankCard from './BankCard';
import DataStore from '../../stores/DataStore';
import Loading from '../basic/Loading';
import PlanBankCardForm from '../forms/PlanBankCardForm';
import PlanForm from '../forms/PlanForm';
import router from '../../router/router';
import ServiceSubscription from './ServiceSubscription';
import { parseQueryString, setQueryString } from '../../utils/Utils';
import CaptionLarge from '../basic/CaptionLarge';
import Text from '../basic/Text';

export default class UserBilling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // null - loading not started, false - started, but not finished yet
      customerLoaded: null,

      defaultCard: null,
      serviceSubscription: null,
      mode: 'info',
    };
    this.onSuccess = this.onSuccess.bind(this);
  }

  componentWillMount() {
    window.setTimeout(() => {
      ApiActions.getAll(
        'customers',
        { user: this.props.user.id, expand: 'cards,service_subscriptions' },
      );
      this.setState({ customerLoaded: false });
    });
  }

  componentWillReceiveProps(nextProps) {
    const state = {
      defaultCard: null,
      currentServiceSubscription: null,
    };
    const user = nextProps.user;
    const customer = user.related.customer && user.related.customer[0];
    if (customer) {
      state.defaultCard = customer.defaultCard();
      state.serviceSubscription = customer.serviceSubscription(nextProps.site);
    }
    // customerLoaded === null means loading wasn't even started yet
    if (this.state.customerLoaded === false) {
      if (!DataStore.nowLoading('customers')) {
        state.customerLoaded = true;
        if (!state.serviceSubscription && this.props.defaultPlanId) {
          state.mode = state.defaultCard ? 'plan' : 'card';
        }
      }
    }
    this.setState(state);
  }

  onSuccess() {
    if (this.props.defaultPlanId && regflow.inprogress()) {
      const nextUrl = setQueryString(
        this.props.routes.EXPERIENCE.url,
        parseQueryString(),
      );
      router.navigate(nextUrl);
    } else {
      this.setState({ mode: 'info' });
    }
  }

  defaultPlan() {
    return this.props.defaultPlanId && _.find(
      this.props.site.activePlans(),
      (plan) => plan.id === this.props.defaultPlanId,
    );
  }

  render() {
    if (!this.state.customerLoaded) {
      return <Loading />;
    }

    const setMode = (newMode) => (
      ((mode, e) => {
        this.setState({ mode });
        if (e && typeof e.preventDefault === 'function') {
          e.preventDefault();
        }
      }).bind(this, newMode)
    );

    const caption = (<CaptionLarge>Your plan details</CaptionLarge>);
    const classBilling = 'cub-Billing';
    const plans = this.props.site.activePlans();
    const ss = this.state.serviceSubscription;
    if (!ss && !plans.length) {
      return (
        <div className={classBilling}>
          {caption}
          <Text>This site has no paid plans yet. Enjoy using it for free!</Text>
        </div>
      );
    }

    switch (this.state.mode) {
      case 'info':
        return (
          <div className={classBilling}>
            {caption}
            <BankCard
              card={this.state.defaultCard}
              onChange={setMode('card')}
            />
            <ServiceSubscription
              serviceSubscription={ss}
              onChange={setMode(this.state.defaultCard ? 'plan' : 'card')}
            />
          </div>
        );

      case 'card': {
        const showPlans = !this.state.defaultCard && !ss;
        return (
          <div className={classBilling}>
            <CaptionLarge>Enter your card details</CaptionLarge>
            <PlanBankCardForm
              onSuccess={this.onSuccess}
              onCancel={!this.props.defaultPlanId && setMode('info')}
              site={this.props.site}
              plans={showPlans && plans}
              user={this.props.user}
              initialPlan={showPlans && (this.defaultPlan() || plans[0])}
            />
          </div>
        );
      }

      case 'plan': {
        const currentPlan = ss && !ss.get('cancel_at_period_end') &&
            !ss.expired() && ss.get('plan');
        const currentProduct = currentPlan && currentPlan.get('product');
        return (
          <div className={classBilling}>
            <CaptionLarge>
              {currentPlan ? 'Change plan' : 'Sign up'}
            </CaptionLarge>
            {currentPlan && (
              <Text>
                <span className="cub-Plan">
                  Current plan:{' '}
                  <span className="cub-Plan-name">
                    {currentProduct.get('name')}
                  </span>
                  {' '}
                  <span className="cub-Plan-price">
                    {currentPlan.price()}
                  </span>
                  <span className="cub-Plan-description">
                    {currentPlan.get('description') || ''}
                  </span>
                </span>
              </Text>
            )}
            <PlanForm
              customer={this.state.defaultCard.get('customer')}
              initialPlan={this.defaultPlan()}
              onSuccess={this.onSuccess}
              onCancel={setMode('info')}
              plans={plans}
              serviceSubscription={ss}
            />
          </div>
        );
      }

      default:
        return null;
    }
  }
}

UserBilling.propTypes = {
  defaultPlanId: PropTypes.string,
  routes: PropTypes.object,
  site: PropTypes.object,
  user: PropTypes.object,
};
