import LabeledInput from './LabeledInput';

export default class Phone extends LabeledInput {}

Phone.defaultProps = {
  classNameModifier: 'cub-FormControl--phone',
  label: 'Phone',
  maxLength: 30,
  name: 'phone',
  placeholder: '1 555 123 4567',
};
