import _ from 'underscore';
import React from 'react';
import createForm from '../../mixins/createForm';
import LabeledCheckbox from '../fields/LabeledCheckbox';
import router from '../../router/router';
import UserActions from '../../actions/UserActions';
import { NEWSLETTERS } from '../../constants/FormIds';
import { parseQueryString } from '../../utils/Utils';
import regflow from '../../utils/regflow';
import Button from '../basic/Button';
import Form from '../basic/Form';
import NavLink from '../basic/NavLink';
import NewsletterSample from '../views/NewsletterSample';

const NewslettersForm = createForm(NEWSLETTERS, {
  getInitialState() {
    const subscribe = {};
    this.props.user.subscribedMailingLists().forEach((ml) => {
      subscribe[ml.id] = true;
    });
    return {
      mailingLists: this.props.site.mailingLists(),
      subscribe,
      currentMailing: null,
      registration: regflow.inprogress(),
    };
  },

  getCurrentMailing(currentMailingName) {
    let result = null;
    this.state.mailingLists.forEach((ml) => {
      if (ml.id === currentMailingName) {
        result = ml;
      }
    });
    return result;
  },

  checkCurrentMailing() {
    const ml = this.state.currentMailing;
    return (ml) ? this.state.subscribe[ml.id] : false;
  },

  subscribe(id) {
    const subscribe = _.extend({}, this.state.subscribe);
    subscribe[id] = !subscribe[id];
    this.setState({ subscribe });
  },

  onViewSample(e) {
    const id = e.target.parentElement.getAttribute('id');
    this.setState({ currentMailing: this.getCurrentMailing(id) });
    e.preventDefault();
  },

  onCloseSample(e) {
    this.setState({ currentMailing: null });
    e.preventDefault();
  },

  onCloseSampleAndSubscribe(e) {
    const id = this.state.currentMailing.get('id');
    this.subscribe(id);
    this.onCloseSample(e);
  },

  onSubscriptionChange(e) {
    this.subscribe(e.target.name);
  },

  submit() {
    const subscribe = [];
    const unsubscribe = [];
    this.state.mailingLists.forEach((ml) => {
      const id = ml.id;
      if (this.state.subscribe[id]) {
        subscribe.push(id);
      } else {
        unsubscribe.push(id);
      }
    });
    UserActions.updateSubscriptions({
      subscribe: subscribe.join(','),
      unsubscribe: unsubscribe.join(','),
    }, NEWSLETTERS);
  },

  storesSuccess(formId) {
    if (formId === NEWSLETTERS) {
      if (regflow.inprogress()) {
        regflow.finish();
      }
      const redirect = router.getFirstLoginRedirect().url;
      router.navigate(parseQueryString().next || redirect);
    }
  },

  render() {
    let mailingLists = [];
    this.state.mailingLists.forEach((ml) => {
      mailingLists.push(
        <li className="cub-List-item" key={ml.id}>
          <LabeledCheckbox
            name={ml.id}
            label={[
              ml.get('name'),
              ml.get('sample') && (
              <NavLink
                id={ml.id}
                className="cub-Link cub-Link--mlSample"
                onClick={this.onViewSample}
              >
                <span className="cub-Icon cub-Icon--view" />
                <span className="cub-Text cub-Text--sm">
                  (click to preview)
                </span>
              </NavLink>
              ),
            ]}
            description={ml.get('description')}
            checked={this.state.subscribe[ml.id]}
            onChange={this.onSubscriptionChange}
          />
        </li>,
      );
    });
    if (mailingLists.length) {
      mailingLists = (
        <ul className="cub-List cub-List--checklist">
          {mailingLists}
        </ul>
      );
    }

    const btnText = this.state.registration ? 'Complete registration' : 'Done';

    return (
      (this.state.currentMailing) ? (
        <NewsletterSample
          checked={this.checkCurrentMailing()}
          mailing={this.state.currentMailing}
          onSubscribe={this.onCloseSampleAndSubscribe}
          onClose={this.onCloseSample}
        />
      ) : (
        <Form
          classNameModifier="cub-Form--manageSubscriptions"
          caption="My newsletters"
          onSubmit={this.submit}
          isProcessing={this.state.isProcessing}
          disableWhenProcessing
        >
          {mailingLists}
          <Button
            type="submit"
            isProcessing={this.state.isProcessing}
            text={btnText}
          />
        </Form>
      )
    );
  },
});

export default NewslettersForm;
