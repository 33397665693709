import PropTypes from 'prop-types';
import React from 'react';
import Smartdate from '../basic/Smartdate';
import Button from '../basic/Button';
import Text from '../basic/Text';
import Caption from '../basic/Caption';
import { siteSentry } from '../../utils/Utils';

export default class ServiceSubscription extends React.Component {
  render() {
    const ss = this.props.serviceSubscription;
    const activeTill = ss && ss.get('active_till');
    const expired = ss && ss.expired();
    const classNames = {
      ss: 'cub-ServiceSubscriptions',
      ssInfo: 'cub-ServiceSubscriptions-info',
      utils: '',
    };
    let caption = (<Caption>Current plan</Caption>);

    let plan;
    let productName;
    if (ss) {
      plan = ss.get('plan');
      const product = plan.get('product');
      try {
        productName = product.get('name');
      } catch (e) {
        siteSentry.captureException(e, { extra: { plan, product } });
        productName = product.name;
      }
    }

    if (!ss || expired) {
      let msg;
      if (expired) {
        msg = (
          <Text>
            Your &quot;{productName}&quot; subscription has
            expired on <Smartdate date={activeTill} mode="date" />
          </Text>
        );
      } else {
        msg = <Text>You have no active subscription yet</Text>;
        caption = null;
        classNames.utils = 'cub-Util-floatNone cub-Util-width100';
      }
      return (
        <div className={classNames.ss}>
          {caption}
          <div className={`${classNames.ssInfo} ${classNames.utils}`}>
            {msg}
          </div>
          {this.props.onChange && (
            <Button
              classNameModifier={expired ? 'cub-Button--changePlan' : ''}
              onClick={this.props.onChange}
              text={expired ? 'Renew' : 'Sign up'}
            />
          )}
        </div>
      );
    }

    let nextCharge;
    let changeBtnTitle = 'Change';
    if (activeTill) {
      if (ss.get('cancel_at_period_end')) {
        nextCharge = (
          <Text>
            Your subscription will
            expire <Smartdate date={activeTill} mode="future" />
            . It will not auto-renew, since you have chosen to cancel it.
          </Text>
        );
        changeBtnTitle = 'Renew';
      } else {
        nextCharge = (
          <Text>
            Next charge will be on <Smartdate mode="date" date={activeTill} />
          </Text>
        );
      }
    }
    const discountDescription = ss.discountDescription();
    return (
      <div className={classNames.ss}>
        {caption}
        <div className={classNames.ssInfo}>
          <Text>
            <span className="cub-Plan">
              <span className="cub-Plan-name">{productName}</span>
              ,{' '}
              <span className="cub-Plan-price">
                {plan.price(ss)}
              </span>
              <span className="cub-Plan-description">
                {plan.get('description') || ''}
              </span>
            </span>
          </Text>
          {discountDescription && (
            <Text>
              Discount applied: {ss.discountDescription()}{' '}
              (original price - {plan.price()})
            </Text>
          )}
          {nextCharge}
        </div>
        {this.props.onChange && (
          <Button
            classNameModifier={'cub-Button--edit ' +
              'cub-Button--changePlan'}
            onClick={this.props.onChange}
            text={changeBtnTitle}
          />
        )}
      </div>
    );
  }
}

ServiceSubscription.propTypes = {
  serviceSubscription: PropTypes.object,
  onChange: PropTypes.func,
};
