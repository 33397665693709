import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import { escapeRegExp } from '../../utils/Utils';

function highlighted(text, highlight) {
  const txt = text || '';
  const highlightLcase = (highlight || '').toLowerCase();
  const lcase = txt.toLowerCase();

  const chunks = highlightLcase.replace(/^\s+|\s+$/g, '').split(/\s+/);
  const rx = new RegExp(
    `(${_.map(chunks, (c) => escapeRegExp(c)).join('|')})`,
    'i',
  );

  const parts = lcase.split(rx);
  if (!highlightLcase || !chunks.length || parts.length === 1) {
    return txt;
  }

  const result = [];
  let piece;
  let pos = 0;
  let isHighlighted = true;
  for (let i = 0, l = parts.length; i < l; i++) {
    isHighlighted = !isHighlighted;
    const part = parts[i];
    if (part) {
      piece = txt.slice(pos, part.length + pos); // text before match
      if (piece) {
        result.push(
          isHighlighted ?
            <span className="cub-Highlight" key={`h${i}`}>{piece}</span> :
            <span key={i}>{piece}</span>,
        );
      }
      pos += part.length;
    }
  }
  return result;
}

export default class Highlight extends React.Component {
  render() {
    const props = _.omit(this.props, 'elementType', 'text', 'highlight');
    return React.createElement(
      this.props.elementType,
      props,
      highlighted(this.props.text, this.props.highlight),
    );
  }
}

Highlight.propTypes = {
  elementType: PropTypes.string,
  highlight: PropTypes.string,
  text: PropTypes.string,
};

Highlight.defaultProps = {
  elementType: 'span',
};
