import PropTypes from 'prop-types';
import React from 'react';
import NavLink from '../basic/NavLink';
import CaptionLarge from '../basic/CaptionLarge';
import Text from '../basic/Text';

export default class UsernameChangeDone extends React.Component {
  render() {
    return (
      <div className={'cub-InfoBlock cub-InfoBlock--usernameChanged' +
          ' isDataSaved'}
      >
        <CaptionLarge>Username has been changed</CaptionLarge>
        <Text>
          Your username has been changed to{' '}
          <span className="cub-Text-dataValue">
            {this.props.user.get('username')}
          </span>
        </Text>
        <NavLink
          className="cub-Button"
          route={this.props.routes.USER_PROFILE}
        >
          <span className="cub-Button-icon" />
          OK
        </NavLink>
      </div>
    );
  }
}

UsernameChangeDone.propTypes = {
  routes: PropTypes.object,
  user: PropTypes.object,
};
