import LabeledTextarea from './LabeledTextarea';

export default class Notes extends LabeledTextarea {}

Notes.defaultProps = {
  classNameModifier: 'cub-FormControl--notes',
  label: 'Notes',
  maxLength: 1000,
  name: 'notes',
};
