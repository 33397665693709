import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import ExperienceStore from '../../stores/ExperienceStore';
import Membership from '../views/Membership';
import MembershipAddForm from '../forms/MembershipAddForm';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import Button from '../basic/Button';
import Caption from '../basic/Caption';

/* eslint-disable react/prefer-es6-class */
const UserMembership = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    // auto switch to Add Mode if user has no membership records
    registrationMode: PropTypes.bool,
    site: PropTypes.object,
    tag: PropTypes.string,
    title: PropTypes.string,
    user: PropTypes.object,
    routes: PropTypes.object,
  },

  /* eslint-disable react/no-unused-class-component-methods,react/sort-comp */
  mixins: [storeListenerMixin(ExperienceStore)],

  getStateFromStores() {
    return {
      recentlyAdded: ExperienceStore.recentlyAddedMember(),
    };
  },
  /* eslint-enable react/no-unused-class-component-methods,react/sort-comp */

  getDefaultProps() {
    return {
      title: 'My experience',
    };
  },

  getInitialState() {
    return {
      addMode: false,
      recentlyAdded: null,
    };
  },

  toggleAdd() {
    this.setState((prevState) => ({ addMode: !prevState.addMode }));
  },

  render() {
    const members = this.props.user.orderedMembership();

    const forceAddMode = this.props.registrationMode && members.length === 0;
    let addMembershipComponent = null;
    if (this.state.addMode || forceAddMode) {
      addMembershipComponent = (
        <MembershipAddForm
          user={this.props.user}
          site={this.props.site}
          tag={this.props.tag}
          hideCancel={forceAddMode}
          onSave={this.toggleAdd}
          onCancel={this.toggleAdd}
        />
      );
    } else {
      addMembershipComponent = (
        <Button
          classNameModifier="cub-Button--add"
          onClick={this.toggleAdd}
          text="Add Organization"
        />
      );
    }

    return (
      <div className="cub-UserExperience">
        {this.props.title && <Caption>{this.props.title}</Caption>}
        {members.map((m) => (
          <Membership
            key={m.id}
            member={m}
            autoAddPosition={m === this.state.recentlyAdded}
            routes={this.props.routes}
            site={this.props.site}
            showVerificationLinks={!this.props.registrationMode}
          />
        ))}
        {addMembershipComponent}
      </div>
    );
  },
});

export default UserMembership;
