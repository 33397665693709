import ApiActions from '../actions/ApiActions';
import * as Cookies from './Cookies';

const REGFLOW = 'regflow';
const regflow = new (class {
  subscriptionsAvailable(opts) {
    const userEmail = opts.user && opts.user.get('email');
    const subscriptions = opts.site.mailingLists();
    return this.inprogress() && userEmail && subscriptions.length > 0;
  }

  benefitsAvailable(opts) {
    const hasBenefits = (
      opts.site.availableBenefits().length > 0 ||
      opts.site.get('has_segmented_benefits')
    );
    return this.inprogress() && hasBenefits;
  }

  start() {
    // Setting SameSite=Lax because this cookie should be sent with top-level
    // navigations and it's not used on any sites that are embedded in an
    // iframe.
    Cookies.set(REGFLOW, 'basic', { samesite: 'Lax' });
  }

  inprogress() {
    return Cookies.get(REGFLOW) === 'basic';
  }

  finish() {
    Cookies.expire(REGFLOW, 'basic');
    window.setTimeout(() => ApiActions.getAll('notifications'));
  }
})();

export default regflow;
