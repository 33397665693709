import React from 'react';
import Text from '../basic/Text';

export default class PasswordResetLinkSent extends React.Component {
  render() {
    return (
      <div className={'cub-InfoBlock' +
          ' cub-InfoBlock--passwordResetSent isSuccess'}
      >
        <Text>
          Please check your email. We just emailed you a password reset
          link, it should be in your mailbox in a few moments
          if the provided email is correct.
        </Text>
      </div>
    );
  }
}
