import _ from 'underscore';
import AppDispatcher from '../dispatcher/AppDispatcher';
import CubApi from '../api/CubApi';
import FormActions from './FormActions';
import router from '../router/router';
import {
  GOT_XDOMAIN_COOKIES,
  EMAIL_CONFIRMATION_ERROR,
  EMAIL_CONFIRMATION_SUCCESS,
  IDP_CONFIRMATION_ERROR,
  IDP_CONFIRMATION_SUCCESS,
  IMPERSONATE,
  PASSWORD_RESET_TOKEN_CHECKED,
  PASSWORD_RESET_TOKEN_ERROR,
  USER_LOAD_ERROR,
  USER_LOGOUT,
  USER_SET_CURRENT_ORGANIZATION,
  USER_SELECT_TRUSTING_ORG,
  USER_REMOVE_PHOTO,
  USER_UPDATED,
} from '../constants/ActionTypes';
import { SIGN_IN, REGISTER, SSO_SIGN_IN } from '../constants/FormIds';
import ostat from '../utils/ostat';
import ConfigStore from '../stores/ConfigStore';
import TokenStore from '../stores/PasswordResetStore';
import UserStore from '../stores/UserStore';
import {
  dateToISOWithLocalTZ,
  logger,
  parseQueryString,
  siteSentry,
  jwtDecodeLogged,
} from '../utils/Utils';
import lytics from '../utils/lytics';
import ga from '../utils/ga';
import regflow from '../utils/regflow';

const USER_EXPAND = [
  'membership__organization__country',
  'membership__organization__state',
  'membership__organization__active_members',
  'membership__positions',
  'membership__group_membership',
  'membership__group_membership__group',
  'subscriptions',
  'userbenefits__benefit',
].join(',');

const LyticsEvents = {
  loggedIn(response) {
    try {
      const { email, id } = response;
      // we should send email and uid to connect with Lytics _uid(seerid) cookie
      const payload = { email, user_id: id };
      const datetime = dateToISOWithLocalTZ(new Date());
      if (datetime) payload.loginDatetimeWithTZ = datetime;
      lytics.send(payload);
    } catch (err) {
      siteSentry.captureException(err);
    }
  },
  connectWithLyticsCookie(response) {
    // we should send email and uid to connect with Lytics _uid(seerid) cookie
    try {
      const { email, id } = response;
      lytics.send({ email, user_id: id });
    } catch (err) {
      siteSentry.captureException(err);
    }
  },
};

const GoogleAnalyticsEvents = {
  loggedIn() {
    ga.push('send', 'event', {
      eventCategory: 'User Login',
      eventAction: 'login',
    });
  },
};

function loggedIn(response) {
  LyticsEvents.loggedIn(response);
  GoogleAnalyticsEvents.loggedIn();
  ostat.handleLogIn(response);
}

const UserActions = {
  USER_EXPAND,

  submitAnonForm(formId, nextUrl, url, data, success, error) {
    FormActions.submitAnonForm(
      formId,
      nextUrl,
      url,
      _.extend({ expand: USER_EXPAND }, data),
      success,
      error,
    );
  },

  submitUserForm(formId, url, data, success, error) {
    FormActions.submitUserForm(
      formId,
      url,
      _.extend({ expand: USER_EXPAND }, data),
      success,
      error,
    );
  },

  /**
   * Initiate login process with given credentials and
   * report success or errors back to form which initiated this
   *
   * @param  {object} credentials
   * @param  {string} formId - initiator form ID (optional)
   * @param  {string} nextUrl - redirect url after successful login (optional)
   */
  login(credentials, formId, nextUrl) {
    const data = _.extend({ expand: USER_EXPAND }, credentials);
    FormActions.formSubmitted(formId);
    CubApi.post(
      'user/login',
      { data, _formId: formId, _nextUrl: nextUrl },
      (response, meta, method, url, options) => {
        loggedIn(response);
        UserActions.userUpdated(response, meta, method, url, options);
      },
      UserActions.loginError,
    );
  },

  loginError(response, meta, method, url, options) {
    const params = (response && response.error && response.error.params) || {};
    if (params.redirect) {
      router.navigate(params.redirect);
    } else {
      FormActions.dispatchFormError(response, meta, method, url, options);
    }
  },

  resetLookupResults() {
    AppDispatcher.handleViewAction({
      actionType: 'RESET_USERNAME_LOOKUP',
    });
  },

  lookup(username, formId, nextUrl) {
    const data = { username, expand: 'sso_options' };
    FormActions.submitAnonForm(
      formId,
      nextUrl,
      'user/lookup',
      data,
    );
  },

  updatePrivacy(data) {
    const params = {
      apiKey: UserStore.token(true),
      data: _.extend({ expand: USER_EXPAND }, data),
    };
    CubApi.post(
      'user/privacy',
      params,
      UserActions.userUpdated,
    );
  },

  forgotPassword(credentials, formId, nextUrl) {
    UserActions.submitAnonForm(
      formId, nextUrl, 'user/forgot-password', credentials,
    );
  },

  register(data, formId, nextUrl) {
    FormActions.formSubmitted(formId);
    const params = {
      data: _.extend({ expand: USER_EXPAND }, data),
      _formId: formId,
      _nextUrl: nextUrl,
    };
    CubApi.post(
      'user/register',
      params,
      UserActions.userUpdated,
      FormActions.dispatchFormError,
    );
  },

  getUser(token) {
    CubApi.get(`user?expand=${USER_EXPAND}`,
      { apiKey: token },
      UserActions.userUpdated,
      UserActions.userLoadError);
  },

  createPasswordResetLink(userId, formId) {
    FormActions.submitUserForm(
      formId,
      `users/${userId}/create-password-reset-link`,
    );
  },

  resetPassword(data, formId, nextUrl) {
    const token = TokenStore.token();
    UserActions.submitAnonForm(
      formId,
      nextUrl,
      `user/reset-password/${token}`,
      data,
      UserActions.userUpdated,
    );
  },

  userUpdated(response, meta, method, url, options, overrideXSSSO, originIdP) {
    LyticsEvents.connectWithLyticsCookie(response);

    const opts = options || {};
    const HOME = router.routes.HOME;
    opts._nextUrl = opts._nextUrl || (HOME && HOME.url);
    FormActions.dispatchFormSuccess(response, meta, method, url, opts);
    const formId = opts._formId;
    let silent = false;
    if (formId === SIGN_IN || formId === REGISTER || formId === SSO_SIGN_IN) {
      if (router.pageReloadRequired(opts._nextUrl)) {
        silent = true;
      }
    }
    AppDispatcher.handleServerAction({
      actionType: USER_UPDATED,
      response,
      meta,
      silent,
      overrideXSSSO,
      originIdP,
    });
  },

  userUpdatedWithoutForm(response, meta, silent) {
    AppDispatcher.handleServerAction({
      actionType: USER_UPDATED,
      response,
      meta,
      silent,
    });
  },

  userLoadError(response, meta) {
    AppDispatcher.handleFormAction({
      actionType: USER_LOAD_ERROR,
      response,
      meta,
    });
  },

  checkPasswordResetToken(token) {
    CubApi.get(`user/reset-password/${token}?expand=${USER_EXPAND}`,
      {},
      UserActions.passwordResetTokenChecked,
      UserActions.passwordResetTokenLoadError);
  },

  passwordResetTokenChecked(response, meta) {
    AppDispatcher.handleFormAction({
      actionType: PASSWORD_RESET_TOKEN_CHECKED,
      response,
      meta,
    });
  },

  passwordResetTokenLoadError(response, meta) {
    AppDispatcher.handleFormAction({
      actionType: PASSWORD_RESET_TOKEN_ERROR,
      response,
      meta,
    });
  },

  confirmEmail(token) {
    const data = { expand: USER_EXPAND };
    CubApi.post(`user/confirm-email/${token}`,
      { data },
      UserActions.emailConfirmationSuccess,
      UserActions.emailConfirmationError);
  },

  emailConfirmationSuccess(response, meta) {
    AppDispatcher.handleFormAction({
      actionType: EMAIL_CONFIRMATION_SUCCESS,
      response,
      meta,
    });
  },

  emailConfirmationError(response, meta) {
    AppDispatcher.handleFormAction({
      actionType: EMAIL_CONFIRMATION_ERROR,
      response,
      meta,
    });
  },

  idpConfirm(token) {
    const data = { expand: USER_EXPAND };
    CubApi.post(`user/idp-confirm-association/${token}`,
      { data },
      UserActions.idpConfirmationSuccess,
      UserActions.idpConfirmationError);
  },

  idpConfirmationSuccess(response, meta) {
    AppDispatcher.handleFormAction({
      actionType: IDP_CONFIRMATION_SUCCESS,
      response,
      meta,
    });
  },

  idpConfirmationError(response, meta) {
    AppDispatcher.handleFormAction({
      actionType: IDP_CONFIRMATION_ERROR,
      response,
      meta,
    });
  },

  updateProfile(data, formId) {
    UserActions.submitUserForm(formId, 'user', data, UserActions.userUpdated);
  },

  changeEmail(data, formId) {
    UserActions.submitUserForm(
      formId, 'user/email', data, UserActions.userUpdated,
    );
  },

  changePassword(data, formId) {
    UserActions.submitUserForm(
      formId, 'user/password', data, UserActions.userUpdated,
    );
  },

  changeUsername(data, formId) {
    UserActions.submitUserForm(
      formId, 'user/username', data, UserActions.userUpdated,
    );
  },

  updateSubscriptions(data, formId) {
    UserActions.submitUserForm(
      formId, 'user/subscriptions', data, UserActions.userUpdated,
    );
  },

  updateBenefits(data, formId) {
    UserActions.submitUserForm(
      formId, 'user/benefits', data, UserActions.userUpdated,
    );
  },

  removePhotoStarted() {
    AppDispatcher.handleViewAction({ actionType: USER_REMOVE_PHOTO });
  },

  impersonate(token, silent) {
    const userData = { token };
    try {
      userData.id = jwtDecodeLogged(userData.token).user;
    } catch (e) {
      return;
    }
    AppDispatcher.handleViewAction({
      actionType: IMPERSONATE,
      response: userData,
      silent: silent || false,
    });
  },

  logout(silent, nextUrl) {
    AppDispatcher.handleViewAction({
      actionType: USER_LOGOUT,
      silent: silent || false,
      nextUrl,
    });
  },

  setCurrentOrganization(organization) {
    AppDispatcher.handleViewAction({
      actionType: USER_SET_CURRENT_ORGANIZATION,
      organization,
    });
  },

  selectTrustingOrg(organization) {
    AppDispatcher.handleViewAction({
      actionType: USER_SELECT_TRUSTING_ORG,
      organization,
    });
  },

  loginByToken(token, nextUrl, errBack) {
    const data = { expand: USER_EXPAND };
    CubApi.post(`user/login/${token}`,
      { data, _nextUrl: nextUrl, _formId: SIGN_IN },
      UserActions.ssoLoginSuccess,
      (typeof errBack === 'function') ? errBack : UserActions.userLoadError);
  },

  ssoLoginSuccess(response, meta, method, url, options) {
    loggedIn(response);
    const overrideXSSSO = true;
    const originIdP = parseQueryString().originIdP;
    const newuser = parseQueryString().newuser;
    if (newuser) {
      regflow.start();
    }
    UserActions.userUpdated(
      response, meta, method, url, options, overrideXSSSO, originIdP,
    );
    const switchToOrgId = (
      parseQueryString()[ConfigStore.get('organizationIdCookie')]
    );
    if (switchToOrgId) {
      UserActions.setCurrentOrganization(switchToOrgId);
    }
  },

  setEmail(data, formId) {
    UserActions.submitUserForm(
      formId, 'user/email', data, UserActions.userUpdated,
    );
  },

  getXCookies() {
    logger.debug('SingleSO: loading cookies from iframe');
    CubApi.getXCookies((value) => {
      AppDispatcher.handleServerAction({
        actionType: GOT_XDOMAIN_COOKIES,
        value,
      });
    });
  },

  setXCookies(value, done) {
    logger.debug('SingleSO: saving cookies to iframe', value);
    CubApi.setXCookies(value, done);
  },

  loginByTokenToApp(sourceAppUserToken, targetAppPubKey, originIdP, onError) {
    const data = { expand: USER_EXPAND };
    const overrideXSSSO = false;

    CubApi.post(
      `user/login/by-token-to-app/${targetAppPubKey}`,
      { data, apiKey: sourceAppUserToken },
      (response, meta, method, url, options) => {
        loggedIn(response);
        UserActions.userUpdated(
          response, meta, method, url, options, overrideXSSSO, originIdP,
        );
      },
      onError || _.noop,
    );
  },
};

export default UserActions;
