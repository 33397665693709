import PropTypes from 'prop-types';
import React from 'react';
import Text from './Text';
import Grid from './Grid';

export default class FilterGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      maxRows: props.pageSize,
      filtered: this.filtered(props.items, ''),
    };
    this.loadMore = this.loadMore.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.applyFilter(nextProps.items, this.state.search);
  }

  onSearchChange(search) {
    this.applyFilter(this.props.items, search);
  }

  applyFilter(items, keyword) {
    const filtered = this.filtered(items, keyword);
    this.setState({ search: keyword, filtered });
  }

  /**
   * Find items using keyword.
   *
   * Search is performed through all displayFields, case-insensitive.
   */
  filtered(items, keyword) {
    const keywordLase = (keyword || '').toLowerCase();
    let filtered = items;
    if (keywordLase) {
      filtered = items.filter((item) => {
        const displayFields = this.props.displayFields(item);
        for (let i = 0, l = displayFields.length; i < l; i++) {
          if (displayFields[i].toLowerCase().indexOf(keywordLase) !== -1) {
            return true;
          }
        }
        return false;
      });
    }
    return filtered;
  }

  loadMore() {
    this.setState((state, props) => ({
      maxRows: state.maxRows + props.pageSize,
    }));
  }

  render() {
    if (!this.props.items.length) {
      return (
        <Text classNameModifier="is-empty">{this.props.emptyMessage}</Text>
      );
    }

    let items = this.state.filtered;
    if (this.state.maxRows) {
      items = this.state.filtered.slice(0, this.state.maxRows);
    }

    let loadMore = this.loadMore;
    if (items.length === this.state.filtered.length) {
      loadMore = undefined;
    }

    return (
      <Grid
        {...this.props}
        items={items}
        foundCount={items.length}
        value={this.state.search}
        search={this.state.search}
        onSearchChange={this.onSearchChange}
        loadMore={loadMore}
      />
    );
  }
}

FilterGrid.propTypes = {
  btns: PropTypes.any,
  className: PropTypes.string,
  cols: PropTypes.array,
  displayFields: PropTypes.func,
  emptyMessage: PropTypes.string,
  items: PropTypes.array,
  links: PropTypes.func,
  onSelect: PropTypes.func,
  orderBy: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  pageSize: PropTypes.number,
};

FilterGrid.defaultProps = {
  emptyMessage: 'None',
  pageSize: null,
};
