import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import SearchGrid from '../basic/SearchGrid';
import { siteSentry } from '../../utils/Utils';

/* eslint-disable react/prefer-es6-class */
const MemberGrid = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    btns: PropTypes.func,
    className: PropTypes.string,
    currentMember: PropTypes.object,
    organization: PropTypes.object,
    // TODO: implement emptyMessage
    emptyMessage: PropTypes.string,
    members: PropTypes.array,
    filters: PropTypes.object,
    onSelect: PropTypes.func,
    roles: PropTypes.func,
    routes: PropTypes.object,
    showLinks: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      showLinks: true,
      filters: {},
    };
  },

  getInitialState() {
    return { q: '' };
  },

  onSearchChange(search) {
    this.setState({ q: search });
  },

  getFilters() {
    return _.extend({}, this.props.filters, {
      organization: this.props.organization.get('id'),
      q: this.state.q,
    });
  },

  displayFields(member) {
    const person = member.get('user');
    // attempt to debug frequent error
    // "e.get('user').displayName is not a function"
    if (!person.displayName) {
      siteSentry.setExtras({
        Membergrid_displayFields_person: person,
      });
    }
    return [
      person.displayName(true),
      member.currentPositionsStr(),
      member.get('personal_id') || '',
      this.props.roles ? this.props.roles(member) : this.role(member),
    ];
  },

  role(member) {
    let role = member.get('is_admin') ? 'Org. Admin' : 'User';
    const currentMember = this.props.currentMember;
    if (
      currentMember && (
        currentMember.id === member.id ||
        currentMember.get('user').id === member.get('user').id
      )
    ) {
      role += ' (You)';
    }
    return role;
  },

  links(member) {
    return this.props.routes.MEMBER.makeUrl(member.id);
  },

  render() {
    const labels = this.props.organization.fieldLabels();
    return (
      <SearchGrid
        className={this.props.className}
        cols={[
          'Name',
          `Title/Position, ${labels.unit}`,
          labels.personal_id,
          'Role',
        ]}
        displayFields={this.displayFields}
        links={this.props.showLinks ? this.links : null}
        btns={this.props.btns}
        models={this.props.members}
        endpoint="members"
        filters={this.getFilters()}
        value={this.getFilters().q}
        pageSize={20}
        onSearchChange={this.onSearchChange}
        onSelect={this.props.onSelect}
      />
    );
  },
});

export default MemberGrid;
