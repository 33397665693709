import React from 'react';
import ApiActions from '../../actions/ApiActions';
import createForm from '../../mixins/createForm';
import Email from '../fields/Email';
import MemberCreateForm from './MemberCreateForm';
import MemberActions from '../../actions/MemberActions';
import router from '../../router/router';
import { MEMBER_ADD, MEMBER_PERMISSIONS } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';
import OrganizationSearch from '../fields/OrganizationSearch';
import DataStore from '../../stores/DataStore';
import NavLink from '../basic/NavLink';
import UserActions from '../../actions/UserActions';

const MemberAddForm = createForm(MEMBER_ADD, {
  getInitialState() {
    return {
      email: '',
      emailSubmitted: null,
      memberSearchStarted: null,
      createMode: false,
      createWithoutEmail: false,
      loading: false,
      selectedOrg: this.props.organization,
    };
  },

  componentDidMount() {
    this.refs.email.focus();
  },

  onOrgChange(event) {
    const org = event.value;
    if (org.related == null) {
      return;
    }
    this.setState({ selectedOrg: org });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(org);
    }
    UserActions.selectTrustingOrg(org);
  },

  addWithoutEmail() {
    this.setState({
      createMode: true,
      createWithoutEmail: true,
      email: '',
    });
  },

  submit() {
    const email = this.state.email;
    const organization = this.state.selectedOrg;
    let member;
    this.setState({ emailSubmitted: email });
    if (email) {
      member = organization.getMemberByEmail(email);
    }
    if (member) {
      this.memberAlreadyExists(email);
    } else {
      MemberActions.newMember({
        email,
        organization: organization.id,
      }, MEMBER_ADD);
    }
  },

  memberAlreadyExists(email) {
    const memberEmail = email || this.state.emailSubmitted;
    if (memberEmail) {
      const member = this.state.selectedOrg.getMemberByEmail(memberEmail);
      if (member) {
        if (!member.get('is_active')) {
          // if member is not active, activating him SRC-482
          this.setState({ loading: true });
          MemberActions.setMemberPermissions(
            member,
            { is_active: true },
            MEMBER_PERMISSIONS,
          );
        } else {
          router.navigate(this.props.routes.MEMBER, member.id);
        }
      } else if (this.state.memberSearchStarted !== memberEmail) {
        ApiActions.getChunk('members', {
          organization: this.state.selectedOrg.id,
          email: memberEmail,
        }, () => this.memberAlreadyExists(memberEmail));
        this.setState({ memberSearchStarted: memberEmail });
      }
    }
  },

  storesSuccess(formId, resp) {
    if (formId === MEMBER_PERMISSIONS) {
      router.navigate(this.props.routes.MEMBER, resp.id);
    }
  },

  formStateFromStores(formState) {
    let createMode = false;
    let emailError = formState.errors.fieldError('email') || '';
    let topError = formState.errors.topError() || '';

    if (topError.indexOf('is required') !== -1) {
      // Original error message is 'Either user or email parameter is required'
      // but this is not relevant here, because we provide only email parameter
      // and users can be confused. So I re-write error message to have it look
      // like similar to other forms.
      emailError = 'This field is required.';
      topError = '';
    } else if (
      emailError.indexOf('no account') !== -1 ||
      this.state.createWithoutEmail
    ) {
      emailError = '';
      createMode = true;
    } else if (topError.indexOf('is already a member') !== -1) {
      topError = '';
      this.memberAlreadyExists();
    }

    return { topError, emailError, createMode };
  },

  cancelCreate(e) {
    this.setState({
      createMode: false,
      createWithoutEmail: false,
    });
    e.preventDefault();
  },

  render() {
    const org = this.state.selectedOrg;

    const user = DataStore.currentUser();
    const showOrgControl = user.isTrustedAdmin();
    // TODO: Uncomment the following line and remove two preceding lines when
    //  risk pool admins no longer need to be org admins (HS-2905).
    // const showOrgControl = DataStore.currentMember().get('is_trusted_admin');

    if (this.state.createMode) {
      return (
        <MemberCreateForm
          organization={org}
          email={this.state.email}
          onMemberAlreadyExists={this.memberAlreadyExists}
          onCancel={this.cancelCreate}
        />
      );
    }
    return (
      <Form
        classNameModifier="cub-Form--memberAdd"
        error={this.state.topError}
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <Email
          ref="email"
          label="Enter email of new member"
          value={this.state.email}
          required
          onChange={this.onInputChange}
          error={this.state.emailError}
        />

        {showOrgControl && (
          <OrganizationSearch
            label="Add to organization"
            value={org.toFullJson()}
            onChange={this.onOrgChange}
            searchManagedTrusting
            required
          />
        )}

        <Button
          classNameModifier="cub-Util-marginRight"
          type="submit"
          isProcessing={this.state.isProcessing || this.state.loading}
          text="Add member"
        />
        {org.get('allow_create_members_wo_email') && (
          <NavLink
            className="cub-Link"
            onClick={this.addWithoutEmail}
          >
            Add member without email
          </NavLink>
        )}
      </Form>
    );
  },
});

export default MemberAddForm;
