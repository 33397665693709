import _ from 'underscore';
import smartdate from 'smartdate';
import { CubModel, Data } from './DataBase';
import { siteSentry, moneyFormat } from '../utils/Utils';
import DataStore from './DataStore';

const validPrefixFor = (model, val) => (
  _.isString(val) && (val.indexOf(`${model.prototype.prefix}_`) === 0)
);

export const BankAccount = CubModel.extend({
  object: 'bankaccount',
  prefix: 'bac',

  displayStr() {
    return `${this.get('bank_name')} **** ${this.get('last4')}`;
  },
});

export const Benefit = CubModel.extend({
  object: 'benefit',
  prefix: 'bft',
});

export const Card = CubModel.extend({
  object: 'card',
  prefix: 'crd',

  displayStr() {
    const month = this.get('exp_month');
    return `${this.get('brand')} **** ${this.get('last4')} expires ` +
        `${month < 10 ? `0${month}` : month} / ${this.get('exp_year')}`;
  },
});

const Country = CubModel.extend({
  object: 'country',
  prefix: 'cry',
  cascadeDelete: ['state'],
});
Country.alike = (val) => _.isObject(val) &&
  (val.object === Country.prototype.object) &&
  _.isString(val.id) &&
  (val.id.indexOf(Country.prototype.prefix) === 0);
export { Country };

export const Customer = CubModel.extend({
  object: 'customer',
  prefix: 'cus',
  cascadeDelete: ['order'],
  collections: {
    bank_accounts: 'bankaccount',
    cards: 'card',
    service_subscriptions: 'servicesubscription',
  },

  defaultBankAccount() {
    return this.related.bankaccount[0];
  },

  defaultCard() {
    return _.find(this.related.card, (card) => card.get('default'));
  },

  serviceSubscription(site) {
    const sitePlanIds = site.related.siteplan.map((sp) => sp.get('plan').id);
    const serviceSubscriptions = this.related.servicesubscription;
    for (let i = 0, l = serviceSubscriptions.length; i < l; i++) {
      const ss = serviceSubscriptions[i];
      if (sitePlanIds.indexOf(ss.get('plan').id) >= 0) {
        return ss;
      }
    }
    return null;
  },
});

export const Group = CubModel.extend({
  object: 'group',
  prefix: 'grp',
  cascadeDelete: ['groupmember'],

  activeMembers() {
    return this.members().filter((m) => m.get('is_active'));
  },

  members() {
    return this.related.groupmember.map((gm) => gm.get('member'));
  },

  potentialMembers() {
    const organization = this.get('organization');
    if (!organization) {
      return [];
    }
    const members = this.members();
    return organization.activeMembers().filter(
      (m) => members.indexOf(m) === -1,
    );
  },
});

export const GroupMember = CubModel.extend({
  object: 'groupmember',
  prefix: 'grm',
});

export const LeadForm = CubModel.extend({
  object: 'leadform',
  prefix: 'lfm',
});

export const Lead = CubModel.extend({
  object: 'lead',
  prefix: 'led',
});

export const MailingList = CubModel.extend({
  object: 'mailinglist',
  prefix: 'mlt',
  cascadeDelete: ['sitemailinglist', 'subscription'],
  anonymousData: true,
});

export const Member = CubModel.extend({
  object: 'member',
  prefix: 'mbr',
  cascadeDelete: ['groupmember', 'memberposition'],
  collections: {
    group_membership: 'groupmember',
    positions: 'memberposition',
  },

  /**
   * Verification requests related to member organization only
   * @returns {Array}
   */
  verificationRequests() {
    return this.get('user').related.verificationrequest.filter(
      (vr) => vr.get('organization').id === this.get('organization').id,
    );
  },

  orderedVerificationRequests() {
    const requests = this.verificationRequests().slice();
    // sort by creation date, most recent first
    requests.sort((r1, r2) => r2.get('created') - r1.get('created'));
    return requests;
  },

  lastVerificationRequest() {
    return this.orderedVerificationRequests()[0];
  },

  userIsVerified(site) {
    const user = this.get('user');
    return Boolean(
      this.tagsForVerification(site).map(user.isVerified, user).length,
    );
  },

  tagsForVerification(site) {
    const orgTags = this.get('organization').get('tags');
    const siteTags = site.get('suggest_verification');
    return siteTags.filter((st) => orgTags.indexOf(st) !== -1);
  },

  canBeVerified(site) {
    return site.get('verification_without_tags') ||
      Boolean(this.tagsForVerification(site).length);
  },

  verificationApproved() {
    return Boolean(
      _.find(
        this.verificationRequests(),
        (r) => r.get('status') === 'Approved',
      ),
    );
  },

  verificationPending() {
    const lastRequest = this.lastVerificationRequest();
    return Boolean(lastRequest && lastRequest.get('status') === 'Pending');
  },

  verificationDeclined() {
    const lastRequest = this.lastVerificationRequest();
    return Boolean(lastRequest && lastRequest.get('status') === 'Declined');
  },

  currentPositions() {
    const now = new Date();
    return this.related.memberposition.filter((mp) => {
      const dateFrom = mp.dateFrom();
      const dateTo = mp.dateTo();
      return (!dateFrom || dateFrom <= now) && (!dateTo || dateTo > now);
    });
  },

  currentPositionsStr() {
    return this.currentPositions().map((p) => {
      const position = p.get('position');
      const unit = p.get('unit');
      if (position && unit) {
        return `${position} with ${unit}`;
      }
      return position || unit;
    }).join(', ');
  },

  latestPositionDate() {
    let latest = null; // until present
    const l = this.related.memberposition.length;
    for (let i = 0; i < l; i++) {
      const dateTo = this.related.memberposition[i].dateTo();
      if (!dateTo) {
        return null;
      }
      if (!latest || latest < dateTo) {
        latest = dateTo;
      }
    }
    return latest;
  },

  getLastPosition() {
    const positions = this.orderedPositions();
    const positionsCnt = positions.length;
    if (positionsCnt === 0) {
      return null;
    }
    // return last "present" or first position with end date
    let prevPosition = null;
    for (let i = 0; i < positionsCnt; i++) {
      if (prevPosition !== null && positions[i].dateTo()) {
        return prevPosition;
      }
      prevPosition = positions[i];
    }
    return prevPosition;
  },

  orderedPositions() {
    const positions = this.related.memberposition.slice();
    const _10000yrs = 1000 * 365 * 24 * 60 * 60 * 1000; // 10000 years in ms
    positions.sort((p1, p2) => {
      // Order positions by end date, most recent at the top. Place positions
      // without end dates at the top, ordered by record creation date.
      let date1 = p1.dateTo();
      date1 = date1 ? date1.getTime() : _10000yrs - p1.get('created').getTime();
      let date2 = p2.dateTo();
      date2 = date2 ? date2.getTime() : _10000yrs - p2.get('created').getTime();
      if (date1 === date2) {
        return 0;
      }
      return date1 < date2 ? 1 : -1;
    });
    return positions;
  },

  /**
   * Get list of groups which this member can manage
   *
   * @return {Array}
   */
  manageableGroups(organization = null) {
    if (this.get('is_admin')) {
      // Organization admin can manage all groups
      const org =
        this.get('is_trusted_admin') ?
          organization :
          this.get('organization');
      return org.related.group;
    }
    return this.related.groupmember
      .filter((gm) => gm.get('is_admin'))
      .map((gm) => gm.get('group'));
  },

  isGroupOwner() {
    return this.manageableGroups().length > 0;
  },
});

export const MemberPosition = CubModel.extend({
  object: 'memberposition',
  prefix: 'mpo',

  dateFrom() {
    const year = this.get('year_from');
    if (!year) {
      return null; // unknown
    }
    let month = this.get('month_from');
    if (!month) {
      return new Date(year, 0, 1);
    }
    month = parseInt(month, 10) - 1;
    const day = this.get('day_from');
    if (!day) {
      return new Date(year, month, 1);
    }
    return new Date(year, month, day);
  },

  dateTo() {
    let year = this.get('year_to');
    if (!year) {
      return null; // until present
    }
    year = parseInt(year, 10);
    let month = this.get('month_to');
    let day;
    if (!month) {
      year += 1;
      month = 0;
      day = 1;
    } else {
      // JS months are 0-based and months in the API are 1-based.
      month = parseInt(month, 10) - 1;
      day = this.get('day_to');
      if (!day) {
        month += 1;
        day = 1;
      } else {
        day = parseInt(day, 10) + 1;
      }
    }
    const result = new Date(year, month, day);
    result.setSeconds(-1);
    return result;
  },
});

export const Notification = CubModel.extend({
  object: 'notification',
  prefix: 'ntf',
});

export const Order = CubModel.extend({
  object: 'order',
  prefix: 'ord',
  cascadeDelete: ['orderitem'],
  collections: {
    items: 'orderitem',
  },

  total() {
    let total = 0;
    this.related.orderitem.forEach((item) => {
      total += item.get('amount') * item.get('quantity');
    });
    return parseFloat(total.toFixed(2));
  },
});

export const OrderItem = CubModel.extend({
  object: 'orderitem',
  prefix: 'oit',
});

const Organization = CubModel.extend({
  object: 'organization',
  prefix: 'org',
  cascadeDelete: ['member'],

  activeMembers() {
    return this.related.member.filter((m) => m.get('is_active'));
  },

  membersCount() {
    const counts = this.get('active_members');
    if (_.isObject(counts)) {
      return counts;
    }
    return {
      joined: null,
      invited: null,
    };
  },

  getMemberByEmail(email) {
    const emailLower = email.toLowerCase();
    return _.find(
      this.related.member,
      (m) => (m.get('user').get('email') || '').toLowerCase() === emailLower,
    );
  },

  groupTypes() {
    const result = ['Group'];
    const perTag = {
      Fire: ['Shift', 'Station'],
    };
    _.each(this.get('tags'), (tag) => {
      if (perTag[tag]) {
        Array.prototype.push.apply(result, perTag[tag]);
      }
    });
    Array.prototype.push.apply(
      result,
      this.related.group.map((g) => g.get('type')),
    );
    return _.uniq(result).sort();
  },

  units() {
    const memberPositions = Data.memberposition.models;
    const units = [];
    for (let i = 0, l = memberPositions.length; i < l; i++) {
      const memberPosition = memberPositions[i];
      const member = memberPosition.get('member');
      if (member.get('organization') === this && member.get('is_active')) {
        const unit = memberPosition.get('unit');
        if (unit && units.indexOf(unit) === -1) {
          units.push(unit);
        }
      }
    }
    return _.sortBy(units, (u) => u.toLowerCase());
  },

  fullAddress() {
    const address = this.get('address');
    const city = this.get('city');
    const postalCode = this.get('postal_code');
    const parts = [];
    let country = this.get('country');
    let state = this.get('state');
    country = country && country.get('name');
    state = state && state.get('name');
    if (address) {
      parts.push(address);
    }
    if (city) {
      parts.push(city);
    }
    if (state) {
      parts.push(state);
    }
    if (postalCode) {
      parts.push(postalCode);
    }
    if (country) {
      parts.push(country === 'United States' ? 'US' : country);
    }
    return parts.join(', ');
  },

  fieldLabels() {
    const labels = {
      'Law Enforcement': {
        unit: 'Division/Unit',
        personal_id: 'Badge/ID #',
      },
      Corrections: {
        unit: 'Division/Unit',
        personal_id: 'Badge/ID #',
      },
      Military: {
        unit: 'Division/Unit',
        personal_id: 'Badge/ID #',
      },
      Fire: {
        unit: 'Specialty Discipline',
        personal_id: 'Dept. ID',
      },
      EMS: {
        unit: 'Specialty Discipline',
        personal_id: 'Personal ID',
      },
      Vendor: {
        unit: 'Division',
        personal_id: 'Personal ID',
      },
      'Private Security': {
        unit: 'Division',
        personal_id: 'Personal ID',
      },
      'Non-Sworn Law Enforcement': {
        unit: 'Division/Unit',
        personal_id: 'Badge/ID #',
      },
      generic: {
        unit: 'Unit',
        personal_id: 'Personal ID',
      },
    };
    const orgTags = this.get('tags');
    if (orgTags.length) {
      return labels[orgTags[0]] || labels.generic;
    }
    return labels.generic;
  },

  toFullJson() {
    const state = (this.get('state') && this.get('state').toJSON()) || null;
    const country = (
      this.get('country') && this.get('country').toJSON()
    ) || null;

    const result = this.toJSON();
    result.country = country;
    result.state = state;
    return result;
  },

  postIdVisible() {
    return false;
  },
});
Organization.validPrefix = (val) => validPrefixFor(Organization, val);
Organization.alike = (val) => _.isObject(val) &&
  (val.object === Organization.prototype.object) &&
  _.isString(val.id) &&
  (val.id.indexOf(Organization.prototype.prefix) === 0);
export { Organization };

export const Product = CubModel.extend({
  object: 'product',
  prefixes: ['prd', 'prod'],
  anonymousData: true,
});

export const Plan = CubModel.extend({
  object: 'plan',
  prefix: 'pln',
  cascadeDelete: ['siteplan', 'servicesubscription', 'product'],
  anonymousData: true,

  /**
   * Get human-friendly plan price description
   *
   * @param serviceSubscription - optional, calculate price
   *                              with discounts for this subscription
   * @returns {string} -
   */
  price(serviceSubscription) {
    const count = this.get('interval_count');
    const interval = this.get('interval');
    const intervalStr = count > 1 ? `${count} ${interval}s` : interval;
    const discount = serviceSubscription ?
      serviceSubscription.discountAmount() :
      0;
    const total = moneyFormat(this.get('amount') - discount);
    return `${total} / ${intervalStr}`;
  },
});

export const Position = CubModel.extend({
  object: 'position',
  prefix: 'pos',
  comparator(p) {
    return p.get('name').toLowerCase();
  },
});

export const ServiceSubscription = CubModel.extend({
  object: 'servicesubscription',
  prefix: 'ssu',

  expired() {
    const activeTill = this.get('active_till');
    return activeTill instanceof Date && activeTill < (new Date());
  },

  discountAmount() {
    const discountEnd = this.get('discount_end');
    const planAmount = this.get('plan').get('amount');
    if (discountEnd && discountEnd < (new Date())) {
      return 0;
    }
    const amountOff = this.get('discount_amount_off');
    if (amountOff) {
      return amountOff > planAmount ? planAmount : amountOff;
    }
    const percentOff = this.get('discount_percent_off');
    if (percentOff) {
      return Math.round(planAmount * percentOff) / 100;
    }
    return 0;
  },

  discountDescription() {
    const amountOff = this.get('discount_amount_off');
    const percentOff = this.get('discount_percent_off');
    if (!amountOff && !percentOff) {
      return '';
    }
    const discountEnd = this.get('discount_end');
    if (discountEnd && discountEnd < (new Date())) {
      return '';
    }
    const until = discountEnd ?
      `until ${smartdate.format(discountEnd, { mode: 'date' })}` :
      'forever';
    if (amountOff) {
      return `${moneyFormat(this.discountAmount())} off, ${until}`;
    }
    return `${percentOff}% off, ${until}`;
  },
});

export const Site = CubModel.extend({
  object: 'site',
  prefix: 'ste',
  cascadeDelete: ['sitemailinglist', 'siteplan'],
  collections: {
    mailinglists: 'sitemailinglist',
    siteplans: 'siteplan',
    sitebenefits: 'sitebenefit',
  },
  anonymousData: true,

  idpLoginEnabled() {
    const ssoSettings = this.get('sso_settings') || [];
    return ssoSettings.length !== 0;
  },

  passLoginEnabled() {
    return !this.get('login_with_password_disabled');
  },

  loginEnabled() {
    return this.passLoginEnabled() || this.idpLoginEnabled();
  },

  getIdPConfigByUid(providerUid) {
    const ssoSettings = this.get('sso_settings') || [];
    return _.findWhere(ssoSettings, { uid: providerUid });
  },

  activePlans() {
    const sitePlans = this.related.siteplan.filter((sp) => sp.get('is_active'));
    return _.sortBy(sitePlans, (sp) => sp.get('order')).map(
      (sp) => sp.get('plan'),
    );
  },

  availableBenefits() {
    const sorted = _.sortBy(
      this.related.sitebenefit,
      (sb) => sb.get('priority'),
    );
    return sorted.map((sb) => sb.get('benefit'));
  },

  verificationRequired() {
    return (this.get('suggest_verification').length > 0) ||
      this.get('verification_without_tags');
  },

  mailingLists() {
    return this.related.sitemailinglist.map((sml) => sml.get('mailinglist'));
  },

  urls() {
    const apps = this.get('widget_settings');
    const result = {};
    Object.keys(apps).forEach((app) => {
      const baseUrl = apps[app].baseUrl || '';
      const urls = _.extend({}, apps[app].urls);
      Object.keys(urls).forEach((url) => {
        result[url] = baseUrl + urls[url];
      });
    });
    return result;
  },

  getWidgetSetting(app, setting) {
    const apps = this.get('widget_settings');
    const value = (apps[app] && apps[app][setting]) ?
      apps[app][setting] : undefined;
    return value;
  },

  keepSignedIn() {
    if (!this.has('keep_signed_in')) {
      return true;
    }
    return this.get('keep_signed_in');
  },
});
Site.USER_PHONE_REQUIRED = 'required';
Site.USER_PHONE_OPTIONAL = 'optional';
Site.USER_PHONE_DISABLED = 'disabled';

export const SiteBenefit = CubModel.extend({
  object: 'sitebenefit',
  prefix: 'sbf',
});

export const SiteMailingList = CubModel.extend({
  object: 'sitemailinglist',
  prefix: 'sml',
  anonymousData: true,
});

export const SitePlan = CubModel.extend({
  object: 'siteplan',
  prefix: 'spl',
  anonymousData: true,
});

const State = CubModel.extend({
  object: 'state',
  prefix: 'stt',
});
State.alike = (val) => _.isObject(val) &&
  (val.object === State.prototype.object) &&
  _.isString(val.id) &&
  (val.id.indexOf(State.prototype.prefix) === 0);
export { State };

export const Subscription = CubModel.extend({
  object: 'subscription',
  prefix: 'sub',
});

export const User = CubModel.extend({
  object: 'user',
  prefix: 'usr',
  cascadeDelete: ['member', 'subscription'],
  collections: {
    membership: 'member',
    subscriptions: 'subscription',
    userbenefits: 'userbenefit',
  },

  fullName(lastFirst) {
    const first = (
      `${this.get('first_name') || ''} ${this.get('middle_name') || ''}`
    ).trim();
    const last = (this.get('last_name') || '').trim();
    if (lastFirst) {
      // 'Willis, Bruce'
      return last && first ? `${last}, ${first}` : last || first;
    }
    // 'Bruce Willis'
    return first && last ? `${first} ${last}` : first || last;
  },

  displayName(lastFirst) {
    return (
      this.fullName(lastFirst) ||
      this.get('email') ||
      this.get('username') ||
      ''
    );
  },

  birthdate() {
    const bd = this.get('birth_date');
    const parts = bd && bd.split('-');
    return bd && new Date(+parts[0], +parts[1] - 1, +parts[2]);
  },

  subscribedMailingLists() {
    return this.related.subscription.map((s) => s.get('mailinglist'));
  },

  toJSON() {
    return this.omit('token'); // user token shouldn't leak out
  },

  profileAttrs() {
    const attrs = this.pick(
      'first_name',
      'middle_name',
      'last_name',
      'gender',
      'birth_date',
      'retired',
      'phone',
      'purchasing_role_buy_for_self_only',
      'purchasing_role_buy_for_organization',
      'purchasing_role_specify_for_organization',
      'purchasing_role_recommend',
    );
    attrs.birth_date = attrs.birth_date || ''; // replace null with empty str
    return attrs;
  },

  searchInCurrentOrgs(startsWith) {
    const members = this.related.member.slice();
    const filterName = (o) => {
      const name = o.get('name') || '';
      return name.toLowerCase().indexOf(startsWith.toLowerCase()) !== -1;
    };

    const orgs = members.map((m) => m.get('organization'));
    return orgs.filter(
      (o) => !o.get('moderator_approved') && filterName(o),
    );
  },

  isTrustedAdmin() {
    // TODO: Remove this method when risk pool admins no longer need to be org
    //  admins (HS-2905).
    const site = DataStore.currentSite();
    const siteOwnerOrg = site.get('limit_search_to_trusting_orgs');
    if (!siteOwnerOrg) {
      return false;
    }
    const predicate = (member) => (
      member.get('organization').get('id') === siteOwnerOrg &&
      member.get('is_active') &&
      member.get('is_trusted_admin')
    );
    return this.related.member.some(predicate);
  },

  orderedMembership() {
    const members = this.related.member.slice();
    const _10000yrs = 1000 * 365 * 24 * 60 * 60 * 1000; // 10000 years in ms
    members.sort((m1, m2) => {
      // Order organizations by latest position date, most recent first.
      // Place 'until present' organizations at the top, ordered by record
      // creation date.
      let date1 = m1.latestPositionDate();
      // attempt to debug error
      // "undefined is not an object (evaluating 'e.get("created").getTime'"
      if (!m1.get('created')) {
        siteSentry.setExtras({
          User_orderedMembership_m1: m1,
          User_orderedMembership_members: members,
        });
      }
      date1 = date1 ? date1.getTime() : _10000yrs - m1.get('created').getTime();
      let date2 = m2.latestPositionDate();
      if (!m2.get('created')) {
        siteSentry.setExtras({
          User_orderedMembership_m2: m2,
          User_orderedMembership_members: members,
        });
      }
      date2 = date2 ? date2.getTime() : _10000yrs - m2.get('created').getTime();
      if (date1 === date2) {
        return 0;
      }
      return date1 < date2 ? 1 : -1;
    });
    return members;
  },

  orderedMembershipAlphabetical() {
    const members = this.related.member.filter((m) => m.get('is_active'));
    members.sort((m1, m2) => {
      const name1 = m1.get('organization').get('name');
      const name2 = m2.get('organization').get('name');
      if (name1 === name2) {
        return 0;
      }
      return name1 > name2 ? 1 : -1;
    });
    return members;
  },

  getLastMembership() {
    const membership = this.orderedMembership();
    const membershipCnt = membership.length;
    if (membershipCnt === 0) {
      return null;
    }
    let prevMembership = null;
    // return last membership with "present" position
    // or first membership with end date of position
    for (let i = 0; i < membershipCnt; i++) {
      const latestPositionDate = membership[i].latestPositionDate();
      if (prevMembership !== null && latestPositionDate !== null) {
        break;
      }
      prevMembership = membership[i];
    }
    return prevMembership;
  },

  membershipForVerification(site) {
    return this.orderedMembership().filter((m) => m.canBeVerified(site));
  },

  pendingNotifications() {
    // Returns non-delivered notifications only, 'welcome' comes first.
    return _.sortBy(
      this.related.notification.filter((n) => !n.get('delivered')),
      (n) => n.get('code') !== 'welcome',
    );
  },

  isVerified(tag) {
    return this.get('verified_tags').indexOf(tag) >= 0;
  },

  getPhoto(photoKey) {
    let photoUrl = this.get(photoKey);
    const imageRefreshKey = this.get('image_refresh_key');
    if (photoUrl && imageRefreshKey) {
      photoUrl += photoUrl.indexOf('?') === -1 ? '?' : '&';
      photoUrl += imageRefreshKey;
    }
    return photoUrl;
  },

  activeBenefits() {
    const active = _.filter(
      this.related.userbenefit,
      (ub) => !ub.get('cancelled'),
    );
    return active.map((ub) => ub.get('benefit'));
  },
});

export const UserBenefit = CubModel.extend({
  object: 'userbenefit',
  prefix: 'ubf',
});

export const VerificationRequest = CubModel.extend({
  object: 'verificationrequest',
  prefix: 'vrq',
});

export const Coupon = CubModel.extend({
  object: 'coupon',
  prefix: 'cpn',

  discount(price) {
    if (this.get('amount_off')) {
      return this.get('amount_off') > price ? price : this.get('amount_off');
    }
    return (price * this.get('percent_off') * 0.01).toFixed(2);
  },

  discountStr(price) {
    let until;
    const valueStr = moneyFormat(this.discount(price));

    if (this.get('duration') === 'once') {
      return valueStr;
    }
    if (this.get('duration') === 'forever') {
      until = 'forever';
    } else {
      const endDate = new Date();
      endDate.setMonth(endDate.getMonth() + this.get('duration_in_months'));
      until = `until ${smartdate.format(endDate, { mode: 'date' })}`;
    }
    return `${valueStr} off, ${until}`;
  },
});

export const DataModels = [
  BankAccount,
  Benefit,
  Card,
  Country,
  Customer,
  Group,
  GroupMember,
  Lead,
  LeadForm,
  MailingList,
  Member,
  MemberPosition,
  Notification,
  Order,
  OrderItem,
  Organization,
  Plan,
  Position,
  Product,
  ServiceSubscription,
  Site,
  SiteBenefit,
  SiteMailingList,
  SitePlan,
  State,
  Subscription,
  User,
  UserBenefit,
  VerificationRequest,
  Coupon,
];
