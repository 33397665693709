import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import ApiActions from '../../actions/ApiActions';
import Button from '../basic/Button';
import Error from '../basic/Error';

/* eslint-disable react/prefer-es6-class */
/**
 * Component for direct file uploads from browser to S3. Compatible with IE8+
 */
const ImageUploadForm = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    apiEndpoint: PropTypes.string.isRequired,
    expand: PropTypes.string,
    errorClassNameModifier: PropTypes.string,
    onRemove: PropTypes.func,
    onRemoveStarted: PropTypes.func,
    onRemoveDone: PropTypes.func,
    onS3UploadDone: PropTypes.func,
    onUploadStarted: PropTypes.func,
    onUploadDone: PropTypes.func,
    removeBtnText: PropTypes.string,
    uploadBtnLoadingText: PropTypes.string,
    uploadBtnText: PropTypes.string,
  },

  getDefaultProps() {
    return {
      expand: '',
      errorClassNameModifier: 'cub-Form-error--top',
      uploadBtnLoadingText: 'Uploading photo',
      uploadBtnText: 'Upload',
    };
  },

  getInitialState() {
    return {
      action: '',
      fields: [],
      loading: false,
      error: null,
    };
  },

  onChange() {
    const filename = this.filename();

    if (this.onPreUploadCheck(filename)) {
      // Get credentials for S3 upload
      ApiActions.get(
        this.props.apiEndpoint,
        { filename },
        this.onUploadParamsReceived,
        this.onError,
      );
      this.setState({ loading: true, error: null });
      if (typeof this.props.onUploadStarted === 'function') {
        this.props.onUploadStarted(filename);
      }
    }
  },

  // function for check file extensions before file uploading
  onPreUploadCheck(filename) {
    const supportedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const filetype = this.getFileExtension(filename).toLowerCase();

    if (!(filename && filetype &&
      (supportedExtensions.indexOf(filetype) >= 0))) {
      const msg = `File upload failed. Unsupported file format. *.
          ${supportedExtensions.join(' *.')} are only accepted.`;
      this.setState({ error: msg });
      return false;
    }
    return true;
  },

  onUploadParamsReceived(response) {
    this.setState(response);
    this.submit(response);
  },

  onS3UploadDone() {
    let filename;
    // Call the props function if it's defined
    if (this.props.onS3UploadDone && this.state.url) {
      // exclude port from url
      filename = this.state.url.replace(/:[0-9]+/, '');

      this.props.onS3UploadDone(filename);
      this.reset();

      return;
    }

    filename = this.filename();
    // Notify API that file is uploaded
    if (filename) {
      ApiActions.post(
        this.props.apiEndpoint,
        { filename, expand: this.props.expand },
        this.onUploadDone,
        this.onError,
      );
    }
  },

  onUploadDone(...args) {
    this.reset();
    if (this.props.onUploadDone) {
      this.props.onUploadDone.apply(window, args);
    }
  },

  onError(response) {
    const hasError = response && response.error;
    const hasErrorParams = hasError && response.error.params;
    let msg;
    // catch upload file size limit error
    if (hasErrorParams) {
      msg = response.error.params.filename;
    // catch other errors
    } else if (hasError) {
      msg = response.error.description;
    }
    msg = msg || 'An error occurred while uploading your image. ' +
        'This could be a temporarily network problem, ' +
        'please check your internet connection and try again.';
    this.setState({ loading: false, error: msg });
    this.refs.file.value = '';
  },

  onRemove(e) {
    e.preventDefault();

    // Call props onRemove func if only a fake remove from control is needed
    if (this.props.onRemove) {
      this.props.onRemove();
      return;
    }

    ApiActions.del(
      this.props.apiEndpoint,
      { expand: this.props.expand },
      this.props.onRemoveDone,
    );
    if (typeof this.props.onRemoveStarted === 'function') {
      this.props.onRemoveStarted();
    }
  },

  // function for getting file extension
  getFileExtension(filename) {
    const parts = filename.split('.');
    return parts.length > 1 ? parts.pop() : '';
  },

  reset() {
    this.setState({ loading: false, error: null });
    this.refs.file.value = '';
  },

  submit(data) {
    // Check if browser supports FormData (IE8-9 don't)
    if (typeof FormData === 'undefined') {
      window.console.log('Your browser doesn\'t support FormData. ' +
        'File uploading feature is disabled.');
      this.reset();
      return;
    }
    const formData = new FormData();
    const file = this.refs.file.files[0];
    const xhr = new XMLHttpRequest();
    const logError = () => {
      window.console.log(`Error ${xhr.status} occurred while file uploading.`);
    };

    data.fields.forEach((item) => {
      formData.append(item.name, item.value);
    });
    formData.append('file', file);

    xhr.addEventListener('load', () => {
      if (xhr.status >= 200 && xhr.status < 400) {
        this.onS3UploadDone();
      } else {
        logError();
      }
    }, false);

    xhr.addEventListener('error', () => {
      logError();
    }, false);

    xhr.open('POST', this.state.action, true);
    xhr.send(formData);
  },

  filename() {
    return this.refs.file.value // not using FileAPI, because IE8
      .replace(/\\/g, '/') // Windows
      .split('/').slice(-1)[0];
  },

  render() {
    const uploadBtnText = this.state.loading ?
      this.props.uploadBtnLoadingText :
      this.props.uploadBtnText;
    let removeBtn = null;
    if (this.props.removeBtnText && !this.state.loading) {
      removeBtn = (
        <Button
          classNameModifier="cub-Button--delete"
          onClick={this.onRemove}
          title={this.props.removeBtnText}
          text={this.props.removeBtnText}
        />
      );
    }

    return (
      <div className="cub-ImageUpload">
        {this.state.error && (
          <Error classNameModifier={this.props.errorClassNameModifier}>
            {this.state.error}
          </Error>
        )}
        <div className="cub-FormGroup cub-FormGroup--buttons">
          <div
            className="cub-FormControl cub-FormControl--imageUpload"
          >
            <div
              className="cub-Form cub-Form--imageUpload"
            >
              {this.state.fields.map((f) => (
                <input
                  type="hidden"
                  key={f.name}
                  name={f.name}
                  value={f.value}
                />
              ))}
              <div
                className={'cub-Button cub-Button--imageUpload' +
                  ` ${this.state.loading ? 'is-processing' : ''}`}
                title={uploadBtnText}
              >
                <span className="cub-Button-icon" />
                <span className="cub-Button-text">{uploadBtnText}</span>
                <input
                  className="cub-FormControl cub-FormControl--input"
                  type="file"
                  name="file"
                  ref="file"
                  accept="image/jpeg, image/jpg, image/png, image/gif"
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          {removeBtn}
        </div>
      </div>
    );
  },
});

export default ImageUploadForm;
