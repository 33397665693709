import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import DataStore from '../../stores/DataStore';
import GroupActions from '../../actions/GroupActions';
import GroupDetailsForm from '../forms/GroupDetailsForm';
import Loading from '../basic/Loading';
import MemberGrid from './MemberGrid';
import router from '../../router/router';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import {
  GROUP_MEMBER_ADD,
  GROUP_MEMBER_PERMISSIONS,
} from '../../constants/FormIds';
import Button from '../basic/Button';
import Tabs from '../basic/Tabs';

/* eslint-disable react/prefer-es6-class */
const Group = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    args: PropTypes.array,
    currentMember: PropTypes.object,
    loadingStarted: PropTypes.bool,
    organization: PropTypes.object,
    route: PropTypes.object,
    routes: PropTypes.object,
  },

  /* eslint-disable react/no-unused-class-component-methods,react/sort-comp */
  mixins: [storeListenerMixin(DataStore)],

  getStateFromStores() {
    const group = DataStore.group.get(this.props.args[0]);
    let members = [];
    let potentialMembers = [];
    if (group) {
      members = group.activeMembers();
      potentialMembers = group.potentialMembers();
    }
    return { group, members, potentialMembers };
  },
  /* eslint-enable react/no-unused-class-component-methods,react/sort-comp */

  getInitialState() {
    return {
      addMode: this.addMode(this.props.route),
      selectedMembers: [],
    };
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ addMode: this.addMode(nextProps.route) });
  },

  onMembersSelect(members) {
    this.setState({ selectedMembers: members });
  },

  onMembersBtnClick() {
    const fn = this.state.addMode ? this.addMember : this.removeMember;
    this.state.selectedMembers.forEach(fn.bind(this));
  },

  getGroupMember(member) {
    return DataStore.groupmember.filterRelated([this.state.group, member])[0];
  },

  setPermissions(groupMember, data) {
    GroupActions.setGroupMemberPermissions(
      groupMember,
      data,
      GROUP_MEMBER_PERMISSIONS,
    );
  },

  removeMember(member) {
    GroupActions.deleteGroupMember(this.getGroupMember(member));
  },

  addMember(member) {
    GroupActions.newGroupMember({
      group: this.state.group.id,
      member: member.id,
    }, GROUP_MEMBER_ADD);
  },

  addMode(route) {
    return (route === this.props.routes.GROUP_ADD_MEMBERS);
  },

  roles(member) {
    let role = 'User';
    if (member.get('is_admin')) {
      role = 'Org. Admin';
    } else {
      const gm = this.getGroupMember(member);
      if (gm && gm.get('is_admin')) {
        role = 'Group Owner';
      }
    }
    const currentMember = this.props.currentMember;
    if (
      currentMember && (
        currentMember.id === member.id ||
        currentMember.get('user').id === member.get('user').id
      )
    ) {
      role += ' (You)';
    }
    return role;
  },

  memberBtns(member) {
    /* eslint-disable react/jsx-no-bind */
    let btns;
    if (this.state.addMode) {
      btns = (
        <Button
          classNameModifier="cub-Button--add"
          onClick={this.addMember.bind(this, member)}
          text="Add"
        />
      );
    } else if (member === this.props.currentMember && !member.get('is_admin')) {
      // Hide 'Remove' & 'Revoke Owner' buttons
      // from current user if he is owner of this group
      btns = null;
    } else {
      btns = [];
      if (!member.get('is_admin')) {
        const gm = this.getGroupMember(member);
        if (gm) {
          const isAdmin = gm.get('is_admin');

          btns.push(
            <Button
              classNameModifier={
                `cub-Button--${isAdmin ? 'revoke' : 'grant'}`
              }
              key={isAdmin ? 'revoke' : 'grant'}
              onClick={this.setPermissions.bind(
                this, gm, { is_admin: !isAdmin },
              )}
              text={`${isAdmin ? 'Revoke' : 'Make'} Owner`}
            />,
          );
        }
      }
      btns.push(
        <Button
          classNameModifier="cub-Button--delete"
          key="delete"
          onClick={this.removeMember.bind(this, member)}
          text="Remove"
        />,
      );
    }
    return btns;
    /* eslint-enable react/jsx-no-bind */
  },

  render() {
    const group = this.state.group || DataStore.group.get(this.props.args[0]);
    const organization = this.props.organization;
    if (!group) {
      if (this.props.loadingStarted &&
          !DataStore.nowLoadingRelatedTo(organization)) {
        // Looks like this group was not found
        router.navigate(this.props.routes.GROUPS);
      }
      return <Loading />;
    }

    // Check if current user is allowed to manage this group
    const currentMember = this.props.currentMember;
    if (currentMember.manageableGroups(organization).indexOf(group) === -1) {
      router.navigate(this.props.routes.GROUPS);
      return <Loading />;
    }

    const id = {
      group: this.props.routes.GROUP.makeUrl(group.id),
      groupAdd: this.props.routes.GROUP_ADD_MEMBERS.makeUrl(group.id),
      current: this.props.route.makeUrl(group.id),
    };

    let members;
    let filters = { is_active: true, order_by: 'user__last_name' };
    if (this.state.addMode) {
      members = this.state.potentialMembers;
      filters = _.extend({}, filters, { exclude_group: group.id });
    } else {
      members = this.state.members;
      filters = _.extend({}, filters, { group: group.id });
    }

    let membersBtn = null;
    let membersBtnCls = '';
    let membersBtnTitle;

    const count = this.state.members ? this.state.members.length : 0;
    const tabsData = [
      {
        id: id.group,
        href: id.group,
        name: 'groupMembers',
        title: 'Group members',
        route: this.props.routes.GROUP,
        count,
      },
      {
        id: id.groupAdd,
        href: id.groupAdd,
        name: 'addMembers',
        title: 'Add members',
        route: this.props.routes.GROUP_ADD_MEMBERS,
      },
    ];

    if (members.length) {
      const selectedLength = this.state.selectedMembers.length;

      membersBtnCls += !selectedLength ? ' isDisabled' : '';
      membersBtnCls += this.state.addMode ?
        ' cub-Button--add' :
        ' cub-Button--delete';
      membersBtnTitle = this.state.addMode ? 'Add selected' : 'Remove selected';
      membersBtnTitle += ` (${selectedLength})`;
      membersBtn = (
        <Button
          classNameModifier={membersBtnCls}
          onClick={this.onMembersBtnClick}
          text={membersBtnTitle}
        />
      );
    }

    const membersEmptyMessage = this.state.addMode ?
      'All organization members are added into this group' :
      'Group is empty';

    return (
      <div className="cub-Group">
        <GroupDetailsForm group={group} />

        <Tabs
          classNameModifier="cub-Tabs--members"
          options={tabsData}
          active={id.current}
        />

        {membersBtn}

        <MemberGrid
          className="cub-Grid cub-Grid--memberGroups"
          routes={this.props.routes}
          members={members}
          filters={filters}
          onSelect={this.onMembersSelect}
          btns={this.memberBtns}
          showLinks={currentMember.get('is_admin')}
          roles={this.roles}
          emptyMessage={membersEmptyMessage}
          currentMember={currentMember}
          organization={organization}
        />
      </div>
    );
  },
});

export default Group;
