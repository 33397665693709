import PropTypes from 'prop-types';
import React from 'react';
import smartdate from 'smartdate';
import ApiActions from '../../actions/ApiActions';
import Button from '../basic/Button';
import FormErrors from '../FormErrors';
import Plan from '../fields/Plan';
import Error from '../basic/Error';
import NavLink from '../basic/NavLink';
import Form from '../basic/Form';
import VerifyCouponForm from './VerifyCouponForm';

export default class PlanForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      plan: this.props.initialPlan || (
        props.serviceSubscription && props.serviceSubscription.get('plan')
      ),
      couponCode: '',
      isProcessing: false,
      processingCancel: false,
    };
    this.onPlanChange = this.onPlanChange.bind(this);
    this.onCouponCodeChange = this.onCouponCodeChange.bind(this);
    this.onCancelSubscription = this.onCancelSubscription.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.onCouponVerified = this.onCouponVerified.bind(this);
  }

  onPlanChange(plan) {
    this.setState({ plan });
  }

  onCouponCodeChange(e) {
    this.setState({ couponCode: e.target.value });
  }

  onCouponVerified(coupon) {
    this.setState({ coupon });
  }

  onCancelSubscription() {
    const ss = this.props.serviceSubscription;
    const paidTill = smartdate.format(
      ss.get('active_till'),
      { mode: 'datetime' },
    );
    const msg = 'Are you sure you want to discard paid access to the site?' +
        `\n\nYour subscription is paid until ${paidTill}. If you decide to ` +
        'cancel it, you\'ll keep paid access until that date, but after ' +
        'that your subscription will not auto-renew.' +
        '\n\nClick OK if you really want to discard paid access.';
    if (window.confirm(msg)) { // eslint-disable-line no-alert
      ApiActions.del(
        `servicesubscriptions/${ss.id}`,
        {},
        this.onSuccess,
        this.onError,
      );
      this.setState({ processingCancel: true });
    }
  }

  onSubmit(e) {
    if (!this.state.isProcessing) {
      const plan = (this.state.plan && this.state.plan.id) || '';
      let coupon = this.state.couponCode;
      if (this.props.serviceSubscription) {
        if (this.props.serviceSubscription.get('plan').id === plan) {
          // Prevent users from accidentally cancelling their applied coupons
          // for current subscription. But if plan was changed, coupon is lost.
          coupon = coupon || this.props.serviceSubscription.get('coupon');
        }
        ApiActions.post(
          `servicesubscriptions/${this.props.serviceSubscription.id}`,
          { coupon, plan },
          this.onSuccess,
          this.onError,
        );
      } else {
        ApiActions.post(
          'servicesubscriptions',
          { customer: this.props.customer.id, coupon, plan },
          this.onSuccess,
          this.onError,
        );
      }
      this.setState({ isProcessing: true });
    }

    e.preventDefault();
  }

  onSuccess() {
    this.setState({ isProcessing: false, processingCancel: false });
    if (typeof this.props.onSuccess === 'function') {
      this.props.onSuccess();
    }
  }

  onError(response) {
    this.setState({
      error: new FormErrors(response).firstError(),
      isProcessing: false,
      processingCancel: false,
    });
  }

  render() {
    const plans = this.props.plans;
    const ss = this.props.serviceSubscription;
    const buttons = (
      <div className="cub-FormGroup cub-FormGroup--buttons">
        {plans.length > 0 && (
          <Button
            type="submit"
            isProcessing={this.state.isProcessing}
            onClick={this.onSubmit}
            text="OK"
          />
        )}
        {(plans.length > 0 &&
          !this.state.isProcessing &&
          this.props.onCancel) && (
          <Button
            classNameModifier="cub-Button--cancel"
            onClick={this.props.onCancel}
            text="Cancel"
          />
        )}
      </div>
    );
    return (
      <div className="cub-ChoosePlan">
        <Error classNameModifier="cub-Form-error--top">
          {this.state.error}
        </Error>
        {!this.state.processingCancel && (
          <div className="cub-PlanList">
            <Form classNameModifier="cub-Form--planList">
              <Plan
                options={plans}
                value={this.state.plan}
                onChange={this.onPlanChange}
              />
              <VerifyCouponForm
                plan={this.state.plan}
                onChange={this.onCouponCodeChange}
                onSuccess={this.onCouponVerified}
                onError={this.onError}
              />
              {this.state.coupon && (
                <p>Discount applied:{' '}
                  {this.state.coupon.discountStr(this.state.plan.get('amount'))}
                </p>
              )}
              {buttons}
            </Form>
          </div>
        )}
        {!this.state.isProcessing && ss &&
          !ss.expired() && !ss.get('cancel_at_period_end') && (
          <NavLink
            className={'cub-Link cub-Link--danger cub-Link--remove' +
              `${this.state.processingCancel ? ' is-processing' : ''}`}
            onClick={this.onCancelSubscription}
          >
            <span className="cub-Link-icon" />
            Cancel subscription
          </NavLink>
        )}
      </div>
    );
  }
}

PlanForm.propTypes = {
  customer: PropTypes.object,
  initialPlan: PropTypes.object,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  plans: PropTypes.array,
  serviceSubscription: PropTypes.object,
};
