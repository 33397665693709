import PropTypes from 'prop-types';
import React from 'react';
import MemberActions from '../../actions/MemberActions';
import MemberPositions from './MemberPositions';
import Button from '../basic/Button';
import NavLink from '../basic/NavLink';
import { siteSentry } from '../../utils/Utils';
import LabeledCheckbox from '../fields/LabeledCheckbox';
import { MEMBER_DETAILS } from '../../constants/FormIds';
import Text from '../basic/Text';

export default class Membership extends React.Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
    this.onProfileEditableChange = this.onProfileEditableChange.bind(this);
  }

  onDelete() {
    const org = this.props.member.get('organization');
    const orgName = (org && org.get('name')) || 'this organization';
    let confirmation = null;
    if (this.props.member.get('is_active')) {
      confirmation = 'Are you sure you want to remove your membership from ' +
          `${orgName}?\n\n` +
          'You are currently a verified member of this organization. ' +
          'If you cancel your membership, you\'ll loose access to services ' +
          'provided through your organization\'s paid subscriptions.\n\n' +
          `If you later decide to re-add your membership to ${orgName} ` +
          'your membership status will need to be reviewed and officially ' +
          'verified.';
    } else if (this.props.member.related.memberposition.length > 0) {
      confirmation = `Our system records of your job positions at ${orgName} ` +
          'will be deleted. Click OK to proceed with deletion.';
    }
    if (!confirmation ||
        window.confirm(confirmation)) { // eslint-disable-line no-alert
      MemberActions.deleteMember(this.props.member);
    }
  }

  onProfileEditableChange() {
    const member = this.props.member;
    const isProfileEditable = member.get('is_profile_editable');
    const confirmation = 'Are you sure you want to revoke the ability for ' +
      'your organization administrator(s) ' +
      'to edit your basic personal information? ' +
      'Proceeding with this action may make it more difficult ' +
      'to match your personal information with official records ' +
      'within your organization.';
    // eslint-disable-next-line no-alert
    const update = !isProfileEditable || window.confirm(confirmation);
    if (update) {
      MemberActions.updateMember(member, {
        personal_id: member.get('personal_id'),
        post_id: member.get('post_id'),
        notes: member.get('notes'),
        is_profile_editable: !isProfileEditable,
      }, MEMBER_DETAILS);
    }
  }

  render() {
    const routes = this.props.routes;
    const member = this.props.member;
    const site = this.props.site;
    const isAdmin = member.get('is_admin');
    const isProfileEditable = member.get('is_profile_editable');
    const organization = member.get('organization');
    // attempt to debug frequent error
    // "Unable to get property 'fullAddress' of undefined"
    if (!organization) {
      siteSentry.setExtras({
        Membership_render_organization: organization,
        Membership_render_member: member,
        Membership_render_member_attrs: member && member.attributes,
        Membership_render_member_mpos: member && member.related.memberposition,
      });
    }
    const address = organization.fullAddress();
    const phone = organization.get('phone');
    let verificationBlock = null;
    let verification = {};
    if (this.props.showVerificationLinks && member.canBeVerified(site)) {
      const verified = member.verificationApproved();
      const pending = member.verificationPending();

      if (verified) {
        verification = {
          title: 'Verified',
          status: 'verified',
          msgStatus: 'cub-Msg--success',
          text: 'You\'ve been successfully verified.',
        };
      } else {
        verification = {
          title: 'Get Verified',
          status: 'unverified',
          msgStatus: 'cub-Msg--warning',
          text: 'Verifying your employment is important. Click ' +
            '"Get verified" to become a verified member.',
        };

        if (pending) {
          verification = {
            title: 'Check Status',
            status: 'pending',
            msgStatus: '',
            text: 'Your verification request is in processing.',
          };
        } else if (member.verificationDeclined()) {
          verification = {
            title: 'Try again',
            status: 'declined',
            msgStatus: 'cub-Msg--danger',
            text: 'Your verification request has been declined. Click ' +
              '"Try again" for more details.',
          };
        }
      }

      verificationBlock = (
        <div className={`cub-Msg ${verification.msgStatus}`}>
          <div className={'cub-Row cub-Util-flexAlignCenter ' +
            'cub-Util-flexJustifyBetween cub-Util-flexNoWrap ' +
            'cub-Util-flexWrapMobileXL'}
          >
            <div className="cub-Row-column cub-Util-flexShrink">
              <Text>{verification.text}</Text>
            </div>
            {!verified && (
              <div className="cub-Row-column cub-Util-textCenter">
                <NavLink
                  className={'cub-Button cub-Button--verification ' +
                    `is-${verification.status}`}
                  route={routes.VERIFICATION_REQUESTS}
                >
                  <span className="cub-Button-icon" />
                  {verification.title}
                </NavLink>
              </div>
            )}
          </div>
        </div>
      );
    }
    const verifyStatus = verification.status ? `is-${verification.status}` : '';
    return (
      <div className={`cub-Organization ${verifyStatus}`}>
        <div className="cub-Organization-header">
          <Button
            classNameModifier="cub-Button--delete"
            onClick={this.onDelete}
            text="Remove"
          />
          <p className="cub-Organization-name">{organization.get('name')}</p>
          {address && <p className="cub-Organization-address">{address}</p>}
          {phone && (
            <p className="cub-Organization-phone">
              <span>Phone: </span>
              <span className="cub-Organization-phoneNumber">
                {phone}
              </span>
            </p>
          )}
          {!isAdmin && (
          <LabeledCheckbox
            label={'Organization administrator can edit ' +
                   'your basic profile information'}
            name="is_profile_editable"
            value="true"
            onChange={this.onProfileEditableChange}
            checked={isProfileEditable}
          />
          )}
          {verificationBlock}
        </div>
        <MemberPositions
          autoAdd={this.props.autoAddPosition}
          header=""
          member={this.props.member}
        />
      </div>
    );
  }
}

Membership.propTypes = {
  autoAddPosition: PropTypes.bool,
  member: PropTypes.object,
  routes: PropTypes.object,
  showVerificationLinks: PropTypes.bool,
  site: PropTypes.object,
};
