import LabeledInput from './LabeledInput';

export default class GroupName extends LabeledInput {}

GroupName.defaultProps = {
  classNameModifier: 'cub-FormControl--groupName',
  label: 'Name',
  maxLength: 100,
  name: 'name',
};
