import PropTypes from 'prop-types';
import React from 'react';

export default class AsyncComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Component: null,
    };
  }

  componentWillMount() {
    if (!this.state.Component) {
      this.props.moduleProvider().then(
        (Component) => {
          this.setState({ Component: Component.default });
        },
      );
    }
  }

  render() {
    /* eslint-disable react/no-unused-class-component-methods */
    // this.component is used in AsyncComponent.jsx
    const { Component } = this.state;
    return Component ? (
      <Component
        ref={(instance) => { this.component = instance; }}
        {...this.props}
      />
    ) : <span>Loading...</span>;
    /* eslint-enable react/no-unused-class-component-methods */
  }
}

AsyncComponent.propTypes = {
  moduleProvider: PropTypes.func,
};
