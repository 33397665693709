import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import LoginStore from '../../stores/LoginStore';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import UserLookupForm from '../forms/UserLookupForm';
import UserPasswordLoginForm from '../forms/UserPasswordLoginForm';
import UserSsoLoginView from './UserSsoLoginView';

const VIEW_USERNAME = 1;
const VIEW_PASSWORD = 2;
const VIEW_SSO = 3;

/* eslint-disable react/prefer-es6-class */
const MultiStepLogin = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    routes: PropTypes.object,
  },

  /* eslint-disable react/no-unused-class-component-methods,react/sort-comp */
  mixins: [storeListenerMixin(LoginStore)],

  getStateFromStores() {
    return {
      username: LoginStore.getUsername(),
      placeholder: LoginStore.getPlaceholder(),
      ssoOptions: LoginStore.getSsoOptions(),
      lastVisitedSiteNames: LoginStore.getLastVisitedSiteNames(),
    };
  },
  /* eslint-enable react/no-unused-class-component-methods,react/sort-comp */

  getCurrentView() {
    if (!this.state.username) {
      return VIEW_USERNAME;
    }
    const ssoOptions = this.state.ssoOptions;
    if (ssoOptions !== null && ssoOptions.length > 0) {
      return VIEW_SSO;
    }

    return VIEW_PASSWORD;
  },

  render() {
    const routes = this.props.routes;
    switch (this.getCurrentView()) {
      case VIEW_USERNAME: {
        return (
          <UserLookupForm
            routes={routes}
            placeholder={this.state.placeholder}
          />
        );
      }

      case VIEW_PASSWORD:
        return (
          <UserPasswordLoginForm
            routes={routes}
            username={this.state.username}
            lastVisitedSiteNames={this.state.lastVisitedSiteNames}
          />
        );
      case VIEW_SSO:
        return (
          <UserSsoLoginView
            routes={routes}
            username={this.state.username}
            options={this.state.ssoOptions}
          />
        );
      default:
        throw new Error('Unknown view - show error');
    }
  },
});

export default MultiStepLogin;
