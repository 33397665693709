import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import LabeledRadioGroup from './LabeledRadioGroup';
import { siteSentry } from '../../utils/Utils';

export default class Plan extends React.Component {
  onChange(e) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(
        _.find(this.props.options, (plan) => plan.id === e.target.value),
      );
    }
  }

  render() {
    const options = {};
    this.props.options.forEach((plan) => {
      const product = plan.get('product');
      let productName;
      try {
        productName = product.get('name');
      } catch (e) {
        siteSentry.captureException(e, { extra: { plan, product } });
        productName = product.name;
      }
      options[plan.id] = (
        <span className="cub-Plan">
          <span className="cub-Plan-name">{productName}</span>,{' '}
          <span className="cub-Plan-price">{plan.price()}</span>
          <span className="cub-Plan-description">
            {plan.get('description') || ''}
          </span>
        </span>
      );
    });
    /* eslint-disable react/jsx-no-bind */
    return (
      <LabeledRadioGroup
        label={this.props.label}
        name="plan"
        onChange={this.onChange.bind(this)}
        options={options}
        value={this.props.value && this.props.value.id}
      />
    );
    /* eslint-enable react/jsx-no-bind */
  }
}

Plan.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.object,
};
