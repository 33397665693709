import _ from 'underscore';

const localStorageKey = 'cubWidgetCache';

const CUB_STORAGE_SUPPORTED = 'CUB_STORAGE_SUPPORTED';
export function storageSupported(storageType) {
  if (!_.contains(['localStorage', 'sessionStorage'], storageType)) {
    throw Error('"storageType" must be "localStorage" or "sessionStorage"');
  }

  try {
    if (storageType in window && Boolean(window[storageType])) {
      window[storageType][CUB_STORAGE_SUPPORTED] = 'yes';
      if (window[storageType][CUB_STORAGE_SUPPORTED] === 'yes') {
        return true;
      }
      return false;
    }
  } catch (e) {
    // any error means that storage is not supported
  }
  return false;
}

export function clearLocalStorage() {
  if (storageSupported('localStorage')) {
    try {
      delete window.localStorage[localStorageKey];
    } catch (e) {
      // suppress missing key exception in IE8
    }
  }
}

export function putToLocalStorage(objectToPut) {
  let localStorageContent;
  try {
    localStorageContent = JSON.parse(window.localStorage[localStorageKey]);
  } catch (e) {
    if (e instanceof SyntaxError) {
      localStorageContent = {};
    } else {
      throw e;
    }
  }
  localStorageContent = _.extend(localStorageContent, objectToPut);
  window.localStorage[localStorageKey] = JSON.stringify(localStorageContent);
}

export function getFromLocalStorage(key) {
  const localStorageContentRaw = window.localStorage[localStorageKey];
  if (!localStorageContentRaw) {
    return undefined;
  }

  let localStorageContent;
  try {
    localStorageContent = JSON.parse(localStorageContentRaw);
  } catch (e) {
    if (e instanceof SyntaxError) {
      return undefined;
    }
    throw e;
  }

  if (!key) {
    return localStorageContent;
  }
  return localStorageContent[key];
}
