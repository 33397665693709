import LabeledInput from './LabeledInput';

export default class FirstName extends LabeledInput {}

FirstName.defaultProps = {
  classNameModifier: 'cub-FormControl--firstName',
  label: 'First Name',
  maxLength: 40,
  name: 'first_name',
};
