import _ from 'underscore';
import React from 'react';
import createForm from '../../mixins/createForm';
import Notes from '../fields/Notes';
import MemberActions from '../../actions/MemberActions';
import PersonalId from '../fields/PersonalId';
import PostId from '../fields/PostId';
import { MEMBER_DETAILS } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';

const MemberDetailsForm = createForm(MEMBER_DETAILS, {
  getInitialState() {
    return {
      personal_id: this.props.member.get('personal_id') || '',
      post_id: this.props.member.get('post_id') || '',
      notes: this.props.member.get('notes') || '',
    };
  },

  componentWillReceiveProps(nextProps) {
    if (!this.prevMemberAttrs) {
      // we can't just compare attrs of member from current and next props
      // because it references to the same object
      // store copy of attrs to compare with next props
      this.prevMemberAttrs = _.clone(this.props.member.attributes);
      return;
    }

    const updates = {};
    const attrs = this.prevMemberAttrs;
    const nextAttrs = nextProps.member.attributes;

    _.each(['personal_id', 'post_id', 'notes'], (field) => {
      if (attrs[field] !== nextAttrs[field]) {
        // override input only if we received updated member info
        updates[field] = nextAttrs[field];
      }
    });

    if (!_.isEmpty(updates)) this.setState(updates);
    this.prevMemberAttrs = _.clone(this.props.member.attributes);
  },

  submit() {
    MemberActions.updateMember(this.props.member, {
      personal_id: this.state.personal_id,
      post_id: this.state.post_id,
      notes: this.state.notes,
    }, MEMBER_DETAILS);
  },

  render() {
    const organization = this.props.member.get('organization');

    // display only for Law Enforcement
    let postIdField = null;
    if (organization.postIdVisible()) {
      postIdField = (
        <PostId
          value={this.state.post_id}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('post_id')}
        />
      );
    }

    return (
      <Form
        classNameModifier="cub-Form--memberDetails"
        error={this.state.errors.topError()}
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <PersonalId
          value={this.state.personal_id}
          label={organization.fieldLabels().personal_id}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('personal_id')}
        />
        {postIdField}

        <Notes
          value={this.state.notes}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('notes')}
        />

        <Button
          type="submit"
          isProcessing={this.state.isProcessing}
          text="Update"
        />
      </Form>
    );
  },
});

export default MemberDetailsForm;
