import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import IdpConfirmationStore from '../../stores/IdpConfirmationStore';
import Loading from '../basic/Loading';
import NavLink from '../basic/NavLink';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import CaptionLarge from '../basic/CaptionLarge';
import Text from '../basic/Text';
import UserActions from '../../actions/UserActions';

/* eslint-disable react/prefer-es6-class */
const IdpConfirmation = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    routes: PropTypes.object,
    args: PropTypes.array,
  },

  /* eslint-disable react/no-unused-class-component-methods,react/sort-comp */
  mixins: [storeListenerMixin(IdpConfirmationStore)],

  getStateFromStores() {
    return {
      status: IdpConfirmationStore.status(),
    };
  },
  /* eslint-enable react/no-unused-class-component-methods,react/sort-comp */

  getInitialState() {
    return {
      token: this.props.args[0],
    };
  },

  componentDidMount() {
    if (this.state.token) {
      UserActions.idpConfirm(this.state.token);
    }
  },

  render() {
    const routes = this.props.routes;
    let state;
    switch (this.state.status) {
      case 'confirmed': {
        state = (
          <div className={'cub-InfoBlock' +
          ' cub-InfoBlock--idpConfirmed isSuccess'}
          >
            <CaptionLarge>Association confirmation</CaptionLarge>
            <Text>
              Thank you, your account association is now confirmed.
            </Text>
            <Text>
              You are welcome
              to <NavLink route={routes.LOGIN}>Login</NavLink> now.
            </Text>
          </div>
        );
        break;
      }

      case 'bad token':
        state = (
          <div className="cub-InfoBlock hasBadToken">
            <Text>The confirmation link you are attempting to use is invalid.
            </Text>
            <Text>
              If you copied the link into your browser manually, please try
              again and make sure that it is copied correctly.
            </Text>
            <Text>
              If this problem persists, please contact our{' '}
              <NavLink route={routes.SUPPORT}>Customer Support</NavLink>.
            </Text>
          </div>
        );
        break;

      default:
        state = <Loading />;
    }
    return state;
  },
});

export default IdpConfirmation;
