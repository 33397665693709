import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Combobox from 'react-widgets/lib/Combobox';
import Error from '../basic/Error';

export default class LabeledCombobox extends React.Component {
  constructor(props) {
    super(props);
    this.value = props.value || null;
    this.state = { open: null };
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.validate = this.validate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.value = nextProps.value;
  }

  onToggle(isOpen) {
    if (this.props.showNoResults) {
      this.setState({ open: isOpen });
    } else {
      this.setState({ open: isOpen && this.hasSuggestions(this.value) });
    }
  }

  onChange(value) {
    this.value = value;
    if (!this.hasSuggestions(this.value)) {
      this.setState({ open: false });
    }
    this.fireChange(value);
  }

  onSelect() {
    this.setState({ open: false });
  }

  fireChange(value) {
    if (typeof this.props.onChange === 'function' && this.props.name) {
      if (_.isObject(value) && _.isEmpty(value)) return;
      // emulate event.target
      this.props.onChange({
        target: { name: this.props.name, value },
      });
    }
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  focus() {
    const label = ReactDOM.findDOMNode(this);
    if (label.click) {
      label.click();
    } else {
      label.focus();
    }
  }

  hasSuggestions(value) {
    const data = this.props.data;
    let findFunc;
    if (!Array.isArray(data)) {
      // Anything except array is not supported
      return true;
    }
    if (!value) {
      return data.length > 0;
    }
    // Only arrays of strings are supported
    if (this.props.filter === 'startsWith') {
      findFunc = (item) => item.indexOf(value) === 0;
    } else if (this.props.filter === 'contains') {
      findFunc = (item) => item.indexOf(value) !== -1;
    } else if (this.props.filter === 'endsWith') {
      findFunc = (item) => item.indexOf(value) !== (item.length - value.length);
    }
    return Boolean(_.find(data, findFunc));
  }

  validate() {
    const {
      data, value, required, restrictToKnown,
    } = this.props;
    if (required && !String(value)) {
      return 'This field is required.';
    }
    if (restrictToKnown && value && !_.contains(data, value)) {
      return 'Must be chosen from dropdown list.';
    }
    return null;
  }

  render() {
    const props = _.omit(
      this.props,
      'onChange',
      'onSelect',
      'label',
      'error',
      'open',
      'onToggle',
      'classNameModifier',
      'showNoResults',
    );
    props.onChange = this.onChange;
    props.onSelect = this.onSelect;
    props.onToggle = this.onToggle;
    props.ref = 'combobox';
    props.open = this.state && this.state.open; // Weird bug in IE9
    const classNames = [
      'cub-FormControl',
      'cub-FormControl--combobox',
    ];
    if (this.props.classNameModifier) {
      classNames.push(this.props.classNameModifier);
    }
    if (Array.isArray(props.data) && !props.data.length) {
      classNames.push('is-empty');
    }
    return (
      <div className="cub-FormGroup cub-FormGroup--combobox">
        <label className="cub-Label cub-Label--combobox">
          {this.props.label}
          {this.props.required && <span className="cub-Label-asterisk">*</span>}
        </label>
        <div className={classNames.join(' ')}>
          <Combobox {...props} />
        </div>
        <Error dataField={this.props.name}>
          {this.props.error}
        </Error>
      </div>
    );
  }
}

LabeledCombobox.propTypes = {
  classNameModifier: PropTypes.string,
  data: PropTypes.any,
  error: PropTypes.string,
  filter: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  showNoResults: PropTypes.bool,
  restrictToKnown: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
};

LabeledCombobox.defaultProps = {
  classNameModifier: '',
  showNoResults: false,
  restrictToKnown: false,
};
