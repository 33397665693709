import PropTypes from 'prop-types';
import React from 'react';

export default class Button extends React.Component {
  // eslint-disable-next-line react/no-unused-class-component-methods
  focus() {
    const buttonEl = this.buttonEl;
    if (buttonEl) {
      buttonEl.focus();
    }
  }

  render() {
    const classNameBase = this.props.classNameBase;
    const disabled = this.props.disabled ||
                     (this.props.disableWhenProcessing &&
                      this.props.isProcessing);
    let className = `${classNameBase} ${this.props.classNameModifier}`;
    if (this.props.isProcessing) {
      className += ' is-processing';
    }
    let onClick = this.props.onClick;
    if (disabled) {
      if (!this.props.isProcessing) className += ' is-disabled';
      onClick = null;
    }

    return (
      <button
        ref={(el) => { this.buttonEl = el; }}
        className={className}
        type={this.props.type || 'button'}
        onClick={onClick}
        onSubmit={this.props.onSubmit}
        disabled={disabled}
        title={this.props.title}
      >
        {this.props.showIcon &&
          <span className={`${classNameBase}-icon`} />}
        {this.props.text &&
          <span className={`${classNameBase}-text`}>{this.props.text}</span>}
      </button>
    );
  }
}

Button.propTypes = {
  classNameBase: PropTypes.string,
  classNameModifier: PropTypes.string,
  disableWhenProcessing: PropTypes.bool,
  isProcessing: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onSubmit: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  showIcon: PropTypes.bool,
};

Button.defaultProps = {
  classNameBase: 'cub-Button',
  classNameModifier: 'cub-Button--submit',
  showIcon: true,
};
