import PropTypes from 'prop-types';
import React from 'react';
import createForm from '../../mixins/createForm';
import UserActions from '../../actions/UserActions';
import { CREATE_PASSWORD_RESET_LINK } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';

const CreatePasswordResetLinkForm = createForm(CREATE_PASSWORD_RESET_LINK, {
  getInitialState() {
    return {
      link: null,
      linkCopied: false,
    };
  },

  submit() {
    UserActions.createPasswordResetLink(
      this.props.userId,
      CREATE_PASSWORD_RESET_LINK,
    );
  },

  storesSuccess(formId, response) {
    if (formId === CREATE_PASSWORD_RESET_LINK) {
      this.setState({ link: response });
    }
  },

  copyLink() {
    this.linkInput.select();
    document.execCommand('copy');
    this.setState({ linkCopied: true });
  },

  render() {
    return (
      <Form
        classNameModifier="cub-Form"
        onSubmit={this.submit}
        error={this.state.errors.topError()}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <Button
          type="submit"
          classNameModifier="cub-Button--add"
          isProcessing={this.state.isProcessing}
          text={this.props.buttonText}
          disabled={!!this.state.link}
        />
        {this.state.link && (
          <div className="cub-InputGroup">
            <input
              ref={(el) => { this.linkInput = el; }}
              className={'cub-FormControl cub-FormControl--input ' +
                'cub-InputGroup-input'}
              readOnly
              value={this.state.link}
            />
            <Button
              classNameModifier="cub-InputGroup-button cub-Button--noIcon"
              onClick={this.copyLink}
              text={this.state.linkCopied ? 'Copied!' : 'Copy'}
            />
          </div>
        )}
      </Form>
    );
  },
});

CreatePasswordResetLinkForm.propTypes = {
  userId: PropTypes.string,
  buttonText: PropTypes.string,
};

CreatePasswordResetLinkForm.defaultProps = {
  buttonText: 'Create Password Reset Link',
};

export default CreatePasswordResetLinkForm;
