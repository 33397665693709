import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import Multiselect from 'react-widgets/lib/Multiselect';
import Error from '../basic/Error';

export default class LabeledMultiselect extends React.Component {
  constructor(props) {
    super(props);
    const _value = _.pluck(props.value, props.valueField) || [];
    this.state = { value: _value };
    this.validate = this.validate.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    const newValue = _.pluck(value, this.props.valueField);
    this.setState({ value: newValue });

    if (typeof this.props.onChange === 'function' && this.props.name) {
      if (_.isObject(newValue) && _.isEmpty(newValue)) return;

      // emulate event.target
      this.props.onChange({
        target: { name: this.props.name, value: newValue, type: 'multiselect' },
      });
    }
  }

  validate() {
    if (this.props.required &&
      Array.isArray(this.state.value) && !this.state.value.length) {
      return 'This field is required.';
    }
    return null;
  }

  render() {
    const props = _.omit(
      this.props,
      'classNameModifier',
      'error',
      'label',
      'value',
      'onChange',
      'required',
    );
    props.ref = 'multiselect';
    props.defaultValue = this.state.value;
    props.onChange = this.onChange;
    const classNames = [
      'cub-FormControl',
      'cub-FormControl--multiselect',
    ];
    if (this.props.classNameModifier) {
      classNames.push(this.props.classNameModifier);
    }

    if (Array.isArray(props.data) && !props.data.length) {
      classNames.push('is-empty');
    }
    return (
      <div className="cub-FormGroup cub-FormGroup--multiselect">
        <label className="cub-Label cub-Label--multiselect">
          {this.props.label}
          {this.props.required && <span className="cub-Label-asterisk">*</span>}
        </label>
        <div className={classNames.join(' ')}>
          <Multiselect {...props} />
        </div>
        <Error dataField={this.props.name}>
          {this.props.error}
        </Error>
      </div>
    );
  }
}

LabeledMultiselect.propTypes = {
  classNameModifier: PropTypes.string,
  data: PropTypes.array,
  error: PropTypes.string,
  filter: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.array,
  valueField: PropTypes.string,
  textField: PropTypes.string,
};

LabeledMultiselect.defaultProps = {
  classNameModifier: '',
  data: [],
  valueField: 'id',
  textField: 'title',
};
