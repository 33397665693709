import PropTypes from 'prop-types';
import React from 'react';
import LabeledRadioGroup from './LabeledRadioGroup';

export default class Gender extends React.Component {
  render() {
    return (
      <LabeledRadioGroup
        classNameModifier="cub-FormGroup--gender"
        name="gender"
        options={{
          male: 'Male',
          female: 'Female',
        }}
        value={String(this.props.value)}
        onChange={this.props.onChange}
      />
    );
  }
}

Gender.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf(['male', 'female', '']),
};
