import { keyMirror } from '../../utils/Utils';
import LabeledSelect from './LabeledSelect';

const options = keyMirror([
  '',
  '1-10',
  '11-50',
  '51-100',
  '101-250',
  '251-500',
  '501-1000',
  '1001-2000',
  '2001+',
]);
options[''] = ' - please select - ';

export default class OrganizationEmployees extends LabeledSelect { }

OrganizationEmployees.options = options;

OrganizationEmployees.defaultProps = {
  classNameModifier: 'cub-FormControl--organizationEmployees',
  label: 'Organization size',
  name: 'employees',
  options,
};
