import LabeledInput from './LabeledInput';

export default class Username extends LabeledInput {}

Username.defaultProps = {
  classNameModifier: 'cub-FormControl--username',
  label: 'Username',
  maxLength: 60,
  name: 'username',
};
