import LabeledCombobox from './LabeledCombobox';

export default class GroupType extends LabeledCombobox {}

GroupType.defaultProps = {
  classNameModifier: 'cub-FormControl--groupType',
  filter: 'startsWith',
  label: 'Type',
  name: 'type',
};
