import LabeledInput from './LabeledInput';

export default class UsernameOrEmail extends LabeledInput {}

UsernameOrEmail.defaultProps = {
  classNameModifier: 'cub-FormControl--usernameOrEmail',
  label: 'Username or email',
  maxLength: 254,
  name: 'username',
};
