import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import ApiActions from '../../actions/ApiActions';
import DataStore from '../../stores/DataStore';
import Group from './Group';
import GroupAddForm from '../forms/GroupAddForm';
import GroupList from './GroupList';
import Loading from '../basic/Loading';
import NavLink from '../basic/NavLink';
import router from '../../router/router';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import CaptionLarge from '../basic/CaptionLarge';
import Text from '../basic/Text';

/* eslint-disable react/prefer-es6-class */
const Groups = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    route: PropTypes.object,
    routes: PropTypes.object,
  },

  /* eslint-disable react/no-unused-class-component-methods,react/sort-comp */
  mixins: [storeListenerMixin(DataStore)],

  getStateFromStores() {
    const member = DataStore.currentMember();
    const updatedState = {
      accessDenied: true,
      currentMember: member,
    };
    let organization = DataStore.selectedTrustingOrg();
    if (!organization) {
      organization = member && member.get('organization');
      updatedState.selectedOrg = organization;
    }
    if (member && (member.get('is_admin') || member.isGroupOwner())) {
      updatedState.accessDenied = false;
      this.loadGroups(organization);
    }
    return updatedState;
  },
  /* eslint-enable react/no-unused-class-component-methods,react/sort-comp */

  getInitialState() {
    this.loadingScheduled = false;
    const currentMember = DataStore.currentMember();
    const selectedTrustingOrg = DataStore.selectedTrustingOrg();
    const currentOrg = currentMember && currentMember.get('organization');
    return {
      currentMember,
      selectedOrg: selectedTrustingOrg || currentOrg,
      loadingStarted: false,
    };
  },

  onOrgChange(org) {
    this.loadingScheduled = false;
    this.setState({
      selectedOrg: org,
    });
  },

  loadGroups(organization) {
    if (this.loadingScheduled) {
      return;
    }
    window.setTimeout(() => {
      ApiActions.getAll('groups', { organization: organization.id });
      this.setState({ loadingStarted: true });
    }, 0);
    this.loadingScheduled = true;
  },

  render() {
    const currentMember = this.state.currentMember;

    if (!currentMember) {
      return <Loading />;
    }
    if (this.state.accessDenied) {
      router.navigate(this.props.routes.HOME);
      return <Loading />;
    }

    const organization = this.state.selectedOrg;
    const routes = this.props.routes;
    let currentView;
    let header = null;

    if (DataStore.currentSite().getWidgetSetting('groups', 'header')) {
      header = (
        <header className="cub-Header">
          <CaptionLarge classNameModifier="cub-Caption--large">
            Groups: {organization.get('name')}
          </CaptionLarge>
          <Text>
            <NavLink
              className="cubLink cubLink--manageMembers cub-Util-marginRight"
              route={routes.MEMBERS_ACTIVE}
            >
              <i
                className={'cub-Icon cub-Icon--manageMembers ' +
                  'cub-Util-noMarginLeft'}
              />
              Manage members
            </NavLink>
            <NavLink
              className="cub-Link cubLink--manageGroups"
              route={routes.GROUPS}
            >
              <i className={'cub-Icon cub-Icon--manageGroups ' +
                'cub-Util-noMarginLeft'}
              />
              Manage groups
            </NavLink>
          </Text>
        </header>
      );
    }

    if (this.props.route === routes.GROUPS_NEW) {
      currentView = (
        <GroupAddForm
          routes={routes}
          organization={organization}
          currentMember={currentMember}
          onChange={this.onOrgChange}
        />
      );
    } else if (this.props.route === routes.GROUPS) {
      currentView = (
        <GroupList
          routes={routes}
          organization={organization}
          currentMember={currentMember}
          onChange={this.onOrgChange}
        />
      );
    } else {
      currentView = (
        <Group
          {...this.props}
          organization={organization}
          currentMember={currentMember}
          loadingStarted={this.state.loadingStarted}
        />
      );
    }

    return <div className="cub-Groups">{header} {currentView}</div>;
  },
});

export default Groups;
