import React from 'react';
import createForm from '../../mixins/createForm';
import UserActions from '../../actions/UserActions';
import { FORGOT_PASSWORD } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';

const MemberResetPasswordForm = createForm(FORGOT_PASSWORD, {
  getInitialState() {
    return {
      passwordResetSent: false,
    };
  },

  submit() {
    UserActions.forgotPassword({
      email: this.props.email,
    }, FORGOT_PASSWORD);
  },

  storesSuccess(formId) {
    if (formId === FORGOT_PASSWORD) {
      this.setState({ passwordResetSent: true });
    }
  },

  render() {
    return (
      <Form
        classNameModifier="cub-Form--memberResetPassword"
        onSubmit={this.submit}
        error={this.state.errors.topError()}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <Button
          type="submit"
          isProcessing={this.state.isProcessing}
          text="Reset Password"
        />
        {this.state.passwordResetSent && (
          <div className="cub-Msg cub-Msg--success">
            Password reset link sent.
          </div>
        )}
      </Form>
    );
  },
});

export default MemberResetPasswordForm;
