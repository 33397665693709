import PropTypes from 'prop-types';
import React from 'react';
import DataStore from '../../stores/DataStore';
import BankCardForm from './BankCardForm';
import Button from '../basic/Button';
import ConnectBankAccountForm from './ConnectBankAccountForm';
import Error from '../basic/Error';
import FormErrors from '../FormErrors';
import LabeledRadioGroup from '../fields/LabeledRadioGroup';
import NavLink from '../basic/NavLink';
import OrderActions from '../../actions/OrderActions';
import { parseQueryString, moneyFormat } from '../../utils/Utils';
import Tabs from '../basic/Tabs';
import Text from '../basic/Text';
import Smartdate from '../basic/Smartdate';
import VerifyCouponForm from './VerifyCouponForm';

export default class OrderPaymentForm extends React.Component {
  constructor(props) {
    super(props);
    const customer = this.getCustomerFromPropsSafe(props);
    const defaultBankAccount = customer.defaultBankAccount();
    const defaultCard = customer.defaultCard();
    const paymentMethods = props.order.get('payment_methods');
    this.state = {
      error: null,
      isProcessing: false,
      ach: (defaultBankAccount && defaultBankAccount.id) || 'new',
      card: (defaultCard && defaultCard.id) || 'new',
      // possible values: 'card', 'ach'
      paymentMethod: (paymentMethods.length && paymentMethods[0]) || 'card',
      couponCode: '',
    };
    this.onPaymentMethodChange = this.onPaymentMethodChange.bind(this);
    this.onSourceSelect = this.onSourceSelect.bind(this);
    this.onPayWithSelectedSource = this.onPayWithSelectedSource.bind(this);
    this.onCardCapture = this.onCardCapture.bind(this);
    this.onCouponCodeChange = this.onCouponCodeChange.bind(this);
    this.onCouponVerified = this.onCouponVerified.bind(this);
    this.onError = this.onError.bind(this);
    this.onStripeError = this.onStripeError.bind(this);
    this.onNewCardSubmit = this.onNewCardSubmit.bind(this);
    this.onBankAccountLinked = this.onBankAccountLinked.bind(this);
  }

  onPaymentMethodChange(paymentMethod) {
    this.setState({ paymentMethod, error: null });
  }

  onSourceSelect(e) {
    const newState = { error: null };
    newState[this.state.paymentMethod] = e.target.value;
    this.setState(newState);
  }

  onPayWithSelectedSource() {
    this.setState({ isProcessing: true, error: null });
    OrderActions.payOrder(
      this.props.order.id,
      this.selectedSource(),
      this.onError,
      this.props.invoiceToken,
      this.state.couponCode,
    );
  }

  onCardCapture(cardToken) {
    OrderActions.payOrder(
      this.props.order.id,
      cardToken,
      this.onError,
      this.props.invoiceToken,
      this.state.couponCode,
    );
  }

  onCouponCodeChange(e) {
    this.setState({ couponCode: e.target.value });
  }

  onError(response) {
    const errors = new FormErrors(response);
    this.setState({
      error: errors.firstError(),
      isProcessing: false,
    });
  }

  onStripeError(message) {
    this.setState({ isProcessing: false, error: message });
  }

  onNewCardSubmit() {
    if (this.state.isProcessing) {
      return false; // disable form submit handler
    }
    this.setState({ isProcessing: true, error: null });
    return true;
  }

  onBankAccountLinked(response) {
    this.setState({ isProcessing: false, error: null, ach: response.id });
  }

  onCouponVerified(coupon) {
    this.setState({ coupon, error: null });
  }

  getCustomerFromPropsSafe(props) {
    const customerRelation = props.invoiceToken ? 'payer_customer' : 'customer';
    let result = props.order.get(customerRelation);
    if (typeof result === 'string') {
      result = DataStore.customer.get(result);
    }
    return result;
  }

  selectedSource() {
    return this.state[this.state.paymentMethod];
  }

  textInfo(label, text) {
    return (
      <div className="cub-Row small-text-box">
        <div className="cub-Row-column cub-Row-column--20">
          <label className="cub-Label">{label}:</label>
        </div>
        <div className="cub-Row-column cub-Row-column--80">
          <Text classNameModifier="public-DraftStyleDefault-block">{text}</Text>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line consistent-return
  renderMerchantInfo() {
    const merchant = this.props.site.get('stripe_merchant');
    if (merchant && merchant.address && merchant.address.length) {
      return (
        <div className="cub-Msg">
          <div className="cub-Row">
            <h2 className="h-underlined mt-0 cub-Row-column--100">
              Merchant Information
            </h2>
          </div>
          {this.textInfo('Name', merchant.name)}
          {this.textInfo('Address', merchant.address)}
        </div>
      );
    }
  }

  renderPriceBlock() {
    const order = this.props.order;
    const coupon = this.state.coupon;
    const subtotal = order.total();
    if (coupon) {
      const total = subtotal - coupon.discount(subtotal);
      return (
        <div>
          <Text>
            <label className="cub-Label cub-Markdown-styleButton">
              Subtotal:
            </label>
            {moneyFormat(subtotal)}
          </Text>
          <Text>
            <label className="cub-Label cub-Markdown-styleButton">
              Discount <span role="img" aria-label="Tag">🏷️</span>
              {this.state.couponCode}:
            </label>
            {coupon.discountStr(-subtotal)}
          </Text>
          {this.renderTotalBlock(total)}
        </div>
      );
    }
    return this.renderTotalBlock(subtotal);
  }

  renderTotalBlock(total) {
    return (
      <Text>
        <label className="cub-Label cub-Markdown-styleButton">Total:</label>
        <strong>{moneyFormat(total)}</strong>
      </Text>
    );
  }

  render() {
    const order = this.props.order;
    const customer = this.getCustomerFromPropsSafe(this.props);
    const status = order.get('status');
    let availablePaymentMethods = order.get('payment_methods');
    if (!availablePaymentMethods.length) {
      availablePaymentMethods = ['card', 'ach'];
    }

    const charge = order.get('charges').length &&
      order.get('charges')[0];
    if (charge) {
      const next = parseQueryString().next;
      return (
        <div>
          <Text>Thank you, the order has been paid successfully.</Text>
          {charge.bank_account && (
            <Text>
              Please allow 5 to 7 days for ACH payments to be fully processed.
            </Text>
          )}
          {next && (
            <NavLink
              className="cub-Button cub-Button--continue"
              href={next}
            >
              <span className="cub-Button-icon" />
              <span className="cub-Button-text">Next</span>
            </NavLink>
          )}
        </div>
      );
    }
    if (status !== 'created') {
      return <div><Text>The order is {status}.</Text></div>;
    }
    if (order.total() === 0) {
      return <div><Text>The order is empty.</Text></div>;
    }

    const tabsData = [];
    availablePaymentMethods.forEach((method) => {
      if (method === 'ach' && !this.props.site.get('plaid_pk')) {
        return;
      }

      const title = (method === 'card' ? 'Credit Card' : 'ACH');
      tabsData.push({
        title,
        id: method,
        name: method,
      });
    });
    // Main form blocks:
    let paymentSources = null;
    let addPaymentSource = null;
    let payBtnEnabled = true;

    switch (this.state.paymentMethod) {
      case 'card':
        if (customer.related.card.length) {
          paymentSources = {};
          customer.related.card.forEach((card) => {
            paymentSources[card.id] = card.displayStr();
          });
          paymentSources.new = 'Enter a new card';
        }
        if (this.state.card === 'new') {
          // We're replacing payment button with the button inside the form
          payBtnEnabled = false;
          addPaymentSource = (
            <BankCardForm
              onCardCapture={this.onCardCapture}
              onError={this.onStripeError}
              onSubmit={this.onNewCardSubmit}
              site={this.props.site}
            >
              {this.renderPriceBlock()}
              <div className="cub-FormGroup cub-FormGroup--buttons">
                <Button
                  type="submit"
                  isProcessing={this.state.isProcessing}
                  text="Pay"
                />
              </div>
            </BankCardForm>
          );
        }
        break;

      case 'ach':
        if (customer.related.bankaccount.length) {
          paymentSources = {};
          customer.related.bankaccount.forEach((bankAccount) => {
            paymentSources[bankAccount.id] = bankAccount.displayStr();
          });
          paymentSources.new = 'Connect a new bank account';
        }
        if (this.state.ach === 'new') {
          payBtnEnabled = false;
          addPaymentSource = (
            <ConnectBankAccountForm
              customer={customer}
              onError={this.onError}
              onSuccess={this.onBankAccountLinked}
              site={this.props.site}
            />
          );
        }
        break;

      default:
    }

    let clientCutoff = null;
    if (order.get('server_now')) {
      const clientOffset = Date.parse(order.get('server_now')) - Date.now();
      clientCutoff = order.get('cutoff') && (
        Date.parse(order.get('cutoff')) + clientOffset);
    }
    return (
      <div>
        <Tabs
          classNameModifier="Tabs--paymentMethod"
          options={tabsData}
          active={this.state.paymentMethod}
          onTabClick={this.onPaymentMethodChange}
        />
        {clientCutoff !== null && (clientCutoff - Date.now()) > 0 && (
        <Text classNameModifier="cub-Msg cub-Msg--info">
          Complete payment&nbsp;
          <Smartdate
            date={new Date(clientCutoff).toISOString()}
            mode="future"
          />
          &nbsp;to avoid cancellation of your current order.
        </Text>
        )}
        <Error classNameModifier="cub-Form-error--top">
          {this.state.error}
        </Error>
        <VerifyCouponForm
          order={order}
          onChange={this.onCouponCodeChange}
          onSuccess={this.onCouponVerified}
          onError={this.onError}
        />
        {paymentSources && (
          <LabeledRadioGroup
            options={paymentSources}
            disabled={this.state.isProcessing}
            onChange={this.onSourceSelect}
            value={this.selectedSource()}
          />
        )}
        {addPaymentSource}
        {payBtnEnabled && (
          <div className="cub-PaymentSummary">
            {this.renderPriceBlock()}
            <div className="cub-FormGroup cub-FormGroup--buttons">
              <Button
                isProcessing={this.state.isProcessing}
                disableWhenProcessing
                onClick={this.onPayWithSelectedSource}
                text="Pay"
              />
            </div>
          </div>
        )}
        {this.renderMerchantInfo()}
      </div>
    );
  }
}

OrderPaymentForm.propTypes = {
  order: PropTypes.object,
  site: PropTypes.object,
  invoiceToken: PropTypes.string,
};
