import PropTypes from 'prop-types';
import React from 'react';
import NavLink from './basic/NavLink';

export default class MenuItem extends React.Component {
  render() {
    const className = this.props.subMenuItem ?
      'cub-Menu-submenuItem' :
      'cub-Menu-item';
    return <li className={className}><NavLink {...this.props} /></li>;
  }
}

MenuItem.propTypes = {
  subMenuItem: PropTypes.bool,
};

MenuItem.defaultProps = {
  subMenuItem: true,
};
