import React from 'react';
import PropTypes from 'prop-types';
import createForm from '../../mixins/createForm';
import MemberActions from '../../actions/MemberActions';
import { MEMBER_EDIT } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';
import FirstName from '../fields/FirstName';
import LastName from '../fields/LastName';
import MiddleName from '../fields/MiddleName';
import BirthDate from '../fields/BirthDate';
import { Site } from '../../stores/DataModels';
import DataStore from '../../stores/DataStore';
import Phone from '../fields/Phone';

const MemberEditForm = createForm(MEMBER_EDIT, {
  propTypes: {
    member: PropTypes.object,
    routes: PropTypes.object,
    cancelClick: PropTypes.func,
  },

  getInitialState() {
    const user = this.props.member.get('user');
    return {
      first_name: user.get('first_name') || '',
      middle_name: user.get('middle_name') || '',
      last_name: user.get('last_name') || '',
      phone: user.get('phone') || '',
      birth_date: user.get('birth_date') || '',
    };
  },

  submit() {
    MemberActions.updateProfile(this.props.member, {
      first_name: this.state.first_name,
      middle_name: this.state.middle_name,
      last_name: this.state.last_name,
      phone: this.state.phone,
      birth_date: this.state.birth_date,
    }, MEMBER_EDIT);
  },

  render() {
    const site = DataStore.currentSite();
    const showPhoneField = (
      site.get('user_phone_field') !== Site.USER_PHONE_DISABLED
    );
    return (
      <Form
        classNameModifier="cub-Form--memberEdit"
        error={this.state.errors.topError()}
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <FirstName
          value={this.state.first_name}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('first_name')}
        />

        <MiddleName
          value={this.state.middle_name}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('middle_name')}
        />

        <LastName
          value={this.state.last_name}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('last_name')}
        />
        {showPhoneField && (
          <Phone
            value={this.state.phone}
            error={this.state.errors.fieldError('phone')}
            onChange={this.onInputChange}
          />
        )}

        <BirthDate
          value={this.state.birth_date}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('birth_date')}
        />

        <div className="cub-FormGroup cub-FormGroup--buttons">
          <Button
            type="submit"
            isProcessing={this.state.isProcessing}
            text="Save"
          />

          <Button
            onClick={this.props.cancelClick}
            text="Cancel"
            classNameModifier="cub-Button--cancel"
          />
        </div>
      </Form>
    );
  },
});

export default MemberEditForm;
