import _ from 'underscore';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import DataStore from '../../stores/DataStore';
import FormErrors from '../FormErrors';
import Loading from '../basic/Loading';
import OrderActions from '../../actions/OrderActions';
import OrderPaymentForm from '../forms/OrderPaymentForm';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import Text from '../basic/Text';
import { siteSentry } from '../../utils/Utils';

/* eslint-disable react/prefer-es6-class */
const Orders = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    args: PropTypes.array,
    route: PropTypes.object,
    routes: PropTypes.object,
  },

  mixins: [storeListenerMixin(DataStore)],

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.args, nextProps.args)) {
      // Handle Order ID/ invoice_token change in URL
      this.setState(this.getStateFromStores(nextProps));
    }
  },

  onLoadError(response) {
    const errors = new FormErrors(response);
    this.setState({ loadError: errors.firstError() });
  },

  getStateFromStores(props) {
    const orderId = this.currentOrderId(props);
    const loadingStarted = this.state && this.state.orderLoadingStarted;
    const loadError = this.state && this.state.loadError;
    const invoiceToken = this.invoiceTokenFromArgs(props);
    const orderHash = `${orderId}${invoiceToken || ''}`;
    const orderLoaded = (orderHash === loadingStarted) && !loadError;
    const state = {
      site: DataStore.currentSite(),
      user: DataStore.currentUser(),
      currentOrder: (orderLoaded && DataStore.order.get(orderId)) || null,
      orderLoaded: false,
      invoiceToken,
    };
    state.loading = !state.site || !state.user || !state.currentOrder;
    if (state.currentOrder) {
      state.loadError = null;
    }
    if (orderId) {
      state.loading = state.loading || !state.currentOrder;
      if (loadingStarted !== orderHash) {
        window.setTimeout(() => {
          OrderActions.getOrder(orderId, this.onLoadError, state.invoiceToken);
        });
        state.orderLoadingStarted = orderHash;
        state.loadError = null;
      }
    }
    return state;
  },

  currentOrderId(props) {
    props = props || this.props;
    if (!props.routes || !props.route) {
      return null;
    }
    const orderRoutes = [
      props.routes.ORDER_PAYMENT,
      props.routes.ORDER_INVOICE_PAYMENT,
    ];
    if (orderRoutes.indexOf(props.route) !== -1) {
      return props.args[0];
    }
    return null;
  },

  invoiceTokenFromArgs(props) {
    props = props || this.props;
    if (props.route === props.routes.ORDER_INVOICE_PAYMENT) {
      if (props.args && props.args.length > 1) {
        return props.args[1];
      }
      siteSentry.captureMessage('Invoice payment route without args', {
        level: 'error',
        extra: { props },
      });
    }
    return null;
  },

  render() {
    if (this.state.loadError) {
      return <div><Text>{this.state.loadError}</Text></div>;
    }
    if (this.state.loading) return <Loading />;

    const routes = this.props.routes;
    const args = {
      site: this.state.site,
      user: this.state.user,
      invoiceToken: this.state.invoiceToken,
      routes,
    };
    let view;
    switch (this.props.route) {
      case routes.ORDER_PAYMENT:
      case routes.ORDER_INVOICE_PAYMENT:
        view = <OrderPaymentForm order={this.state.currentOrder} {...args} />;
        break;

      default:
        view = null;
    }

    return <div className="cub-Orders">{view}</div>;
  },
});

export default Orders;
