import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import LabeledInput from './LabeledInput';
import Error from '../basic/Error';

export default class LabeledInputGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
  }

  onChange(e) {
    if (typeof this.props.onChange === 'function') {
      const item = e.target;

      if (item.value === '') {
        delete this.state.value[item.name];
      } else {
        this.state.value[item.name] = item.value;
      }

      this.props.onChange({
        target: {
          name: this.props.name,
          type: 'inputgroup',
          value: this.state.value,
        },
      });
    }
  }

  validate() {
    if (this.props.required && !Object.values(this.state.value).join('')) {
      return 'This field is required.';
    }
    return null;
  }

  render() {
    return (
      <div className="cub-FormGroup cub-FormGroup--inputGroup">
        <legend className="cub-Label cub-Label--inputGroup">
          {this.props.label}
          {this.props.required && <span className="cub-Label-asterisk">*</span>}
        </legend>
        <Error dataField={this.props.name}>
          {this.props.error}
        </Error>
        {_.map(this.props.options, (label, key) => (
          <LabeledInput
            key={key}
            ref={key}
            label={label}
            classNameLabelModifier="cub-Label--inputGroupItem"
            name={`${this.props.name}[${key}]`}
            onChange={this.onChange}
            value={this.props.value[key]}
          />
        ))}
      </div>
    );
  }
}

LabeledInputGroup.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  required: PropTypes.bool,
  value: PropTypes.object,
};
