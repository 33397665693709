import _ from 'underscore';
import { Dispatcher } from 'flux';
import { siteSentry, logger } from '../utils/Utils';

const AppDispatcher = _.extend(new Dispatcher(), {
  handleNavAction(action) {
    logger.debug({
      message: action.actionType || '',
      category: 'cub.action',
      data: { action },
    });
    siteSentry.addBreadcrumb({
      message: action.actionType || '',
      category: 'cub.action',
    });
    this.dispatch({
      source: 'NAV_ACTION',
      action,
    });
  },

  handleFormAction(action) {
    logger.debug({
      message: action.actionType || '',
      category: 'cub.action',
      data: { action },
    });
    siteSentry.addBreadcrumb({
      message: action.actionType || '',
      category: 'cub.action',
    });
    this.dispatch({
      source: 'FORM_ACTION',
      action,
    });
  },

  handleViewAction(action) {
    logger.debug({
      message: action.actionType || '',
      category: 'cub.action',
      data: { action },
    });
    siteSentry.addBreadcrumb({
      message: action.actionType || '',
      category: 'cub.action',
    });
    this.dispatch({
      source: 'VIEW_ACTION',
      action,
    });
  },

  handleServerAction(action) {
    logger.debug({
      message: action.actionType || '',
      category: 'cub.action',
      data: { action },
    });
    siteSentry.addBreadcrumb({
      message: action.actionType || '',
      category: 'cub.action',
    });
    this.dispatch({
      source: 'SERVER_ACTION',
      action,
    });
  },

  handleAppAction(action) {
    logger.debug({
      message: action.actionType || '',
      category: 'cub.action',
      data: { action },
    });
    siteSentry.addBreadcrumb({
      message: action.actionType || '',
      category: 'cub.action',
    });
    this.dispatch({
      source: 'APP_ACTION',
      action,
    });
  },
});

export default AppDispatcher;
