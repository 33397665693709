/*
 * Cookies.js
 * https://github.com/ScottHamper/Cookies
 *
 * This is free and unencumbered software released into the public domain.
 */

import _ from 'underscore';
import { hasOwnProperty } from './Utils';

const _maxExpireDate = new Date('Fri, 31 Dec 9999 23:59:59 UTC');

export const defaults = {
  path: '/',
  secure: false,
};

let _cache;
let _cachedDocumentCookie;

export function parse(documentCookie) {
  const cookieObj = {};
  const cookiesArray = documentCookie ? documentCookie.split('; ') : [];

  for (let i = 0, len = cookiesArray.length; i < len; i++) {
    const cookieKvp = _getKeyValuePairFromCookieString(cookiesArray[i]);

    if (!hasOwnProperty(cookieObj, cookieKvp.key)) {
      cookieObj[cookieKvp.key] = cookieKvp.value;
    }
  }

  return cookieObj;
}

export function get(key) {
  if (_cachedDocumentCookie !== window.document.cookie) {
    _renewCache();
  }
  if (!hasOwnProperty(_cache, key)) {
    return undefined;
  }
  const value = _cache[key];
  return value === undefined ? undefined : decodeURIComponent(value);
}

export function stringify(key, value, options) {
  let opts;
  if (options !== null) {
    opts = _getExtendedOptions(options);
    opts.expires = _getExpiresDate(value === undefined ? -1 : opts.expires);
  }
  return _generateCookieString(key, value, opts);
}

export function set(key, value, options) {
  window.document.cookie = stringify(key, value, options);
}

export function expire(key, options) {
  return set(key, undefined, options);
}

function _getKeyValuePairFromCookieString(cookieString) {
  // "=" is a valid character in a cookie value according to RFC6265, so cannot
  // `split('=')`
  let separatorIndex = cookieString.indexOf('=');

  // IE omits the "=" when the cookie value is an empty string
  separatorIndex = separatorIndex < 0 ? cookieString.length : separatorIndex;

  const key = cookieString.substr(0, separatorIndex);
  let decodedKey;
  try {
    decodedKey = decodeURIComponent(key);
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return {
    key: decodedKey,
    // Defer decoding value until accessed
    value: cookieString.substr(separatorIndex + 1),
  };
}

function _renewCache() {
  _cache = parse(window.document.cookie);
  _cachedDocumentCookie = window.document.cookie;
}

function _getExtendedOptions(options) {
  return {
    path: (options && options.path) || defaults.path,
    domain: (options && options.domain) || defaults.domain,
    expires: (options && options.expires) || defaults.expires,
    samesite: (options && options.samesite) || defaults.samesite,
    secure: options && (
      options.secure !== undefined ? options.secure : defaults.secure
    ),
  };
}

function _isValidDate(date) {
  return (
    Object.prototype.toString.call(date) === '[object Date]' &&
    !_.isNaN(date.getTime())
  );
}

function _getExpiresDate(expires) {
  if (expires === Infinity) {
    expires = _maxExpireDate;
  } else if (typeof expires === 'number') {
    const now = new Date();
    expires = new Date(now.getTime() + (expires * 1000));
  } else if (typeof expires === 'string') {
    expires = new Date(expires);
  }

  if (expires && !_isValidDate(expires)) {
    throw new Error(
      '`expires` parameter cannot be converted to a valid Date instance',
    );
  }

  return expires;
}

function _generateCookieString(key, value, opts = {}) {
  let k = key.replace(/[^#$&+^`|]/g, encodeURIComponent);
  k = k.replace(/\(/g, '%28').replace(/\)/g, '%29');
  const v = `${value}`.replace(/[^!#$&-+\--:<-[\]-~]/g, encodeURIComponent);

  let cookieString = `${k}=${v}`;
  cookieString += opts.path ? `;path=${opts.path}` : '';
  cookieString += opts.domain ? `;domain=${opts.domain}` : '';
  cookieString += opts.expires ? `;expires=${opts.expires.toUTCString()}` : '';
  cookieString += opts.samesite ? `;samesite=${opts.samesite}` : '';
  cookieString += opts.secure ? ';secure' : '';

  return cookieString;
}
