import PropTypes from 'prop-types';
import React from 'react';
import Error from '../basic/Error';
import Button from '../basic/Button';

export default class BankCard extends React.Component {
  render() {
    const card = this.props.card;
    if (!card) {
      return null;
    }
    const month = card.get('exp_month');
    const year = card.get('exp_year');
    const cardExpireDate = new Date(year, month, 0); // last day of month
    const now = new Date();
    let warning = null;
    if (cardExpireDate < now) {
      warning = 'Card has expired';
    } else if ((cardExpireDate - now) / 1000 / 60 / 60 / 24 < 60) {
      // Less than 60 days remain until expiration
      warning = 'Card will expire soon';
    }
    return (
      <div className="cub-BankCard">
        {warning && (
          <Error classNameModifier="cub-Form-error--bankCardWarning">
            {warning}
          </Error>
        )}
        <p className="cub-BankCard-info">
          <span className="cub-BankCard-brand">{card.get('brand')}</span>
          <span className="cub-BankCard-last4numbers">
            {' '}**** {card.get('last4')}
          </span>
          <br />
          <span className="cub-BankCard-expiration">
            Expires: {month < 10 ? `0${month}` : month} / {year}
          </span>
        </p>
        <Button
          classNameModifier="cub-Button--edit cub-Button--changeCard"
          onClick={this.props.onChange}
          text={warning ? 'Replace' : 'Change'}
        />
      </div>
    );
  }
}

BankCard.propTypes = {
  card: PropTypes.object,
  onChange: PropTypes.func,
};
