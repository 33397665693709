import React from 'react';

export default class Loading extends React.Component {
  render() {
    return (
      <p className="cub-Loading">
        <span
          className={'cub-Icon cub-Icon--refresh ' +
            'cub-Icon--rightSpacing cub-Util-animateInfinite'}
        />
        <span className="cub-Loading-text">
          Loading...
        </span>
      </p>
    );
  }
}
