import React from 'react';
import Text from '../basic/Text';

export default class IdpConfirmationRequired extends React.Component {
  render() {
    return (
      <Text classNameModifier="cub-Msg cub-Msg--info">
        To finish logging in, you need to confirm this account belongs to you.
        Please check your email and click the confirmation link.
      </Text>
    );
  }
}
