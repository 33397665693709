import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import ApiActions from '../../actions/ApiActions';
import ChangeEmailForm from '../forms/ChangeEmailForm';
import ChangePasswordForm from '../forms/ChangePasswordForm';
import ChangeUsernameForm from '../forms/ChangeUsernameForm';
import ExperienceForm from '../forms/ExperienceForm';
import DataStore from '../../stores/DataStore';
import EmailChangeDone from './EmailChangeDone';
import Loading from '../basic/Loading';
import MemberBenefitsForm from '../forms/MemberBenefitsForm';
import NewslettersForm from '../forms/NewslettersForm';
import PasswordChangeDone from './PasswordChangeDone';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import UsernameChangeDone from './UsernameChangeDone';
import UserBilling from './UserBilling';
import UserProfileForm from '../forms/UserProfileForm';
import VerificationRequests from './VerificationRequests';
import SetEmailForm from '../forms/SetEmailForm';

/* eslint-disable react/prefer-es6-class */
const Profile = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    args: PropTypes.array,
    route: PropTypes.object,
    routes: PropTypes.object,
  },

  /* eslint-disable react/no-unused-class-component-methods,react/sort-comp */
  mixins: [storeListenerMixin(DataStore)],

  getStateFromStores() {
    const vrLoaded = this.state.verificationRequestsLoadStarted &&
        !DataStore.nowLoading('verificationrequests');
    return {
      site: DataStore.currentSite(),
      user: DataStore.currentUser(),
      verificationRequestsLoaded: vrLoaded,
    };
  },
  /* eslint-enable react/no-unused-class-component-methods,react/sort-comp */

  getInitialState() {
    return {
      verificationRequestsLoadStarted: false,
    };
  },

  componentWillMount() {
    // Using setTimeout to avoid "Can't dispatch in the middle of dispatch .."
    window.setTimeout(() => {
      ApiActions.getAll('verificationrequests');
      this.setState({ verificationRequestsLoadStarted: true });
    });
  },

  render() {
    const routes = this.props.routes;
    const site = this.state.site;
    const user = this.state.user;
    if (!site || !user || !this.state.verificationRequestsLoaded) {
      return <Loading />;
    }

    const args = { routes, site, user };
    let view;
    switch (this.props.route) {
      case routes.USER_PROFILE:
        view = <UserProfileForm {...args} />;
        break;

      case routes.EXPERIENCE:
        view = <ExperienceForm {...args} />;
        break;

      case routes.CHANGE_EMAIL:
        view = <ChangeEmailForm {...args} />;
        break;

      case routes.EMAIL_CHANGE_DONE:
        view = <EmailChangeDone {...args} />;
        break;

      case routes.CHANGE_PASSWORD:
        view = <ChangePasswordForm {...args} />;
        break;

      case routes.PASSWORD_CHANGE_DONE:
        view = <PasswordChangeDone {...args} />;
        break;

      case routes.CHANGE_USERNAME:
        view = <ChangeUsernameForm {...args} />;
        break;

      case routes.USERNAME_CHANGE_DONE:
        view = <UsernameChangeDone {...args} />;
        break;

      case routes.NEWSLETTERS:
        view = <NewslettersForm {...args} />;
        break;

      case routes.MEMBER_BENEFITS:
        view = <MemberBenefitsForm {...args} />;
        break;
      case routes.PREMIUM_BENEFITS:
        view = <MemberBenefitsForm {...args} next={routes.NEWSLETTERS} />;
        break;
      case routes.VERIFICATION_REQUESTS:
        view = <VerificationRequests {...args} />;
        break;

      case routes.REGISTRATION_VERIFICATION:
        view = (
          <VerificationRequests
            hideSkipButton={site.get('hide_skip_button_for_verification_step')}
            next={routes.PREMIUM_BENEFITS}
            {...args}
          />
        );
        break;

      case routes.USER_BILLING:
        view = <UserBilling {...args} />;
        break;

      case routes.USER_BILLING_PLAN:
        view = (
          <UserBilling defaultPlanId={this.props.args[0]} {...args} />
        );
        break;

      case routes.SET_EMAIL:
        view = <SetEmailForm {...args} />;
        break;

      case routes.CONFIRM_MEMBERSHIP:
        view = <Loading />;
        break;

      default:
        view = null;
    }

    return <div className="cub-Profile">{view}</div>;
  },
});

export default Profile;
