import PropTypes from 'prop-types';
import React from 'react';
import StyleButton from './StyleButton';

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'BQ', style: 'blockquote', icon: 'quoteLeft' },
  { label: 'UL', style: 'unordered-list-item', icon: 'listBullet' },
  { label: 'OL', style: 'ordered-list-item', icon: 'listNumbered' },
  // { label: 'Code', style: 'code-block' },
];

export const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="cub-Markdown-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
    </div>
  );
};

BlockStyleControls.propTypes = {
  editorState: PropTypes.any,
  onToggle: PropTypes.func,
};

const INLINE_STYLES = [
  { label: 'B', style: 'BOLD', icon: 'bold' },
  { label: 'I', style: 'ITALIC', icon: 'italic' },
  { label: 'U', style: 'UNDERLINE', icon: 'underline' },
  { label: 'PRE', style: 'CODE', icon: 'code' },
];

export const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="cub-Markdown-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
    </div>
  );
};

InlineStyleControls.propTypes = {
  editorState: PropTypes.any,
  onToggle: PropTypes.func,
};
