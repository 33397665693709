import PropTypes from 'prop-types';
import React from 'react';
import smartdate from 'smartdate';
import { dateFromISOString } from '../../utils/Utils';

export default class Smartdate extends React.Component {
  render() {
    const config = smartdate.config;
    let date = this.props.date;
    if (typeof date === 'string') {
      date = dateFromISOString(date);
    }
    const options = {};
    const props = {
      className: config.className,
      title: date.toLocaleString(),
    };
    props['data-timestamp'] = parseInt(date.getTime() / 1000, 10);
    if (this.props.mode) {
      props['data-mode'] = this.props.mode;
      options.mode = this.props.mode;
    }
    return React.createElement(
      config.tagName,
      props,
      smartdate.format(date, options),
    );
  }
}

Smartdate.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  mode: PropTypes.string,
};
