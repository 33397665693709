import AppDispatcher from '../dispatcher/AppDispatcher';
import CubApi from '../api/CubApi';
import UserStore from '../stores/UserStore';
import {
  CLEAR_FORM_ERROR,
  FORM_CANCELLED,
  FORM_ERROR,
  FORM_SUBMITTED,
  FORM_SUCCESS,
  FORM_RESET,
  LEAD_FORM_UPDATED,
} from '../constants/ActionTypes';

const FormActions = {
  submitAnonForm(formId, nextUrl, url, data, success, error) {
    FormActions.formSubmitted(formId);
    CubApi.post(
      url,
      { data, _formId: formId, _nextUrl: nextUrl },
      success || FormActions.dispatchFormSuccess,
      error || FormActions.dispatchFormError,
    );
  },

  submitUserForm(formId, url, data, success, error) {
    FormActions.formSubmitted(formId);
    CubApi.post(url,
      { apiKey: UserStore.token(true), data, _formId: formId },
      success || FormActions.dispatchFormSuccess,
      error || FormActions.dispatchFormError);
  },

  formSubmitted(formId) {
    if (formId) {
      AppDispatcher.handleFormAction({
        actionType: FORM_SUBMITTED,
        form: formId,
      });
    }
  },

  formCancelled(formId, props) {
    AppDispatcher.handleFormAction({
      actionType: FORM_CANCELLED,
      form: formId,
      props,
    });
  },

  dispatchFormSuccess(response, meta, method, url, options) {
    const formId = options._formId;
    if (formId) {
      AppDispatcher.handleFormAction({
        actionType: FORM_SUCCESS,
        form: formId,
        response,
        options,
      });
    }
  },
  resetForm(formId) {
    AppDispatcher.handleFormAction({
      actionType: FORM_RESET,
      form: formId,
    });
  },

  dispatchFormError(response, meta, method, url, options) {
    const formId = options._formId;
    if (formId) {
      AppDispatcher.handleFormAction({
        actionType: FORM_ERROR,
        form: formId,
        response,
        options,
      });
    }
  },

  clearFormError(formId, fieldName) {
    AppDispatcher.handleFormAction({
      actionType: CLEAR_FORM_ERROR,
      form: formId,
      fieldName,
    });
  },

  loadLeadForm(leadFormId, data, success, error) {
    CubApi.get(
      `leadforms/${leadFormId}`,
      { data },
      FormActions.leadFormLoaded.bind(FormActions, success),
      error,
    );
  },

  leadFormLoaded(success, response) {
    AppDispatcher.handleServerAction({
      actionType: LEAD_FORM_UPDATED,
      response,
    });
    if (typeof success === 'function') {
      success(response);
    }
  },
};

export default FormActions;
