import React from 'react';
import PropTypes from 'prop-types';
import OrderActions from '../../actions/OrderActions';
import createForm from '../../mixins/createForm';

import Button from '../basic/Button';
import Error from '../basic/Error';
import Loading from '../basic/Loading';
import Text from '../basic/Text';
import { RESEND_TOKEN_FORM_ID } from '../../constants/FormIds';

const OrderInvoiceLoginView = createForm(RESEND_TOKEN_FORM_ID, {
  getInitialState() {
    return {
      resend: false,
    };
  },

  storesSuccess(formId) {
    if (formId === RESEND_TOKEN_FORM_ID) {
      this.setState({ resend: true });
    }
  },

  render() {
    if (this.props.autoLoginStatus === false) {
      const errs = this.state.errors;
      if (errs && errs._errors) {
        if (errs._errors.status) {
          return (
            <>
              <Error>{errs._errors.status}</Error>
              <Text>
                If you have any questions, please contact
                our {this.props.supportTeamLink} for additional help.
              </Text>
            </>
          );
        }
      }
      if (this.state.resend) {
        return (
          <Text>
            A new payment link was successfully sent to your email address,
            please check your email inbox to complete the payment.
          </Text>
        );
      }
      return (
        <>
          <Text>
            We{'\''}re sorry, but the link to this invoice has expired. You can
            request a new payment link.
          </Text>
          <Button
            text="Send me a new payment link"
            onClick={this.reSendInvoiceLink}
            disabled={this.state.isProcessing}
          />
        </>
      );
    }
    return (<Loading />);
  },

  reSendInvoiceLink() {
    OrderActions.reSendInvoiceLink(this.props.orderId);
  },
});

OrderInvoiceLoginView.propTypes = {
  autoLoginStatus: PropTypes.bool,
  loginUrl: PropTypes.string,
  orderId: PropTypes.string,
  supportTeamLink: PropTypes.object,
};

export default OrderInvoiceLoginView;
