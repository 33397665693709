import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import ApiActions from '../../actions/ApiActions';
import { State as StateModel } from '../../stores/DataModels';
import LabeledCombobox from './LabeledCombobox';

export default class State extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      options: [],
    };
    this.searchCache = {};
    this.setOptions = this.setOptions.bind(this);
    this.focus = this.focus.bind(this);
    this.optionsLoaded = this.optionsLoaded.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentWillMount() {
    this.setOptions(this.props.country);
  }

  componentWillReceiveProps(nextProps) {
    this.setOptions(nextProps.country);
  }

  setOptions(country) {
    const newState = { loading: false, options: [] };
    if (country) {
      const cached = this.searchCache[country.id || country];
      if (cached) {
        newState.options = cached;
        if (_.isFunction(this.props.onOptionsLoaded)) {
          this.props.onOptionsLoaded(cached);
        }
      } else {
        // TODO: fix states for Latvia & Slovenia. Both of them have more
        // than 100 states, but this implementation is naive and only loads
        // first 100 states, effectively ignoring the rest of the list.
        const statesFilter = {
          offset: 0,
          count: 100,
          order_by: 'name',
        };
        if (country.id) {
          statesFilter.country = country.id;
        } else {
          statesFilter.country__name = country;
        }
        ApiActions.get('states', statesFilter, this.optionsLoaded);
        newState.loading = true;
      }
    }
    this.setState(newState);
  }

  optionsLoaded(response, meta, method, url, options) {
    const countryId = ((options && options.data) || {}).country;
    this.searchCache[countryId] = response;
    if (typeof this.props.country === 'string') {
      this.searchCache[this.props.country] = response;
    }
    this.setOptions(this.props.country);
  }

  validate() {
    const { required, value, restrictToKnown } = this.props;
    if (required && !value) {
      return 'This field is required.';
    }

    if (restrictToKnown && value && !StateModel.alike(value)) {
      return 'Must be chosen from dropdown list.';
    }
    return null;
  }

  focus() {
    this.refs.combobox.focus();
  }

  render() {
    const filter = (item, val) => {
      // use entered value instead of suggested, if possible
      const filterValue = (this.refs.combobox &&
        this.refs.combobox.refs.combobox.refs.inner &&
        this.refs.combobox.refs.combobox.refs.inner.refs.input._last) ||
        val;
      return item.name.toLowerCase().indexOf(filterValue.toLowerCase()) === 0;
    };
    let value = this.props.value;
    if (StateModel.alike(value)) {
      value = value.id;
    }

    return (
      <LabeledCombobox
        classNameModifier="cub-FormControl--state"
        label={this.props.label}
        ref="combobox"
        required={this.props.required}
        textField="name"
        valueField="id"
        value={value}
        name="state"
        data={this.state.options}
        suggest
        placeholder={this.props.placeholder}
        filter={filter}
        busy={this.state.loading}
        onChange={this.props.onChange}
        error={this.props.error}
      />
    );
  }
}

State.propTypes = {
  country: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  error: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onOptionsLoaded: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  restrictToKnown: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

State.defaultProps = {
  label: 'State/Province',
  placeholder: 'Select state/province',
};
