import _ from 'underscore';
import Backbone from 'backbone';

/**
 * Selection extends standard Backbone.Collection and allows to
 * select any number of models in it. When selection changes, special
 * "selected" event is emitted. Selection maintains self integrity and
 * automatically deselects models which no longer exist in collection.
 */
const Selection = Backbone.Collection.extend({
  ensureArray(obj) {
    // Utility function - check if obj is an array, and wrap it into array if
    // it is not. If object is already an array, return it unchanged.
    if (Object.prototype.toString.call(obj) === '[object Array]') {
      return obj;
    }
    return [obj];
  },

  init() {}, // Override this instead of initialize

  initialize(...args) {
    this.selection = new Backbone.Collection();
    this.on('remove', this.onModelRemove, this);
    this.on('reset', this.cleanup, this);
    this.init(...args);
  },

  onModelRemove(model) {
    this.deselect(model);
  },

  cleanup(options) {
    // Remove models from selection which no longer exist in a collection
    const silent = options && options.silent;
    let updated = false;
    // Walk through selection in reverse order
    // because we're deleting items from it
    let l = this.selection.models.length;
    while (l) {
      const model = this.selection.models[l];
      if (!this.contains(model)) {
        this.selection.remove(model);
        updated = true;
        l += 1;
      }
    }
    if (updated && !silent) {
      this.trigger('select');
    }
  },

  select(models, options, context) {
    // 'models' can be a function, a single model or array of models
    const mdls = _.isFunction(models) ?
      this.filter(models, context) :
      this.ensureArray(models);
    const silent = options && options.silent;
    let updated = false;
    mdls.forEach((item) => {
      const model = this.get(item);
      if (model && !this.selection.contains(model)) {
        this.selection.add(model);
        updated = true;
      }
    });
    if (updated && !silent) {
      this.trigger('select');
    }
    return this;
  },

  selectAll(options) {
    if (this.allSelected()) {
      return this;
    }
    const updated = this.length === 0 || this.selection.length < this.length;
    const silent = options && options.silent;
    this.selection.reset(this.models);
    if (updated && !silent) {
      this.trigger('select');
    }
    return this;
  },

  selectOnly(models, options, context) {
    const mdls = _.isFunction(models) ?
      this.filter(models, context) :
      this.ensureArray(models);
    if (this.isSelected(mdls) && this.selection.length === mdls.length) {
      return this;
    }
    this.deselectAll({ silent: true });
    this.select(mdls, options);
    return this;
  },

  deselect(models, options, context) {
    const mdls = _.isFunction(models) ?
      this.filter(models, context) :
      this.ensureArray(models);
    const silent = options && options.silent;
    let updated = false;
    mdls.forEach((item) => {
      const model = this.selection.get(item);
      if (model) {
        this.selection.remove(model);
        updated = true;
      }
    });
    if (updated && !silent) {
      this.trigger('select');
    }
    return this;
  },

  deselectAll(options) {
    const updated = this.selection.length > 0;
    const silent = options && options.silent;
    this.selection.reset();
    if (updated && !silent) {
      this.trigger('select');
    }
    return this;
  },

  toggleSelection(models, options, context) {
    // Inverse selection for given models or the whole collection
    const silent = options && options.silent;
    let updated = false;
    let mdls;
    if (_.isFunction(models)) {
      mdls = this.filter(models, context);
    } else {
      mdls = _.isUndefined(models) ? this.models : this.ensureArray(models);
    }
    mdls.forEach((item) => {
      const model = this.get(item);
      if (model) {
        if (this.selection.contains(model)) {
          this.selection.remove(model);
        } else {
          this.selection.add(model);
        }
        updated = true;
      }
    });
    if (updated && !silent) {
      this.trigger('select');
    }
    return this;
  },

  selected() {
    // Returns selected models in the same order as they go in the collection
    const selection = this.selection;
    return this.models.filter((model) => selection.contains(model));
  },

  firstSelected() {
    const selected = this.selected();
    return selected ? selected[0] : undefined;
  },

  isSelected(models) {
    // Returns true if all given models are selected. Models is an optional
    // parameter and can be a single model or array of models. When called
    // without parameters, it will return true if anything is selected.
    if (models) {
      return this.ensureArray(models).every((item) => this.selection.get(item));
    }
    return this.selection.length > 0;
  },

  allSelected() {
    return this.length > 0 && this.length === this.selection.length;
  },
});

export default Selection;
