import _ from 'underscore';
import React from 'react';
import BirthDate from '../fields/BirthDate';
import createForm from '../../mixins/createForm';
import FirstName from '../fields/FirstName';
import Gender from '../fields/Gender';
import LastName from '../fields/LastName';
import MiddleName from '../fields/MiddleName';
import NavLink from '../basic/NavLink';
import Notifications from '../views/Notifications';
import Retired from '../fields/Retired';
import UserActions from '../../actions/UserActions';
import UserMembership from '../views/UserMembership';
import { USER_PROFILE } from '../../constants/FormIds';
import Button from '../basic/Button';
import CaptionLarge from '../basic/CaptionLarge';
import Form from '../basic/Form';
import Text from '../basic/Text';
import DataStore from '../../stores/DataStore';
import ConfigStore from '../../stores/ConfigStore';
import ImageControl from '../fields/ImageControl';
import { Site } from '../../stores/DataModels';
import Phone from '../fields/Phone';

const UserProfileForm = createForm(USER_PROFILE, {
  getInitialState() {
    return this.props.user.profileAttrs();
  },

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps.user.profileAttrs());
    const member = DataStore.currentMember();
    if (member && member.get('is_active') && !member.get('is_admin')) {
      this.member = member;
      if (this.state.lock_personal !== false) {
        this.setState({ lock_personal: true });
      }
    } else {
      this.member = null;
      this.setState({ lock_personal: null });
    }
  },

  onImageUploaded(...args) {
    const user = DataStore.currentUser();
    user.set({
      image_refresh_key: new Date().getTime(),
    });
    UserActions.userUpdated.apply(window, args);
  },

  submit() {
    const profileAttrs = _.extend(
      this.props.user.profileAttrs(),
      _.omit(this.state, 'errors', 'isProcessing'),
    );
    UserActions.updateProfile(profileAttrs, USER_PROFILE);
  },

  toggleDisablePersonal() {
    const locked = this.state.lock_personal;
    const text = 'Are you sure you want to change your personal info with the' +
      ' understanding that it may affect your official organization records?';
    if (locked) {
      if (!window.confirm(text)) { // eslint-disable-line no-alert
        return;
      }
    }
    this.setState({ lock_personal: !locked });
  },

  render() {
    const routes = this.props.routes;
    const user = this.props.user;
    const site = DataStore.currentSite();
    const showPhoneField = (
      site.get('user_phone_field') !== Site.USER_PHONE_DISABLED
    );
    const phoneRequired = (
      site.get('user_phone_field') === Site.USER_PHONE_REQUIRED
    );
    let emailLink = null;
    let lockMessage;
    if (this.member) {
      const text = this.state.lock_personal ? 'Unlock' : 'Lock';
      const org = this.member.get('organization').get('name');
      lockMessage = (
        <div>
          <Text classNameModifier="cub-Msg cub-Msg--warning">
            You are an active member of {org}. Your organization may rely on
            your personal information for official purposes like training data.
            <br /><br />
            Please do not change your personal information unless you are
            certain it will not create conflicts with your organization official
            records.<br /><br />
            Note that Administrators of {org} may change the data in these
            locked fields. You may want to discuss any changes with them before
            making updates.
          </Text>
          <Button
            text={text}
            classNameModifier="cub-Button--edit"
            onClick={this.toggleDisablePersonal}
          />
          <br />
        </div>
      );
    }

    if (!this.state.lock_personal) {
      emailLink = (
        <NavLink
          className="cub-Link cub-Link--changeEmail"
          route={routes.CHANGE_EMAIL}
        >
          change...
        </NavLink>
      );
    }

    let siteTag = '';
    if (site) {
      const tags = site.get('tags');
      if (tags.length > 0) {
        siteTag = tags[0];
      }
    }

    return (
      <div className="cub-Profile-content">
        <CaptionLarge>My profile</CaptionLarge>

        <Notifications user={user} />

        <ImageControl
          classNameModifier="cub-ImageControl--user"
          apiEndpoint="user/photo"
          altText="Me"
          expand={UserActions.USER_EXPAND}
          onRemoveDone={UserActions.userUpdated}
          onRemoveStarted={UserActions.removePhotoStarted}
          onUploadDone={this.onImageUploaded}
          src={user.getPhoto('photo_large')}
          srcDefault={ConfigStore.static('img/user_large.png')}
          size="large"
        />

        <Form
          classNameModifier="cub-Form--profile"
          error={this.state.errors.topError()}
          onSubmit={this.submit}
          isProcessing={this.state.isProcessing}
          disableWhenProcessing
        >
          <Text classNameModifier="cub-Text--userName">
            User name:{' '}
            <span className="cub-Text-dataValue">
              {user.get('username')}
            </span>{' '}
            <NavLink
              className="cub-Link cub-Link--changeUsername"
              route={routes.CHANGE_USERNAME}
            >
              change...
            </NavLink>
          </Text>

          {lockMessage}

          <Text classNameModifier="cub-Text--userEmail">
            Email:{' '}
            <span className="cub-Text-dataValue">
              {user.get('email') || 'not set'}
            </span>{' '}
            {emailLink}
          </Text>

          <FirstName
            value={this.state.first_name}
            required
            onChange={this.onInputChange}
            error={this.state.errors.fieldError('first_name')}
            disabled={this.state.lock_personal}
          />

          <MiddleName
            value={this.state.middle_name}
            onChange={this.onInputChange}
            error={this.state.errors.fieldError('middle_name')}
            disabled={this.state.lock_personal}
          />

          <LastName
            value={this.state.last_name}
            required
            onChange={this.onInputChange}
            error={this.state.errors.fieldError('last_name')}
            disabled={this.state.lock_personal}
          />

          {showPhoneField && (
            <Phone
              value={this.state.phone}
              required={phoneRequired}
              error={this.state.errors.fieldError('phone')}
              onChange={this.onInputChange}
              disabled={this.state.lock_personal}
            />
          )}

          <Gender
            value={this.state.gender}
            onChange={this.onInputChange}
            error={this.state.errors.fieldError('gender')}
          />

          <BirthDate
            value={this.state.birth_date}
            onChange={this.onInputChange}
            error={this.state.errors.fieldError('birth_date')}
            disabled={this.state.lock_personal}
          />

          <Retired
            checked={Boolean(this.state.retired)}
            onChange={this.onInputChange}
            error={this.state.errors.fieldError('retired')}
          />

          <Button
            type="submit"
            isProcessing={this.state.isProcessing}
            text="Save"
          />
        </Form>

        {this.props.user && <UserMembership tag={siteTag} {...this.props} />}
      </div>
    );
  },
});

export default UserProfileForm;
