import PropTypes from 'prop-types';
import React from 'react';
import ApiActions from '../../actions/ApiActions';
import BankCardForm from './BankCardForm';
import Button from '../basic/Button';
import FormErrors from '../FormErrors';
import Plan from '../fields/Plan';
import Caption from '../basic/Caption';
import Text from '../basic/Text';
import VerifyCouponForm from './VerifyCouponForm';

export default class PlanBankCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isProcessing: false,
      plan: props.initialPlan || null,
      couponCode: '',
    };
    this.onCardCapture = this.onCardCapture.bind(this);
    this.onCardAddError = this.onCardAddError.bind(this);
    this.onCouponCodeChange = this.onCouponCodeChange.bind(this);
    this.onCouponVerified = this.onCouponVerified.bind(this);
    this.onPlanChange = this.onPlanChange.bind(this);
    this.onStripeError = this.onStripeError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCouponError = this.onCouponError.bind(this);
  }

  onCardCapture(cardToken) {
    const plan = (this.state.plan && this.state.plan.id) || '';
    const expand = 'cards,service_subscriptions';
    this.setState({ error: null });
    ApiActions.post(
      'customers',
      {
        stripe_token: cardToken,
        coupon: this.state.couponCode,
        plan,
        expand,
      },
      this.props.onSuccess,
      this.onCardAddError,
    );
  }

  onCouponVerified(coupon) {
    this.setState({ coupon });
  }

  onCardAddError(response) {
    const errors = new FormErrors(response);
    this.setState({
      error: errors.firstError(),
      isProcessing: false,
    });
  }

  onCouponCodeChange(e) {
    this.setState({ couponCode: e.target.value });
  }

  onPlanChange(plan) {
    this.setState({ plan });
  }

  onStripeError(message) {
    this.setState({ isProcessing: false, error: message });
  }

  onCouponError(response) {
    this.setState({
      error: new FormErrors(response).firstError(),
      isProcessing: false,
    });
  }

  onSubmit() {
    if (this.state.isProcessing) {
      return false; // disable form submit handler
    }
    this.setState({ isProcessing: true, error: null });
    return true;
  }

  render() {
    const plans = this.props.plans || [];
    const showCancel = !this.state.isProcessing &&
        typeof this.props.onCancel === 'function';

    let planAnnouncement = null;
    if (this.state.plan) {
      const verificationTag = this.state.plan.get('require_verification');
      if (verificationTag && !this.props.user.isVerified(verificationTag)) {
        planAnnouncement = (
          <Text>
            This subscription plan provides an access to secure content
            for active {verificationTag} personnel. We require all members
            to be verified in order to access this content. On the next steps
            we&apos;ll ask you to add an information about your current
            employment and submit a verification request.
          </Text>
        );
      }
    }

    return (
      <BankCardForm
        site={this.props.site}
        error={this.state.error}
        onCardCapture={this.onCardCapture}
        onSubmit={this.onSubmit}
        onError={this.onStripeError}
      >

        {(plans.length > 0) && (
          <div className="cub-ChoosePlan">
            <Caption>Choose plan</Caption>
            {planAnnouncement}
            <Plan
              onChange={this.onPlanChange}
              options={plans}
              value={this.state.plan}
            />
            <VerifyCouponForm
              plan={this.state.plan}
              onChange={this.onCouponCodeChange}
              onSuccess={this.onCouponVerified}
              onError={this.onCouponError}
            />
            {this.state.coupon && (
              <p>Discount applied:{' '}
                {this.state.coupon.discountStr(this.state.plan.get('amount'))}
              </p>
            )}
          </div>
        )}

        <div className="cub-FormGroup cub-FormGroup--buttons">
          <Button
            type="submit"
            isProcessing={this.state.isProcessing}
            text={plans.length > 0 ? 'Next' : 'Save'}
          />

          {showCancel && (
            <Button
              classNameModifier="cub-Button--cancel"
              onClick={this.props.onCancel}
              text="Cancel"
            />
          )}
        </div>
      </BankCardForm>
    );
  }
}

PlanBankCardForm.propTypes = {
  initialPlan: PropTypes.object,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  plans: PropTypes.array,
  site: PropTypes.object,
  user: PropTypes.object,
};
