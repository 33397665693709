import PropTypes from 'prop-types';
import React from 'react';

export default class Error extends React.Component {
  createMarkup(html) {
    return { __html: html };
  }

  render() {
    const className = `cub-Form-error ${this.props.classNameModifier}`;
    if (typeof this.props.children === 'string') {
      return (
        <p
          className={className}
          data-field={this.props.dataField}
          dangerouslySetInnerHTML={this.createMarkup(this.props.children)}
        />
      );
    }
    return (
      <div className={className} data-field={this.props.dataField}>
        {this.props.children}
      </div>
    );
  }
}

Error.propTypes = {
  children: PropTypes.any,
  classNameModifier: PropTypes.string,
  dataField: PropTypes.string,
};

Error.defaultProps = {
  classNameModifier: '',
};
