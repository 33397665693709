import PropTypes from 'prop-types';
import React from 'react';

export default class Caption extends React.Component {
  render() {
    const className = 'cub-Caption' +
        `${this.props.isInsideForm ? ' cub-Caption--form' : ''}` +
        ` ${this.props.classNameModifier}`;
    return (
      <h2 className={className}>
        {this.props.children}
      </h2>
    );
  }
}

Caption.propTypes = {
  classNameModifier: PropTypes.string,
  children: PropTypes.any,
  isInsideForm: PropTypes.bool,
};

Caption.defaultProps = {
  classNameModifier: '',
};
