import _ from 'underscore';
import AppDispatcher from '../dispatcher/AppDispatcher';
import CubApi from '../api/CubApi';
import { GENERIC_FORM } from '../constants/FormIds';
import UserStore from '../stores/UserStore';
import {
  CHUNK_LOADED,
  CHUNKED_LOADING_STARTED,
  FORM_SUCCESS,
} from '../constants/ActionTypes';

const defaultExpand = {
  members: 'user,positions,group_membership',
};

const ApiActions = {
  defaultExpand(url) {
    return defaultExpand[url] || '';
  },

  get(url, data, onSuccess, onError) {
    CubApi.get(
      url,
      { apiKey: UserStore.token(true), data },
      onSuccess,
      onError,
    );
  },

  simplePost(url, data, onSuccess, onError) {
    CubApi.post(
      url,
      { apiKey: UserStore.token(true), data },
      onSuccess,
      onError,
    );
  },

  genericPost(url, data, onSuccess, onError) {
    CubApi.genericRequest(
      'post',
      url,
      { apiKey: UserStore.token(true), data },
      ApiActions.dispatchPostSuccess.bind(ApiActions, onSuccess),
      onError,
    );
  },

  post(url, data, onSuccess, onError) {
    CubApi.post(
      url,
      { apiKey: UserStore.token(true), data },
      ApiActions.dispatchPostSuccess.bind(ApiActions, onSuccess),
      onError,
    );
  },

  dispatchPostSuccess(onSuccess, response, meta, method, url, options) {
    AppDispatcher.handleFormAction({
      actionType: FORM_SUCCESS,
      form: GENERIC_FORM,
      response,
      options,
    });
    if (typeof onSuccess === 'function') {
      onSuccess(response, meta, method, url, options);
    }
  },

  del(url, data, onSuccess, onError) {
    CubApi.del(
      url,
      { apiKey: UserStore.token(true), data },
      ApiActions.dispatchPostSuccess.bind(ApiActions, onSuccess),
      onError,
    );
  },

  getChunk(url, filter, onSuccess, onError) {
    ApiActions.get(
      url,
      _.extend({ expand: ApiActions.defaultExpand(url) }, filter),
      // eslint-disable-next-line no-shadow
      (response, meta, method, url, options) => {
        AppDispatcher.handleServerAction({
          actionType: CHUNK_LOADED,
          nonCacheable: true,
          response,
        });
        onSuccess(response, meta, method, url, options);
      },
      onError,
    );
  },

  getList(url, filter) {
    ApiActions.get(
      url,
      _.extend({ expand: ApiActions.defaultExpand(url) }, filter),
      chunkOfListLoaded,
      chunkOfListLoadError,
    );
  },

  getAll(url, filter) {
    const paginatedFilter = _.extend({}, filter, { offset: 0, count: 100 });
    ApiActions.getList(url, paginatedFilter);
    AppDispatcher.handleServerAction({
      actionType: CHUNKED_LOADING_STARTED,
      url,
      filter: paginatedFilter,
    });
  },
};

function chunkOfListLoaded(response, meta, method, url, options) {
  const filter = (options && options.data) || {};
  const offset = +filter.offset;
  const count = +filter.count;
  let loadingComplete = null; // means unknown
  if (count) {
    if (response.length === count) {
      ApiActions.getList(url, _.extend({}, filter, { offset: offset + count }));
      loadingComplete = false;
    } else {
      loadingComplete = true;
    }
  }
  AppDispatcher.handleServerAction({
    actionType: CHUNK_LOADED,
    response,
    url,
    filter,
    loadingComplete,
  });
}

function chunkOfListLoadError(response, meta, method, url, options) {
  const errCode = parseInt(meta && meta.code, 10);
  const filter = (options && options.data) || {};
  // in case of timeout or server error make a retry in 5 seconds
  if (_.isNaN(errCode) || errCode >= 500) {
    window.setTimeout(() => {
      ApiActions.getList(url, filter);
    }, 5000);
  }
}

export default ApiActions;
