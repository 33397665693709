import 'core-js';
import PropTypes from 'prop-types';
import React from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { BlockStyleControls, InlineStyleControls } from './Controls';
import Error from '../../basic/Error';

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'cub-Markdown-blockquote';
    default:
      return null;
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

export default class MarkdownEditor extends React.Component {
  constructor(props) {
    super(props);

    const editorState = props.value ?
      EditorState.createWithContent(stateFromMarkdown(props.value)) :
      EditorState.createEmpty();
    this.state = { editorState };

    this.focus = () => this.refs.editor.focus();
    this.onChange = this.onChange.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.onTab = this.onTab.bind(this);
    this.toggleBlockType = this.toggleBlockType.bind(this);
    this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleKeyCommand(command) {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  onChange(editorState) {
    this.setState({
      editorState,
    });
    if (this.props.onChange) {
      const value = stateToMarkdown(editorState.getCurrentContent())
        .trim()
        .replace(/\u200B/g, '');
      this.props.onChange({
        target: { name: this.props.name, value },
      });
    }
  }

  onTab(e) {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  }

  toggleBlockType(blockType) {
    this.onChange(
      RichUtils.toggleBlockType(
        this.state.editorState,
        blockType,
      ),
    );
  }

  toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle,
      ),
    );
  }

  validate() {
    if (this.props.required && !String(this.props.value)) {
      return 'This field is required.';
    }
    return null;
  }

  render() {
    const { editorState } = this.state;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'cub-Markdown-editor';
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' cub-Markdown-hidePlaceholder';
      }
    }

    return (
      <div className="cub-FormGroup cub-FormGroup--textarea">
        <label className="cub-Label cub-Label--textarea">
          {this.props.label}
          {this.props.required && <span className="cub-Label-asterisk">*</span>}
        </label>
        <div className="cub-FormControl--markdown cub-FormControl">
          <BlockStyleControls
            editorState={editorState}
            onToggle={this.toggleBlockType}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
          />
          <div
            className={className}
            role="textbox"
            tabIndex={0}
            onClick={this.focus}
          >
            <Editor
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              onChange={this.onChange}
              onTab={this.onTab}
              placeholder={this.props.placeholder}
              ref="editor"
              spellCheck
            />
          </div>
        </div>
        <Error data-field={this.props.name}>
          {this.props.error}
        </Error>
      </div>
    );
  }
}

MarkdownEditor.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
  value: PropTypes.string,
};
