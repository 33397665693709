import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';

import Grid from './Grid';
import Loading from './Loading';

import ApiActions from '../../actions/ApiActions';
import { Deferred, logger } from '../../utils/Utils';

export default class SearchGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      found: [],
      foundTotal: null,
      search: props.value,
      hasMore: false,
      loading: false,
    };
    this.offset = 0;
    this.pageSize = props.pageSize;

    this.loadMore = this.loadMore.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.debouncedSearch = _.debounce(this.search.bind(this), 200);
  }

  componentWillMount() {
    this.fetchItems(this.allFilters(this.props.filters));
  }

  componentWillReceiveProps(nextProps) {
    const filters = this.props.filters;
    const nextFilters = nextProps.filters;
    if (_.isEqual(filters, nextFilters)) return;

    if (_.isEqual(_.omit(filters, 'q'), _.omit(nextFilters, 'q'))) {
      // if nothing changes except 'q'- debounce search
      this.debouncedSearch(nextFilters);
      return;
    }

    this.debouncedSearch.cancel();
    this.search(nextFilters);
  }

  componentWillUnmount() {
    this.debouncedSearch.cancel();
    if (this._currentFetch) this._currentFetch.reject({ silent: true });
  }

  onSearchChange(search) {
    if (this.props.onSearchChange) {
      this.props.onSearchChange(search);
    }
  }

  search(filters) {
    this.clearSearchResults();
    this.setState({ search: filters.q });
    this.fetchItems(this.allFilters(filters));
  }

  allFilters(filters) {
    return _.extend({}, filters, { offset: this.offset, count: this.pageSize });
  }

  clearSearchResults() {
    this.offset = 0;
    this.setState({ found: [], hasMore: false });
  }

  fetchItems(filters) {
    this.setState({ loading: true, foundTotal: null });
    if (this._currentFetch) this._currentFetch.reject({ silent: true });

    const dfd = new Deferred();
    this._currentFetch = dfd;
    ApiActions.getChunk(
      this.props.endpoint,
      filters,
      (response, meta) => dfd.resolve({ response, meta }),
      (response, meta) => dfd.reject({ response, meta }),
    );
    this._currentFetch.then(
      ({ response, meta }) => {
        const fetched = response;
        const foundTotal = +meta.headers['X-PAGING-TOTAL'];

        let hasMore = true;
        if (fetched.length < this.pageSize) {
          hasMore = false;
        }
        this.setState((prevState) => ({
          hasMore,
          found: prevState.found.concat(_.pluck(fetched, 'id')),
          foundTotal,
          loading: false,
        }));
      },
      ({ response, meta, silent }) => {
        if (silent) return;
        logger.warn('Failed to fetch members: ', response, meta);
      },
    );
    return this._currentFetch;
  }

  foundItems() {
    const items = [];
    _.each(this.state.found, (id) => {
      const exclude = this.props.exclude;
      if (exclude && _.contains(_.pluck(exclude, 'id'), id)) {
        return;
      }

      const item = _.find(this.props.models, (model) => model.get('id') === id);
      if (item) items.push(item);
    });
    return items;
  }

  loadMore() {
    this.offset += this.pageSize;
    this.fetchItems(this.allFilters(this.props.filters));
  }

  render() {
    const items = this.foundItems();

    let loadMore;
    if (this.state.hasMore && !this.state.loading) {
      loadMore = this.loadMore;
    }

    let foundTotal = null;
    if (!this.state.loading) {
      foundTotal = this.state.foundTotal;
      if (items.length === 0) {
        // to show 'No results' when all items where removed from models
        foundTotal = 0;
      }
    }
    return (
      <div>
        <Grid
          {...this.props}
          items={items}
          foundCount={foundTotal}
          value={this.props.value}
          search={this.state.search}
          onSearchChange={this.onSearchChange}
          loadMore={loadMore}
        />
        {this.state.loading && <Loading />}
      </div>
    );
  }
}

SearchGrid.propTypes = {
  btns: PropTypes.any,
  className: PropTypes.string,
  cols: PropTypes.array,
  displayFields: PropTypes.func,
  models: PropTypes.array,
  exclude: PropTypes.array,
  links: PropTypes.func,
  filters: PropTypes.object,
  endpoint: PropTypes.string,
  onSelect: PropTypes.func,
  onSearchChange: PropTypes.func,
  value: PropTypes.string,
  pageSize: PropTypes.number,
};

SearchGrid.defaultProps = {
  pageSize: null,
};
