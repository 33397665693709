import PropTypes from 'prop-types';
import React from 'react';
import createForm from '../../mixins/createForm';
import Email from '../fields/Email';
import FirstName from '../fields/FirstName';
import LastName from '../fields/LastName';
import UserActions from '../../actions/UserActions';
import { SET_EMAIL } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';
import Text from '../basic/Text';
import DataStore from '../../stores/DataStore';
import { Deferred } from '../../utils/Utils';

const SetEmailForm = createForm(SET_EMAIL, {
  propTypes: {
    user: PropTypes.object,
  },

  getInitialState() {
    return {
      first_name: '',
      last_name: '',
      email: '',
    };
  },

  componentDidMount() {
    const input = (
      this.refs.first_name || this.refs.last_name || this.refs.email
    );
    input.focus();
  },

  submit() {
    const userUpdated = new Deferred();
    if (
      !this.props.user.get('first_name') ||
      !this.props.user.get('last_name')
    ) {
      const profileAttrs = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
      };
      const success = () => userUpdated.resolve();
      UserActions.submitUserForm(SET_EMAIL, 'user', profileAttrs, success);
    } else {
      userUpdated.resolve();
    }

    userUpdated.then(() => {
      UserActions.setEmail({ email: this.state.email }, SET_EMAIL);
    });
  },

  render() {
    const site = DataStore.currentSite();
    const siteName = site.get('friendly_name');
    const user = this.props.user;
    const setNameRequired = !user.get('first_name') || !user.get('last_name');
    return (
      <Form
        classNameModifier="cub-Form--setEmail"
        caption="Registration"
        error={this.state.errors.topError()}
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <Text>
          Please {setNameRequired ? 'fill out your profile' :
          'enter your email address'} to complete registration on {siteName}.
        </Text>

        {!user.get('first_name') && (
          <FirstName
            ref="first_name"
            value={this.state.first_name}
            required
            onChange={this.onInputChange}
            error={this.state.errors.fieldError('first_name')}
          />
        )}

        {!user.get('last_name') && (
          <LastName
            ref="last_name"
            value={this.state.last_name}
            required
            onChange={this.onInputChange}
            error={this.state.errors.fieldError('last_name')}
          />
        )}

        <Email
          ref="email"
          label="Email"
          required
          value={this.state.email}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('email')}
        />

        <Button
          type="submit"
          isProcessing={this.state.isProcessing}
          text="Continue"
        />
      </Form>
    );
  },
});

export default SetEmailForm;
