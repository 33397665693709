import PropTypes from 'prop-types';
import React from 'react';
import ApiActions from '../../actions/ApiActions';
import { injectScript } from '../../utils/injector';
import Loading from '../basic/Loading';
import Button from '../basic/Button';
import Text from '../basic/Text';

export default class ConnectBankAccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { plaidLoaded: false, plaidLinkHandler: null };
    this.onPlaidLoaded = this.onPlaidLoaded.bind(this);
    this.onPlaidSuccess = this.onPlaidSuccess.bind(this);
    this.testMode = this.testMode.bind(this);
  }

  componentWillMount() {
    // Load Plaid
    if (window.Plaid && window.Plaid.version) {
      this.onPlaidLoaded();
      return;
    }
    injectScript(
      'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
      this.onPlaidLoaded,
    );
  }

  onPlaidLoaded() {
    const plaidLinkHandler = window.Plaid.create({
      env: this.testMode() ? 'sandbox' : 'production',
      clientName: this.props.site.get('friendly_name'),
      apiVersion: 'v2',
      key: this.props.site.get('plaid_pk'),
      product: ['auth'],
      selectAccount: true,
      onSuccess: this.onPlaidSuccess,
    });
    this.setState({
      plaidLoaded: true,
      plaidLinkHandler: () => plaidLinkHandler.open(),
    });
  }

  onPlaidSuccess(publicToken, metadata) {
    ApiActions.post(
      'bankaccounts',
      { plaid_token: publicToken, account_id: metadata.account_id || '' },
      this.props.onSuccess,
      this.props.onError,
    );
  }

  testMode() {
    return this.props.site.get('stripe_pk').lastIndexOf('pk_test', 0) === 0;
  }

  render() {
    const plaidPk = this.props.site.get('plaid_pk');
    if (!plaidPk) {
      return (
        <Text classNameModifier="cub-Text--plaidMissingMsg">
          Plaid Account is not configured for this site.
          <br />
          &apos;Connect new Bank Account&apos; feature is unavailable.
        </Text>
      );
    }

    if (!this.state.plaidLoaded) {
      return <Loading />;
    }

    return (
      <div>
        <Button
          text="Connect your bank account"
          onClick={this.state.plaidLinkHandler}
        />
        {this.testMode() && (
          <Text>
            You are currently in Sandbox mode. Credentials »<br />
            username: user_good | password: pass_good<br />
            answer: tomato or ketchup | code: 1234
          </Text>
        )}
      </div>
    );
  }
}

ConnectBankAccountForm.propTypes = {
  customer: PropTypes.object,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  site: PropTypes.object,
};
