import PropTypes from 'prop-types';
import React from 'react';
import ConfirmEmail from './ConfirmEmail';
import RegistrationForm from '../forms/RegistrationForm';

export default class Registration extends React.Component {
  render() {
    const routes = this.props.routes;
    let view;

    switch (this.props.route) {
      case routes.REGISTER:
      case routes.REGISTER_FOR_A_PRODUCT:
        view = <RegistrationForm routes={routes} />;
        break;

      case routes.REGISTER_FOR_A_PLAN:
        view = (
          <RegistrationForm planId={this.props.args[0]} routes={routes} />
        );
        break;

      case routes.EMAIL_CONFIRMATION:
        view = <ConfirmEmail routes={routes} />;
        break;

      default:
    }

    return <div className="cub-Registration">{view}</div>;
  }
}

Registration.propTypes = {
  args: PropTypes.array,
  route: PropTypes.object,
  routes: PropTypes.object,
};
