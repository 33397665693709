import React from 'react';
import createForm from '../../mixins/createForm';
import FirstName from '../fields/FirstName';
import MemberActions from '../../actions/MemberActions';
import LastName from '../fields/LastName';
import PersonalId from '../fields/PersonalId';
import PostId from '../fields/PostId';
import { MEMBER_CREATE } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';
import Text from '../basic/Text';
import Username from '../fields/Username';
import Phone from '../fields/Phone';
import { Site } from '../../stores/DataModels';
import DataStore from '../../stores/DataStore';

const MemberCreateForm = createForm(MEMBER_CREATE, {
  getInitialState() {
    return {
      first_name: '',
      last_name: '',
      phone: '',
      personal_id: '',
      post_id: '',
      username: '',
    };
  },

  componentDidMount() {
    this.refs.first_name.focus();
  },

  submit() {
    MemberActions.newMember({
      organization: this.props.organization.id,
      email: this.props.email,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      phone: this.state.phone,
      personal_id: this.state.personal_id,
      username: this.state.username,
      post_id: this.state.post_id,
      create_user: true,
    }, MEMBER_CREATE);
  },

  formStateFromStores(formState) {
    let topError = formState.errors.topError() || '';
    if (topError.indexOf('is already a member') !== -1) {
      topError = '';
      this.props.onMemberAlreadyExists();
    }
    return { topError };
  },

  render() {
    const organization = this.props.organization;
    const site = DataStore.currentSite();
    const showPhoneField = (
      site.get('user_phone_field') !== Site.USER_PHONE_DISABLED
    );

    // display only for Law Enforcement
    let postIdField = null;
    if (organization.postIdVisible()) {
      postIdField = (
        <PostId
          value={this.state.post_id}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('post_id')}
        />
      );
    }

    return (
      <Form
        classNameModifier="cub-Form--memberInvite"
        error={this.state.topError}
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >

        {this.props.email && (
        <Text>
          Invitation for{' '}
          <span className="cub-Text-dataValue">
            {this.props.email}
          </span>
        </Text>
        )}

        {!this.props.email && (
        <Username
          ref="username"
          label="Username"
          required
          value={this.state.username}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('username')}
        />
        )}

        <Text>Personal details for a new member (optional):</Text>

        <FirstName
          ref="first_name"
          value={this.state.first_name}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('first_name')}
        />

        <LastName
          value={this.state.last_name}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('last_name')}
        />

        {showPhoneField && (
          <Phone
            value={this.state.phone}
            error={this.state.errors.fieldError('phone')}
            onChange={this.onInputChange}
          />
        )}

        <PersonalId
          value={this.state.personal_id}
          label={organization.fieldLabels().personal_id}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('personal_id')}
        />

        {postIdField}

        <div className="cub-FormGroup cub-FormGroup--buttons">
          <Button
            type="submit"
            isProcessing={this.state.isProcessing}
            text={this.props.email ? 'Send invitation' : 'Create a member'}
          />

          <Button
            classNameModifier="cub-Button--back"
            onClick={this.props.onCancel}
            text="Back"
          />
        </div>
      </Form>
    );
  },
});

export default MemberCreateForm;
