import LabeledInput from './LabeledInput';

export default class Email extends LabeledInput {}

Email.defaultProps = {
  classNameModifier: 'cub-FormControl--email',
  label: 'Email',
  maxLength: 254,
  name: 'email',
};
