import PropTypes from 'prop-types';
import React from 'react';

export default class Image extends React.Component {
  render() {
    const className = `cub-Image cub-Image--${this.props.size}` +
      ` ${this.props.classNameModifier}`;
    const src = this.props.src ? this.props.src : this.props.srcDefault;

    return (
      <img
        className={className}
        src={src}
        data-purpose={this.props.dataPurpose}
        alt={this.props.altText}
      />
    );
  }
}

Image.propTypes = {
  classNameModifier: PropTypes.string,
  altText: PropTypes.string,
  dataPurpose: PropTypes.string,
  size: PropTypes.string,
  src: PropTypes.string,
  srcDefault: PropTypes.string,
};

Image.defaultProps = {
  classNameModifier: '',
  altText: '',
  size: 'large',
  src: '',
  srcDefault: '',
};
