import _ from 'underscore';
import UserActions from '../actions/UserActions';
import CubApi from '../api/CubApi';
import ConfigStore from '../stores/ConfigStore';
import DataStore from '../stores/DataStore';
import * as Cookies from './Cookies';
import { injectScript } from './injector';
import { Deferred, siteSentry, urlParse } from './Utils';

const bannerDynamicParagraph = (
  'We and selected partners ' +
  '[if not gdprApplies and ccpaApplies]' +
    'collect personal information.' +
  '[/if]' +
  '[if gdprApplies]' +
    'use cookies or similar technologies as specified in the ' +
    '[link cookie_policy]cookie policy[/link].' +
    '[if enableTcf]' +
      '<br/>With respect to advertising, we and selected ' +
      '[link vendors]third parties[/link] may <em>use precise geolocation ' +
      'data</em> and <em>actively scan device characteristics for ' +
      'identification</em> in order to <em>store and/or access information ' +
      'on a device</em> and process personal data (e.g. browsing data, IP ' +
      'addresses, usage data or unique identifiers) for the following ' +
      'purposes: <em>personalised ads and content, ad and content ' +
      'measurement, and audience insights; develop and improve products</em>.' +
      '<br/>You can freely give, deny, or withdraw your consent at any time ' +
      'by accessing the [link adv_pref]advertising preferences panel[/link].' +
      '[if isTCFConsentGlobal]' +
        ' Consider that your choices made here will be applied globally ' +
        '(your settings will be available and applied on other websites and ' +
        'apps that adhere to the IAB Transparency and Consent Framework).' +
      '[/if]' +
    '[/if]' +
  '[/if]' +
  // '[if ccpaApplies]' +
  //   '<br/>In case of sale of your personal information, you ' +
  //   'may opt out by using the link "%{do_not_sell}".' +
  //   '<br/>To find out more about the categories of personal information ' +
  //   'collected and the purposes for which such information will be used, ' +
  //   'please refer to our %{privacy_policy}.' +
  // '[/if]' +
  ''
);

function init() {
  window._iub = window._iub || [];
  window._iub.csConfiguration = {
    siteId: ConfigStore.get('iubendaSiteId'),
    cookiePolicyId: ConfigStore.get('iubendaCookiePolicyId'),
    lang: 'en',
    logLevel: 'nolog',
    cookiePolicyUrl: 'https://www.lexipol.com/cookie-notice/',
    privacyPolicyUrl: 'https://www.lexipol.com/privacy-policy/',
    cookiePolicyInOtherWindow: true,
    whitelabel: false, // Show iubenda brand.
    consentOnContinuedBrowsing: false,
    startOnDomReady: true, // Defaults to true.
    // Cross-domain consent. Doesn't work when any of the following conditions
    // apply:
    // - CCPA applies
    // - Google Additional Consent is enabled
    // - There's no first-party euconsent-v2 cookie and askConsentIfCMPNotFound
    //   is true
    enableRemoteConsent: false,

    enableGdpr: false, // The actual value is set in onScriptLoaded.
    gdprApplies: undefined, // If undefined, will detect country and decide.
    gdprAppliesGlobally: false, // Show the GDPR banner only to EU users.
    countryDetection: true,
    perPurposeConsent: true,
    purposes: '1, 4, 5',
    priorConsent: false,
    enableTcf: false, // IAB Transparency and Consent Framework.
    googleAdditionalConsentMode: true,
    // If true, saves TCF consent globally on consensu.org domain. However, this
    // doesn't seem to work with TCF v2.0.
    isTCFConsentGlobal: false,
    // If true and there's no first-party euconsent-v2 cookie, then shows the
    // banner even if there's remote consent. Defaults to true.
    askConsentIfCMPNotFound: true,

    enableCcpa: true,
    ccpaNoticeDisplay: false, // Don't show the banner if only CCPA applies.
    // If CCPA doesn't apply, the iubenda-ccpa-opt-out links won't work. We
    // should honor these requests for all users, even if they are not in CA/VA.
    // TODO: Does it count as a page view?
    ccpaApplies: true,
    ccpaAcknowledgeOnDisplay: false,
    // 'Countries' in iub.cc that require a consent banner
    cookieConsentUS: [
      'US-CA',
      'US-CO',
      'US-CT',
      'US-MT',
      'US-OR',
      'US-TX',
      'US-UT',
      'US-VA',
      'US-DE',
      'US-IA',
      'US-NE',
      'US-NH',
      'US-NJ',
    ],

    banner: {
      position: 'float-bottom-center',
      customizeButtonDisplay: true,
      acceptButtonDisplay: true,
      closeButtonDisplay: true,
      rejectButtonDisplay: false, // Show "×" button instead.
      // Set the following styles to null so that their default values are not
      // set in the inline styles on .iubenda-cs-content:
      textColor: null,
      backgroundColor: null,
      // Removed 'zIndex: null' because it makes the preferences dialog appear
      // underneath the banner. It broke in iubenda v1.28.0.
    },

    callback: {
      onBeforePreload: onConfigured,
      onReady,
      onCcpaOptOut,
      onPreferenceFirstExpressed,
      onPreferenceExpressedOrNotNeeded,
      onStartupFailed: siteSentry.captureException,
      onError: siteSentry.captureException,
      onFatalError: siteSentry.captureException,
    },

    i18n: {
      en: {
        banner: {
          dynamic: {
            paragraph_1: bannerDynamicParagraph,
          },
        },
        per_purpose: {
          purposes: {
            4: {
              name: 'Analytics',
            },
          },
        },
      },
    },
  };

  getUSPrivacyCookie().then(injectScripts);
}

function getUSPrivacyCookie() {
  // Try to get the 'usprivacy' cookie from the '.lexipol.com' domain.
  //
  // The opt-out link is located on www.lexipol.com. Therefore, the 'usprivacy'
  // cookie is stored on '.lexipol.com' by default. Getting the cookie just from
  // the local domain does not reflect that the user could have opted out on
  // lexipol.com.
  const gotUSPrivacy = new Deferred();
  CubApi.getXCookies((cookies) => {
    const parsedCookies = Cookies.parse(cookies);
    const usprivacy = parsedCookies.usprivacy;
    if (usprivacy == null) {
      gotUSPrivacy.resolve(false);
      return;
    }

    let parsedUSPrivacy;
    const urlDecodedUSPrivacy = decodeURIComponent(usprivacy);
    try {
      parsedUSPrivacy = JSON.parse(urlDecodedUSPrivacy);
    } catch (e) {
      siteSentry.captureException(e, { extra: { usprivacy } });
      gotUSPrivacy.resolve(false);
      return;
    }

    const actionDateStr = (
      parsedUSPrivacy.optOutDate || parsedUSPrivacy.firstAcknowledgeDate
    );

    let expires;
    if (actionDateStr) {
      const actionDate = new Date(actionDateStr);
      const year = 365 * 24 * 60 * 60 * 1000;
      expires = new Date(actionDate.getTime() + year);
    }
    if (expires == null || _.isNaN(expires.getTime())) {
      // Make the usprivacy cookie expire in a week. A week later we'll try to
      // get the cross-domain cookie again.
      const now = new Date();
      const week = 7 * 24 * 60 * 60 * 1000;
      expires = new Date(now.getTime() + week);
    }

    Cookies.set('usprivacy', urlDecodedUSPrivacy, {
      expires,
      domain: uspCookieDomain(window.location.hostname),
      secure: true,
      samesite: 'none',
    });
    gotUSPrivacy.resolve(true);
  });
  return gotUSPrivacy;
}

function uspCookieDomain(hostName) {
  if (hostName === 'localhost') {
    return hostName;
  }

  const isIP = /^[0-9]+$/.test(hostName.split(':')[0].split('.').join(''));
  if (isIP) {
    return hostName;
  }

  if (hostName.indexOf('www.') !== 0) {
    hostName = `www.${hostName}`;
  }
  // Get the top-most identifiable domain.
  const domains = hostName.split('.');
  const secondLevel = domains[domains.length - 2];
  let n = 2;
  if (domains.length > 3 && secondLevel.length < 4) {
    // A domain like london.gov.uk.
    n = 3;
  }
  const topDomain = domains.slice(domains.length - n).join('.');
  return `.${topDomain}`;
}

function injectScripts() {
  // TODO: Inject TCF stub for GDPR.
  // Original script: https://cdn.iubenda.com/cs/tcf/stub-v2.js
  // injectScript(ConfigStore.static('vendor/iubenda/tcf/stub-v2.js'));

  // Original script: https://cdn.iubenda.com/cs/ccpa/stub.js
  injectScript(ConfigStore.static('vendor/iubenda/ccpa/stub.js'));

  // Original script: https://cdn.iubenda.com/cs/versions/iubenda_cs-1.28.1.js
  const apiUrl = ConfigStore.get('apiUrl');
  const referer = encodeURIComponent(window.location.href);
  const iubendaCS = (
    `${urlParse(apiUrl).netloc}/cs/iubenda_cs.js?referer=${referer}`
  );
  injectScript(iubendaCS, onScriptLoaded);
}

function onScriptLoaded() {
  // This callback is executed when the iubenda_cs.js script finishes loading.
  // The script contains the detected country and whether the cookie banner is
  // enabled for this site in the LID Admin.
  const iub = window._iub;

  let enableGdpr = ConfigStore.get('enableCookieBanner');
  if (enableGdpr == null) {
    enableGdpr = Boolean(iub.cubEnableCookieBanner);
  }
  iub.csConfiguration.enableGdpr = enableGdpr;

  if (checkUS()) {
    // Enable GDPR banner "globally" so it adds the cookie preferences to the
    // CCPA banner.
    iub.csConfiguration.gdprAppliesGlobally = true;
  }

  const detectedCountry = iub.cc;
  if (detectedCountry === 'EU') {
    // Enable TCF API only for EU users. We don't need TCF in California. This
    // also shows a floating preferences button in the bottom right corner.
    iub.csConfiguration.enableTcf = true;

    // The user must either explicitly accept or explicitly reject the cookies.
    iub.csConfiguration.banner.closeButtonDisplay = false;
    iub.csConfiguration.banner.rejectButtonDisplay = true;
  }
}

function checkUS() {
  // Helper function to return if consent is needed in specific US States
  const iub = window._iub;
  const config = iub.csConfiguration;
  return config.enableGdpr && config.cookieConsentUS.includes(iub.cc);
}

function onConfigured() {
  // This callback is executed when the _iub.csConfiguration has been parsed and
  // validated.

  // TODO: Load the cookie preferences from the id.lexipol.com domain.
}

function onReady() {
  // If the consent of the user has not yet been processed (for example, because
  // it’s their first visit) the onReady callback is invoked as soon as the
  // banner cookie is displayed. Otherwise, if the user has already given their
  // consent to the installation of cookies, this callback is invoked as soon as
  // the iubenda Cookie Solution is initialized.

  const iub = window._iub;
  const cs = iub.cs;
  const preferenceExpressed = cs.api.isPreferenceExpressed();
  if (checkUS() && !preferenceExpressed) {
    // The user is in US State that requires consent and this is their first
    // time on their site. Allow all cookie preference categories. Note: This
    // setPreference API is not so public. Purpose #1 is "Strictly necessary"
    // and it's always enabled.
    cs.purposesPreference.setPreference(2, true);
    cs.purposesPreference.setPreference(3, true);
    cs.purposesPreference.setPreference(4, true);
    cs.purposesPreference.setPreference(5, true);
    // Public API.
    window._iub.cs.api.activateSnippets();
    hasApprovedAdsPromise.resolve(true);
  }
}

const hasApprovedAdsPromise = new Deferred();

function onCcpaOptOut() {
  sendCCPAOptOut();
  if (
    window._iub.cs.state &&
    window._iub.cs.state.ccpaOptOutConfirmationOpen
  ) {
    // When opting out by pressing the Do Not Sell link.
    const message = (
      "You've been opted out of the sale of your personal information."
    );
    // eslint-disable-next-line no-alert
    setTimeout(() => alert(message), 0);
  }
}

function sendCCPAOptOut() {
  // This callback is executed every time iubenda initializes and finds the
  // opt-out cookie.
  // TODO: (A) Relay the opt-out to Google.
  // TODO: (A) Give the feedback to the user.
  // TODO: (A) Block the scripts that are not compliant with CCPA.
  const user = DataStore.currentUser();
  if (!user) {
    return;
  }
  const privacy = user.get('privacy');
  if (privacy != null && privacy.ccpa_opted_out_date != null) {
    return;
  }
  // When the user signs in, the user info is cached, and then the widget
  // reloads the page. After the reload, this onCcpaOptOut callback is executed.
  // It gets the user data from the cache. So, it should be enough to send the
  // opt-out here, and not when we get the response from the login endpoint.
  UserActions.updatePrivacy({
    ccpa_opted_out: true,
  });
}

function onPreferenceExpressedOrNotNeeded(preference) {
  // TODO: Store cookie preferences on the id.lexipol.com domain.
  // TODO: Consider refreshing the page to apply the preferences in CA, so that
  //  scripts could be deactivated.

  if (!preference) {
    // Preference is not given.
    const cs = window._iub.cs;
    if (!cs.api.gdprApplies()) {
      hasApprovedAdsPromise.resolve(true);
    }
    return;
  }

  if (typeof preference.consent === 'boolean') {
    hasApprovedAdsPromise.resolve(preference.consent);
  } else if (preference.purposes) {
    hasApprovedAdsPromise.resolve(preference.purposes[5]);
  }

  sendCookiePreference(preference);
}

function sendCookiePreference(preference) {
  if (!preference) {
    return;
  }

  const user = DataStore.currentUser();
  if (!user) {
    return;
  }

  const localPrivacy = {
    cookies_analytical: null,
    cookies_targeting: null,
  };
  if (typeof preference.consent === 'boolean') {
    localPrivacy.cookies_analytical = preference.consent;
    localPrivacy.cookies_targeting = preference.consent;
  } else if (preference.purposes) {
    localPrivacy.cookies_analytical = preference.purposes[4];
    localPrivacy.cookies_targeting = preference.purposes[5];
  }

  const remotePrivacy = user.get('privacy') || {
    cookies_analytical: null,
    cookies_targeting: null,
  };
  if (
    localPrivacy.cookies_analytical !== remotePrivacy.cookies_analytical ||
    localPrivacy.cookies_targeting !== remotePrivacy.cookies_targeting
  ) {
    UserActions.updatePrivacy(localPrivacy);
  }
}

function onPreferenceFirstExpressed(preference) {
  if (preference && (preference.consent === true || preference.purposes)) {
    window._iub.cs.api.activateSnippets();
  }
}

export default { init, hasApprovedAdsPromise };
