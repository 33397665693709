import crypto from 'crypto';
import ConfigStore from '../stores/ConfigStore';
import DataStore from '../stores/DataStore';
import UserActions from '../actions/UserActions';
import FormActions from '../actions/FormActions';
import { SSO_CALLBACK } from '../constants/FormIds';
import { storageSupported } from '../storage/storage';
import * as Cookies from '../utils/Cookies';
import CubApi from '../api/CubApi';
import {
  absoluteURL,
  cookieOptions,
  getRandomString,
  logger,
  setQueryString,
  urlParse,
} from '../utils/Utils';

const StateStorage = {
  setVerifier(verifier) {
    StateStorage._set('cubPkceVerifier', verifier);
  },

  getVerifier() {
    return StateStorage._get('cubPkceVerifier');
  },

  setState(state) {
    StateStorage._set('cubPkceState', state);
  },

  getState() {
    return StateStorage._get('cubPkceState');
  },

  clear() {
    StateStorage._remove('cubPkceVerifier');
    StateStorage._remove('cubPkceState');
  },

  _set(key, val) {
    if (storageSupported('localStorage')) {
      window.localStorage.setItem(key, val);
      return;
    }
    Cookies.set(key, val, cookieOptions());
  },

  _get(key) {
    if (storageSupported('localStorage')) {
      return window.localStorage.getItem(key);
    }
    return Cookies.get(key);
  },

  _remove(key) {
    if (storageSupported('localStorage')) {
      window.localStorage.removeItem(key);
      return;
    }
    Cookies.set(key, '', cookieOptions());
    Cookies.expire(key, cookieOptions());
  },

};

const Sso = {
  _getCallbackUri() {
    const site = DataStore.currentSite();
    let callbackUri = site.urls().SSO_CALLBACK;
    if (callbackUri.indexOf('#') === 0) {
      // make sure that we will get consistent absolute URL
      // otherwise generated callback uri will be based on current path
      // and may lead to difference between what sent to /authorize and
      // what was submitted to the /token endpoints
      callbackUri = `/${callbackUri}`;
    }
    return absoluteURL(callbackUri, true);
  },
  authorize(providerConfig, targetLinkUri, loginHint) {
    const params = {
      client_id: providerConfig.client_id,
    };
    const pkceVerifier = getRandomString(64);
    const rnd = getRandomString(32);
    const pkceState = `${rnd}.${providerConfig.client_id}`;
    const pkceChallenge = crypto.createHash('sha256')
      .update(pkceVerifier)
      .digest()
      .toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');

    params.response_type = 'code';
    params.code_challenge = pkceChallenge;
    params.code_challenge_method = 'S256';
    params.redirect_uri = Sso._getCallbackUri();
    params.state = pkceState;

    StateStorage.setVerifier(pkceVerifier);
    StateStorage.setState(pkceState);
    if (loginHint) {
      params.login_hint = loginHint;
    }
    if (targetLinkUri) {
      params.target_link_uri = decodeURIComponent(targetLinkUri);
    }

    window.location = setQueryString(providerConfig.url, params);
  },

  callback(code, state, nextUrl) {
    const storedState = StateStorage.getState();
    if (!state || (state !== storedState)) {
      logger.error('SSO: Incorrect state', { code, state, nextUrl });
      StateStorage.clear();
      const msg = '' +
        'Your login was unsuccessful. Please try again. ' +
        'Make sure that you haven\'t copy-pasted this URL and ' +
        'that you use the same browser from start to finish.';
      FormActions.dispatchFormError({
        error: { description: msg },
      }, null, null, null, { _formId: SSO_CALLBACK });
      return;
    }

    const data = {
      grant_type: 'authorization_code',
      code,
      redirect_uri: Sso._getCallbackUri(),
      client_id: state.split('.')[1],
      code_verifier: StateStorage.getVerifier(),
    };

    // TODO-pkce: get rid of callback muddle
    const apiHost = urlParse(ConfigStore.get('apiUrl')).netloc;
    CubApi.post(
      // absolute url to call the SSO API and not the usual API
      // otherwise data will be posted to the {netloc}/v1/sso/token
      // check getApiXdmRequest from CubApi.js for more context
      `${apiHost}/sso/token`,
      { data, _formId: SSO_CALLBACK },
      (response) => {
        StateStorage.clear();
        const token = response.access_token;
        CubApi.get(`user?expand=${UserActions.USER_EXPAND}`,
          { apiKey: token, _nextUrl: nextUrl, _formId: SSO_CALLBACK },
          (response, ...args) => { // eslint-disable-line no-shadow
            response.token = token;
            UserActions.ssoLoginSuccess(response, ...args);
          },
          (...args) => {
            logger.error(args);
            FormActions.dispatchFormError(...args);
          });
      },
      (...args) => {
        logger.error(args);
        StateStorage.clear();
        FormActions.dispatchFormError(...args);
      },
    );
  },
};

export default Sso;
