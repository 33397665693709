import _ from 'underscore';
import createStore from '../mixins/createStore';

const _config = {
  debug: true,
  apiUrl: 'https://id.lexipol.com/v1',
  apiKey: null,
  apiTimeout: 40000, // ms
  staticUrl: 'https://lid.cdn.lexipol.com/static',
  menuSelector: '#cub-widget-menu',
  appSelector: '#cub-widget-app',
  userTokenCookie: 'cubUserToken',
  organizationIdCookie: 'cubOrganizationId',
  cookieDomain: null,
  memberPositionUnitChoices: null,
  // cookieSecure = null means auto mode. In auto mode cookieSecure
  // is set to true when parent page is loaded via SSL, and false otherwise
  cookieSecure: null,
  // Automatically reload current page from server when token cookie changes
  cookiePageReload: true,
  smartdateClass: 'cub-Smartdate',
  onDataSync: null,
  forms: [],
  iubendaSiteId: 2008027,
  iubendaCookiePolicyId: 52405076,
  // Specified in the Site settings in LID Admin. Can be overridden when
  // starting the widget.
  enableCookieBanner: null,
};

const ConfigStore = createStore({
  get(name) {
    return _config[name];
  },

  config(options) {
    const opts = _.isObject(options) ? options : {};
    _.extend(_config, opts);
    // Cut trailing slash from staticUrl
    _config.staticUrl = _config.staticUrl.replace(/\/$/, '');
    return _config;
  },

  static(url) {
    return _config.staticUrl + (url.indexOf('/') === 0 ? url : `/${url}`);
  },
});

export default ConfigStore;
