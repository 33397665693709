import LabeledCombobox from './LabeledCombobox';

export default class Unit extends LabeledCombobox {}

Unit.defaultProps = {
  classNameModifier: 'cub-FormControl--unit',
  filter: 'startsWith',
  name: 'unit',
  suggest: true,
};
