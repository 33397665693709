import React from 'react';
import PropTypes from 'prop-types';
import { RESEND_TOKEN_FORM_ID } from '../../constants/FormIds';
import createForm from '../../mixins/createForm';

import Text from '../basic/Text';
import Button from '../basic/Button';
import Error from '../basic/Error';

import OrderActions from '../../actions/OrderActions';

const OrderInvoiceError = createForm(RESEND_TOKEN_FORM_ID, {
  getInitialState() {
    return {
      sentSuccess: false,
    };
  },

  storesSuccess(formId) {
    if (formId === RESEND_TOKEN_FORM_ID) {
      this.setState({ sentSuccess: true });
    }
  },

  reSendInvoiceLink() {
    OrderActions.reSendInvoiceLink(this.props.orderId);
  },

  parseErrors(formErrors, resendErrors = false) {
    const result = {
      msg: '',
      content: '',
    };

    if (formErrors.invoice_token) {
      result.msg = 'The link to this invoice has expired';
      result.content = this.buildResendForm();
    } else if (formErrors.status) { // order status validate failed.
      result.msg = formErrors.status;
      result.content = (
        <Text>
          If you have any questions, please contact
          our {this.props.supportTeamLink} for additional help.
        </Text>
      );
    }

    if (!result.msg && resendErrors) { // resending link, not a status error
      result.msg = 'An error occurred while sending a new payment link.';
      result.content = (
        <Text>
          If you have any questions, please contact
          our {this.props.supportTeamLink} for additional help.
        </Text>
      );
      return result;
    }

    return result;
  },

  buildResendForm() {
    return (
      <>
        <Text>
          We{'\''}re sorry, but the link to this invoice has expired. You can
          request a new payment link.
        </Text>
        <Button
          text="Send me a new payment link"
          onClick={this.reSendInvoiceLink}
          disabled={this.state.isProcessing}
        />
      </>
    );
  },

  render() {
    if (this.state.sentSuccess) {
      return (
        <Text>
          A new payment link was successfully sent to your email address,
          please check your email inbox to complete the payment.
        </Text>
      );
    }
    const reSendErrors = this.state.errors;
    let errObj;
    if (reSendErrors && reSendErrors._errors) {
      errObj = this.parseErrors(reSendErrors._errors, true);
    } else {
      errObj = this.parseErrors(this.props.errors, false);
    }

    if (errObj.msg) {
      return (
        <>
          <Error>{errObj.msg}</Error>
          {errObj.content}
        </>
      );
    }

    return (
      <div>Order invoice error</div>
    );
  },
});

OrderInvoiceError.propTypes = {
  supportTeamLink: PropTypes.object,
  orderId: PropTypes.string,
  errors: PropTypes.object,
};

export default OrderInvoiceError;
