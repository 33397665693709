import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../basic/Button';
import CaptionLarge from '../basic/CaptionLarge';

/* eslint-disable react/prefer-es6-class */
const NewsletterSample = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    checked: PropTypes.bool,
    mailing: PropTypes.object,
    onSubscribe: PropTypes.func,
    onClose: PropTypes.func,
  },

  getInitialState() {
    return {
      mailing: this.props.mailing,
    };
  },

  render() {
    const name = this.state.mailing.get('name');
    const imgUrl = this.state.mailing.get('sample');
    const subscribeBtn = !this.props.checked && (
      <Button
        onClick={this.props.onSubscribe}
        text="Select to subscribe"
      />
    );
    return (
      <div className="cub-NewsletterSample">
        <CaptionLarge>{name} sample</CaptionLarge>

        <div className="cub-NewsletterSample-controls">
          <div className="cub-FormGroup cub-FormGroup--buttons">
            <Button
              classNameModifier="cub-Button--back"
              onClick={this.props.onClose}
              text="Back"
            />
            {subscribeBtn}
          </div>
        </div>

        <img className="cub-NewsletterSample-image" src={imgUrl} alt="" />
      </div>
    );
  },
});

export default NewsletterSample;
