import _ from 'underscore';
import { logger, hasOwnProperty } from '../utils/Utils';

function Route(name, url, handler) {
  this.name = name;
  this.url = url;
  this._handler = handler;
  this.handle = (...args) => {
    // Call handler with route as a first argument
    this._handler(...[this].concat(args));
  };
  this._paramRegex = /(\{\w+\})/g;
}

Route.prototype._applyArgs = function _applyArgs(args) {
  let index = 0;
  return this.url.replace(
    this._paramRegex,
    (match) => { // eslint-disable-line arrow-body-style
      if (args.length > index) {
        index += 1;
        return args[index - 1];
      }
      return match;
    },
  );
};

Route.prototype._applyKwargs = function _applyKwargs(kwargs) {
  return this.url.replace(this._paramRegex, (match) => {
    const arg = match.slice(1, match.length - 1);
    return hasOwnProperty(kwargs, arg) ? kwargs[arg] : match;
  });
};

Route.prototype.makeUrl = function makeUrl(kwargs) {
  let url;
  /* eslint-disable prefer-rest-params */
  if (_.isObject(kwargs)) {
    url = this._applyKwargs(kwargs);
  } else {
    url = this._applyArgs(arguments);
  }
  if (url.match(this._paramRegex)) {
    logger.warn(
      'makeUrl failed, not enough args to resolve',
      this.name,
      'Args passed: ',
      arguments,
    );
    return null;
  }
  /* eslint-enable prefer-rest-params */
  return url;
};

export default Route;
