import { USER_LOOKUP } from '../constants/FormIds';
import AppDispatcher from '../dispatcher/AppDispatcher';
import AppStore from './AppStore';
import createStore from '../mixins/createStore';
import { FORM_SUCCESS, RESET_USERNAME_LOOKUP } from '../constants/ActionTypes';

let ssoOptions = null;
let username = null;
let lastVisitedSiteNames = null;
let placeholder = '';

const LoginStore = createStore({
  getSsoOptions() {
    return ssoOptions;
  },
  getUsername() {
    return username;
  },
  getLastVisitedSiteNames() {
    return lastVisitedSiteNames;
  },
  getPlaceholder() {
    return placeholder;
  },
});

LoginStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;

  AppDispatcher.waitFor([AppStore.dispatchToken]);

  switch (action.actionType) {
    case FORM_SUCCESS:
      if (action.form === USER_LOOKUP) {
        ssoOptions = action.response.sso_options;
        username = action.response.username;
        lastVisitedSiteNames = action.response.last_visited_site_names;
        LoginStore.emitChange();
      }
      break;
    case RESET_USERNAME_LOOKUP:
      placeholder = username;
      username = '';
      lastVisitedSiteNames = null;
      LoginStore.emitChange();
      break;
    default:
      break;
  }

  return true;
});

export default LoginStore;
