import _ from 'underscore';
import CubApi from '../api/CubApi';
import ConfigStore from '../stores/ConfigStore';
import UserStore from '../stores/UserStore';
import * as Cookies from './Cookies';
import { logger, throwSafely, urlParse } from './Utils';

const ATTEMPTS_TO_LOAD_DATA = 10;
const LYTICS_COOKIE_NAME = 'seerid';

function getLoadTime() {
  if (window.performance && window.performance.now) {
    return window.performance.now();
  }
  return -1;
}

class Storage {
  constructor() {
    this.lyticsId = '';
    this.dataLayer = null;
    this.url = '';
    this.lastSendUrl = '';
    this.attempts = 0;
    this.initTime = getLoadTime();
  }

  setUrl(url) {
    this.url = url;
  }

  setLyticsId(lyticsId) {
    if (lyticsId && !this.lyticsId) {
      this.lyticsId = lyticsId;
    }
  }

  setDataLayer(dataLayer) {
    if (dataLayer) {
      this.dataLayer = dataLayer;
    }
  }

  readyToSend() {
    return this.lyticsId && this.dataLayer;
  }

  needToSend() {
    return this.url !== this.lastSendUrl;
  }

  onDataSent() {
    this.attempts = 0;
    this.lastSendUrl = this.url;
  }

  dataToSend(eventName) {
    const uid = UserStore.currentUserId() || UserStore.getUserIdFromCookie();
    const data = {
      lyticsId: this.lyticsId,
      url: this.url,
      event: eventName,
      luid: uid,
      lts: getLoadTime(),
      its: this.initTime,
      _ga: Cookies.get('_ga') || null, // ga detect user 2 years
      _gid: Cookies.get('_gid') || null, // ga detect user 24 hours
      lVersion: 2, // lexipolid version of data
    };

    if (this.dataLayer) {
      _.defaults(data, this.dataLayer);
    }
    return data;
  }
}

const storage = new Storage();

function sendEvent(extraArgs) {
  const parsedApiUrl = urlParse(ConfigStore.get('apiUrl'));
  logger.debug('OSTAT: sending event', extraArgs);
  const endpoint = `${parsedApiUrl.netloc}/collect_data/`;

  const data = {};
  Object.keys(extraArgs).forEach((key) => {
    const value = extraArgs[key];
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i += 1) {
        data[`${key}[${i}]`] = value[i];
      }
    } else {
      data[key] = value === null ? '' : value;
    }
  });
  CubApi.post(endpoint, { data });
}

function getLyticsId() {
  if (window.jstag && window.jstag.getid) {
    return window.jstag.getid();
  }
  return Cookies.get(LYTICS_COOKIE_NAME) || null;
}

function getDataLayer() {
  if (window.dataLayer && Array.isArray(window.dataLayer)) {
    for (let i = 0; i < window.dataLayer.length; i += 1) {
      const item = window.dataLayer[i];
      if (item.productCategory) {
        return item;
      }
    }

    // pc not found.
    return window.dataLayer[0];
  }
  return null;
}

function init() {
  const eventName = 'lid-pv'; // lexipol id sendEvent
  try {
    // url
    storage.setLyticsId(getLyticsId());
    storage.setDataLayer(getDataLayer());
    storage.setUrl(window.location.href);
    storage.attempts += 1;

    if (storage.attempts > ATTEMPTS_TO_LOAD_DATA) {
      // @todo: generate custom id, set track cookie
      logger.debug('OSTAT: Failed to load data to send page view');
      sendEvent(storage.dataToSend(eventName));
      storage.onDataSent();
      return;
    }

    if (!storage.readyToSend()) {
      setTimeout(init, 333);
      logger.debug('OSTAT: Data still not prepared', storage.readyToSend());
      return;
    }

    if (storage.needToSend()) {
      sendEvent(storage.dataToSend(eventName));
      storage.onDataSent();
    } else {
      logger.debug('OSTAT: No need to send event');
    }
  } catch (err) {
    throwSafely(err);
  }
}

function handleLogIn(response) {
  try {
    const data = storage.dataToSend('lid-login');
    data.luid = response.id || '';
    sendEvent(data);
  } catch (err) {
    throwSafely(err);
  }
}

export default {
  init,
  handleLogIn,
  Storage,
};
