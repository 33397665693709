import React from 'react';
import FormListener from '../FormListener';
import Password from '../fields/Password';
import UserActions from '../../actions/UserActions';
import { CHANGE_PASSWORD } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';

class ChangePasswordForm extends FormListener {
  constructor(props) {
    super(props);
    this.formId = CHANGE_PASSWORD;
    this.skipClearingFormErrorFor.add('new_password');
    this.state = {
      password: '',
      new_password: '',

      triedSubmitting: false,
    };
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.refs.password.focus();
  }

  submit() {
    UserActions.changePassword({
      password: this.state.password,
      new_password: this.state.new_password,
    }, this.formId);
    this.setState({ triedSubmitting: true });
  }

  render() {
    return (
      <Form
        classNameModifier="cub-Form--changePassword"
        caption="Change password"
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <Password
          ref="password"
          label="Current password"
          required
          value={this.state.password}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('password')}
        />

        <Password
          classNameModifier="cub-FormControl--newPassword"
          label="New password"
          name="new_password"
          required
          value={this.state.new_password}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('new_password')}
          showRequirements={!this.state.triedSubmitting}
        />

        <Button
          type="submit"
          isProcessing={this.state.isProcessing}
          text="Change password"
        />
      </Form>
    );
  }
}

export default ChangePasswordForm;
