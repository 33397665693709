import AppDispatcher from '../dispatcher/AppDispatcher';
import AppStore from './AppStore';
import createStore from '../mixins/createStore';
import {
  ORDER_INVOICE_AUTOLOGIN_FAILED,
  REGISTER_FOR_INVOICE_FAILED,
  URL_CHANGED,
} from '../constants/ActionTypes';

function noInvoiceToken() {
  return {
    token: null,
    status: null, // null - not loaded yet, false - invalid, true - valid
    autoLoginToken: null,
    autoLoginStatus: null,
    registrationError: null,
    suggestLoginWhenError: false,
  };
}

let invoiceToken = noInvoiceToken();

const OrderInvoiceStore = createStore({
  token() {
    return invoiceToken.token;
  },

  status() {
    return invoiceToken.status;
  },

  autoLoginToken() {
    return invoiceToken.autoLoginToken;
  },

  autoLoginTokenStatus() {
    return invoiceToken.autoLoginStatus;
  },

  isLoading() {
    return this.token() === null || this.status();
  },

  registrationError() {
    return invoiceToken.registrationError;
  },

});

function setRegistrationError(response) {
  const params = (response.error && response.error.params) || {};
  const err = {
    msg: '',
    suggestLogin: false,
  };
  if (params.email && params.email.indexOf('already')) {
    err.msg = params.email;
    err.suggestLogin = true;
  }
  invoiceToken.registrationError = err;
}

OrderInvoiceStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;

  AppDispatcher.waitFor([AppStore.dispatchToken]);

  switch (action.actionType) {
    case URL_CHANGED: {
      const route = action.route;
      const args = action.args;
      const routes = AppStore.routes();
      if (route === routes.ORDER_INVOICE) {
        const tokenParts = args[1].split('_');
        invoiceToken = noInvoiceToken();
        invoiceToken.token = tokenParts[0];
        invoiceToken.loaded = true;
        if (tokenParts.length > 1) {
          invoiceToken.autoLoginToken = tokenParts[1];
        }
      } else if (invoiceToken.token) {
        invoiceToken = noInvoiceToken();
      }
      OrderInvoiceStore.emitChange();
      break;
    }

    case ORDER_INVOICE_AUTOLOGIN_FAILED:
      if (invoiceToken.token) {
        invoiceToken.autoLoginStatus = false;
        OrderInvoiceStore.emitChange();
      }
      break;
    case REGISTER_FOR_INVOICE_FAILED:
      setRegistrationError(action.response);
      OrderInvoiceStore.emitChange();
      break;
    default:
      break;
  }
  return true;
});

export default OrderInvoiceStore;
