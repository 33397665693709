import PropTypes from 'prop-types';
import React from 'react';

export default class Year extends React.Component {
  render() {
    return (
      <input
        className={'cub-FormControl cub-FormControl--input' +
          ` ${this.props.classNameModifier}`}
        type="text"
        name={this.props.name}
        maxLength={4}
        placeholder="Year"
        value={this.props.value}
        onChange={this.props.onChange}
      />
    );
  }
}

Year.propTypes = {
  classNameModifier: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

Year.defaultProps = {
  classNameModifier: 'cub-FormControl--year',
};
