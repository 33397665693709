import PropTypes from 'prop-types';
import React from 'react';
import CaptionLarge from '../basic/CaptionLarge';
import Text from '../basic/Text';
import NavLink from '../basic/NavLink';

export default class EmailChangeDone extends React.Component {
  emailConfirmation() {
    return (
      <div className="cub-InfoBlock cub-InfoBlock--emailChanged isSuccess">
        <CaptionLarge>Email confirmation</CaptionLarge>
        <Text>
          Email confirmation link was sent to{' '}
          <span className="cub-Text-dataValue">
            {this.props.user.get('email')}
          </span>
        </Text>
        <Text>
          Please check your mailbox and click email confirmation link
          to complete registered email change.
        </Text>
        <NavLink
          className="cub-Button"
          route={this.props.routes.USER_PROFILE}
        >
          <span className="cub-Button-icon" />
          OK
        </NavLink>
      </div>
    );
  }

  emailSuccess() {
    const text = !this.props.user.get('email') ? (
      <Text>
        Email unset successfully
      </Text>
    ) : (
      <Text>
        Email changed successfully to{' '}
        <span className="cub-Text-dataValue">
          {this.props.user.get('email')}
        </span>
      </Text>
    );
    return (
      <div className="cub-InfoBlock cub-InfoBlock--emailChanged isSuccess">
        <CaptionLarge>Email changed successfully</CaptionLarge>
        {text}
        <NavLink
          className="cub-Button"
          route={this.props.routes.USER_PROFILE}
        >
          <span className="cub-Button-icon" />
          OK
        </NavLink>
      </div>
    );
  }

  render() {
    const userAttrs = this.props.user.attributes;
    if (userAttrs.email_confirmed || !userAttrs.email) {
      return this.emailSuccess();
    }
    return this.emailConfirmation();
  }
}

EmailChangeDone.propTypes = {
  user: PropTypes.object,
  routes: PropTypes.object,
};
