import React from 'react';
import PropTypes from 'prop-types';
import { RESEND_TOKEN_FORM_ID } from '../../constants/FormIds';
import createForm from '../../mixins/createForm';
import DataStore from '../../stores/DataStore';

import Text from '../basic/Text';
import Button from '../basic/Button';
import Error from '../basic/Error';

import OrderActions from '../../actions/OrderActions';

const OrderInvoiceCreateAccount = createForm(RESEND_TOKEN_FORM_ID, {
  getInitialState() {
    return {
      creatingAccount: false,
      resend: false,
      cooldown: null,
      continuePressed: false,
    };
  },

  storesSuccess(formId) {
    if (formId === RESEND_TOKEN_FORM_ID) {
      this.setState({ resend: true });
    }
  },

  getErrorMessage() {
    const result = {
      msg: '',
      help: '',
    };
    if (this.props.registrationError) {
      if (this.props.registrationError.msg) {
        result.msg = this.props.registrationError.msg;
      } else {
        result.msg = 'Failed to create account, please contact our team';
      }
      return result;
    }
    if (this.state.errors && this.state.errors._errors) {
      result.msg = 'An error occurred while sending a new payment link.';
      result.help = (
        <Text>
          If you have any questions, please contact
          our {this.props.supportTeamLink} for additional help.
        </Text>
      );
      return result;
    }

    const errors = this.props.formErrors._errors; // @todo: mute eslint err

    if (!errors) {
      return result;
    }

    if (errors.status) { // order status validate failed.
      result.msg = errors.status;
      result.help = (
        <Text>
          If you have any questions, please contact
          our {this.props.supportTeamLink} for additional help.
        </Text>
      );
      return result;
    }

    result.msg = 'The link to this invoice has expired';
    result.help = ( // @todo: Send invoice component
      <>
        <Text>
          We{'\''}re sorry, but the link to this invoice has expired. You can
          request a new payment link.
        </Text>
        <Button
          text="Send me a new payment link"
          onClick={this.reSendInvoiceLink}
          disabled={this.state.isProcessing}
        />
      </>
    );

    return result;
  },

  reSendInvoiceLink() {
    OrderActions.reSendInvoiceLink(this.props.orderId);
  },

  onAccountCreated() {
    this.setState({ cooldown: 9 });
    this.timer = setInterval(
      () => {
        const cd = this.state.cooldown;
        if (cd <= 0) {
          clearInterval(this.timer);
          this.continuePayment();
        } else {
          this.setState({ cooldown: cd - 1 });
        }
      },
      1000,
    );
  },

  createAccount() {
    OrderActions.registerForAcceptToken(
      this.props.orderId,
      this.props.invoiceToken,
      this.onAccountCreated,
    );
    this.setState({ creatingAccount: true });
  },
  continuePayment() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.setState({ continuePressed: true });
    OrderActions.acceptInvoice(this.props.orderId, this.props.invoiceToken);
  },
  render() {
    if (this.state.cooldown !== null) {
      const site = DataStore.currentSite();
      return (
        <>
          <Text>Your account for the site {' '}
            {site.get('friendly_name')} was created. We{'\''}ve sent you an
            email with your account details, which you can set up after
            completing payment.
          </Text>
          <Text>
            You will be automatically redirected to the payment page after{' '}
            (<strong>{this.state.cooldown}</strong>) seconds.
          </Text>
          <Button
            classNameBase="cub-Button"
            onClick={this.continuePayment}
            disabled={this.state.continuePressed}
            isProcessing={this.state.continuePressed}
            text="Continue to payment"
          />
        </>
      );
    }

    if (this.state.resend) {
      return (
        <Text>
          A new payment link was successfully sent to your email address,
          please check your email inbox to complete the payment.
        </Text>
      );
    }

    if (this.props.invoiceTokenStatus === false) { // accept token failed
      return (
        <Text>
          We{'\''}re sorry, but the link to this invoice has expired.
          You can ask the person who created the order to send the
          invoice again or contact our {this.props.supportTeamLink}.
        </Text>
      );
    }
    const errObj = this.getErrorMessage();
    const suggestLogin = (
      this.props.registrationError &&
      this.props.registrationError.suggestLogin
    ) || false;

    if (errObj.msg) {
      return (
        <>
          <Error>{errObj.msg}</Error>
          {suggestLogin && (
          <Text>If you{'\''}re the account owner of this account you can{' '}
            request a new payment link or you can contact our{' '}
            {this.props.supportTeamLink} for additional help.
          </Text>
          )}
          {(!suggestLogin && errObj.help) || ''}
        </>
      );
    }

    return (
      <>
        <Text>You must create an account to complete payment.</Text>
        <Button
          classNameBase="cub-Button"
          onClick={this.createAccount}
          disabled={this.state.creatingAccount}
          isProcessing={this.state.creatingAccount}
          text="Create account and continue"
        />
      </>
    );
  },
});

OrderInvoiceCreateAccount.propTypes = {
  registrationError: PropTypes.object,
  supportTeamLink: PropTypes.object,
  loginUrl: PropTypes.string,
  orderId: PropTypes.string,
  invoceToken: PropTypes.string,
  invoiceToken: PropTypes.string,
  invoiceTokenStatus: PropTypes.bool,
  formErrors: PropTypes.object,
};

export default OrderInvoiceCreateAccount;
