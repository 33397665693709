import _ from 'underscore';
import AppDispatcher from '../dispatcher/AppDispatcher';
import UserActions from '../actions/UserActions';
import createStore from '../mixins/createStore';
import router from '../router/router';
import ConfigStore from './ConfigStore';
import DataStore from './DataStore';
import {
  GOT_XSSSO_GROUP,
  APP_INITIALIZED,
  EMAIL_CONFIRMATION_SUCCESS,
  IMPERSONATE,
  PASSWORD_RESET_TOKEN_CHECKED,
  USER_LOAD_ERROR,
  USER_LOGOUT,
  USER_SET_CURRENT_ORGANIZATION,
  USER_SELECT_TRUSTING_ORG,
  GOT_XDOMAIN_COOKIES,
  USER_UPDATED,
  MEMBER_DELETED,
} from '../constants/ActionTypes';
import {
  storageSupported,
  putToLocalStorage,
  getFromLocalStorage,
} from '../storage/storage';
import * as Cookies from '../utils/Cookies';
import {
  cookieOptions,
  hasOwnProperty,
  logger,
  siteSentry,
  getDomainRoot,
  jwtDecodeLogged,
} from '../utils/Utils';

const ORGANIZATION_ID_COOKIE_ID = 'organizationIdCookie';
const USER_TOKEN_COOKIE_ID = 'userTokenCookie';
const USER_TOKEN_OVERRIDE_XSSSO = 'cubUserTokenOverrideXSSSO';
const USER_TOKEN_ORIGIN_IDP = 'cubUserTokenOriginIdP';

function anonymousUserData() {
  return { id: null, token: null };
}

let loading = false;
let user = anonymousUserData();
let selectedOrganizationId = null;
let selectedTrustingOrganizationId = null;

const debouncedPageReload = _.debounce(() => { router.pageReload(); }, 10);

function onCookieChange() {
  if (ConfigStore.get('cookiePageReload')) {
    debouncedPageReload();
  }
}

function getCookie(cookieId) {
  return Cookies.get(ConfigStore.get(cookieId));
}

function tokenIsExpired(token) {
  const decodedToken = jwtDecodeLogged(token);
  // cast UNIX timestamp in seconds to absolute datetime
  const expireAt = new Date(decodedToken.exp * 1000);
  const now = new Date();
  return expireAt < now;
}

function tokenCookieExpires(token) {
  const decodedToken = jwtDecodeLogged(token);
  try {
    // cast UNIX timestamp in seconds to absolute datetime
    const expireAt = new Date(decodedToken.exp * 1000);
    return getFromLocalStorage('keepSignedIn') ? expireAt : 0;
  } catch (e) {
    // I guess this is the safest way
    return 0;
  }
}

function setCookie(cookieId, value, silent, extra = {}) {
  const previousCookie = getCookie(cookieId);
  Cookies.set(ConfigStore.get(cookieId), value, cookieOptions(extra));
  if (!silent && previousCookie != value) { // eslint-disable-line eqeqeq
    logger.debug('setCookie: calling onCookieChange ', {
      cookieId, previousCookie, value, silent,
    });
    onCookieChange();
  }
}

function setTokenCookie(token, silent, overrideXSSSO) {
  const expires = tokenCookieExpires(token);
  setCookie(
    USER_TOKEN_COOKIE_ID,
    token,
    silent,
    { expires },
  );
  Cookies.set(
    USER_TOKEN_OVERRIDE_XSSSO,
    overrideXSSSO ? true : '',
    cookieOptions({ expires: 10 }), // expires in 10 secs
  );
}

function clearCookieByName(cookieName) {
  const options = cookieOptions();
  // try really hard to remove cookie
  Cookies.expire(cookieName);
  Cookies.expire(cookieName, { domain: `.${document.location.hostname}` });
  Cookies.expire(cookieName, options);
  if (options.domain && options.domain[0] !== '.') {
    options.domain = `.${options.domain}`;
    Cookies.expire(cookieName, options);
  }
}

function clearCookies(cookieIds, silent) {
  let shouldHandleChange = false;
  cookieIds.forEach((cookieId) => {
    const previousCookie = getCookie(cookieId);
    const cookieName = ConfigStore.get(cookieId);
    clearCookieByName(cookieName);
    if (!silent && previousCookie) {
      logger.debug('clearCookie: calling onCookieChange ', {
        cookieId, previousCookie, silent,
      });
      shouldHandleChange = true;
    }
  });
  if (shouldHandleChange) {
    onCookieChange();
  }
}

function setUserToken(token) {
  user.token = token;
  try {
    user.id = jwtDecodeLogged(user.token).user;
  } catch (e) {
    // no-op
  }
}

function setOrganizationId(organizationId) {
  selectedOrganizationId = organizationId;
  siteSentry.addBreadcrumb({
    message: 'Organization ID set from Cookie',
    category: 'cub.log',
    data: { selectedOrganizationId },
  });
}

function reloadUser() {
  if (user.token && !loading) {
    if (router.routes.HOME) { // is url conf already loaded?
      loading = true;
      UserActions.getUser(user.token);
    } else {
      // waiting for url conf to be loaded
      window.setTimeout(reloadUser, 100);
    }
  }
}

const SingleSO = {
  active: false,
  xsSSOGroupId: null,
  setup(xsSSOGroup) {
    this.xsSSOGroupId = xsSSOGroup.id;
    logger.debug('SingleSO: was setup', xsSSOGroup);
  },
  activate() {
    const prev = this.active;
    this.active = true;
    if (this.active && !prev) { // if changed from disabled to active
      logger.debug('SingleSO: was activated');
      UserActions.getXCookies();
    }
  },
};

function getXCookieDomain() {
  const iframeDomainRoot = getDomainRoot(ConfigStore.get('apiUrl'));
  // '.' in front is important, to make cookie avaiable on
  // lexipol.com domain and it's sub-domains
  return `.${iframeDomainRoot}`;
}

function parseXDomainCookies(cookieString) {
  const xCookies = Cookies.parse(cookieString);
  logger.debug('SingleSO: parsing xdomain cookies', xCookies);

  const xsSSOGroups = _.reduce(_.pairs(xCookies), (dict, pair) => {
    const [name, val] = pair;
    const groupKey = _.last(name.split('lidSSO.'));
    if (groupKey.indexOf('xsg_') !== 0) return dict;
    try {
      const [groupId, key] = groupKey.split('.');
      dict[groupId] = dict[groupId] || {};
      dict[groupId][key] = val;
      return dict;
    } catch (err) {
      siteSentry.captureException(err);
      return dict;
    }
  }, {});
  logger.debug('SingleSO: found xsSSOGroups', xsSSOGroups);

  return xsSSOGroups;
}

function saveXDomainCookies(cookies, done) {
  const xsSSOGroupId = SingleSO.xsSSOGroupId;
  const tokenKey = `lidSSO.${xsSSOGroupId}.userToken`;
  const originIdPKey = `lidSSO.${xsSSOGroupId}.originIdP`;
  const keepSignedInKey = `lidSSO.${xsSSOGroupId}.keepSignedIn`;
  const initializedKey = `lidSSO.${xsSSOGroupId}.initialized`;

  const toSave = [];

  const { token, keepSignedIn, originIdP } = cookies;
  const expires = token ? tokenCookieExpires(token) : new Date(0);
  try {
    const tokenCookie = Cookies.stringify(tokenKey, token, cookieOptions({
      expires, domain: getXCookieDomain(), samesite: 'None', secure: true,
    }));
    toSave.push(tokenCookie);
  } catch (err) {
    siteSentry.captureException(err);
  }

  const keepSignedInCookie = Cookies.stringify(
    keepSignedInKey,
    keepSignedIn,
    cookieOptions({
      expires, domain: getXCookieDomain(), samesite: 'None', secure: true,
    }),
  );
  toSave.push(keepSignedInCookie);

  const initializedCookie = Cookies.stringify(
    initializedKey,
    true,
    cookieOptions({
      expires: Infinity,
      domain: getXCookieDomain(),
      samesite: 'None',
      secure: true,
    }),
  );
  toSave.push(initializedCookie);

  const originIdPCookie = Cookies.stringify(
    originIdPKey,
    originIdP,
    cookieOptions({
      expires, domain: getXCookieDomain(), samesite: 'None', secure: true,
    }),
  );
  toSave.push(originIdPCookie);

  if (!toSave.length) {
    done();
    return;
  }
  UserActions.setXCookies(toSave, done);
}

function saveCurrentCookiesToXDomain() {
  const done = () => clearCookieByName(USER_TOKEN_OVERRIDE_XSSSO);
  // save xCookies to iframe only if SingleSO fully activated
  if (!SingleSO.active) {
    done();
    return;
  }

  let currentKeepSignedIn;
  if (storageSupported('localStorage')) {
    currentKeepSignedIn = getFromLocalStorage('keepSignedIn');
  }

  const originIdP = Cookies.get(USER_TOKEN_ORIGIN_IDP);

  saveXDomainCookies({
    token: user.token,
    keepSignedIn: currentKeepSignedIn,
    originIdP,
  }, done);
}

function clearXDomainCookies(done) {
  // delete xCookies from iframe even if SinleSO is not yet fully active
  // but already configured to make sure that xCookies are deleted on logout
  if (!SingleSO.xsSSOGroupId) {
    done();
    return;
  }

  saveXDomainCookies({
    token: '',
    keepSignedIn: '',
    originIdP: '',
  }, done);
}

function logout(silent, nextUrl) {
  let redirectTo = nextUrl;

  const site = DataStore.currentSite();
  if (site) {
    const originIdP = Cookies.get(USER_TOKEN_ORIGIN_IDP);
    const idpConfig = site.getIdPConfigByUid(originIdP);
    if (idpConfig && idpConfig.end_session_endpoint) {
      redirectTo = idpConfig.end_session_endpoint;
    }
  }

  clearXDomainCookies(() => {
    if (redirectTo) router.navigate(redirectTo);
    clearCookieByName(USER_TOKEN_ORIGIN_IDP);
    clearCookieByName(USER_TOKEN_OVERRIDE_XSSSO);
    clearCookies([USER_TOKEN_COOKIE_ID, ORGANIZATION_ID_COOKIE_ID], silent);
  });
  user = anonymousUserData();
  loading = false;
  selectedTrustingOrganizationId = null;
}

function updateUserByXDomainCookies(cookieString) {
  const currentGroupId = SingleSO.xsSSOGroupId;
  const xGroups = parseXDomainCookies(cookieString);
  const currentGroup = xGroups[currentGroupId];

  const overrideXSSSO = Cookies.get(USER_TOKEN_OVERRIDE_XSSSO) || false;
  // if xsSSOGroup was not present in iframe - this is new group
  if (overrideXSSSO || !currentGroup) {
    // we should pre-populate iframe with this group if user currently signed-in
    if (!user.token) return false;
    if (tokenIsExpired(user.token)) return false;

    saveCurrentCookiesToXDomain();
    return false;
  }

  if (router.isCurrentRoute(router.routes.TOKEN_LOGIN)) return false;
  if (router.isCurrentRoute(router.routes.ORDER_INVOICE)) return false;
  if (router.isCurrentRoute(router.routes.IMPERSONATE)) return false;

  const xtoken = currentGroup.userToken;
  if (!xtoken) {
    // there was already a logout from xsSSOGroup
    // clear cookie to make sure user won't be redirected again to IdP
    clearCookieByName(USER_TOKEN_ORIGIN_IDP);
    logout();
    return false;
  }

  let decodedXToken;
  try {
    decodedXToken = jwtDecodeLogged(xtoken);
  } catch (err) {
    siteSentry.captureException(err);
    logout();
    return false;
  }

  // if current token fresher than xtoken from iframe - update iframe xtoken
  if (user.token && (jwtDecodeLogged(user.token).exp > decodedXToken.exp)) {
    logger.debug('SingleSO: current token fresher than xtoken');
    // save newer token to iframe
    saveCurrentCookiesToXDomain();
    return false;
  }

  if (decodedXToken.user === user.id) return false;

  logger.debug('SingleSO: found fresher token with new user_id');
  logger.debug('SingleSO: sign in user to current site by token');
  // we must get token for current site app
  const apiKey = ConfigStore.get('apiKey');
  const timeBeforeSend = new Date().getTime() / 1000;
  UserActions.loginByTokenToApp(
    xtoken,
    apiKey,
    currentGroup.originIdP,
    (response, meta, method, url, options) => {
      if (decodedXToken.exp < timeBeforeSend) {
        // Token expired, no need to capture the error in Sentry.
        return;
      }
      siteSentry.captureMessage(
        'Failed to login user using token from another app.', {
          level: 'error',
          extra: {
            response, meta, method, url, options,
          },
        },
      );
    },
  );
  if (storageSupported('localStorage')) {
    putToLocalStorage({ keepSignedIn: currentGroup.keepSignedIn });
  }

  return true;
}

function updateUser(
  userData, silent, passwordChangeRequired, overrideXSSSO, originIdP,
) {
  const previousId = user.id;
  const previousToken = user.token;
  user = userData;
  loading = false;
  if (previousId === user.id && !user.token && previousToken) {
    user.token = previousToken;
  }
  if (user.token) {
    if (originIdP) {
      const expires = tokenCookieExpires(user.token);
      Cookies.set(
        USER_TOKEN_ORIGIN_IDP,
        originIdP,
        cookieOptions({ expires }),
      );
    }

    const tokenDiffers = (previousToken !== user.token);
    if (tokenDiffers) {
      // save crossdomain cookies before local cookies because
      // local cookie setting will trigger reload and
      // reload can happen before iframe will get message
      // with crossdomain cookies (at least during debugging)
      saveCurrentCookiesToXDomain();
    }

    if (!getCookie(USER_TOKEN_COOKIE_ID) || tokenDiffers) {
      setTokenCookie(user.token, silent, overrideXSSSO);
    }
  }
  siteSentry.setUser({
    id: user.id,
    username: user.username,
    email: user.email,
  });
  const emptyEmail = hasOwnProperty(user, 'email') && !user.email;
  const emailSetRequired = user.email_set_required === true;
  if (emptyEmail && emailSetRequired && !passwordChangeRequired) {
    router.navigate(router.routes.SET_EMAIL);
  }
}

const UserStore = createStore({
  init() {
    const token = getCookie(USER_TOKEN_COOKIE_ID);
    if (!token) return;

    if (tokenIsExpired(token)) {
      logout(true);
      return;
    }

    setUserToken(token);
    setOrganizationId(getCookie(ORGANIZATION_ID_COOKIE_ID));
  },

  getUserIdFromCookie() {
    const token = getCookie(USER_TOKEN_COOKIE_ID);
    if (!token) {
      return null;
    }

    try {
      return jwtDecodeLogged(token).user;
    } catch (e) {
      // do nothing
    }

    return null;
  },

  refreshUser() {
    if (user.token) {
      reloadUser();
    } else {
      logout();
    }
  },

  loading() {
    return loading;
  },

  userData() {
    return user;
  },

  membership() {
    return ((user && user.membership) || []).filter((m) => m.is_active);
  },

  isActiveMember(org) {
    if (!user || !org) {
      return false;
    }
    const orgId = _.isObject(org) && org.id ? org.id : org;
    const membership = this.membership();
    let memberOrgId;
    for (let l = membership.length - 1; l >= 0; l--) {
      const member = membership[l];
      memberOrgId = member.organization;
      if (_.isObject(memberOrgId) && memberOrgId.id) {
        memberOrgId = memberOrgId.id;
      }
      if (memberOrgId === orgId) {
        return true;
      }
    }
    return false;
  },

  selectedMember() {
    if (!selectedOrganizationId) {
      return null;
    }
    return _.find(this.membership(), (m) => {
      let org = m.organization;
      if (_.isObject(org) && org.id) {
        org = org.id;
      }
      return org === selectedOrganizationId;
    });
  },

  token(logoutOnExpiredToken = false) {
    const token = user.token;
    if (token && logoutOnExpiredToken && tokenIsExpired(token)) {
      logout();
      return null;
    }
    return token;
  },

  tokenCookieExpires() {
    return tokenCookieExpires(user.token);
  },

  currentUserId() {
    return user.id;
  },

  currentMemberId() {
    const selectedMember = this.selectedMember();
    return selectedMember && selectedMember.id;
  },

  selectedTrustingOrgId() {
    return selectedTrustingOrganizationId;
  },
});

/**
 * Check if user is a member of given organization, select it as current
 * organization and set cookie. Fallback to first organization from user
 * membership.
 *
 * @param {object} organization - organization object or organization ID
 * @param {boolean} silent - don't trigger page reload on cookie change
 * @return {string} - organization ID which was set, or null
 */
function setCurrentOrganization(organization, silent) {
  let org = organization;
  if (!UserStore.isActiveMember(org)) {
    const membership = UserStore.membership();
    org = membership.length > 0 ? membership[0].organization : null;
  }
  selectedOrganizationId = (_.isObject(org) && org.id ? org.id : org) || null;
  if (selectedOrganizationId) {
    setCookie(
      ORGANIZATION_ID_COOKIE_ID,
      selectedOrganizationId,
      silent,
      { expires: Infinity },
    );
    siteSentry.addBreadcrumb({
      message: 'Organization ID Cookie set',
      category: 'cub.log',
      data: { selectedOrganizationId },
    });
  } else {
    clearCookies([ORGANIZATION_ID_COOKIE_ID], silent);
    siteSentry.addBreadcrumb({
      message: 'Organization ID Cookie cleared',
      category: 'cub.log',
      data: { selectedOrganizationId },
    });
  }
  return selectedOrganizationId;
}

function clearOrganization(organization, silent) {
  const org = organization;
  const oldOrgId = getCookie(ORGANIZATION_ID_COOKIE_ID);
  const orgId = (_.isObject(org) && org.id ? org.id : org) || null;
  if (oldOrgId === orgId) {
    clearCookies([ORGANIZATION_ID_COOKIE_ID], silent);
  }
}

function selectTrustingOrganization(organization) {
  const o = organization;
  selectedTrustingOrganizationId = (_.isObject(o) && o.id ? o.id : o) || null;
  return selectedTrustingOrganizationId;
}

UserStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;

  switch (action.actionType) {
    case IMPERSONATE:
    case USER_UPDATED:
    case EMAIL_CONFIRMATION_SUCCESS: {
      let overrideXSSSO;
      let originIdP;
      if (action.actionType === USER_UPDATED) {
        overrideXSSSO = action.overrideXSSSO;
        originIdP = action.originIdP;
      }
      if (action.actionType === IMPERSONATE) {
        overrideXSSSO = true;
      }
      const passwordChangeRequired = false;
      updateUser(
        action.response,
        action.silent,
        passwordChangeRequired,
        overrideXSSSO,
        originIdP,
      );
      setCurrentOrganization(selectedOrganizationId, action.silent);
      break;
    }

    case PASSWORD_RESET_TOKEN_CHECKED: {
      logout(true);
      const silent = true;
      const passwordChangeRequired = true;
      updateUser(action.response, silent, passwordChangeRequired);
      setCurrentOrganization(selectedOrganizationId, silent);
      break;
    }

    case USER_SET_CURRENT_ORGANIZATION:
      setCurrentOrganization(action.organization);
      selectedTrustingOrganizationId = null;
      break;

    case USER_SELECT_TRUSTING_ORG:
      selectTrustingOrganization(action.organization);
      break;

    case USER_LOAD_ERROR:
      loading = false;
      if (action.meta.code === 401) { // bad token
        logout();
      } else if (UserStore.token()) {
        window.setTimeout(reloadUser, 10000); // retry in 10 sec
      }
      break;

    case USER_LOGOUT:
      logout(action.silent, action.nextUrl);
      break;

    case APP_INITIALIZED: {
      const site = action.site;

      if (SingleSO.xsSSOGroupId && site.loginEnabled()) {
        SingleSO.activate();
      }
      break;
    }

    case GOT_XSSSO_GROUP: {
      SingleSO.setup(action.xsSSOGroup);
      break;
    }

    case GOT_XDOMAIN_COOKIES: {
      updateUserByXDomainCookies(action.value);
      break;
    }

    case MEMBER_DELETED:
      clearOrganization(action.instance.get('organization'));
      break;

    default:
      return true;
  }

  UserStore.emitChange();
  return true;
});

export default UserStore;
