import PropTypes from 'prop-types';
import React from 'react';
import DataStore from '../../stores/DataStore';
import FormActions from '../../actions/FormActions';
import FormStore from '../../stores/FormStore';
import Email from '../fields/Email';
import FirstName from '../fields/FirstName';
import LabeledCheckbox from '../fields/LabeledCheckbox';
import LastName from '../fields/LastName';
import NavLink from '../basic/NavLink';
import Password from '../fields/Password';
import UserActions from '../../actions/UserActions';
import { parseQueryString, setQueryString } from '../../utils/Utils';
import { REGISTER, SSO_SIGN_IN } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';
import Text from '../basic/Text';
import ExternalLogin from '../fields/ExternalLogin';
import FormListener from '../FormListener';
import Phone from '../fields/Phone';
import { Site } from '../../stores/DataModels';

class RegistrationForm extends FormListener {
  constructor(props) {
    super(props);
    this.formId = REGISTER;
    this.skipClearingFormErrorFor.add('password');
    this.stores.push(DataStore);

    this.state = {
      ssoError: '',
      first_name: '',
      last_name: '',
      gender: '',
      email: '',
      phone: '',
      password: '',
      subscribe: '',
      lead_checkbox: '',
      lead_form: null,

      generateLead: true,
      triedSubmitting: false,
    };
    this.onLeadToggle = this.onLeadToggle.bind(this);
    this.submit = this.submit.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  formStateFromStores(formState) {
    const site = DataStore.currentSite();
    const mailingLists = site.related.sitemailinglist
      .filter((sml) => sml.get('subscribe_at_registration'))
      .map((sml) => sml.get('mailinglist').id);
    return {
      subscribe: mailingLists.join(','),
      lead_checkbox: site.get('lead_checkbox'),
      lead_form: site.get('lead_form'),
      ssoError: FormStore.errors(SSO_SIGN_IN).topError(),
    };
  }

  componentDidMount() {
    this.refs.first_name.focus();
  }

  onLeadToggle() {
    this.setState({ generateLead: !this.state.generateLead });
  }

  onFieldChange(name, value) {
    super.onFieldChange(name, value);
    if (this.state.ssoError) {
      FormActions.resetForm(SSO_SIGN_IN);
    }
  }

  submit() {
    // preserve query string while moving between registration steps
    let nextUrl;
    if (this.props.planId) {
      nextUrl = this.props.routes.USER_BILLING_PLAN.makeUrl(this.props.planId);
    } else {
      nextUrl = this.props.routes.EXPERIENCE.url;
    }
    nextUrl = setQueryString(nextUrl, parseQueryString());
    let leadForm = this.state.lead_form || '';
    if (!this.state.generateLead) {
      leadForm = '';
    }
    UserActions.register({
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      gender: this.state.gender,
      email: this.state.email,
      password: this.state.password,
      phone: this.state.phone,
      subscribe: this.state.subscribe,
      lead_form: leadForm,
    }, this.formId, nextUrl);
    if (this.state.ssoError) {
      FormActions.resetForm(SSO_SIGN_IN);
    }
    this.setState({ triedSubmitting: true });
  }

  render() {
    const routes = this.props.routes;
    const site = DataStore.currentSite();
    const siteName = site.get('friendly_name');
    const siteBrand = site.get('brand') || siteName;
    const siteTags = site.get('tags');
    const showPhoneField = (
      site.get('user_phone_field') !== Site.USER_PHONE_DISABLED
    );
    const phoneRequired = (
      site.get('user_phone_field') === Site.USER_PHONE_REQUIRED
    );
    const hideSsoButtons = site.get('hide_sso_buttons');
    let topMsgBlock = this.state.errors.topError();
    let emailError = this.state.errors.fieldError('email');

    let signInUrl = routes.LOGIN.url;
    let next;
    if (this.props.planId) {
      next = routes.USER_BILLING_PLAN.makeUrl(this.props.planId);
    } else {
      next = routes.USER_BILLING.url;
    }
    signInUrl = setQueryString(signInUrl, { next });

    const signInLink = (<NavLink href={signInUrl}>Sign In</NavLink>);

    const supportUrl = routes.SUPPORT.url;
    const supportLink = (
      <NavLink href={supportUrl}>Contact {siteName} now.</NavLink>
    );

    let leadCheckbox = null;
    if (this.state.lead_checkbox && this.state.lead_form) {
      leadCheckbox = (
        <ul className="cub-List cub-List--checklist">
          <li className="cub-List-item" key="lead">
            <LabeledCheckbox
              name="generateLead"
              label={this.state.lead_checkbox}
              checked={this.state.generateLead}
              onChange={this.onLeadToggle}
            />
          </li>
        </ul>
      );
    }

    return (
      <>
        <Form
          classNameModifier="cub-Form--registration"
          caption={
            this.props.planId ?
              'Register for an Individual Subscription' : 'Register'
          }
          error={topMsgBlock || this.state.ssoError}
          onSubmit={this.submit}
          isProcessing={this.state.isProcessing}
          disableWhenProcessing
        >
          {this.props.planId && (
            <Text classNameModifier="cub-Text--alreadyMember">
              New to {siteBrand}? Create a FREE account. Already a member?
              {' '}{signInLink} to complete your purchase for an individual
              subscription and start using {siteName} right away!
            </Text>
          )}

          {!hideSsoButtons && (
            <ExternalLogin text="Join with" />
          )}

          <FirstName
            ref="first_name"
            value={this.state.first_name}
            required
            onChange={this.onInputChange}
            error={this.state.errors.fieldError('first_name')}
          />

          <LastName
            value={this.state.last_name}
            required
            onChange={this.onInputChange}
            error={this.state.errors.fieldError('last_name')}
          />

          {showPhoneField && (
            <Phone
              value={this.state.phone}
              required={phoneRequired}
              error={this.state.errors.fieldError('phone')}
              onChange={this.onInputChange}
            />
          )}

          <Email
            value={this.state.email}
            required
            onChange={this.onInputChange}
            error={emailError}
          />

          <Password
            label="Create Password"
            value={this.state.password}
            required
            onChange={this.onInputChange}
            error={this.state.errors.fieldError('password')}
            showRequirements={!this.state.triedSubmitting}
          />

          {leadCheckbox}

          <Text classNameModifier="cub-Text--registration">
            By clicking the Join Now button, you agree to the{' '}
            <NavLink route={routes.TERMS_OF_SERVICE} target="_blank">
              Terms of Service
            </NavLink>.
          </Text>

          <Button
            type="submit"
            isProcessing={this.state.isProcessing}
            text="Join Now"
          />
        </Form>
        {this.props.planId && (
          <Text classNameModifier="cub-Text--support">
            Need help? {supportLink}
          </Text>
        )}
      </>
    );
  }
}

RegistrationForm.propTypes = {
  planId: PropTypes.string,
  routes: PropTypes.object,
};

export default RegistrationForm;
