import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import ApiActions from '../../actions/ApiActions';
import DataStore from '../../stores/DataStore';
import LabeledCombobox from './LabeledCombobox';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import { Country as CountryModel } from '../../stores/DataModels';

/* eslint-disable react/prefer-es6-class */
const Country = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    error: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    restrictToKnown: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  },

  /* eslint-disable react/no-unused-class-component-methods,react/sort-comp */
  mixins: [storeListenerMixin(DataStore)],

  getStateFromStores() {
    this.setDefault();
  },
  /* eslint-enable react/no-unused-class-component-methods,react/sort-comp */

  getDefaultProps() {
    return {
      label: 'Country',
      name: 'country',
      placeholder: 'Select country',
    };
  },

  componentDidMount() {
    // Cache countries for 5 minutes
    if (!DataStore.loadedInLastSeconds(DataStore.country, 300)) {
      // Using _.defer to avoid "Cannot dispatch in the middle of a dispatch"
      _.defer(ApiActions.getAll, 'countries');
    }
    this.setDefault();
  },

  setDefault() {
    if (!this.props.value) {
      const usa = DataStore.country.findWhere({ name: 'United States' });
      if (usa && typeof this.props.onChange === 'function') {
        this.props.onChange({
          target: {
            name: 'country',
            value: usa.attributes,
          },
        });
      }
    }
  },

  // eslint-disable-next-line react/no-unused-class-component-methods
  validate() {
    const { required, value, restrictToKnown } = this.props;
    if (required && !value) {
      return 'This field is required.';
    }
    if (restrictToKnown && value && !CountryModel.alike(value)) {
      return 'Must be chosen from dropdown list.';
    }
    return null;
  },

  // eslint-disable-next-line react/no-unused-class-component-methods
  focus() {
    this.refs.combobox.focus();
  },

  render() {
    const value = this.props.value;
    const filter = (item, val) => {
      // use entered value instead of suggested, if possible
      const filterValue = (this.refs.combobox &&
        this.refs.combobox.refs.combobox.refs.inner &&
        this.refs.combobox.refs.combobox.refs.inner.refs.input._last) ||
        val;
      return item.name.toLowerCase().indexOf(filterValue.toLowerCase()) === 0;
    };

    return (
      <LabeledCombobox
        classNameModifier="cub-FormControl--country"
        label={this.props.label}
        ref="combobox"
        required={this.props.required}
        name={this.props.name}
        value={value && (value.id || value)}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        data={DataStore.country.toJSON()}
        textField="name"
        valueField="id"
        suggest
        filter={filter}
        error={this.props.error}
      />
    );
  },
});

export default Country;
