import _ from 'underscore';
import AppDispatcher from '../dispatcher/AppDispatcher';
import CubApi from '../api/CubApi';
import UserStore from '../stores/UserStore';
import UserActions from './UserActions';
import FormActions from './FormActions';
import {
  ACCEPT_INVOICE_FORM_ID,
  CHECK_TOKEN_FORM_ID,
  RESEND_TOKEN_FORM_ID,
} from '../constants/FormIds';

import {
  ORDER_UPDATED,
  ORDER_INVOICE_AUTOLOGIN_FAILED,
  REGISTER_FOR_INVOICE_FAILED,
} from '../constants/ActionTypes';

const ORDER_EXPAND = 'customer__bank_accounts,' +
    'customer__cards,' +
    'customer__service_subscriptions,' +
    'payer_customer__cards__customer,' +
    'charges,' +
    'items';

const OrderActions = {
  ORDER_EXPAND,

  reSendInvoiceLink(orderId) {
    const formId = RESEND_TOKEN_FORM_ID;
    FormActions.formSubmitted(formId);

    CubApi.post(
      `orders/${orderId}/resend_invoice`,
      { _formId: formId, data: {} },
      FormActions.dispatchFormSuccess,
      FormActions.dispatchFormError,
    );
  },

  checkToken(orderId, token) {
    const formId = CHECK_TOKEN_FORM_ID;
    const data = { invoice_token: token };

    FormActions.formSubmitted(formId);

    CubApi.post(
      `orders/${orderId}/check_invoice`,
      { data, _formId: formId },
      FormActions.dispatchFormSuccess,
      FormActions.dispatchFormError,
    );
  },

  acceptInvoice(orderId, invoiceToken) {
    const formId = ACCEPT_INVOICE_FORM_ID;

    FormActions.formSubmitted(formId);

    CubApi.post(
      `orders/${orderId}/accept_invoice`,
      {
        apiKey: UserStore.token(true),
        data: {
          invoice_token:
          invoiceToken,
        },
        _formId: formId,
      },
      FormActions.dispatchFormSuccess,
      FormActions.dispatchFormError,
    );
  },

  registerForAcceptToken(orderId, token, onAccCreated) {
    const data = {
      invoice_token: token,
    };
    const params = {
      data: _.extend({ expand: UserActions.USER_EXPAND }, data),
    };

    const success = (response, meta) => {
      UserActions.userUpdatedWithoutForm(response, meta, true);
      onAccCreated();
    };

    const fail = (response, meta) => {
      AppDispatcher.handleFormAction({
        actionType: REGISTER_FOR_INVOICE_FAILED,
        response,
        meta,
      });
    };

    CubApi.post(
      `orders/${orderId}/register_with_invoice`,
      params,
      success,
      fail,
    );
  },

  invoiceTokenLoginFailed(response, meta) {
    AppDispatcher.handleFormAction({
      actionType: ORDER_INVOICE_AUTOLOGIN_FAILED,
      response,
      meta,
    });
  },

  getOrder(orderId, onError, invoiceToken) {
    const data = { expand: ORDER_EXPAND };
    if (invoiceToken) {
      data.invoice_token = invoiceToken;
    }
    CubApi.get(`orders/${orderId}`,
      { apiKey: UserStore.token(true), data },
      OrderActions.orderLoaded,
      onError);
  },

  payOrder(orderId, source, onError, invoiceToken, coupon) {
    const data = { expand: ORDER_EXPAND, source };
    if (invoiceToken) data.invoice_token = invoiceToken;
    if (coupon) data.coupon = coupon;
    CubApi.post(`orders/${orderId}/pay`,
      { apiKey: UserStore.token(true), data },
      OrderActions.orderLoaded,
      onError);
  },

  orderLoaded(response) {
    AppDispatcher.handleServerAction({
      actionType: ORDER_UPDATED,
      response,
    });
  },
};

export default OrderActions;
