import PropTypes from 'prop-types';
import React from 'react';
import Error from '../basic/Error';

export default class CustomControl extends React.Component {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    const onLoadFunc = typeof (this.props.onLoad) === 'string' ?
      window[this.props.onLoad] :
      this.props.onLoad;
    onLoadFunc(this.el, this.props.value, (content) => {
      this.onChange(content);
    });
  }

  componentWillUnmount() {
    if (this.props.onUnload) {
      const onUnloadFunc = typeof (this.props.onUnload) === 'string' ?
        window[this.props.onUnload] :
        this.props.onUnload;
      onUnloadFunc(this.el);
    }
  }

  onChange(content) {
    if (this.props.onChange) {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: content,
        },
      });
    }
  }

  validate() {
    if (this.props.required && !String(this.props.value)) {
      return 'This field is required.';
    }
    if (this.props.maxLength &&
      String(this.props.value).length > this.props.maxLength) {
      return `Maximum length is ${this.props.maxLength} characters.`;
    }
    return null;
  }

  render() {
    const label = this.props.label && (
      <label
        className={'cub-Label cub-Label--custom-control' +
            ` ${this.props.classNameLabelModifier}`}
      >
        {this.props.label}
        {this.props.required && <span className="cub-Label-asterisk">*</span>}
      </label>
    );

    return (
      <div className="cub-FormGroup cub-FormGroup--custom-control">
        {label}

        <div ref={(el) => { this.el = el; }} />

        <Error dataField={this.props.name}>
          {this.props.error}
        </Error>
      </div>
    );
  }
}

CustomControl.propTypes = {
  error: PropTypes.any,
  classNameModifier: PropTypes.string,
  classNameLabelModifier: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onLoad: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  onUnload: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
};

CustomControl.defaultProps = {
  classNameModifier: '',
  classNameLabelModifier: '',
};
