import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import createForm from '../../mixins/createForm';
import DataStore from '../../stores/DataStore';
import regflow from '../../utils/regflow';
import GenericForm from './GenericForm';
import LabeledRadioGroup from '../fields/LabeledRadioGroup';
import router from '../../router/router';
import UserActions from '../../actions/UserActions';
import UserMembership from '../views/UserMembership';
import { EXPERIENCE } from '../../constants/FormIds';
import { parseQueryString, setQueryString } from '../../utils/Utils';
import Button from '../basic/Button';
import CaptionLarge from '../basic/CaptionLarge';
import Form from '../basic/Form';
import Tabs from '../basic/Tabs';
import Text from '../basic/Text';
import Loading from '../basic/Loading';

const ExperienceForm = createForm(EXPERIENCE, [DataStore], {
  propTypes: {
    routes: PropTypes.object,
    site: PropTypes.object,
    user: PropTypes.object,
  },

  formStateFromStores() {
    const state = this.props.user.profileAttrs();
    state.tab = state.retired ? 'other' : 'active';
    state.skipClicked = false;
    return state;
  },

  getInitialState() {
    return this.formStateFromStores();
  },

  storeLastFocusedElement() {
    if (document.activeElement !== document.body) {
      this.lastFocusedElement = document.activeElement;
    }
  },

  toggleRetired() {
    const retired = !this.state.retired;
    this.setState({ retired });
    if (this.lastFocusedElement) {
      this.lastFocusedElement.focus();
    }
  },

  skip() {
    this.setState({ skipClicked: true });
  },

  submit(e) {
    const profileAttrs = _.extend(
      this.props.user.profileAttrs(),
      _.omit(this.state, 'tab', 'errors', 'isProcessing'),
    );
    UserActions.updateProfile(profileAttrs, EXPERIENCE);
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
  },

  vendorLeadFormSubmit(e) {
    this.refs.vendorLeadForm.submit();
    e.preventDefault();
  },

  checkMemberVerification(member) {
    return !member.verificationApproved() &&
      !member.verificationPending() &&
      !(member.get('is_active') && member.userIsVerified(this.props.site));
  },

  shouldSeeVerificationPage() {
    const membershipForVerification =
      this.props.user.membershipForVerification(this.props.site);
    for (let i = 0, l = membershipForVerification.length; i < l; i++) {
      const member = membershipForVerification[i];
      if (this.checkMemberVerification(member)) {
        return true;
      }
    }
    return false;
  },

  navigateNextUrl() {
    let nextRoute;
    if (regflow.subscriptionsAvailable(this.props)) {
      nextRoute = this.props.routes.NEWSLETTERS;
    }
    if (regflow.benefitsAvailable(this.props)) {
      nextRoute = this.props.routes.PREMIUM_BENEFITS;
    }
    if (this.shouldSeeVerificationPage()) {
      nextRoute = this.props.routes.REGISTRATION_VERIFICATION;
    }

    if (nextRoute) {
      router.navigate(setQueryString(nextRoute.url, parseQueryString()));
    } else {
      if (regflow.inprogress()) {
        regflow.finish();
      }
      const redirect = router.getFirstLoginRedirect().url;
      router.navigate(parseQueryString().next || redirect);
    }
  },

  storesSuccess(formId) {
    if (formId === EXPERIENCE) {
      this.navigateNextUrl();
    }
  },

  onTabClick(data) {
    const newState = { tab: data };
    if (newState.tab !== 'other') {
      newState.retired = false;
    }
    this.setState(newState);
  },

  onVendorLeadFormSubmit() {
    this.setState({ isProcessing: true });
  },

  onVendorLeadFormError() {
    this.setState({ isProcessing: false });
  },

  render() {
    const site = this.props.site;
    const experienceStep = site.get('experience_step');
    if (regflow.inprogress() && experienceStep === ExperienceForm.SKIPPED) {
      this.navigateNextUrl();
      return <Loading />;
    }

    const tab = this.state.tab;
    const verificationRequired = site.verificationRequired();
    const adviseFillingInExperience = (
      experienceStep === ExperienceForm.INSIST || verificationRequired
    );
    const requireUnit = (experienceStep === ExperienceForm.UNIT_REQUIRED);
    const requireExperience = (
      experienceStep === ExperienceForm.REQUIRED || requireUnit
    );
    let greets = `Welcome to ${site.get('friendly_name')}. ` +
        'Tell us about your experience by choosing from one of the options ' +
        'below and enter your organization to get started.';
    if (requireExperience) {
      greets += ' Organization is required for registration.';
    } else if (verificationRequired) {
      greets += ' Organization is required for verification.';
    }
    const siteTag = site.get('tags')[0] || '';
    const vendorLeadForm = site.get('vendor_lead_form');
    const activeProfessionalTitle = {
      Corrections: "I'm Active in Corrections",
      Education: "I'm Active in the Education profession",
      'Emergency Management': "I'm Active in Emergency Management",
      EMS: "I'm Active in the EMS profession",
      Fire: "I'm Active in the Fire Service",
      Government: 'I Currently Serve in the Government',
      'Law Enforcement': "I'm Active in Law Enforcement",
      Military: "I'm Currently in the Military",
      'Private Security': "I'm Active in Private Security",
      'Non-Sworn Law Enforcement': "I'm Active in Non-Sworn Law Enforcement",
    }[siteTag] || "I'm an active professional";

    const tabsData = [
      {
        id: 'active',
        name: 'active',
        title: activeProfessionalTitle,
      },
    ];
    if (vendorLeadForm) {
      tabsData.push(
        {
          id: 'vendor',
          name: 'vendor',
          title: "I'm a Vendor",
        },
      );
    }
    tabsData.push(
      {
        id: 'other',
        name: 'other',
        title: 'Other or Retired',
      },
    );

    let userMembershipTag = '';
    if (tab === 'active') {
      userMembershipTag = siteTag;
    } else if (tab === 'vendor') {
      userMembershipTag = 'Vendor';
    }

    let leadForm = null;
    let mainFormSubmit = this.submit;
    if (tab === 'vendor' && vendorLeadForm) {
      leadForm = (
        <GenericForm
          ref="vendorLeadForm"
          action="leads"
          fieldsets={vendorLeadForm.get('fieldsets')}
          submitBtn=""
          onSubmit={this.onVendorLeadFormSubmit}
          onError={this.onVendorLeadFormError}
          onSuccess={this.submit}
        />
      );
      mainFormSubmit = this.vendorLeadFormSubmit;
    }

    const members = this.props.user.related.member;
    const hasMembers = members.length > 0;

    /* onSubmit explaination:
      1) if Verification isn't required, use the regular mainFormSubmit
      2) if the Skip button wasn't clicked, use the skip fn and show a Warning
      3) if a User has filled the ExperienceForm use regular mainFormSubmit
    */
    const onSubmit = (!adviseFillingInExperience || // *1
      this.state.skipClicked || // *2
      hasMembers) ? // *3
      mainFormSubmit :
      this.skip;

    const editableMembers = members.filter((member) => (
      member.get('organization').get('allow_members_edit_membership_info')
    ));
    const memberHasUnit = (member) => (
      member.related.memberposition.some((mp) => mp.get('unit'))
    );
    const anyEditableMemberHasUnit = (
      editableMembers.length === 0 ||
      editableMembers.some(memberHasUnit)
    );
    const hideContinue = (
      (requireExperience && !hasMembers) ||
      // The user must fill in the experience (organizations). Also, if there
      // are any orgs where members are allowed to edit their membership info,
      // then the user must fill in the unit in at least one of them.
      (requireUnit && !(hasMembers && anyEditableMemberHasUnit))
    );
    const warnAboutExperience = (
      hideContinue ||
      (adviseFillingInExperience && this.state.skipClicked && !hasMembers)
    );
    const requiredInfoKind = (
      (requireUnit && 'organization and position') ||
      (requireExperience && 'organization') ||
      'this'
    );

    const continueBtnTitle = hasMembers ? 'Continue' : 'Skip';

    let helpText = null;
    if (hasMembers) {
      helpText = 'You can add more work experience information ' +
        'to your profile here.';
      if (!hideContinue) {
        helpText += ` Or, simply use the "${continueBtnTitle}" button to ` +
          'go on to the next step. You can always edit this later via ' +
          'the My Profile area of the website.';
      }
    } else if (this.state.retired) {
      helpText = 'What was your last job?';
    } else {
      helpText = 'What is your current or last job?';
    }

    const experienceWarningText = `
      By not providing ${requiredInfoKind} information, you will not be
      able to access
      ${!(verificationRequired || requireExperience) ? ' certain' : ''} 
      content and features on this site${verificationRequired ? ' that ' +
      'require verification' : ''}.
      ${!requireExperience ? ' Are you sure you want to skip?' : ''}
    `;
    return (
      <div
        className="cub-Experience"
        onClick={this.storeLastFocusedElement}
        role="button"
        tabIndex="0"
      >
        <CaptionLarge>My experience</CaptionLarge>
        <p>{greets}</p>
        {warnAboutExperience && requireUnit && (
          <div className="cub-Msg cub-Msg--warning">
            {experienceWarningText}
          </div>
        )}
        <Tabs
          classNameModifier="cub-Tabs--experience"
          options={tabsData}
          active={tab}
          onTabClick={this.onTabClick}
        />

        <div className="cub-Tabs-header">
          {tab === 'other' && (
            <LabeledRadioGroup
              classNameModifier="cub-FormGroup--retired"
              name="retired"
              options={{
                false: 'I work in another industry',
                true: "I'm retired",
              }}
              value={String(this.state.retired)}
              onChange={this.toggleRetired}
            />
          )}

          {helpText && <Text>{helpText}</Text>}
        </div>

        <UserMembership
          registrationMode
          tag={userMembershipTag}
          title=""
          routes={this.props.routes}
          {...this.props}
        />

        {leadForm}

        <Form
          classNameModifier="cub-Form--continue"
          onSubmit={onSubmit}
        >
          {warnAboutExperience && !requireUnit && (
            <div className="cub-Msg cub-Msg--warning">
              {experienceWarningText}
            </div>
          )}
          {!hideContinue && (
            <Button
              classNameModifier="cub-Button--continue"
              type="submit"
              isProcessing={this.state.isProcessing}
              text={continueBtnTitle}
            />
          )}
        </Form>
      </div>
    );
  },
});

ExperienceForm.SKIPPED = 'skipped';
ExperienceForm.INSIST = 'insist';
ExperienceForm.REQUIRED = 'required';
ExperienceForm.UNIT_REQUIRED = 'unit_required';

export default ExperienceForm;
