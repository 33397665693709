import React from 'react';
import CaptionMedium from '../basic/CaptionMedium';
import CaptionLarge from '../basic/CaptionLarge';
import Text from '../basic/Text';

export default class TermsOfService extends React.Component {
  render() {
    return (
      <div className="cub-TermsOfService">
        <div className="cub-TermsOfService-content">
          <CaptionLarge>Terms of Service</CaptionLarge>
          <Text>
            Welcome to the Lexipol network. By registering on this website owned
            and operated by Lexipol, you are gaining access to Lexipol’s network
            of websites and agree to receive services from Lexipol subject to
            the following Terms of Service (&quot;TOS&quot;), which may be
            updated by us from time to time without notice to you. You can
            review the most current version of the TOS at any time. In addition,
            when using particular Lexipol services, you shall be subject to any
            posted guidelines applicable to such services. All such guidelines
            are hereby incorporated by reference into the TOS.
          </Text>
          <CaptionMedium>DESCRIPTION OF SERVICE</CaptionMedium>
          <Text>
            Lexipol currently provides users with access to a rich collection of
            online resources, including various communications tools, online
            forums, services, personalized content and branded programming.
            Unless explicitly stated otherwise, any new features that augment or
            enhance the current Service, including the release of new Lexipol
            properties, shall be subject to the TOS. You understand and agree
            that the Service is provided &quot;AS-IS&quot; and that Lexipol
            assumes no responsibility for the timeliness, deletion, mis-delivery
            or failure to store any user communications or personalization
            settings.
          </Text>
          <Text>
            In order to use the Service, you must obtain access to the World
            Wide Web, either directly or through devices that access web-based
            content, and pay any service fees associated with such access. In
            addition, you must provide all equipment necessary to make such
            connection to the World Wide Web, including a computer and modem or
            other access device. Please be aware that Lexipol has created
            certain areas on the Service that contain adult or mature content.
            You must be at least 18 years of age to access and view such areas.
          </Text>
          <CaptionMedium>YOUR REGISTRATION OBLIGATIONS</CaptionMedium>
          <Text>
            In consideration of use of the Service, you agree to: (a) provide
            true, accurate, current and complete information about yourself as
            prompted by the Service&apos;s registration form and (b) maintain
            and promptly update the Registration Data to keep it true, accurate,
            current and complete. If you provide any information that is untrue,
            inaccurate, not current or incomplete, or Lexipol has reasonable
            grounds to suspect that such information is untrue, inaccurate, not
            current or incomplete, Lexipol has the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Service (or any portion thereof).
          </Text>
          <CaptionMedium>PRIVACY POLICY</CaptionMedium>
          <Text>
            Pursuant to Lexipol&apos;s privacy policy, we may disclose to third
            parties certain aggregate information contained in your Registration
            Data or related data, provided that, such information will NOT
            include personally identifying information, except as specifically
            authorized by you or in the good faith belief that such action is
            reasonably necessary to comply with the law, legal process, or to
            enforce the TOS. For more information, please see our full privacy
            policy.
          </Text>
          <Text>
            Any information we receive in order to verify a user&apos;s
            military, police, or other professional status, once reviewed, will
            be deleted from our system. We do not aggregate or hold on to any
            private service documentation provided by users as part of the
            verification status.
          </Text>
          <CaptionMedium>MEMBER ACCOUNT, PASSWORD AND SECURITY</CaptionMedium>
          <Text>
            You will receive a password and account designation upon completing
            the Service&apos;s registration process. You are responsible for
            maintaining the confidentiality of the password and account, and are
            fully responsible for all activities that occur under your password
            or account. You agree to (a) immediately notify Lexipol of any
            unauthorized use of your password or account or any other breach of
            security, and (b) ensure that you exit from your account at the end
            of each session. Lexipol cannot and will not be liable for any loss
            or damage arising from your failure to comply with this Section.
          </Text>
          <CaptionMedium>
            INDIVIDUAL USER SUBSCRIPTION AGREEMENT FOR PAID INDIVIDUAL
            SUBSCRIBERS
          </CaptionMedium>
          <Text>
            By purchasing an individual subscription on this website owned and
            operated by Lexipol, you are gaining access to Lexipol&apos;s
            network of websites and platforms (including but not limited to
            Corrections1 Academy, EMS1 Academy, FireRescue1 Academy, LocalGov
            Academy and PoliceOne Academy), agree to receive services from
            Lexipol subject to the following Terms of Service (&quot;TOS&quot;),
            which may be updated by us from time to time without notice to you.
            You can review the most current version of the TOS at any time. In
            addition, when using particular Lexipol services, you shall be
            subject to any posted guidelines applicable to such services. All
            such guidelines are hereby incorporated by reference into the TOS.
          </Text>
          <Text>
            The initial term of this Agreement shall be a set duration of months
            or years (the &quot;Initial Term&quot;), selected by the Individual
            User during purchase, commencing on the date of purchase.
          </Text>
          <Text>
            During the Term, subject to the terms and conditions of this
            Agreement, and solely for your personal purposes, Lexipol grants you
            a right to access and use our Services for the Individual
            Subscription purchased, and support, if applicable, in accordance
            with the plan you selected.
          </Text>
          <Text>
            At the expiration of the Initial Term, this Agreement will
            automatically renew for a successive period of months or years (
            each a &quot;Renewal Term&quot; and collectively with the Initial
            Term the &quot;Term&quot;) equal in duration to the Initial Term
            originally selected by the Individual User, unless the Individual
            User provides Lexipol with notice of its intent not to renew this
            Agreement or unsubscribes online prior to the expiration of the then
            current term.
          </Text>
          <Text>
            For paid Services purchased online (&quot;Online Services&quot;),
            you must provide Lexipol with a valid credit card to pay for such
            services. Provided credit card will be charged with the amount
            depending on the contract plan selected. You agree that Lexipol has
            permission to retain and/or share with financial institutions and
            payment processing firms (including any institutions or firms
            Lexipol retains in the future) your submitted payment information in
            order to process your purchase. Depending on where you transact with
            us, the type of payment method used and where your payment method
            was issued, your transaction with us may be subject to foreign
            exchange fees or differences in prices, including because of
            exchange rates. Lexipol does not support all payment methods,
            currencies, or locations for payment.
          </Text>
          <Text>
            There is no cancellation during the Initial Term nor during the
            Renewal Term. Contract will be up for optional renewal at the end of
            the Initial Term agreement selected by the Individual User or the
            current Renewal Term, at which point the subsequent Renewal Term is
            again a subscription of duration equal to the Initial Term with no
            cancellation.
          </Text>
          <Text>
            Client agrees to pay for all costs of collection, including but not
            limited to, reasonable attorney fees and other fees made necessary
            by nonpayment. Publisher reserves the right to suspend all services
            until payment is received in full and may terminate this agreement
            at their discretion if Client fails to comply with the terms
            agreement. Accounts over 28 days past due shall be charged a
            twenty-five dollar ($25.00) late fee and one and one- half percent
            (1.5%) monthly service charge on the unpaid balance thereafter.
          </Text>
          <Text>
            Passwords for accessing an Individual Subscription, content and
            videos can be used by the subscribed individual only during the term
            of the agreement. Sharing login access to any Lexipol network
            website or platform or any downloaded or stored video content with
            other personnel/departments is expressly prohibited. Any violation
            of this policy will result in revocation of Academy access.
          </Text>
          <Text>
            Lexipol content and videos (including all aforementioned network
            websites and platforms) contained herein serve as examples of best
            practices and should not in any way replace, interfere, or override
            individual agency or company protocol, tactics or policies. Content
            is provided on an &quot;as is, as available&quot; basis without
            warranty of any kind, expressed, implied or statutory, and any and
            all warranties of merchantability, fitness for a particular purpose
            or non- infringement of third parties&apos; rights are specifically
            disclaimed. Although Lexipol has made best efforts to provide
            accurate training information on the site, it makes no guarantee or
            warranty, express or implied, as to the reliability, accuracy,
            timeliness or completeness of that information and assumes no
            liability for errors or omissions therein.
          </Text>
          <CaptionMedium>MEMBER CONDUCT</CaptionMedium>
          <Text>
            You understand that all information, data, text, software, music,
            sound, photographs, graphics, video, messages or other materials
            (&quot;Content&quot;), whether publicly posted or privately
            transmitted, are the sole responsibility of the person from which
            such Content originated. This means that you, and not Lexipol, are
            entirely responsible for all Content that you upload, post, email or
            otherwise transmit via the Service. Lexipol does not control the
            Content posted via the Service and, as such, does not guarantee the
            accuracy, integrity or quality of such Content. You understand that
            by using the Service, you may be exposed to Content that is
            offensive, indecent or objectionable. Under no circumstances will
            Lexipol be liable in any way for any Content, including, but not
            limited to, for any errors or omissions in any Content, or for any
            loss or damage of any kind incurred as a result of the use of any
            Content posted, emailed or otherwise transmitted via the Service.
          </Text>
          <Text>
            You agree to not use the Service to:
          </Text>
          <ul className="cub-List cub-List--termsOfService">
            <li className="cub-List-item">
              Upload, post, email or otherwise transmit any Content that is
              unlawful, harmful, threatening, abusive, harassing, tortious,
              defamatory, vulgar, obscene, libelous, invasive of another&apos;s
              privacy, hateful, or racially, ethnically or otherwise
              objectionable;
            </li>
            <li className="cub-List-item">
              Harm minors in any way;
            </li>
            <li className="cub-List-item">
              Impersonate any person or entity, including, but not limited to, a
              Lexipol official, forum leader, guide or host, or falsely state or
              otherwise misrepresent your affiliation with a person or entity;
            </li>
            <li className="cub-List-item">
              Forge headers or otherwise manipulate identifiers in order to
              disguise the origin of any Content transmitted through the
              Service;
            </li>
            <li className="cub-List-item">
              Upload, post, email or otherwise transmit any Content that you do
              not have a right to transmit under any law or under contractual or
              fiduciary relationships (such as inside information, proprietary
              and confidential information learned or disclosed as part of
              employment relationships or under nondisclosure agreements);
            </li>
            <li className="cub-List-item">
              Upload, post, email or otherwise transmit any Content that
              infringes any patent, trademark, trade secret, copyright or other
              proprietary rights of any party;
            </li>
            <li className="cub-List-item">
              Upload, post, email or otherwise transmit any unsolicited or
              unauthorized advertising, promotional materials, &quot;junk
              mail,&quot; &quot;spam,&quot; &quot;chain letters,&quot;
              &quot;pyramid schemes,&quot; or any other form of solicitation,
              except in those areas that are designated for such purpose;
            </li>
            <li className="cub-List-item">
              Upload, post, email or otherwise transmit any material that
              contains software viruses or any other computer code, files or
              programs designed to interrupt, destroy or limit the functionality
              of any computer software or hardware or telecommunications
              equipment;
            </li>
            <li className="cub-List-item">
              Disrupt the normal flow of dialogue, cause a screen to
              &quot;scroll&quot; faster than other users of the Service are able
              to type, or otherwise act in a manner that negatively affects
              other users&apos; ability to engage in real time exchanges;
            </li>
            <li className="cub-List-item">
              Interfere with or disrupt the Service or servers or networks
              connected to the Service, or disobey any requirements, procedures,
              policies or regulations of networks connected to the Service;
            </li>
            <li className="cub-List-item">
              Intentionally or unintentionally violate any applicable local,
              state, national or international law, including, but not limited
              to, regulations promulgated by the U.S. Securities and Exchange
              Commission, any rules of any national or other securities
              exchange, including, without limitation, the New York Stock
              Exchange, the American Stock Exchange or the NASDAQ, and any
              regulations having the force of law;
            </li>
            <li className="cub-List-item">
              &quot;Stalk&quot; or otherwise harass another; or
            </li>
            <li className="cub-List-item">
              Collect or store personal data about other users.
            </li>
          </ul>
          <CaptionMedium>
            SPECIAL ADMONITIONS FOR INTERNATIONAL USE
          </CaptionMedium>
          <Text>
            Recognizing the global nature of the Internet, you agree to comply
            with all local rules regarding online conduct and acceptable
            Content. Specifically, you agree to comply with all applicable laws
            regarding the transmission of technical data exported from the
            United States or the country in which you reside.
          </Text>
          <CaptionMedium>CONTENT SUBMITTED TO LEXIPOL</CaptionMedium>
          <Text>
            Lexipol does not own Content you submit, unless we specifically tell
            you otherwise before you submit it. You license the Content to
            Lexipol as set forth below for the purpose of displaying and
            distributing such Content on our network of properties and for the
            promotion and marketing of our services. By submitting Content to
            any Lexipol property, you automatically grant, or warrant that the
            owner of such Content has expressly granted Lexipol the
            royalty-free, perpetual, irrevocable, non-exclusive and fully
            sub-licensable right and license to use, reproduce, modify, adapt,
            publish, translate, create derivative works from, distribute,
            perform and display such Content (in whole or part) worldwide and/or
            to incorporate it in other works in any form, media, or technology
            now known or later developed. You acknowledge that Lexipol does not
            pre-screen Content, but that Lexipol and its designees shall have
            the right (but not the obligation) in their sole discretion to
            refuse, edit, move or remove any Content that is publicly available
            via the Service. Without limiting the foregoing, Lexipol and its
            designees shall have the right to remove any Content that violates
            the TOS or is otherwise objectionable. You agree that you must
            evaluate, and bear all risks associated with, the use of any
            Content, including any reliance on the accuracy, completeness, or
            usefulness of such Content. In this regard, you acknowledge that you
            may not rely on any Content created by Lexipol or submitted to
            Lexipol, including without limitation information in Message Boards,
            News, and in all other parts of the Service.
          </Text>
          <CaptionMedium>INDEMNITY</CaptionMedium>
          <Text>
            You agree to indemnify and hold Lexipol, and its subsidiaries,
            affiliates, officers, agents, co-branders or other partners, and
            employees, harmless from any claim or demand, including reasonable
            attorneys&apos; fees, made by any third party due to or arising out
            of your Content, your use of the Service, your connection to the
            Service, your violation of the TOS, or your violation of any rights
            of another.
          </Text>
          <CaptionMedium>NO RESALE OF SERVICE</CaptionMedium>
          <Text>
            You agree not to reproduce, duplicate, copy, sell, resell or exploit
            for any commercial purposes, any portion of the Service, use of the
            Service, or access to the Service. Re-posting any content from any
            Lexipol website is strictly prohibited without written permission.
          </Text>
          <CaptionMedium>
            GENERAL PRACTICES REGARDING USE AND STORAGE
          </CaptionMedium>
          <Text>
            You acknowledge that Lexipol may establish general practices and
            limits concerning use of the Service, including without limitation
            the maximum number of days that email messages, message board
            postings or other uploaded Content will be retained by the Service,
            the maximum number of email messages that may be sent from or
            received by an account on the Service, the maximum size of any email
            message that may be sent from or received by an account on the
            Service, the maximum disk space that will be allotted on
            Lexipol&apos;s servers on your behalf, and the maximum number of
            times (and the maximum duration for which) you may access the
            Service in a given period of time. You agree that Lexipol has no
            responsibility or liability for the deletion or failure to store any
            messages and other communications or other Content maintained or
            transmitted by the Service. You acknowledge that Lexipol reserves
            the right to log off accounts that are inactive for an extended
            period of time. You further acknowledge that Lexipol reserves the
            right to change these general practices and limits at any time, in
            its sole discretion, with or without notice.
          </Text>
          <CaptionMedium>MODIFICATIONS TO SERVICE</CaptionMedium>
          <Text>
            Lexipol reserves the right at any time and from time to time to
            modify or discontinue, temporarily or permanently, the Service (or
            any part thereof) with or without notice. You agree that Lexipol
            shall not be liable to you or to any third party for any
            modification, suspension or discontinuance of the Service.
          </Text>
          <CaptionMedium>TERMINATION</CaptionMedium>
          <Text>
            You agree that Lexipol, in its sole discretion, may terminate your
            password, account (or any part thereof) or use of the Service, and
            remove and discard any Content within the Service, for any reason,
            including, without limitation, for lack of use or if Lexipol
            believes that you have violated or acted inconsistently with the
            letter or spirit of the TOS. Lexipol may also in its sole discretion
            and at any time discontinue providing the Service, or any part
            thereof, with or without notice. You agree that any termination of
            your access to the Service under any provision of this TOS may be
            effected without prior notice, and acknowledge and agree that
            Lexipol may immediately deactivate or delete your account and all
            related information and files in your account and/or bar any further
            access to such files or the Service. Further, you agree that Lexipol
            shall not be liable to you or any third-party for any termination of
            your access to the Service.
          </Text>
          <CaptionMedium>DEALINGS WITH ADVERTISERS</CaptionMedium>
          <Text>
            Your correspondence or business dealings with, or participation in
            promotions of, advertisers found on or through the Service,
            including payment and delivery of related goods or services, and any
            other terms, conditions, warranties or representations associated
            with such dealings, are solely between you and such advertiser. You
            agree that Lexipol shall not be responsible or liable for any loss
            or damage of any sort incurred as the result of any such dealings or
            as the result of the presence of such advertisers on the Service.
          </Text>
          <CaptionMedium>LINKS</CaptionMedium>
          <Text>
            The Service may provide, or third parties may provide, links to
            other World Wide Web sites or resources. Because Lexipol has no
            control over such sites and resources, you acknowledge and agree
            that Lexipol is not responsible for the availability of such
            external sites or resources, and does not endorse and is not
            responsible or liable for any Content, advertising, products, or
            other materials on or available from such sites or resources. You
            further acknowledge and agree that Lexipol shall not be responsible
            or liable, directly or indirectly, for any damage or loss caused or
            alleged to be caused by or in connection with use of or reliance on
            any such Content, goods or services available on or through any such
            site or resource.
          </Text>
          <CaptionMedium>
            LEXIPOL&apos;S PROPRIETARY RIGHTS
          </CaptionMedium>
          <Text>
            You acknowledge and agree that the Service and any necessary
            software used in connection with the Service contain proprietary and
            confidential information that is protected by applicable
            intellectual property and other laws. You further acknowledge and
            agree that Content contained in sponsor advertisements or
            information presented to you through the Service or advertisers is
            protected by copyrights, trademarks, service marks, patents or other
            proprietary rights and laws. Except as expressly authorized by
            Lexipol or advertisers, you agree not to modify, rent, lease, loan,
            sell, distribute or create derivative works based on the Service or
            the Software, in whole or in part.
          </Text>
          <Text>
            Lexipol grants you a personal, non-transferable and non-exclusive
            right and license to use the object code of its Software on a single
            computer; provided that you do not (and do not allow any third party
            to) copy, modify, create a derivative work of, reverse engineer,
            reverse assemble or otherwise attempt to discover any source code,
            sell, assign, sublicense, grant a security interest in or otherwise
            transfer any right in the Software. You agree not to modify the
            Software in any manner or form, or to use modified versions of the
            Software, including (without limitation) for the purpose of
            obtaining unauthorized access to the Service. You agree not to
            access the Service by any means other than through the interface
            that is provided by Lexipol for use in accessing the Service.
          </Text>
          <CaptionMedium>DISCLAIMER OF WARRANTIES</CaptionMedium>
          <Text>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
          </Text>
          <Text>
            YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
            PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS.
            LEXIPOL EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
            EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT.
          </Text>
          <Text>
            LEXIPOL MAKES NO WARRANTY THAT (i) THE SERVICE WILL MEET YOUR
            REQUIREMENTS, (ii) THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
            SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM
            THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, (iv) THE
            QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL
            PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR
            EXPECTATIONS, AND (V) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.
          </Text>
          <Text>
            ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
            SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF
            DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
          </Text>
          <Text>
            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
            FROM LEXIPOL OR THROUGH OR FROM THE SERVICE SHALL CREATE ANY
            WARRANTY NOT EXPRESSLY STATED IN THE TOS.
          </Text>
          <CaptionMedium>LIMITATION OF LIABILITY</CaptionMedium>
          <Text>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT LEXIPOL SHALL NOT BE LIABLE
            FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
            EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
            PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF
            LEXIPOL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
            RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE SERVICE;
            (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
            RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR
            OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH
            OR FROM THE SERVICE; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF
            YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD
            PARTY ON THE SERVICE; OR (v) ANY OTHER MATTER RELATING TO THE
            SERVICE.
          </Text>
          <CaptionMedium>EXCLUSIONS AND LIMITATIONS</CaptionMedium>
          <Text>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES
            OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
            CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS OF
            THE ABOVE SECTIONS MAY NOT APPLY TO YOU.
          </Text>
          <CaptionMedium>
            SPECIAL ADMONITION FOR SERVICES RELATING TO FINANCIAL MATTERS
          </CaptionMedium>
          <Text>
            If you intend to create or join any service, receive or request any
            news, messages, alerts or other information from the Service
            concerning companies, stock quotes, investments or securities,
            please read the above Sections again. They go doubly for you. In
            addition, for this type of information particularly, the phrase
            &quot;Let the investor beware&quot; is apt. The Service is provided
            for informational purposes only, and no Content included in the
            Service is intended for trading or investing purposes. Lexipol shall
            not be responsible or liable for the accuracy, usefulness or
            availability of any information transmitted via the Service, and
            shall not be responsible or liable for any trading or investment
            decisions made based on such information.
          </Text>
          <CaptionMedium>NOTICE</CaptionMedium>
          <Text>
            Notices to you may be made via either email or regular mail. The
            Service may also provide notices of changes to the TOS or other
            matters by displaying notices or links to notices to you generally
            on the Service.
          </Text>
          <CaptionMedium>TRADEMARK INFORMATION</CaptionMedium>
          <Text>
            Lexipol, the Lexipol logo, trademarks and service marks, and other
            Lexipol logos and product and service names are trademarks of
            Lexipol Inc. (the &quot;Lexipol Marks&quot;). Without Lexipol
            &apos;s prior permission, you agree not to display or use in any
            manner, the Lexipol Marks.
          </Text>
          <CaptionMedium>COPYRIGHTS AND COPYRIGHT AGENTS</CaptionMedium>
          <Text>
            Lexipol respects the intellectual property of others, and we ask our
            users to do the same. If you believe that your work has been copied
            in a way that constitutes copyright infringement, please provide
            Lexipol&apos;s Copyright Agent the following information:
          </Text>
          <ul className="cub-List cub-List--termsOfService">
            <li className="cub-List-item">
              An electronic or physical signature of the person authorized
              to act on behalf of the owner of the copyright interest;
            </li>
            <li className="cub-List-item">
              A description of the copyrighted work that you claim has
              been infringed;
            </li>
            <li className="cub-List-item">
              A description of where the material that you claim is
              infringing is located on the site;
            </li>
            <li className="cub-List-item">
              Your address, telephone number, and email address;
            </li>
            <li className="cub-List-item">
              A statement by you that you have a good faith belief that
              the disputed use is not authorized by the copyright owner,
              its agent, or the law;
            </li>
            <li className="cub-List-item">
              A statement by you, made under penalty of perjury, that the
              above information in your Notice is accurate and that you are
              the copyright owner or authorized to act on the copyright
              owner&apos;s behalf.
            </li>
          </ul>
          <CaptionMedium>GENERAL INFORMATION</CaptionMedium>
          <Text>
            The TOS constitute the entire agreement between you and Lexipol and
            govern your use of the Service, superceding any prior agreements
            between you and Lexipol. You also may be subject to additional terms
            and conditions that may apply when you use affiliate services,
            third-party content or third-party software. The TOS and the
            relationship between you and Lexipol shall be governed by the laws
            of the State of California without regard to its conflict of law
            provisions. You and Lexipol agree to submit to the personal and
            exclusive jurisdiction of the courts located within the county of
            San Francisco, California. The failure of Lexipol to exercise or
            enforce any right or provision of the TOS shall not constitute a
            waiver of such right or provision. If any provision of the TOS is
            found by a court of competent jurisdiction to be invalid, the
            parties nevertheless agree that the court should endeavor to give
            effect to the parties&apos; intentions as reflected in the
            provision, and the other provisions of the TOS remain in full force
            and effect. You agree that regardless of any statute or law to the
            contrary, any claim or cause of action arising out of or related to
            use of the Service or the TOS must be filed within one (1) year
            after such claim or cause of action arose or be forever barred.
          </Text>
          <CaptionMedium>VIOLATIONS</CaptionMedium>
          <Text>
            Please report any violations of the TOS to our Customer Care group
            via the customer support links on our website.
          </Text>
        </div>
      </div>
    );
  }
}
