import PropTypes from 'prop-types';
import React from 'react';
import FormActions from '../../actions/FormActions';
import UserActions from '../../actions/UserActions';
import { USER_LOOKUP } from '../../constants/FormIds';
import Form from '../basic/Form';
import NavLink from '../basic/NavLink';
import Text from '../basic/Text';
import UsernameOrEmail from '../fields/UsernameOrEmail';
import ExternalLogin from '../fields/ExternalLogin';

export default class UserSsoLoginView extends React.Component {
  onClick() {
    FormActions.resetForm(USER_LOOKUP);
    UserActions.resetLookupResults();
  }

  render() {
    const routes = this.props.routes;
    return (
      <Form
        classNameModifier="cub-Form--login"
        caption="Sign In"
      >
        <UsernameOrEmail
          ref="username"
          value={this.props.username}
          inputIcon="edit"
          onClick={this.onClick}
          onIconClick={this.onClick}
          disabled
        />
        <ExternalLogin
          ssoSettings={this.props.options}
          focusFirstButton
        />

        <Text classNameModifier="cub-Text--register">
          <NavLink route={routes.REGISTER}>Create new account</NavLink>
        </Text>
      </Form>
    );
  }
}

UserSsoLoginView.propTypes = {
  routes: PropTypes.object,
  username: PropTypes.string,
  options: PropTypes.array,
};
