import PropTypes from 'prop-types';
import React from 'react';
import createForm from '../../mixins/createForm';
import Email from '../fields/Email';
import Password from '../fields/Password';
import UserActions from '../../actions/UserActions';
import { CHANGE_EMAIL } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';
import Text from '../basic/Text';

const ChangeEmailForm = createForm(CHANGE_EMAIL, {
  propTypes: {
    user: PropTypes.object,
  },

  getInitialState() {
    return {
      email: '',
      password: '',
    };
  },

  componentDidMount() {
    this.refs.email.focus();
  },

  submit() {
    UserActions.changeEmail({
      email: this.state.email,
      password: this.state.password,
    }, CHANGE_EMAIL);
  },

  render() {
    return (
      <Form
        classNameModifier="cub-Form--changeEmail"
        caption="Change email"
        error={this.state.errors.topError()}
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <Text>
          If you&apos;d like to change your registered email, please enter it
          below. As a safety measure your password is required to perform
          this change.
        </Text>

        {this.props.user.get('email') && (
          <Text>
            Current email:{' '}
            <span className="cub-Text-dataValue">
              {this.props.user.get('email')}
            </span>
          </Text>
        )}

        <Email
          ref="email"
          label="New email"
          required
          value={this.state.email}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('email')}
        />

        <Password
          value={this.state.password}
          required
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('password')}
        />

        <Button
          type="submit"
          isProcessing={this.state.isProcessing}
          text="Change email"
        />
      </Form>
    );
  },
});

export default ChangeEmailForm;
