// eslint-disable-next-line max-classes-per-file
import _ from 'underscore';
import { jwtDecode } from 'jwt-decode';
import ConfigStore from '../stores/ConfigStore';
import { storageSupported } from '../storage/storage';

export function throwSafely(error) {
  // dispatch error without breaking code
  window.setTimeout(() => { throw error; }, 0);
}

const LOGGER_KEY = 'cubLoggerDebug';
const LOGGER_LEVELS = {
  disable: -10,
  // fatal: 0,
  error: 10,
  warn: 20,
  // info: 30,
  debug: 40,
  // trace: 50,
};

export const logger = new (class {
  constructor() {
    try {
      this._level = 'disable';
      this.loggerIsBroken = _.once((err) => {
        // throw only once to save Sentry quota
        throwSafely(err);
      });

      if (storageSupported('sessionStorage')) {
        this.storage = window.sessionStorage;
      } else {
        this.storage = null;
      }

      if (!this.storage) {
        this._level = 'disable';
      } else {
        try {
          const content = this.storage[LOGGER_KEY];
          this._level = JSON.parse(content).level;
        } catch (e) {
          this._level = 'disable';
        }
      }

      if (this._level === 'debug') {
        this._warnAboutPerfomance();
      }

      this.setLevel = this.setLevel.bind(this);
      this.error = this.error.bind(this);
      this.warn = this.warn.bind(this);
      this.debug = this.debug.bind(this);
    } catch (err) {
      // pass
    }
  }

  setLevel(level = 'disable') {
    try {
      if (!_.contains(['disable', 'error', 'warn', 'debug'], level)) {
        this._level = 'disable';
        this.loggerIsBroken(new Error(
          'logger.setLevel: wrong log level',
        ));
        return;
      }

      this._level = level;

      if (this._activeFor('debug')) {
        this._warnAboutPerfomance();
      }

      if (!this.storage) return;
      try {
        const data = JSON.stringify({ level });
        this.storage[LOGGER_KEY] = data;
      } catch (e) {
        // pass
      }
    } catch (err) {
      // pass
    }
  }

  _log(args, logMethod = 'log') {
    try {
      const console = window.console;
      if (!console) return;
      if (!_.isArray(args)) {
        this.loggerIsBroken(new Error('logger._log: "args" must be array'));
        return;
      }

      let argsSnapshot;
      try {
        // try to make deepcopy of arguments
        // becase logged objects evaluated in cosonle when you open them
        argsSnapshot = JSON.parse(JSON.stringify({ args })).args;
      } catch (err) {
        argsSnapshot = args;
      }

      const prefixedArgs = ['CUB | '].concat(argsSnapshot);
      const availableLogMethod = console[logMethod] || console.log || _.noop;
      try {
        availableLogMethod(...prefixedArgs);
      } catch (e) { // IE, version < 10
        availableLogMethod(prefixedArgs.join(' '));
      }
    } catch (err) {
      // pass
    }
  }

  error(...args) {
    try {
      if (!this._activeFor('error')) return;
      this._log(args, 'error');
    } catch (err) {
      // pass
    }
  }

  warn(...args) {
    try {
      if (!this._activeFor('warn')) return;
      this._log(args, 'warn');
    } catch (err) {
      // pass
    }
  }

  debug(...args) {
    try {
      if (!this._activeFor('debug')) return;
      this._log(args, 'log');
    } catch (err) {
      // pass
    }
  }

  _activeFor(level) {
    try {
      const levels = LOGGER_LEVELS;
      return levels[this._level] >= levels[level];
    } catch (err) {
      // pass
    }
    return false;
  }

  _warnAboutPerfomance() {
    try {
      this._log(
        ['Logger uses deepcopy of arguments. ' +
        'On level "debug" there is a lot of logger calls. ' +
        'Be aware that it can affect perfomance because of deepcopy!'],
        'warn',
      );
    } catch (err) {
      // pass
    }
  }
})();

export function urlify(object) {
  if (!_.isObject(object)) {
    logger.warn(object, 'is expected to be an object');
  }
  let result = '';
  Object.keys(object).forEach((k) => {
    if (result) {
      result += '&';
    }
    const v = object[k];
    if (Object.prototype.toString.call(v) === '[object Array]') {
      const urlChunks = [];
      v.forEach((el) => {
        urlChunks.push(
          `${encodeURIComponent(k)}=` +
          `${encodeURIComponent(String(el))}`,
        );
      });
      result += urlChunks.join('&');
    } else {
      result += `${encodeURIComponent(k)}=${encodeURIComponent(String(v))}`;
    }
  });
  return result;
}

export function parseHeaders(rawHeaders) {
  return rawHeaders.split('\n').reduce((headers, header) => {
    const index = header.indexOf(':');
    if (index === -1) return headers;

    const key = (header.substr(0, index) || '').trim();
    const value = (header.substr(index + 1) || '').trim();
    if (key && value) {
      headers[key.toLowerCase()] = value;
    }
    return headers;
  }, {});
}

export function escapeHTML(str) {
  // borrowed from mustache.js
  const entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '\'': '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;',
  };
  return String(str).replace(
    /[&<>"'`=/]/g, (s) => entityMap[s],
  ).replace(/^javascript:/, 'javascript&#58;');
}

export function escapeRegExp(str) {
  // http://stackoverflow.com
  // /questions/3446170/escape-string-for-use-in-javascript-regex
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}

export function absoluteURL(url, cutQueryString) {
  // borrowed from http://stackoverflow.com/a/472729
  const el = document.createElement('div');
  el.innerHTML = `<a href="${escapeHTML(url)}">x</a>`;

  const [href, fragment] = el.firstChild.href.split('#');
  let absUrl = cutQueryString ? href.split('?')[0] : href;

  if (fragment) {
    absUrl += `#${fragment}`;
  }

  return absUrl;
}

// eslint-disable-next-line max-len
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent#Decoding_query_parameters_from_a_URL
export function decodeQueryParam(p) {
  return decodeURIComponent(p.replace(/\+/g, ' '));
}

/**
 * Convert query string to object
 */
export function parseQueryString(queryString) {
  let qs = queryString;
  if (typeof qs !== 'string') {
    qs = window.location.search.substring(1);
  }
  const result = {};
  const vars = qs.split('&');
  vars.forEach((item) => {
    const pair = item.split('=');
    // TODO: switch to decodeQueryParam here
    const key = decodeURIComponent(pair[0]);
    if (key) {
      if (typeof result[key] !== 'undefined') {
        if (Object.prototype.toString.call(result[key]) !== '[object Array]') {
          result[key] = [result[key]];
        }
        result[key].push(decodeURIComponent(pair[1]));
      } else {
        result[key] = decodeURIComponent(pair[1]);
      }
    }
  });
  return result;
}

/**
 * Set/update query string params in the url
 */
export function setQueryString(url, params) {
  let parts = url.split('#');
  const fragment = parts[1] || '';
  parts = parts[0].split('?');
  let result = parts[0]; // [schema, domain and] path
  const qs = urlify(_.extend(parseQueryString(parts[1] || ''), params));
  if (qs) {
    result += `?${qs}`;
  }
  if (fragment) {
    result += `#${fragment}`;
  }
  return result;
}

/**
 * Implementation of addEventListener which works with IE8 too
 */
export function addEventListener(target, type, listener, useCapture) {
  if (target.addEventListener) {
    target.addEventListener(type, listener, useCapture);
  } else if (target.attachEvent) {
    target.attachEvent(type, listener);
  }
}

/**
 * Implementation of removeEventListener which works with IE8 too
 */
export function removeEventListener(target, type, listener, useCapture) {
  if (target.removeEventListener) {
    target.removeEventListener(type, listener, useCapture);
  } else if (target.detachEvent) {
    target.detachEvent(type, listener);
  }
}

/**
 * Convert array to an object with keys equal to values
 */
export function keyMirror(array) {
  const obj = {};
  array.forEach((item) => { obj[item] = item; });
  return obj;
}

/**
 * Basic implementation of String.prototype.startsWith
 *
 * doesn't support second position argument, always looks from zero
 */
export function startsWith(haystack, needle) {
  return haystack.lastIndexOf(needle, 0) === 0;
}

/**
 * Extract server name with protocol from full URL
 *
 * Example: http://example.com/my/path?qs  ->  http://example.com
 */
export function urlParse(fullUrl) {
  let parts = fullUrl.split('://');
  let url;
  let scheme;
  if (parts.length > 1) {
    scheme = parts[0];
    url = parts[1];
  } else {
    scheme = 'http';
    url = parts[0];
  }
  parts = url.split('/');
  const serverName = parts[0];
  url = parts.slice(1).join('/');
  parts = url.split('?');
  const path = `/${parts[0]}`;
  url = parts.slice(1).join('?');
  parts = url.split('#');
  return {
    scheme,
    serverName,
    netloc: `${scheme}://${serverName}`,
    path,
    queryString: parts[0],
    fragment: parts[1] || '',
  };
}

/**
 * Extract domain root from full URL
 *
 * Example: http://example.com/my/path?qs  ->  example.com
 */
export function getDomainRoot(url) {
  const serverName = urlParse(url).serverName;
  const domain = serverName.split(':', 1)[0];
  const rootDomain = _.last(domain.split('.'), 2).join('.');
  return rootDomain;
}

export function isInteger(val) {
  return _.isNumber(val) && _.isFinite(val) && (val % 1 === 0);
}

export function isThenable(it) {
  return _.isObject(it) && _.isFunction(it.then);
}

export function hasOwnProperty(obj, propertyName) {
  return Object.prototype.hasOwnProperty.call(obj, propertyName);
}

export class RecordsBuffer {
  constructor({ items, maxLen } = {}) {
    if (items && !_.isArray(items)) throw Error('"items" must be Array');
    if (maxLen && !isInteger(maxLen)) throw Error('"maxLen" must be Integer');

    this._maxLen = maxLen || Infinity;
    this._buffer = [];

    if (items) items.forEach((val) => this.push(val));
  }

  len() {
    return this._buffer.length;
  }

  asArray() {
    return _.clone(this._buffer);
  }

  push(item) {
    if (this.len() < this._maxLen) {
      this._buffer.push(item);
    } else {
      this._buffer.shift();
      this._buffer.push(item);
    }
  }

  shift() {
    return this._buffer.shift();
  }
}

const SENTRY_RECORDS_KEY = 'cubRavenRecords';
const SENTRY_RECORDS_MAXLEN = 15;
export const siteSentry = new (class {
  constructor() {
    this._getSentry = this._getSentry.bind(this);
    this.addBreadcrumb = this.addBreadcrumb.bind(this);
    this.captureMessage = this.captureMessage.bind(this);
    this.captureException = this.captureException.bind(this);
    this.setExtras = this.setExtras.bind(this);
    this.hideSecrets = this.hideSecrets.bind(this);

    this._alreadyReplayed = false;
    try {
      this._records = new RecordsBuffer({
        items: this._getRecordsFromCache(),
        maxLen: SENTRY_RECORDS_MAXLEN,
      });
      if (this._records.len() > 0) {
        this._record({
          message: '[^ prev breadcrumbs restored from cache (from prev page)]',
          category: 'cub.log',
        });
      }
    } catch (e) {
      this.captureException(e);
      this._records = new RecordsBuffer({ maxLen: SENTRY_RECORDS_MAXLEN });
    }

    const onUnload = _.once(() => {
      const href = window.document.location.href;
      this._record({
        message: '[current location]',
        category: 'cub.log',
        data: { href },
      });
      this._saveRecordsToCache();
    });
    addEventListener(window, 'unload', onUnload);
    addEventListener(window, 'beforeunload', onUnload);
  }

  _getRecordsFromCache() {
    if (!storageSupported('sessionStorage')) {
      return undefined;
    }

    let records;
    try {
      records = JSON.parse(window.sessionStorage[SENTRY_RECORDS_KEY]);
    } catch (e) {
      return undefined;
    }
    return records || undefined;
  }

  _saveRecordsToCache() {
    if (!storageSupported('sessionStorage')) return;
    try {
      const records = this._records.asArray();
      window.sessionStorage[SENTRY_RECORDS_KEY] = JSON.stringify(records);
    } catch (e) {
      this.captureException(e);
    }
  }

  _record(opts) {
    this._records.push(opts);
  }

  _replayRecordsIfNotAlready() {
    if (this._alreadyReplayed) return;

    this._alreadyReplayed = true;
    while (this._records.len() > 0) {
      const opts = this._records.shift();
      opts._playback = true;
      this.addBreadcrumb(opts);
    }
  }

  _getSentry() {
    // TODO: Remove siteRaven property after all sites start setting siteSentry.
    return ConfigStore.get('siteSentry') || ConfigStore.get('siteRaven');
  }

  addBreadcrumb(opts) {
    const sentry = this._getSentry();
    if (sentry) this._replayRecordsIfNotAlready();

    if (!opts._playback) this._record(opts);
    if (sentry && sentry.addBreadcrumb) sentry.addBreadcrumb(opts);

    // we do not log breadcrumbs with logger.debug as in other methods
    // because we suppress a lot of info in breadcrumbs to prevent
    // http response "413 Payload Too Large" from Sentry
    // so, logger.debug usually used alongside siteSentry.captureBreadcrumbs
    // to catch as much data as possible
  }

  captureMessage(msg, opts) {
    const { level, extra } = opts || {};
    const sentry = this._getSentry();
    if (sentry && sentry.withScope && sentry.captureMessage) {
      sentry.withScope((scope) => {
        if (level) {
          scope.setLevel(level);
        }
        if (extra) {
          scope.setExtras(extra);
        }
        sentry.captureMessage(msg);
      });
    } else {
      throwSafely(new Error(msg));
    }
    logger.warn('message: ', msg, ' | ', opts);
  }

  captureException(error, opts) {
    const { extra } = opts || {};
    const sentry = this._getSentry();
    if (sentry && sentry.withScope && sentry.captureException) {
      sentry.withScope((scope) => {
        if (extra) {
          scope.setExtras(extra);
        }
        sentry.captureException(error);
      });
    } else {
      throwSafely(error);
    }
    try {
      logger.error('exception: ', error.message);
    } catch (err) {
      // pass
    }
  }

  setExtras(opts) {
    const sentry = this._getSentry();
    if (sentry && sentry.setExtras) {
      sentry.setExtras(opts);
    }
    logger.debug('extraContext: ', opts);
  }

  setUser(user) {
    const sentry = this._getSentry();
    if (sentry && sentry.setUser) {
      sentry.setUser(user);
    }
    logger.debug('setUser: ', user);
  }

  // to prevent '[Filtered]' stub in Sentry
  hideSecrets(obj) {
    if (!_.isObject(obj)) return obj;

    const clone = _.clone(obj);
    // Secrets list from here
    // eslint-disable-next-line max-len
    // https://github.com/getsentry/sentry/blob/8.19.0/src/sentry/constants.py#L149
    ['secret', 'apiKey', 'token', 'auth'].forEach((key) => {
      if (hasOwnProperty(clone, key)) {
        delete clone[key];
        // mangle key to prevent data scrubbing by Sentry
        clone[key.split('').join('_')] = '[filtered]';
      }
    });

    // Try to hide secrets in common nested objects
    ['data', 'headers', 'response', 'body'].forEach((key) => {
      if (hasOwnProperty(clone, key)) {
        clone[key] = siteSentry.hideSecrets(clone[key]);
      }
    });

    return clone;
  }
})();

export function BaseError(message) {
  // Create a new object, that prototypically inherits
  // from the Error constructor
  // cant use ES6 classes here directly because of babel limitations
  // check this for details https://stackoverflow.com/q/33870684/4491507
  this.name = 'BaseError';
  this.message = message || '';
  this.stack = (new Error(message)).stack;
}
BaseError.prototype = Object.create(Error.prototype);
BaseError.prototype.constructor = BaseError;

export class TimeoutError extends BaseError {
  constructor(msg = 'exceeded max timeout') {
    super();
    this.name = 'TimeoutError';
    this.message = `${this.name}: ${msg}`;
  }
}

export const timeout = (microseconds) => new Promise((resolve, reject) => {
  setTimeout(() => reject(new TimeoutError()), microseconds);
});

export const waitFor = (promise, microseconds) => Promise.race([
  timeout(microseconds),
  promise,
]).catch((err) => {
  if (err instanceof TimeoutError) return;
  throwSafely(err);
});

window.onunhandledrejection = (p) => {
  throwSafely(p ? p.reason : p);
};

export class Deferred {
  constructor() {
    this._promise = new Promise((resolve, reject) => {
      this.promiseResolve = resolve;
      this.promiseReject = reject;
    });
  }

  promise() {
    return this._promise;
  }

  then(onFulfilled, onRejected) {
    return this._promise.then(onFulfilled, onRejected);
  }

  catch(onRejected) {
    return this._promise.catch(onRejected);
  }

  resolve(value) {
    return this.promiseResolve(value);
  }

  reject(reason) {
    return this.promiseReject(reason);
  }
}

export function getWidgetPath() {
  let path = null;
  const widgetScript = document.querySelector('script#cub-widget-script');
  if (widgetScript) {
    path = widgetScript.src.replace(/\/[^/]+$/, '/');
  }
  return path;
}

/**
 * Convert datetime string in ISO-8601 format to Date object.
 *
 * Warning: ISO-8601 format is NOT fully supported. This function can parse
 * only one of many forms allowed by ISO, which is used by Cub API in its
 * responses. Examples: 2013-09-25T12:15:00Z, 2013-09-25T12:15:00.872Z,
 * 2013-09-25T12:15:00.872345Z
 *
 * @param {string} ISODatetimeString - string to parse.
 * @return {Date} - Date object if parsed successfully, or null if not.
 */
export function dateFromISOString(ISODatetimeString) {
  if (typeof ISODatetimeString === 'string') {
    const parts = ISODatetimeString.match(
      /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.((\d{3})|(\d{6})))?Z/,
    );

    if (parts) {
      const millisecs = (parts[8] && parts[8].slice(0, 3)) || 0;
      //                                   ^^ roundup microsecs to millisecs
      //                                   parts[8] can only be 3 or 6 chars
      //                                   as defined in regexp
      return new Date(Date.UTC(
        parts[1],
        parts[2] - 1,
        parts[3],
        parts[4],
        parts[5],
        parts[6],
        millisecs,
      ));
    }
  }
  return null;
}

export function dateToISOWithLocalTZ(dt) {
  try {
    const isoTime = dt.toTimeString();
    siteSentry.setExtras({ dt, isoTime });
    // next line breaks in IE10 - need to debug this
    const offset = isoTime.split(' GMT')[1].split(' (')[0];
    const time = isoTime.split(' GMT')[0];
    const day = dt.getDate() < 10 ? `0${dt.getDate()}` : dt.getDate();
    let month = dt.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    const year = dt.getFullYear();
    return `${year}-${month}-${day}T${time}${offset}`;
  } catch (err) {
    siteSentry.captureException(err);
    return '';
  }
}

// unicode-proof base64 encoding/decoding
// eslint-disable-next-line max-len
// https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa#Unicode_strings
const encode = (str) => window.btoa(unescape(encodeURIComponent(str)));
const decode = (str) => decodeURIComponent(escape(window.atob(str)));
export const base64 = {
  serialize: (val) => encode(JSON.stringify(val)),
  deserialize: (str) => JSON.parse(decode(str)),
};

export function moneyFormat(value) {
  const sign = value < 0 ? '-' : '';
  // http://stackoverflow.com/a/14428340
  const str = Math.abs(value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  return `${sign}$${str}`;
}

export function jwtDecodeLogged(token, opts) {
  let decodedToken;
  try {
    decodedToken = jwtDecode(token, opts);
  } catch (e) {
    siteSentry.captureMessage(
      'Ivalid token, decoding failed',
      { level: 'warning', extra: { failedToken: token } },
    );
    throw e;
  }

  return decodedToken;
}

export function getRandomString(length = 64) {
  let string = '';

  let needMore = true;
  while (needMore) {
    const chunk = Math.random().toString(36).substring(2, 15);
    string += chunk;
    if (string.length >= length) needMore = false;
  }

  return string.substring(0, length);
}

export function cookieOptions(extra = {}) {
  let options = {};

  let secure = ConfigStore.get('cookieSecure');
  if (!(secure instanceof Boolean)) {
    // Auto mode
    secure = window.location.protocol === 'https:';
  }
  options.secure = secure;
  options.samesite = secure ? 'None' : 'Lax';

  const domain = ConfigStore.get('cookieDomain');
  if (domain) {
    options.domain = domain;
  }

  options = _.extend(options, extra);
  return options;
}
