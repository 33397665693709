import PropTypes from 'prop-types';
import React from 'react';
import Button from '../basic/Button';
import CaptionMedium from '../basic/CaptionMedium';
import Smartdate from '../basic/Smartdate';
import VerificationActions from '../../actions/VerificationActions';
import VerificationRequestForm from '../forms/VerificationRequestForm';
import Text from '../basic/Text';

export default class MemberVerificationRequests extends React.Component {
  onCancel(verificationRequest) {
    VerificationActions.cancel(verificationRequest);
  }

  render() {
    const member = this.props.member;
    const org = member.get('organization');
    const orgAddress = org.fullAddress();
    const orgPhone = org.get('phone');
    const personalIdLabel = org.fieldLabels().personal_id;
    const requests = member.orderedVerificationRequests();
    const verified = member.verificationApproved();
    const showForm = !verified && !member.verificationPending();
    return (
      <div className="cub-Organization">
        <div className="cub-Organization-header">
          {verified && (
            <Text classNameModifier="cub-Util-floatRight">
              <i className="cub-Icon cub-Icon--ok" />
              <span className="cub-VerificationStatus is-verified">
                Verified
              </span>
            </Text>
          )}
          <p className="cub-Organization-name">{org.get('name')}</p>
          {orgAddress &&
            <p className="cub-Organization-address">{orgAddress}</p>}
          {orgPhone && (
            <p className="cub-Organization-phone">
              <span>Phone: </span>
              <span className="cub-Organization-phoneNumber">
                {orgPhone}
              </span>
            </p>
          )}
        </div>
        {showForm && <VerificationRequestForm member={member} />}
        {requests.length ?
          <CaptionMedium>Submitted requests</CaptionMedium> : null}
        {requests.map((r) => {
          let cancelBtn = null;
          let statusClass = verified ? 'is-verified' : 'is-declined';
          const status = r.get('status');
          const notes = r.get('notes');
          const reason = r.get('reason');
          if (status === 'Pending') {
            statusClass = 'is-pending';
            /* eslint-disable react/jsx-no-bind */
            cancelBtn = (
              <Button
                classNameModifier="cub-Button--cancel"
                onClick={this.onCancel.bind(this, r)}
                text="Cancel"
              />
            );
            /* eslint-enable react/jsx-no-bind */
          }
          return (
            <div
              className={`cub-VerificationRequest ${statusClass}`}
              key={r.id}
            >
              <div className="cub-Row">
                <div
                  className="cub-Row-column cub-Row-column--35"
                >
                  <Text>Phone: {r.get('phone')}</Text>
                </div>
                <div
                  className="cub-Row-column cub-Row-column--30"
                >
                  <Text>
                    {personalIdLabel}:{' '}
                    {r.get('personal_id') || '- not specified -'}
                  </Text>
                </div>
                <div
                  className="cub-Row-column cub-Row-column--20"
                >
                  <Text>
                    <span className={`cub-VerificationStatus ${statusClass}`}>
                      {status}
                    </span>
                  </Text>
                </div>
                <div
                  className="cub-Row-column cub-Row-column--15"
                >
                  <Text><Smartdate date={r.get('modified')} /></Text>
                </div>
                {(cancelBtn || notes || reason) && (
                  <div
                    className="cub-Row-column cub-Row-column--100"
                  >
                    {cancelBtn}
                    {notes && (
                      <Text
                        classNameModifier="cub-VerificationRequest-notes"
                      >
                        {notes}
                      </Text>
                    )}
                    {reason && (
                      <Text
                        classNameModifier="cub-VerificationRequest-reason"
                      >
                        Rejection reason: {reason}
                      </Text>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

MemberVerificationRequests.propTypes = {
  member: PropTypes.object,
};
