import PropTypes from 'prop-types';
import React from 'react';
import LabeledCheckbox from './LabeledCheckbox';

export default class RegisterMeLabeledCheckbox extends React.Component {
  render() {
    const defaultLabel = `Create my free ${this.props.siteName} membership`;
    const label = this.props.label ? this.props.label : defaultLabel;
    return (
      <LabeledCheckbox
        classNameModifier={this.props.classNameModifier}
        checked={this.props.checked}
        name={this.props.name}
        value="on"
        onChange={this.props.onChange}
        disabled={this.props.isLogged}
        description={this.props.description}
        label={label}
      />
    );
  }
}

RegisterMeLabeledCheckbox.defaultProps = {
  classNameModifier: 'cub-FormControl--registerMeLabeledCheckbox',
  checked: true,
  description: '',
  label: '',
  name: 'register_me',
  isLogged: false,
  siteName: '',
};

RegisterMeLabeledCheckbox.propTypes = {
  classNameModifier: PropTypes.string,
  siteName: PropTypes.string,
  checked: PropTypes.bool,
  description: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isLogged: PropTypes.bool,
};
