import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import Error from '../basic/Error';

export default class LabeledSelect extends React.Component {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
  }

  validate() {
    if (this.props.required && !String(this.props.value)) {
      return 'This field is required.';
    }
    return null;
  }

  render() {
    return (
      <div className="cub-FormGroup cub-FormGroup--select">
        <label className="cub-Label cub-Label--select">
          {this.props.label}
          {this.props.required && <span className="cub-Label-asterisk">*</span>}
        </label>
        <select
          className={'cub-FormControl cub-FormControl--select' +
                ` ${this.props.classNameModifier}`}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
        >
          {_.map(this.props.options, (label, tag) => (
            <option className="cub-FormControl-option" key={tag} value={tag}>
              {label}
            </option>
          ))}
        </select>
        <Error dataField={this.props.name}>
          {this.props.error}
        </Error>
      </div>
    );
  }
}

LabeledSelect.propTypes = {
  classNameModifier: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

LabeledSelect.defaultProps = {
  classNameModifier: '',
};
