import PropTypes from 'prop-types';
import React from 'react';
import Button from '../basic/Button';
import FormListener from '../FormListener';
import FirstName from '../fields/FirstName';
import Form from '../basic/Form';
import LastName from '../fields/LastName';
import NavLink from '../basic/NavLink';
import Password from '../fields/Password';
import Text from '../basic/Text';
import { startsWith } from '../../utils/Utils';
import regflow from '../../utils/regflow';
import UserActions from '../../actions/UserActions';
import { RESET_PASSWORD } from '../../constants/FormIds';
import { Site } from '../../stores/DataModels';
import DataStore from '../../stores/DataStore';
import Phone from '../fields/Phone';

/**
 * Regular user will see regular password reset page, and new user will
 * be able to go through regular registration flow from here.
 * @type {Object}
 */
class PasswordResetOrAcceptInvitationForm extends FormListener {
  constructor(props) {
    super(props);
    this.formId = RESET_PASSWORD;
    this.skipClearingFormErrorFor.add('new_password');
    this.state = {
      new_password: '',
      first_name: this.props.firstName,
      last_name: this.props.lastName,
      phone: this.props.phone,

      triedSubmitting: false,
    };
    this.submit = this.submit.bind(this);
  }

  submit() {
    const alreadyLoggedInOnce = Boolean(this.props.lastLogin);
    let nextUrl = '';
    if (!alreadyLoggedInOnce) {
      nextUrl = this.props.routes.EXPERIENCE;
    }
    UserActions.resetPassword({
      new_password: this.state.new_password,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      phone: this.state.phone,
    }, this.formId, nextUrl);
    this.setState({ triedSubmitting: true });
  }

  storesSuccess(formId) {
    if (formId === this.formId) {
      regflow.start();
    }
  }

  render() {
    let helpText = null;
    if (startsWith(this.props.passwordChangeRequired || '', 'yes')) {
      helpText = 'Please choose a new password. It must be different from ' +
        'your current password.';
    }
    const showHelp = this.props.lastLogin;
    const caption = this.props.lastLogin ? 'Complete Password Reset' :
      'Check your info and set your password';
    const buttonText = this.props.lastLogin ? 'Reset Password' : 'Join Now';
    const site = DataStore.currentSite();
    const showPhoneField = (
      !this.props.lastLogin &&
      site.get('user_phone_field') !== Site.USER_PHONE_DISABLED
    );
    const phoneRequired = (
      site.get('user_phone_field') === Site.USER_PHONE_REQUIRED
    );
    return (
      <Form
        className="cub-Form cub-Form--forgotPassword"
        caption={caption}
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        {showHelp && helpText && <Text>{helpText}</Text>}
        {!this.props.lastLogin && (
        <FirstName
          ref="first_name"
          value={this.state.first_name}
          required
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('first_name')}
        />
        )}
        {!this.props.lastLogin && (
          <LastName
            value={this.state.last_name}
            required
            onChange={this.onInputChange}
            error={this.state.errors.fieldError('last_name')}
          />
        )}
        {showPhoneField && (
          <Phone
            value={this.state.phone}
            required={phoneRequired}
            error={this.state.errors.fieldError('phone')}
            onChange={this.onInputChange}
          />
        )}
        <Password
          classNameModifier="cub-FormControl--newPassword"
          ref="new_password"
          name="new_password"
          label="Enter new password"
          required
          value={this.state.new_password}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('new_password')}
          showRequirements={!this.state.triedSubmitting}
        />

        <Button
          type="submit"
          isProcessing={this.state.isProcessing}
          text={buttonText}
        />
        {!this.props.lastLogin && (
          <Text classNameModifier="cub-Text--registration">
            By clicking the Join Now button, you agree to the{' '}
            <NavLink route={this.props.routes.TERMS_OF_SERVICE} target="_blank">
              Terms of Service
            </NavLink>.
          </Text>
        )}
      </Form>
    );
  }
}

PasswordResetOrAcceptInvitationForm.propTypes = {
  passwordChangeRequired: PropTypes.string,
  lastLogin: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  routes: PropTypes.object,
};

export default PasswordResetOrAcceptInvitationForm;
