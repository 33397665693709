import PropTypes from 'prop-types';
import React from 'react';

import DataStore from '../../stores/DataStore';
import FormActions from '../../actions/FormActions';
import FormStore from '../../stores/FormStore';
import UserActions from '../../actions/UserActions';
import { SIGN_IN, SSO_SIGN_IN, USER_LOOKUP } from '../../constants/FormIds';

import FormListener from '../FormListener';
import NavLink from '../basic/NavLink';
import Password from '../fields/Password';
import ExternalLogin from '../fields/ExternalLogin';
import UsernameOrEmail from '../fields/UsernameOrEmail';
import LabeledCheckbox from '../fields/LabeledCheckbox';
import Button from '../basic/Button';
import Form from '../basic/Form';
import Text from '../basic/Text';
import { storageSupported, putToLocalStorage } from '../../storage/storage';
import { parseQueryString } from '../../utils/Utils';

class UserPasswordLoginForm extends FormListener {
  constructor(props) {
    super(props);
    this.formId = SIGN_IN;
    this.state = {
      password: '',
      ssoError: '',
      errors: FormStore.errors(this.formId),
      isProcessing: false,
    };

    this.submit = this.submit.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentWillMount() {
    super.componentWillMount();
    this.setState({ keep_signed_in: DataStore.currentSite().keepSignedIn() });
  }

  componentDidMount() {
    this.refs.password.focus();
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  formStateFromStores(formState) {
    const ssoErrors = FormStore.errors(SSO_SIGN_IN);
    return {
      ssoError: ssoErrors.topError(),
    };
  }

  onFieldChange(name, value) {
    super.onFieldChange(name, value);
    if (this.state.ssoError) {
      FormActions.resetForm(SSO_SIGN_IN);
    }
  }

  submit() {
    if (storageSupported('localStorage')) {
      putToLocalStorage({
        keepSignedIn: this.state.keep_signed_in,
      });
    }
    FormActions.resetForm(SSO_SIGN_IN);
    UserActions.login({
      username: this.props.username,
      password: this.state.password,
    }, SIGN_IN, parseQueryString().next);
  }

  // eslint-disable-next-line class-methods-use-this
  onClick() {
    FormActions.resetForm(USER_LOOKUP);
    FormActions.resetForm(SIGN_IN);
    FormActions.resetForm(SSO_SIGN_IN);
    UserActions.resetLookupResults();
  }

  render() {
    const { routes, lastVisitedSiteNames } = this.props;
    const site = DataStore.currentSite();
    const siteName = site.get('friendly_name');
    return (
      <Form
        classNameModifier="cub-Form--login"
        caption="Sign In"
        error={this.state.errors.topError() || this.state.ssoError}
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <ExternalLogin />

        {lastVisitedSiteNames && lastVisitedSiteNames.length > 0 && (
          <p className="cub-Text cub-Text--passwordGuidance">
            To access {siteName}, please use your login credentials
            associated with {lastVisitedSiteNames.slice(0, 2).join(' and ')}.
          </p>
        )}

        <UsernameOrEmail
          ref="username"
          value={this.props.username}
          error={this.state.errors.fieldError('username')}
          inputIcon="edit"
          onClick={this.onClick}
          onIconClick={this.onClick}
          disabled
        />

        <Password
          ref="password"
          value={this.state.password}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('password')}
        />

        <Text classNameModifier="cub-Text--forgotPassword">
          <NavLink route={routes.FORGOT_PASSWORD}>
            Forgot your password?
          </NavLink>
        </Text>

        <LabeledCheckbox
          ref="keep_signed_in"
          label="Keep me signed in (I'm not on a shared computer)"
          name="keep_signed_in"
          checked={Boolean(this.state.keep_signed_in)}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('keep_signed_in')}
        />

        <Button
          type="submit"
          isProcessing={this.state.isProcessing}
          text="Login"
        />

        <Text classNameModifier="cub-Text--register">
          <NavLink route={routes.REGISTER}>Create new account</NavLink>
        </Text>
      </Form>
    );
  }
}

UserPasswordLoginForm.propTypes = {
  routes: PropTypes.object,
  username: PropTypes.string,
  lastVisitedSiteNames: PropTypes.array,
};

export default UserPasswordLoginForm;
