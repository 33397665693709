import LabeledInput from './LabeledInput';

export default class OrganizationName extends LabeledInput {}

OrganizationName.defaultProps = {
  classNameModifier: 'cub-FormControl--organizationName',
  label: 'Organization name',
  maxLength: 255,
  name: 'name',
};
