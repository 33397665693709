import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../basic/Loading';
import NavLink from '../basic/NavLink';
import PasswordResetStore from '../../stores/PasswordResetStore';
import PasswordResetOrAcceptInvitationForm
  from '../forms/PasswordResetOrAcceptInvitationForm';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import Text from '../basic/Text';

/* eslint-disable react/prefer-es6-class */
const PasswordReset = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    routes: PropTypes.object,
  },

  /* eslint-disable react/no-unused-class-component-methods */
  mixins: [storeListenerMixin(PasswordResetStore)],

  getStateFromStores() {
    return {
      isValid: PasswordResetStore.isValid(),
      passwordChangeRequired: PasswordResetStore.passwordChangeRequired(),
      lastLogin: PasswordResetStore.lastLogin(),
      firstName: PasswordResetStore.firstName(),
      lastName: PasswordResetStore.lastName(),
      phone: PasswordResetStore.phone(),
    };
  },
  /* eslint-enable react/no-unused-class-component-methods */

  render() {
    const routes = this.props.routes;
    switch (this.state.isValid) {
      case false: {
        return (
          <div className="cub-InfoBlock hasBadToken">
            <Text>
              The password reset link you are attempting to use has expired.
            </Text>
            <Text>
              If you need a new link to setup your account or reset your
              password, please click{' '}
              <NavLink route={routes.FORGOT_PASSWORD}>here</NavLink>.
            </Text>
            <Text>
              If you already set a new password for your account,
              please{' '}
              <NavLink route={routes.LOGIN}>login</NavLink>.
            </Text>
          </div>
        );
      }

      case true:
        return (
          <PasswordResetOrAcceptInvitationForm
            passwordChangeRequired={this.state.passwordChangeRequired}
            lastLogin={this.state.lastLogin}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            phone={this.state.phone}
            routes={routes}
          />
        );

      default:
        return <Loading />;
    }
  },
});

export default PasswordReset;
