import AppDispatcher from '../dispatcher/AppDispatcher';
import AppStore from './AppStore';
import createStore from '../mixins/createStore';
import UserActions from '../actions/UserActions';
import {
  IDP_CONFIRMATION_ERROR,
  IDP_CONFIRMATION_SUCCESS,
  URL_CHANGED,
} from '../constants/ActionTypes';

function noToken() {
  return {
    token: null,
    status: null,
  };
}

let token = noToken();

const IdpConfirmationStore = createStore({
  token() {
    return token.token;
  },

  status() {
    return token.status;
  },

});

function idpConfirm() {
  if (token.token) {
    UserActions.idpConfirm(token.token);
  }
}

IdpConfirmationStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  AppDispatcher.waitFor([AppStore.dispatchToken]);

  switch (action.actionType) {
    case URL_CHANGED: {
      const route = action.route;
      const args = action.args;
      const routes = AppStore.routes();
      if (route === routes.IDP_CONFIRMATION) {
        token = {
          token: args[0],
          status: 'loading',
        };
        IdpConfirmationStore.emitChange();
      } else if (token.token) {
        token = noToken();
        IdpConfirmationStore.emitChange();
      }
      break;
    }

    case IDP_CONFIRMATION_SUCCESS:
      if (token.token) {
        token.status = 'confirmed';
      }
      IdpConfirmationStore.emitChange();
      break;

    case IDP_CONFIRMATION_ERROR:
      if (token.token) {
        if (action.meta.code === 400) {
          token.status = 'bad token';
          IdpConfirmationStore.emitChange();
        } else {
          window.setTimeout(idpConfirm, 5000); // retry in 5 sec
        }
      }
      break;

    default:
  }

  return true;
});

export default IdpConfirmationStore;
