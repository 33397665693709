import PropTypes from 'prop-types';
import React from 'react';
import ApiActions from '../../actions/ApiActions';
import NavLink from '../basic/NavLink';
import router from '../../router/router';

export default class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.updateNotificationState(props);
  }

  componentWillMount() {
    window.setTimeout(() => ApiActions.getAll('notifications'));
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.updateNotificationState(nextProps));
  }

  updateNotificationState(props) {
    // Get a notifications, store them in state (but don't replace existing),
    // and mark them as 'delivered'. Returns a new component state.
    const shownNotifications = (this.state && this.state.notifications) || [];
    const showedIds = shownNotifications.map((item) => item.get('id'));
    const allNotifications = props.user.pendingNotifications();
    if (allNotifications) {
      Object.keys(allNotifications).forEach((idx) => {
        const notify = allNotifications[idx];
        const showed = showedIds.indexOf(notify.get('id')) !== -1;
        if (!showed && notify) {
          shownNotifications.push(notify);
          if (!notify.get('delivered')) {
            window.setTimeout(
              () => ApiActions.post(`notifications/${notify.id}/delivered`),
              500,
            );
          }
        }
      });
    }
    return { notifications: shownNotifications };
  }

  render() {
    const notifications = this.state.notifications;
    if (!notifications || !notifications.length) {
      return null;
    }
    const messagesToDisplay = [];
    Object.keys(notifications).forEach((idx) => {
      const context = notifications[idx].get('context');
      let message;
      switch (notifications[idx].get('code')) {
        case 'welcome':
          message = {
            text: context.message,
            classNameModifier: 'cub-Msg--success',
            button: (
              <NavLink
                className="cub-Button cub-Button--home"
                route={router.routes.HOME}
              >
                <span className="cub-Button-icon" />
                <span className="cub-Button-text">
                  No thanks, take me to the home page
                </span>
              </NavLink>
            ),
          };
          break;
        case 'confirm_email':
          message = {
            text: context.message,
            classNameModifier: 'cub-Msg--success',
            button: null,
          };
          break;
        case 'confirm_required':
          message = {
            text: context.message,
            classNameModifier: 'cub-Msg--warning',
            button: null,
          };
          break;
        default:
          message = null;
          break;
      }
      messagesToDisplay.push(
        message && (
          <div
            key={notifications[idx].id}
            className={`cub-Msg ${message.classNameModifier}`}
          >
            <p className="cub-Text">{message.text}</p>
            {message.button}
          </div>
        ),
      );
    });
    return messagesToDisplay && messagesToDisplay.length && (
      <div className="cub-MsgGroup">{messagesToDisplay}</div>
    );
  }
}

Notifications.propTypes = {
  user: PropTypes.object,
};
