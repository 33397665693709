import _ from 'underscore';
import AppDispatcher from '../dispatcher/AppDispatcher';
import router from '../router/router';
import FormActions from './FormActions';
import UserActions from './UserActions';
import OrderActions from './OrderActions';
import UserStore from '../stores/UserStore';
import MemberActions from './MemberActions';
import DataStore from '../stores/DataStore';
import { SSO_CALLBACK } from '../constants/FormIds';
import { URL_CHANGED, URL_CHANGE_REQUESTED } from '../constants/ActionTypes';
import {
  absoluteURL,
  parseQueryString,
  decodeQueryParam,
  setQueryString,
  hasOwnProperty,
} from '../utils/Utils';
import ApiActions from './ApiActions';
import {
  storageSupported,
  putToLocalStorage,
  getFromLocalStorage,
} from '../storage/storage';
import Sso from '../sso/Sso';

const NavActions = {
  urlChange(route, ...args) {
    const site = DataStore.currentSite();

    const routes = router.routes;
    const redirectHome = setQueryString(routes.HOME.url);
    const redirectLogin = setQueryString(
      routes.LOGIN.url, { next: window.location.href },
    );

    // Require users to be logged-in for all pages except the following:
    const anonymousRoutes = [
      routes.HOME,
      routes.TERMS_OF_SERVICE,
      routes.SUPPORT,
      routes.LOGIN,
      routes.LOGOUT,
      routes.FORGOT_PASSWORD,
      routes.PASSWORD_RESET_LINK_SENT,
      routes.RESET_PASSWORD,
      routes.PASSWORD_RESET_DONE,
      routes.REGISTER,
      routes.REGISTER_FOR_A_PLAN,
      routes.REGISTER_FOR_A_PRODUCT,
      routes.EMAIL_CONFIRMATION,
      routes.IDP_CONFIRMATION,
      routes.IDP_CONFIRMATION_REQUIRED,
      routes.IDP_INITIATED_LOGIN,
      routes.IMPERSONATE,
      routes.TOKEN_LOGIN,
      routes.SSO_CALLBACK,
      routes.ORDER_INVOICE,
    ];
    if (!UserStore.token() && anonymousRoutes.indexOf(route) === -1) {
      router.navigate(site.loginEnabled() ? redirectLogin : redirectHome);
      return;
    }

    const passwordedRoutes = [
      routes.LOGIN,
      routes.REGISTER,
      routes.REGISTER_FOR_A_PLAN,
      routes.REGISTER_FOR_A_PRODUCT,
      routes.FORGOT_PASSWORD,
      routes.RESET_PASSWORD,
      routes.CHANGE_PASSWORD,
    ];
    if (passwordedRoutes.indexOf(route) !== -1) {
      if (!site.loginEnabled()) {
        router.navigate(redirectHome);
        return;
      }
      if (!site.passLoginEnabled()) {
        // If login with password disabled for the site then
        // users won't be able to register or reset password
        if (route !== routes.LOGIN) {
          router.navigate(
            UserStore.token() ? redirectHome : setQueryString(routes.LOGIN.url),
          );
          return;
        }
      }
    }

    const routesAllowedWithEmptyEmail = [
      routes.SET_EMAIL,
      routes.LOGOUT,
    ];
    const userData = UserStore.userData();
    const emptyEmail = hasOwnProperty(userData, 'email') && !userData.email;
    const emailSetRequired = userData.email_set_required === true;
    const isRouteAllowed = routesAllowedWithEmptyEmail.indexOf(route) > -1;
    if (emptyEmail && emailSetRequired && !isRouteAllowed) {
      window.setTimeout(() => router.navigate(routes.SET_EMAIL), 100);
      return;
    }
    const redirect = router.getFirstLoginRedirect();

    if (storageSupported('localStorage')) {
      const prev = getFromLocalStorage('previousPage');
      const prevRoute = prev && routes[prev.name];
      switch (prevRoute) {
        // Check if previous page was TOKEN_LOGIN, request notifications
        case routes.EXPERIENCE:
        case routes.REGISTRATION_VERIFICATION:
        case routes.VERIFICATION_REQUESTS:
        case routes.NEWSLETTERS:
        case routes.TOKEN_LOGIN:
          window.setTimeout(() => ApiActions.getAll('notifications'));
          break;
        default:
      }
      // Save current page as previous in localStorage
      if (!_.isEqual(route, prevRoute)) {
        putToLocalStorage({ previousPage: route });
      }
    }

    // Actions invoked from URL
    switch (route) {
      case routes.LOGOUT: {
        // navigate must go 1st because logout will likely trigger page reload
        const nextUrl = parseQueryString().next || routes.HOME;
        UserActions.logout(undefined, nextUrl);
        return;
      }

      case routes.RESET_PASSWORD:
        UserActions.checkPasswordResetToken(args[0]);
        break;

      case routes.EMAIL_CONFIRMATION:
        UserActions.confirmEmail(args[0]);
        break;

      case routes.CONFIRM_MEMBERSHIP:
        MemberActions.confirmMembership(
          args[0], parseQueryString().next || redirect,
        );
        break;

      case routes.IMPERSONATE:
        // navigate must go 1st b/c impersonate will likely trigger page reload
        router.navigate(parseQueryString().next || redirect);
        UserActions.impersonate(args[0]);
        return;

      case routes.TOKEN_LOGIN:
        UserActions.loginByToken(
          args[0], parseQueryString().next || redirect,
        );
        return;

      case routes.LOGIN:
      case routes.REGISTER:
        if (UserStore.token()) {
          router.navigate(parseQueryString().next || routes.HOME);
        }
        break;
      case routes.REGISTER_FOR_A_PLAN:
        if (UserStore.token()) {
          router.navigate(routes.USER_BILLING_PLAN.makeUrl(args[0]));
        }
        break;
      case routes.ORDER_INVOICE:
        if (args[1].split('_').length > 1) {
          if (UserStore.token()) {
            UserActions.logout(true);
          }
          UserActions.loginByToken(
            args[1].split('_')[1],
            routes.ORDER_INVOICE.makeUrl(args[0], args[1].split('_')[0]),
            OrderActions.invoiceTokenLoginFailed,
          );
        } else if (!UserStore.token()) {
          // use not logged, no autologin link, for new users we will try to
          // create new account, so we have to check token, existing account.
          OrderActions.checkToken(args[0], args[1]);
        }
        break;
      case routes.IDP_INITIATED_LOGIN: {
        const qs = parseQueryString();
        const providerConfig = site.getIdPConfigByUid(args[0]);
        Sso.authorize(providerConfig, qs.target_link_uri, qs.login_hint);
        return;
      }
      case routes.SSO_CALLBACK: {
        // TODO-pkce: extract all of this to the SsoActions?
        FormActions.formSubmitted(SSO_CALLBACK);
        const {
        // eslint-disable-next-line camelcase
          code, state, next, error, error_description,
        } = parseQueryString();
        if (code) {
          // make sure we pass absolute URL as nextUrl
          // to drop querystring with OAuth params and reload the page
          Sso.callback(code, state, next || absoluteURL(redirect, true));
        } else if (error) {
          // eslint-disable-next-line camelcase
          const msg = error_description || error;
          FormActions.dispatchFormError({
            error: { description: decodeQueryParam(msg) },
          }, null, null, null, { _formId: SSO_CALLBACK });
        } else {
          const msg = '' +
            'That\'s weird ─ you shouldn\'t have landed on this page. ' +
            'Either an error  occurred during Single Sign-On, or ' +
            'you\'ve gotten here by accident. If this issue persists, ' +
            'please contact the support team.';
          FormActions.dispatchFormError({
            error: { description: msg },
          }, null, null, null, { _formId: SSO_CALLBACK });
        }
        break;
      }

      default:
    }

    // Propagate URL change
    AppDispatcher.handleNavAction({
      actionType: URL_CHANGED,
      route,
      args,
    });
  },

  urlChangeRequested(route) {
    AppDispatcher.handleNavAction({
      actionType: URL_CHANGE_REQUESTED,
      nextUrl: route,
    });
  },
};

export default NavActions;
