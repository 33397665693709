import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../basic/Button';
import DataStore from '../../stores/DataStore';
import Sso from '../../sso/Sso';
import { parseQueryString } from '../../utils/Utils';

export default class ExternalLogin extends React.Component {
  constructor(props) {
    super(props);
    this.ssoLogin = this.ssoLogin.bind(this);
  }

  componentDidMount() {
    const firstButton = this.firstButton;
    if (this.props.focusFirstButton && firstButton) {
      firstButton.focus();
    }
  }

  ssoLogin(provider) {
    const qs = parseQueryString();
    Sso.authorize(provider, qs.next);
  }

  render() {
    const site = DataStore.currentSite();
    const ssoButtonsGuidance = site.get('sso_buttons_guidance');
    const ssoSettings = (
      this.props.ssoSettings ||
      site.get('sso_settings') ||
      []
    );
    if (Object.keys(ssoSettings).length === 0) {
      return null;
    }
    const buttons = _.filter(_.sortBy(
      ssoSettings, (provider) => provider.order,
    ).map(
      (provider, index) => {
        if (provider.experimental && !parseQueryString().experimental_sso) {
          return null;
        }
        const onClick = () => this.ssoLogin(provider);
        const name = provider.name.toLowerCase();
        const typeName = provider.type_name.toLowerCase();
        let className = 'cub-Button--externalLogin';
        let showIcon = true;

        if (name === 'eso' || name === 'eso (stage)') {
          className += ' cub-Button--eso';
        } else if (name === 'google') {
          className += ' cub-Button--google';
        } else if (typeName === 'facebook') {
          className += ' cub-Button--facebook';
        } else if (typeName === 'openid connect') {
          className += ' cub-Button--openid';
        } else if (typeName === 'saml 2.0') {
          className += ' cub-Button--submit';
        } else {
          showIcon = false;
        }

        // @todo: Why API has provider.text? (check in SAML buttons)
        const text = `${this.props.text} ${provider.name}`;
        const buttonRef = (button) => { this.firstButton = button; };
        return (
          <Button
            key={provider.uid || provider.name}
            classNameModifier={className}
            showIcon={showIcon}
            type="button"
            onClick={onClick}
            text={text}
            ref={index === 0 && buttonRef}
          />
        );
      },
    ));
    return (
      <div className="cub-FormGroup">
        {ssoButtonsGuidance && (
          <h4 className="cub-SsoButtonsCaption">
            {ssoButtonsGuidance}
          </h4>
        )}
        <div className="cub-FormGroup cub-FormGroup--buttons">
          {buttons}
        </div>
      </div>
    );
  }
}

ExternalLogin.propTypes = {
  text: PropTypes.string,
  ssoSettings: PropTypes.array,
  focusFirstButton: PropTypes.bool,
};

ExternalLogin.defaultProps = {
  text: 'Login with',
  focusFirstButton: false,
};
