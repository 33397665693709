import LZString from 'lz-string';
import _ from 'underscore';
import AppDispatcher from '../dispatcher/AppDispatcher';
import ConfigStore from './ConfigStore';
import createStore from '../mixins/createStore';
import { DataModels } from './DataModels';
import UserStore from './UserStore';
import {
  CubCollection,
  Data,
  deleteObjects,
  init,
  updateObjects,
  objCollection,
} from './DataBase';
import {
  CHUNK_LOADED,
  CHUNKED_LOADING_STARTED,
  GOT_XDOMAIN_COOKIES,
  EMAIL_CONFIRMATION_SUCCESS,
  FORM_SUCCESS,
  GROUP_DELETED,
  GROUP_UPDATED,
  LEAD_FORM_UPDATED,
  ORGANIZATION_UPDATED,
  MEMBER_DELETED,
  MEMBER_UPDATED,
  MEMBER_GOT,
  ORDER_UPDATED,
  PASSWORD_RESET_TOKEN_CHECKED,
  SITE_LOADED,
  USER_LOGOUT,
  USER_REMOVE_PHOTO,
  USER_SET_CURRENT_ORGANIZATION,
  USER_SELECT_TRUSTING_ORG,
  USER_UPDATED,
} from '../constants/ActionTypes';
import {
  clearLocalStorage,
  storageSupported,
  putToLocalStorage,
  getFromLocalStorage,
} from '../storage/storage';
import { siteSentry, logger } from '../utils/Utils';

const version = WIDGET_VERSION;

init(DataModels);

// Define ordering for collections that should be ordered
Data.card.comparator = (card) => !card.get('default');
Data.country.comparator = 'name';
Data.position.comparator = 'name';
Data.sitemailinglist.comparator = 'order';
Data.siteplan.comparator = 'order';

const apiListUrlsToObjects = {
  groups: 'group',
  groupmembers: 'groupmember',
  members: 'member',
  memberpositions: 'memberposition',
  organizations: 'organization',
  positions: 'position',
  siteplans: 'siteplan',
  verificationrequests: 'verificationrequest',
};

const loading = {
  // Structure:
  // <urlA>: [{filter1}, {filter2}, ...],
  // <urlB>: [...]
};

let currentUserId = null;
let currentMemberId = null;
let selectedTrustingOrgId = null;

/**
 * Like _.findWhere, but compare all object properties
 */
function findEqual(list, obj) {
  return _.find(list, (item) => _.isEqual(item, obj));
}

function nowLoading(url, filter) {
  const filters = loading[url] || [];
  // if filter was not provided,
  // return true if anything is being loaded from this url
  return filter ? Boolean(findEqual(filters, filter)) : filters.length > 0;
}

/**
 * Check if any models related to given one are now loaded
 */
function nowLoadingRelatedTo(instance) {
  const filter = {};
  filter[instance.object] = instance.id;
  return Object.keys(loading).some((url) => findEqual(loading[url], filter));
}

function getRelatedModels(filter) {
  const models = [];
  Object.keys(filter).forEach((key) => {
    const collection = objCollection(filter[key]);
    if (Object.prototype.hasOwnProperty.call(Data, key) && collection) {
      models.push(collection.getOrCreate(filter[key]));
    }
  });
  return models;
}

function loadingStarted(url, filter) {
  const filterObj = filter || {};
  loading[url] = loading[url] || [];
  if (!findEqual(loading[url], filterObj)) {
    loading[url].push(filterObj);
  }
  const collection = Data[apiListUrlsToObjects[url]];
  if (collection) {
    if (_.isEmpty(filterObj)) {
      collection.markAllAsStale();
    } else {
      const relatedModels = getRelatedModels(filterObj);
      if (relatedModels.length) {
        collection.markRelatedAsStale(relatedModels);
      }
    }
  }
}

function loadingComplete(url, filter) {
  const filters = loading[url] || [];
  const filterObj = findEqual(filters, filter || {});
  if (!filterObj) {
    return;
  }
  loading[url] = _.without(filters, filterObj);
  const collection = Data[apiListUrlsToObjects[url]];
  if (collection) {
    if (_.isEmpty(filterObj)) {
      deleteObjects(collection.staleModels());
    } else {
      const relatedModels = getRelatedModels(filterObj);
      if (relatedModels.length) {
        deleteObjects(collection.filterRelated(relatedModels, true));
      }
    }
  }
}

function saveToCache() {
  if (!storageSupported('localStorage')) {
    return false;
  }

  const data = Data.toJSON({ skipNonCacheable: true });
  const timestamp = (new Date()).getTime();
  data.currentUserId = currentUserId;
  data.currentMemberId = currentMemberId;
  data.selectedTrustingOrgId = selectedTrustingOrgId;
  const compressedData = LZString.compressToUTF16(JSON.stringify(data));
  putToLocalStorage({
    version,
    timestamp,
    data: compressedData,
  });
  const breadcrumb = {
    message: '[localStorage Data cache updated]',
    category: 'cub.log',
    data: { timestamp },
  };
  siteSentry.addBreadcrumb(breadcrumb);
  logger.debug(breadcrumb);
  return true;
}

const debouncedSaveToCache = _.debounce(saveToCache, 3000);

function clearPersonalData() {
  currentUserId = null;
  currentMemberId = null;
  selectedTrustingOrgId = null;
  Object.keys(Data).forEach((key) => {
    const collection = Data[key];
    if (collection instanceof CubCollection) {
      if (!collection.model.prototype.anonymousData) {
        collection.reset([], { silent: true });
      }
    }
  });
}

function clearCache() {
  clearLocalStorage();
}

function restoreFromCache() {
  if (!storageSupported('localStorage')) {
    return false;
  }
  const token = UserStore.token();
  if (!token) {
    clearPersonalData();
    saveToCache();
    return false;
  }

  let cache;
  try {
    cache = getFromLocalStorage();
  } catch (e) {
    return false;
  }
  if (!(_.isObject(cache) &&
      cache.version && cache.timestamp && cache.data)) {
    return false;
  }
  if (cache.version !== version) {
    clearCache();
    return false;
  }
  let data;
  try {
    data = JSON.parse(LZString.decompressFromUTF16(cache.data));
  } catch (e) {
    return false;
  }
  Data.reset(data);
  currentUserId = data.currentUserId;
  currentMemberId = data.currentMemberId;
  selectedTrustingOrgId = data.selectedTrustingOrgId;

  logger.debug({
    dataFromCache: {
      data,
      timestamp: cache.timestamp,
      version: cache.version,
    },
  });

  DataStore.emitChange();
  return true;
}

function takeSnapshot(opts) {
  const data = Data.toJSON(opts);
  data.currentUserId = currentUserId;
  data.currentMemberId = currentMemberId;
  data.selectedTrustingOrgId = selectedTrustingOrgId;
  data.timestamp = (new Date()).getTime();
  return data;
}

const DataStore = createStore(_.extend(Data, {
  nowLoading,

  nowLoadingRelatedTo,

  currentMember() {
    return currentMemberId && Data.member.get(currentMemberId);
  },

  currentSite() {
    return Data.site.findWhere({ domain: window.location.host });
  },

  currentUser() {
    return currentUserId && Data.user.get(currentUserId);
  },

  selectedTrustingOrg() {
    return (
      selectedTrustingOrgId &&
      Data.organization.get(selectedTrustingOrgId)
    );
  },

  saveToCache,

  restoreFromCache,

  takeSnapshot,

  dispatchToken: AppDispatcher.register((payload) => {
    const action = payload.action;
    let immediateCacheUpdate = false;

    AppDispatcher.waitFor([UserStore.dispatchToken]);

    switch (action.actionType) {
      case USER_UPDATED:
      case EMAIL_CONFIRMATION_SUCCESS:
      case PASSWORD_RESET_TOKEN_CHECKED:
      case ORGANIZATION_UPDATED:
      case MEMBER_UPDATED:
      case MEMBER_GOT:
      case GROUP_UPDATED:
      case FORM_SUCCESS:
      case LEAD_FORM_UPDATED:
      case ORDER_UPDATED:
      case SITE_LOADED: {
        updateObjects(action.response, { loaded: true });
        const previousUserId = currentUserId;
        if (!UserStore.loading()) {
          currentUserId = UserStore.currentUserId();
          currentMemberId = UserStore.currentMemberId();
        }
        if (previousUserId !== currentUserId) {
          immediateCacheUpdate = true;
        }
        break;
      }

      case USER_LOGOUT:
        clearPersonalData();
        immediateCacheUpdate = true;
        break;

      case USER_SET_CURRENT_ORGANIZATION:
        currentUserId = UserStore.currentUserId();
        currentMemberId = UserStore.currentMemberId();
        selectedTrustingOrgId = null;
        immediateCacheUpdate = true;
        break;

      case USER_SELECT_TRUSTING_ORG:
        selectedTrustingOrgId = UserStore.selectedTrustingOrgId();
        immediateCacheUpdate = true;
        break;

      case USER_REMOVE_PHOTO:
        // Optimistic update. This action is called prior to API call.
        DataStore.currentUser().set({
          image_refresh_key: null,
          photo_large: null,
          photo_small: null,
        });
        break;

      case CHUNKED_LOADING_STARTED:
        loadingStarted(
          action.url, _.omit(action.filter, 'offset', 'count', 'expand'),
        );
        break;

      case CHUNK_LOADED:
        updateObjects(action.response, {
          loaded: true,
          nonCacheable: action.nonCacheable,
        });
        if (action.loadingComplete) {
          loadingComplete(
            action.url, _.omit(action.filter, 'offset', 'count', 'expand'),
          );
        }
        break;

      case GROUP_DELETED:
      case MEMBER_DELETED:
        deleteObjects(action.instance);
        break;

      case GOT_XDOMAIN_COOKIES:
        break;

      default:
        return true;
    }

    DataStore.emitChange();
    const onDataSync = ConfigStore.get('onDataSync');
    if (typeof onDataSync === 'function') {
      onDataSync(Data);
    }
    if (immediateCacheUpdate) {
      saveToCache();
    } else {
      debouncedSaveToCache();
    }
    return true;
  }),
}));

export default DataStore;
