import PropTypes from 'prop-types';
import React from 'react';
import MemberPositionForm from '../forms/MemberPositionForm';
import Caption from '../basic/Caption';

export default class MemberPositions extends React.Component {
  canEditMemberInfo() {
    if (this.props.allowEditMembershipInfo) {
      // The acting user is an admin. They should be able to edit positions of
      // the given member.
      return true;
    }

    // The acting user requests editing their own membership info.
    const member = this.props.member;
    const org = member.get('organization');
    if (org.get('allow_members_edit_membership_info')) {
      return true;
    }

    return member.get('is_active') && member.get('is_admin');
  }

  render() {
    const editMemberInfo = this.canEditMemberInfo();
    const positions = this.props.member.orderedPositions();
    const forms = positions.map(
      (pos) => (
        <MemberPositionForm
          key={pos.id}
          memberPosition={pos}
          // If editMemberInfo is true, then allow editing positions, but don't
          // show the editor form yet. Otherwise, disallow editing.
          edit={editMemberInfo ? false : null}
        />
      ),
    );
    let notice;
    if (!editMemberInfo) {
      notice = (
        <div className="cub-Msg">
          You are not allowed to edit positions in this organization.
        </div>
      );
    } else {
      forms.push(
        <MemberPositionForm
          key="new"
          member={this.props.member}
          edit={this.props.autoAdd && positions.length === 0}
        />,
      );
    }

    return (
      <div className="cub-MemberPositionsGroup">
        {this.props.header && <Caption>{this.props.header}</Caption>}
        {forms}
        {notice}
      </div>
    );
  }
}

MemberPositions.propTypes = {
  autoAdd: PropTypes.bool,
  header: PropTypes.string,
  member: PropTypes.object,
  allowEditMembershipInfo: PropTypes.bool,
};

MemberPositions.defaultProps = {
  autoAdd: false,
  header: 'Positions',
  allowEditMembershipInfo: null,
};
