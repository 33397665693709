import AppDispatcher from '../dispatcher/AppDispatcher';
import CubApi from '../api/CubApi';
import UserStore from '../stores/UserStore';

import { ORGANIZATION_UPDATED } from '../constants/ActionTypes';

const OrganizationActions = {
  getOrganization(organizationId, expand, onError) {
    const data = { expand };
    CubApi.get(
      `organizations/${organizationId}`,
      { apiKey: UserStore.token(true), data },
      OrganizationActions.organizationLoaded,
      onError,
    );
  },

  organizationLoaded(response) {
    AppDispatcher.handleServerAction({
      actionType: ORGANIZATION_UPDATED,
      response,
    });
  },
};

export default OrganizationActions;
