import _ from 'underscore';
import ApiActions from './ApiActions';
import AppDispatcher from '../dispatcher/AppDispatcher';
import CubApi from '../api/CubApi';
import DataStore from '../stores/DataStore';
import FormActions from './FormActions';
import router from '../router/router';
import UserStore from '../stores/UserStore';
import { logger } from '../utils/Utils';
import {
  MEMBER_ADD,
  MEMBER_CREATE,
  MEMBER_INVITATION_REVOKE,
} from '../constants/FormIds';
import {
  MEMBER_DELETED,
  MEMBER_UPDATED,
  MEMBER_GOT,
} from '../constants/ActionTypes';

const MEMBER_EXPAND = ApiActions.defaultExpand('members');
const MEMBER_POSITION_EXPAND = MEMBER_EXPAND
  .split(',')
  .map((item) => `member__${item}`)
  .join(',');

const MemberActions = {
  MEMBER_EXPAND,

  submitForm(formId, url, data, success, error) {
    FormActions.submitUserForm(
      formId,
      url,
      _.extend({ expand: MEMBER_EXPAND }, data),
      success,
      error,
    );
  },

  getMember(memberId, onSuccess, onError) {
    CubApi.get(`members/${memberId}`,
      { apiKey: UserStore.token(true), data: { expand: MEMBER_EXPAND } },
      (...args) => {
        MemberActions.memberLoaded(...args);
        if (onSuccess) onSuccess(...args);
      },
      onError || logger.warn);
  },

  gotMember(response) {
    AppDispatcher.handleServerAction({
      actionType: MEMBER_GOT,
      response,
    });
  },

  memberLoaded(response) {
    AppDispatcher.handleServerAction({
      actionType: MEMBER_UPDATED,
      response,
    });
  },

  newMember(data, formId, emulateExisting, success, error) {
    const url = 'members';
    if (emulateExisting) {
      const user = DataStore.user.get(data.user);
      const existingMember = user && _.find(
        user.related.member,
        (m) => m.get('organization').id === data.organization,
      );
      if (existingMember) {
        FormActions.dispatchFormSuccess(
          existingMember.toJSON(),
          { code: 200 },
          'post',
          url,
          { _formId: formId },
        );
        return;
      }
    }
    // Member can be added to a new organization which is not known
    // to DataStore yet, so let's expand organization when adding members
    MemberActions.submitForm(
      formId,
      url,
      _.extend({
        expand: `organization__country,organization__state,${MEMBER_EXPAND}`,
      }, data),
      success || MemberActions.newMemberCreated,
      error,
    );
  },

  newMemberCreated(response, meta, method, url, options) {
    const formId = (options || {})._formId;
    if (formId === MEMBER_ADD || formId === MEMBER_CREATE) {
      MemberActions.setMemberPermissions(response, { is_active: true }, formId);
    } else {
      FormActions.dispatchFormSuccess(response, meta, method, url, options);
    }
  },

  resendInvitation(member, formId) {
    MemberActions.submitForm(
      formId,
      `members/${member.id}/resend-invitation`,
      {},
      FormActions.dispatchFormSuccess,
      MemberActions.memberError.bind(this, member),
    );
  },

  setMemberPermissions(member, data, formId) {
    MemberActions.submitForm(
      formId,
      `members/${member.id}/permissions`,
      _.extend({
        expand: `organization__active_members,${MEMBER_EXPAND}`,
      }, data),
      MemberActions.memberPermissionsWereSet,
      MemberActions.memberError.bind(this, member),
    );
  },

  updateMember(member, data, formId) {
    MemberActions.submitForm(
      formId,
      `members/${member.id}`,
      data,
      FormActions.dispatchFormSuccess,
      MemberActions.memberError.bind(this, member),
    );
  },

  deleteMember(member) {
    CubApi.del(
      `members/${member.id}`,
      { apiKey: UserStore.token(true) },
      MemberActions.memberDeleted.bind(MemberActions, member),
      MemberActions.memberError.bind(MemberActions, member),
    );
  },

  memberDeleted(instance) {
    AppDispatcher.handleServerAction({
      actionType: MEMBER_DELETED,
      instance,
    });
  },

  memberError(instance, response, meta, method, url, options) {
    const errCode = parseInt(meta && meta.code, 10);
    if (errCode === 404) {
      MemberActions.memberDeleted(instance);
    }
    FormActions.dispatchFormError(response, meta, method, url, options);
  },

  saveMemberPosition(position, data, formId) {
    FormActions.submitUserForm(
      formId,
      `memberpositions/${(position.id || '')}`,
      _.extend({
        expand: MEMBER_POSITION_EXPAND,
        member: position.get('member').id,
      }, data),
      MemberActions.memberPositionSaved,
      MemberActions.memberPositionError.bind(MemberActions, position),
    );
  },

  memberPermissionsWereSet(response, meta, method, url, options) {
    const formId = options._formId;
    FormActions.dispatchFormSuccess(response, meta, method, url, options);
    if (formId === MEMBER_INVITATION_REVOKE) {
      router.navigate(router.routes.MEMBERS_INVITED);
    }
  },

  memberPositionSaved(response, meta, method, url, options) {
    AppDispatcher.handleServerAction({
      actionType: MEMBER_UPDATED,
      response: response.member,
    });
    FormActions.dispatchFormSuccess(response, meta, method, url, options);
  },

  deleteMemberPosition(position) {
    CubApi.del(
      `memberpositions/${position.id}`,
      { apiKey: UserStore.token(true) },
      MemberActions.memberPositionDeleted.bind(MemberActions, position),
      MemberActions.memberPositionError.bind(MemberActions, position),
    );
  },

  memberPositionDeleted(instance, response, meta, method, url, opts) {
    let memberId = instance.get('member');
    if (_.isObject(memberId)) {
      memberId = memberId.id;
    }
    MemberActions.getMember(memberId);
    FormActions.dispatchFormSuccess(response, meta, method, url, opts);
  },

  memberPositionError(instance, response, meta, method, url, opts) {
    const errCode = parseInt(meta && meta.code, 10);
    if (errCode === 404) {
      // Reload member data
      let memberId = instance.get('member');
      if (_.isObject(memberId)) {
        memberId = memberId.id;
      }
      MemberActions.getMember(memberId);
    }
    FormActions.dispatchFormError(response, meta, method, url, opts);
  },

  updateProfile(member, data, formId, success, error) {
    MemberActions.submitForm(
      formId,
      `members/${member.id}/profile`,
      data,
      success || FormActions.dispatchFormSuccess,
      error || MemberActions.memberError.bind(this, member),
    );
  },

  confirmMembership(memberId, nextUrl) {
    const data = {
      expand: MEMBER_EXPAND,
      is_profile_editable: true,
    };
    CubApi.post(
      `members/${memberId}`,
      { apiKey: UserStore.token(true), data, _nextUrl: nextUrl },
      MemberActions.confirmMembershipSuccess,
      MemberActions.confirmMembershipError,
    );
  },

  confirmMembershipSuccess(response, meta, method, url, options) {
    AppDispatcher.handleServerAction({
      actionType: MEMBER_UPDATED,
      response,
    });
    router.navigate(options._nextUrl);
  },

  confirmMembershipError(response, meta, method, url, options) {
    FormActions.dispatchFormError(response, meta, method, url, options);
    router.navigate(options._nextUrl);
  },
};

export default MemberActions;
