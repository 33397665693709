import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';

import OrganizationEmployees from './OrganizationEmployees';
import OrganizationSearch from './OrganizationSearch';
import Country from '../fields/Country';
import State from '../fields/State';
import City from '../fields/City';
import PostalCode from './PostalCode';

import { OrganizationProp } from '../../constants/PropTypes';
import { logger, Deferred } from '../../utils/Utils';
import ApiActions from '../../actions/ApiActions';
import FormState from '../../forms/FormState';
import { Organization } from '../../stores/DataModels';

export default class OrganizationSubform extends React.Component {
  constructor(props) {
    super(props);
    this._currentFetch = null;
    this.form = new FormState((data) => this.setState(data), () => this.state);
    const { isProcessing, errors } = this.form.getInitialState();
    this.state = {
      isProcessing, // eslint-disable-line react/no-unused-state
      errors,
      name: '',
      employees: '',
      country: null,
      state: null,
      city: '',
      postal_code: '',
      org: props.value,
      _fetching: null,
      _creating: false,
    };

    this.onCountryChange = this.onCountryChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onOrgSearchBlur = this.onOrgSearchBlur.bind(this);
    this.updateFormData = this.updateFormData.bind(this);
    this.validate = this.validate.bind(this);
    this.submit = this.submit.bind(this);
    this.fetchOrg = this.fetchOrg.bind(this);
  }

  componentWillMount() {
    if (Organization.validPrefix(this.state.org)) {
      this.fetchOrg(this.state.org);
    } else if (_.isString(this.state.org)) {
      // drop value if it is not org object or uid
      this.onChange({ name: 'name', value: '' });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.org === nextProps.value) return;

    if (Organization.validPrefix(nextProps.value)) {
      if (this.state._fetching === nextProps.value) return;
      this.fetchOrg(nextProps.value);
    } else if (_.isString(nextProps.value)) {
      // drop value if it is not org object or uid
      this.onChange({ name: 'name', value: '' });
    } else {
      this.setState({ org: nextProps.value });
    }
  }

  onCountryChange(...args) {
    // because different countries have different states
    this.setState({ state: null });
    this.form.onInputChange(...args);
  }

  onChange({ name, value }) {
    if (_.isString(value)) {
      this.nameChanged({ name, value });
    } else if (_.isObject(value)) {
      this.organizationChosen({ name, value });
    }
  }

  onAdd() {
    this.setState({ _creating: true });
    this.props.onChange({ name: this.props.name, value: null });
  }

  onOrgSearchBlur() {
    this.validate();
  }

  fetchOrg(uid) {
    if (this._currentFetch) this._currentFetch.reject({ silent: true });
    this.setState({ _fetching: uid, org: null });

    const dfd = new Deferred();
    this._currentFetch = dfd;
    ApiActions.get(
      `organizations/${uid}`,
      {},
      (response) => dfd.resolve(response),
      (response) => dfd.reject(response),
    );
    this._currentFetch.then(
      (response) => {
        const org = response;
        if (!Organization.alike(org)) {
          this.onChange({ name: 'name', value: '' });
          return;
        }
        this.setState({ _fetching: null });
        this.organizationChosen({
          name: this.props.name, value: org, resetPosition: false,
        });
      },
      (response) => {
        if (response.silent) return;
        this.setState({ _fetching: null });
        this.onChange({ name: 'name', value: '' });
      },
    );
    return this._currentFetch;
  }

  organizationChosen({ value, resetPosition = true }) {
    const org = value;

    this.setState({ _creating: false });

    this.form.onInputChange({ name: 'name', value: '' }); // to clear error
    this.props.onChange(
      { name: this.props.name, value: org }, _.noop, { resetPosition },
    );
  }

  nameChanged({ name, value }) {
    this.form.onInputChange({ name, value });
    this.props.onChange({ name: this.props.name, value: null });
  }

  validate() {
    const { validationErrors } = this.form;
    const errMsg = 'Choose organization from dropdown search results' +
                   ' or provide name and additional info.';

    if (!this.shouldSubmit()) {
      if (this.props.required) {
        if (this.state.org) {
          return null;
        }

        if (this.state._fetching) {
          const errors = {
            name: "This field isn't ready for submission. Please, wait.",
          };
          validationErrors(errors);
          // we must return string to prevent submitting
          return 'ERROR';
        }
      } else if (this.state.name === '') {
        return null;
      }

      this.setState({ _creating: true });
      const errors = { name: errMsg };
      validationErrors(errors);
      // we must return string to prevent submitting
      return 'ERROR';
    }

    const errors = {};
    Object.keys(this.refs).forEach((key) => {
      const field = this.refs[key];
      // Some fields may have no refs and no validation (i.e. hidden field)
      const validateField = field && field.validate;
      let errorMessage = null;
      if (typeof validateField === 'function') {
        errorMessage = validateField();
        if (errorMessage) {
          errors[key] = errorMessage;
        }
      }
    });
    if (errors.name) errors.name = errMsg;
    validationErrors(errors);
    // we must return string to prevent submitting
    return !_.isEmpty(errors) ? 'ERROR' : null;
  }

  // used in GenericForm.jsx
  // eslint-disable-next-line react/no-unused-class-component-methods
  isEmpty() {
    return !this.shouldSubmit() && this.state.name === '';
  }

  formData() {
    const state = this.state;
    return {
      name: state.name,
      employees: state.employees,
      country: (state.country && state.country.id) || '',
      state: (state.state && state.state.id) || '',
      city: state.city,
      postal_code: state.postal_code,
    };
  }

  updateFormData() {
    const state = this.state;
    return _.omit(
      {
        organization_size: state.employees,
        country: state.country,
        state: state.state,
        organization_postal_code: state.postal_code,
      },
      (v) => _.isNull(v) || v === '',
    );
  }

  submit(success, fail) {
    if (!this.shouldSubmit()) {
      logger.warn("Can't submit! To submit data OrganizationSubform " +
            "must be in 'create organization' mode");
      if (_.isFunction(fail)) fail();
      return;
    }

    const { submited, succeeded, failed } = this.form;

    submited();
    ApiActions.simplePost(
      'organizations',
      this.formData(),
      (response) => {
        succeeded();
        const organization = response;
        if (_.isFunction(success)) success(organization);
      },
      (response) => {
        failed(response);
        if (_.isFunction(fail)) fail();
      },
    );
  }

  shouldSubmit() {
    return this.state._creating || this.shouldUpdate();
  }

  shouldUpdate() {
    const org = this.state.org;
    if (!_.isObject(org)) {
      return false;
    }
    return (
      !org.state || !org.employees ||
      (this.props.requirePostalCode && !org.postal_code)
    );
  }

  renderCreateFields() {
    return (
      <div>
        {this.renderEmployees()}
        {this.renderCountry()}
        {this.renderOrgState()}
        <City
          name="city"
          ref="city"
          label={`${this.props.label} City`}
          value={this.state.city}
          required
          error={this.state.errors.fieldError('city')}
          onChange={this.form.onInputChange}
        />
        {this.renderPostalCode()}
      </div>
    );
  }

  renderUpdateFields() {
    const org = this.state.org;
    if (!org) {
      return null;
    }
    return (
      <div>
        {!org.employees && this.renderEmployees()}
        {!org.state && !org.country && this.renderCountry()}
        {!org.state && this.renderOrgState()}
        {!org.postal_code && this.renderPostalCode()}
      </div>
    );
  }

  renderEmployees() {
    return (
      <OrganizationEmployees
        name="employees"
        ref="employees"
        label={`${this.props.label} Size`}
        value={this.state.employees}
        required
        error={this.state.errors.fieldError('employees')}
        onChange={this.form.onInputChange}
      />
    );
  }

  renderCountry() {
    return (
      <Country
        name="country"
        ref="country"
        label={`${this.props.label} Country`}
        value={this.state.country}
        required
        restrictToKnown
        error={this.state.errors.fieldError('country')}
        onChange={this.onCountryChange}
      />
    );
  }

  renderOrgState() {
    let country;
    if (_.isObject(this.state.country)) {
      country = this.state.country;
    } else if (this.state.org && _.isObject(this.state.org.country)) {
      country = this.state.org.country;
    }

    return (
      <State
        name="state"
        ref="state"
        label={`${this.props.label} State`}
        value={this.state.state}
        required
        restrictToKnown
        error={this.state.errors.fieldError('state')}
        country={country}
        placeholder={
          country ? 'Select state/province' : 'Please select country first'
        }
        onChange={this.form.onInputChange}
        onBlur={this.onBlur}
      />
    );
  }

  renderPostalCode() {
    if (!this.props.requirePostalCode) {
      return null;
    }
    return (
      <PostalCode
        name="postal_code"
        ref="postal_code"
        label={`${this.props.label} Postal Code`}
        value={this.state.postal_code}
        required
        error={this.state.errors.fieldError('postal_code')}
        onChange={this.form.onInputChange}
      />
    );
  }

  render() {
    return (
      <div className="cub-FormGroup">
        <OrganizationSearch
          name="name"
          ref="name"
          label={this.props.label}
          value={this.state.name || this.state.org}
          error={this.state.errors.fieldError('name')}
          onChange={this.onChange}
          onAdd={this.onAdd}
          onBlur={this.onOrgSearchBlur}
          required={this.props.required}
          placeholder={this.props.placeholder}
          loading={Boolean(this.state._fetching)}
          disabled={Boolean(this.state._fetching)}
        />
        {this.state._creating ?
          this.renderCreateFields() :
          this.renderUpdateFields()}
      </div>
    );
  }
}

OrganizationSubform.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    OrganizationProp,
  ]),
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  requirePostalCode: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

OrganizationSubform.defaultProps = {
  label: 'Organization',
  requirePostalCode: false,
};
