import LabeledInput from './LabeledInput';

export default class City extends LabeledInput {}

City.defaultProps = {
  classNameModifier: 'cub-FormControl--city',
  label: 'City',
  maxLength: 200,
  name: 'city',
};
