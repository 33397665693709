import PropTypes from 'prop-types';
import React from 'react';

export default class Text extends React.Component {
  render() {
    const className = `cub-Text ${this.props.classNameModifier}`;
    return (
      <p className={className}>{this.props.children}</p>
    );
  }
}

Text.propTypes = {
  classNameModifier: PropTypes.string,
  children: PropTypes.any,
};

Text.defaultProps = {
  classNameModifier: '',
};
