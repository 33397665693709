import LabeledTextarea from './LabeledTextarea';

export default class Address extends LabeledTextarea {}

Address.defaultProps = {
  classNameModifier: 'cub-FormControl--address',
  label: 'Address',
  maxLength: 300,
  name: 'address',
  rows: 3,
};
