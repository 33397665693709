import { Organization } from '../stores/DataModels';

export const OrganizationProp = (props, propName, componentName) => {
  const prop = props[propName];

  if (!Organization.alike(prop)) {
    return new Error(
      `Invalid prop ${propName} supplied to ${componentName}.` +
      'It doesn`t look like Organization record.',
    );
  }

  return undefined;
};
