import _ from 'underscore';
import { EventEmitter } from 'events';
import { CHANGE_EVENT, SUCCESS_EVENT } from '../constants/EventTypes';

export default function createStore(obj) {
  return _.extend({
    addChangeListener(callback) {
      this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener(callback) {
      this.removeListener(CHANGE_EVENT, callback);
    },

    addSuccessListener(callback) {
      this.on(SUCCESS_EVENT, callback);
    },

    removeSuccessListener(callback) {
      this.removeListener(SUCCESS_EVENT, callback);
    },

    emitChange() {
      this.emit(CHANGE_EVENT);
    },

    emitSuccess(...args) {
      this.emit(...[SUCCESS_EVENT].concat(args));
    },
  }, EventEmitter.prototype, obj);
}
