import createReactClass from 'create-react-class';
import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import ApiActions from '../../actions/ApiActions';
import DataStore from '../../stores/DataStore';
import LabeledCombobox from './LabeledCombobox';
import storeListenerMixin from '../../mixins/storeListenerMixin';

/* eslint-disable react/prefer-es6-class */
const Position = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    error: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    tags: PropTypes.array,
    value: PropTypes.string,
  },

  mixins: storeListenerMixin(DataStore),

  getDefaultProps() {
    return {
      name: 'position',
    };
  },

  componentDidMount() {
    // Cache positions for 5 minutes
    if (!DataStore.loadedInLastSeconds(DataStore.position, 300)) {
      // Using _.defer to avoid "Cannot dispatch in the middle of a dispatch"
      _.defer(ApiActions.getAll, 'positions');
    }
  },

  // eslint-disable-next-line react/no-unused-class-component-methods
  validate() {
    if (this.props.required && !this.props.value) {
      return 'This field is required.';
    }
    return null;
  },

  // eslint-disable-next-line react/no-unused-class-component-methods
  focus() {
    this.refs.combobox.focus();
  },

  render() {
    const tags = this.props.tags || [];
    let positions = DataStore.position;
    if (tags.length) {
      positions = positions.filter((position) => {
        const posTags = position.get('tags');
        // Positions without tags are treated as universal
        // and should appear everywhere
        return posTags.length === 0 || _.intersection(tags, posTags).length > 0;
      });
    }
    return (
      <LabeledCombobox
        classNameModifier="cub-FormControl--position"
        label={this.props.label}
        ref="combobox"
        required={this.props.required}
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.onChange}
        data={positions.map((pos) => pos.get('name'))}
        placeholder={this.props.placeholder}
        suggest
        filter="startsWith"
        error={this.props.error}
      />
    );
  },
});

export default Position;
