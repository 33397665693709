import PropTypes from 'prop-types';
import React from 'react';
import Error from '../basic/Error';

export default class LabeledTextarea extends React.Component {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
  }

  validate() {
    if (this.props.required && !String(this.props.value)) {
      return 'This field is required.';
    }
    return null;
  }

  render() {
    return (
      <div className="cub-FormGroup cub-FormGroup--textarea">
        <label className="cub-Label cub-Label--textarea">
          {this.props.label}
          {this.props.required && <span className="cub-Label-asterisk">*</span>}
        </label>
        <textarea
          className={'cub-FormControl cub-FormControl--textarea' +
              ` ${this.props.classNameModifier}`}
          name={this.props.name}
          maxLength={this.props.maxLength}
          placeholder={this.props.placeholder}
          rows={this.props.rows}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        <Error data-field={this.props.name}>
          {this.props.error}
        </Error>
      </div>
    );
  }
}

LabeledTextarea.propTypes = {
  classNameModifier: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

LabeledTextarea.defaultProps = {
  classNameModifier: '',
};
