import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import LabeledCheckbox from './LabeledCheckbox';
import Error from '../basic/Error';
import NavLink from '../basic/NavLink';
import { throwSafely } from '../../utils/Utils';

export default class LabeledCheckboxGroup extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.isAllChecked = this.isAllChecked.bind(this);
    this.validate = this.validate.bind(this);
    this._suppressRequired = false;
  }

  onChange(e) {
    if (typeof this.props.onChange === 'function') {
      const item = e.target.value;
      const value = e.target.checked ?
        _.union(this.props.value, [item]) :
        _.without(this.props.value, item);
      this.props.onChange({
        target: {
          name: this.props.name,
          type: 'checkboxgroup',
          value,
        },
      });
    }
  }

  isAllChecked() {
    return this.props.value.length >= _.keys(this.props.options).length;
  }

  toggleAll(e) {
    e.preventDefault();

    let value = [];
    if (!this.isAllChecked()) {
      value = _.keys(this.props.options);
    }

    this.props.onChange({
      target: {
        name: this.props.name,
        type: 'checkboxgroup',
        value,
      },
    });
  }

  validate() {
    if (this.props.required && this.props.value.length < 1) {
      if (this._suppressRequired) return null;
      return 'This field is required.';
    }
    return null;
  }

  render() {
    if (this.props.required && _.size(this.props.options) < 1) {
      // invalid combination: 'required' but empty 'options'
      this._suppressRequired = true;

      const error = new Error(
        'CheckboxGroup: "required" is true ' +
        'but available "options" are empty!',
      );
      throwSafely(error);
    }

    let columns = this.props.columns;
    if (columns < 1) columns = 1;
    if (columns > 3) columns = 3;

    const allOptions = _.pairs(this.props.options);
    const itemsPerColumn = Math.ceil(allOptions.length / columns);
    const chunks = _.chunk(allOptions, itemsPerColumn);

    // css column widths are multiple of 5: 5%, 10%, 15% ... 100%
    const width = Math.floor((100 / columns) / 5) * 5;

    return (
      <div className="cub-FormGroup cub-FormGroup--checkboxGroup">
        <label className="cub-Label cub-Label--checkboxGroup">
          {this.props.label}
          {this.props.required && <span className="cub-Label-asterisk">*</span>}
          {allOptions && Object.keys(allOptions).length > 1 && (
            <span className="cub-Label-textAdditional cub-Util-textRegular">
              <NavLink
                className="cub-Link"
                onClick={this.toggleAll}
                role="button"
                tabIndex="0"
              >
                {this.isAllChecked() ? 'Uncheck all' : 'Check all'}
              </NavLink>
            </span>
          )}
        </label>
        <Error dataField={this.props.name}>
          {this.props.error}
        </Error>
        <div className="cub-Row">
          {_.map(chunks, (options, index) => (
            <div
              className={`cub-Row-column cub-Row-column--${width}`}
              key={index}
            >
              {_.map(options, ([key, label]) => (
                <LabeledCheckbox
                  key={key}
                  label={label}
                  name={key}
                  value={key}
                  onChange={this.onChange}
                  checked={this.props.value.indexOf(key) >= 0}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

LabeledCheckboxGroup.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object.isRequired,
  required: PropTypes.bool,
  value: PropTypes.array,
  columns: PropTypes.number,
};

LabeledCheckboxGroup.defaultProps = {
  columns: 1,
};
