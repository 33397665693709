import _ from 'underscore';
import { logger, hasOwnProperty } from '../utils/Utils';

function FormErrors(apiErrorResponse) {
  this._errors = null;
  if (_.isObject(apiErrorResponse) &&
      hasOwnProperty(apiErrorResponse, 'error')) {
    this.update(apiErrorResponse.error.params ?
      apiErrorResponse.error.params :
      apiErrorResponse.error.description);
  }
}

FormErrors.prototype.topError = function topError() {
  return typeof this._errors === 'string' ? this._errors : null;
};

FormErrors.prototype.fieldError = function fieldError(fieldName) {
  return _.isObject(this._errors) ? this._errors[fieldName] || null : null;
};

FormErrors.prototype.fieldErrors = function fieldErrors() {
  return _.isObject(this._errors) ? this._errors : {};
};

FormErrors.prototype.firstError = function firstError() {
  let error = this.topError();
  if (error) {
    return error;
  }
  const fieldErrors = this.fieldErrors();
  error = Object.keys(fieldErrors).shift();
  if (error) {
    return fieldErrors[error];
  }
  return null;
};

FormErrors.prototype.clearFieldError = function clearFieldError(fieldName) {
  if (this.topError()) {
    this.update('');
  } else {
    const fieldReset = {};
    fieldReset[fieldName] = '';
    this.update(fieldReset);
  }
};

FormErrors.prototype.update = function update(errors) {
  if (_.isObject(errors)) {
    this._errors = this._errors || {};
    Object.keys(errors).forEach((err) => {
      this._errors[err] = errors[err];
    });
  } else if (typeof errors === 'string') {
    this._errors = errors;
  } else {
    logger.warn('String or Object expected, but got ', errors, 'instead');
  }
};

export default FormErrors;
