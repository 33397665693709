import PropTypes from 'prop-types';
import React from 'react';
import Error from '../basic/Error';

export default class LabeledInput extends React.Component {
  constructor(props) {
    super(props);
    // TODO: Use React.createRef instead of Callback Ref API.
    this.inputElement = null;
    this.focus = this.focus.bind(this);
    this.validate = this.validate.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onIconClick = this.onIconClick.bind(this);
    this.formatError = this.formatError.bind(this);
  }

  onBlur(...args) {
    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(...args);
    }
  }

  onIconClick(ev) {
    // prevent onClick triggering on span-wrapper component
    ev.stopPropagation();
    if (typeof this.props.onIconClick === 'function') {
      this.props.onIconClick(ev);
    }
  }

  focus() {
    this.inputElement.focus();
  }

  validate() {
    if (this.props.required && !String(this.props.value)) {
      return 'This field is required.';
    }
    return null;
  }

  formatError() {
    const error = this.props.error;
    if (typeof error !== 'string') {
      return error;
    }
    const errorArray = error.split('\n');
    if (errorArray.length > 1) {
      return (
        <ul className="cub-List cub-Util-marginTop">
          {errorArray.map((err) => <li className="cub-List-item">{err}</li>)}
        </ul>
      );
    }
    return error;
  }

  render() {
    const label = this.props.label && (
      <label
        className={'cub-Label cub-Label--input' +
            ` ${this.props.classNameLabelModifier || ''}`}
      >
        {this.props.label}
        {this.props.required && <span className="cub-Label-asterisk">*</span>}
      </label>
    );
    let inputClass = `cub-FormControl cub-FormControl--input
      ${this.props.classNameModifier}`;
    if (this.props.disabled) {
      inputClass += ' is-disabled';
    }
    let input = (
      <input
        ref={(el) => { this.inputElement = el; }}
        type={this.props.type || 'text'}
        className={inputClass}
        name={this.props.name}
        maxLength={this.props.maxLength}
        placeholder={this.props.placeholder}
        value={this.props.value}
        onBlur={this.onBlur}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
        onClick={this.props.onClick || null}
      />
    );

    if (this.props.inputIcon) {
      input = (
        <div
          role="presentation"
          className="cub-FormGroup-inputWIcon"
          onClick={this.props.onClick || null}
        >
          {input}
          <span
            role="button"
            tabIndex="0"
            className="cub-FormGroup-icon"
            onClick={this.onIconClick}
            onKeyPress={(e) => (e.key === 'Enter' ? this.onIconClick(e) : null)}
          >
            <i className={`cub-Icon cub-Icon--${this.props.inputIcon}`} />
          </span>
        </div>
      );
    }
    return (
      <div className="cub-FormGroup cub-FormGroup--input">
        {label}
        {input}

        <Error dataField={this.props.name}>
          {this.formatError()}
        </Error>
        {this.props.description}
      </div>
    );
  }
}

LabeledInput.propTypes = {
  error: PropTypes.any,
  classNameModifier: PropTypes.string,
  inputIcon: PropTypes.string,
  classNameLabelModifier: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  description: PropTypes.object,
};

LabeledInput.defaultProps = {
  classNameModifier: '',
  classNameLabelModifier: '',
  inputIcon: '',
};
