import StoreListener from '../components/StoreListener';

export default function storeListenerMixin(...stores) {
  const mixin = { stores };
  Object.getOwnPropertyNames(StoreListener.prototype).forEach((name) => {
    mixin[name] = StoreListener.prototype[name];
  });
  delete mixin.constructor;
  // The following methods must be defined in the component. Leaving them causes
  // mixin conflicts.
  delete mixin.getStateFromStores;
  delete mixin.storeListenerWillUnmount;
  delete mixin.storesSuccess;
  return mixin;
}
