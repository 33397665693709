export const SIGN_IN = 'SIGN_IN';
export const SSO_SIGN_IN = 'SSO_SIGN_IN';
export const SSO_CALLBACK = 'SSO_CALLBACK';
export const USER_LOOKUP = 'USER_LOOKUP';
export const REGISTER = 'REGISTER';
export const EXPERIENCE = 'EXPERIENCE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const USER_PROFILE = 'USER_PROFILE';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const SET_EMAIL = 'SET_EMAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_USERNAME = 'CHANGE_USERNAME';
export const NEWSLETTERS = 'NEWSLETTERS';
export const MEMBER_BENEFITS = 'MEMBER_BENEFITS';
export const CREATE_PASSWORD_RESET_LINK = 'CREATE_PASSWORD_RESET_LINK';

export const MEMBER_ADD = 'MEMBER_ADD';
export const MEMBER_DETAILS = 'MEMBER_DETAILS';
export const MEMBER_INVITATION_RESEND = 'MEMBER_INVITATION_RESEND';
export const MEMBER_INVITATION_REVOKE = 'MEMBER_INVITATION_REVOKE';
export const MEMBER_CREATE = 'MEMBER_CREATE';
export const MEMBER_PERMISSIONS = 'MEMBER_PERMISSIONS';
export const MEMBER_POSITION = 'MEMBER_POSITION';
export const MEMBER_EDIT = 'MEMBER_EDIT';

export const GROUP_ADD = 'GROUP_ADD';
export const GROUP_DETAILS = 'GROUP_DETAILS';
export const GROUP_MEMBER_ADD = 'GROUP_MEMBER_ADD';
export const GROUP_MEMBER_PERMISSIONS = 'GROUP_MEMBER_PERMISSIONS';

export const MEMBERSHIP_ADD = 'MEMBERSHIP_ADD';

export const GENERIC_FORM = 'GENERIC_FORM';

export const CHECK_TOKEN_FORM_ID = 'CHECK_TOKEN_FORM_ID';
export const RESEND_TOKEN_FORM_ID = 'RESEND_TOKEN_FORM_ID';
export const ACCEPT_INVOICE_FORM_ID = 'ACCEPT_INVOICE_FORM_ID';
export const VERIFY_COUPON_FORM = 'VERIFY_COUPON_FORM';
