import _ from 'underscore';
import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ConfigStore from '../stores/ConfigStore';
import DataStore from '../stores/DataStore';
import AppStore from '../stores/AppStore';
import FormActions from '../actions/FormActions';
import GenericForm from '../components/forms/GenericForm';
import { logger } from '../utils/Utils';

const FormsHandler = {
  init() {
    const forms = ConfigStore.get('forms');
    Object.keys(forms).forEach((selector) => {
      if (document.querySelector(selector)) {
        const options = _.extend({}, forms[selector]);
        if (options.load) {
          this.loadForm(options);
        } else if (typeof options.action !== 'string') {
          logger.warn(`Form at selector ${selector} is missing 'action' ` +
            'property. It must be a string with target API endpoint. ' +
            'Please refer to the docs for possible values.');
        } else if (!Array.isArray(options.fieldsets)) {
          logger.warn(`Form at selector ${selector} is missing 'fieldsets' ` +
            'property. It must be an array with fieldsets definitions. ' +
            'Please refer to the docs for examples.');
        }
      } else {
        logger.warn(`Form not found by selector: ${selector}`);
      }
    });

    DataStore.addChangeListener(this.render.bind(this));
    AppStore.addChangeListener(this.render.bind(this));
    this.render();
  },

  loadForm(options) {
    if (!options.load) {
      logger.warn('loadForm failed, "load" parameter missing');
      return;
    }
    const formOptions = _.omit(
      options,
      'load',
      'action',
      'fieldsets',
      'onSubmit',
      'onSuccess',
      'onError',
    );
    FormActions.loadLeadForm(options.load, formOptions);
  },

  _getToken(fieldsets) {
    /* eslint-disable no-restricted-syntax */
    for (const fieldset of fieldsets) {
      if (fieldset.fields) {
        for (const field of fieldset.fields) {
          if (field.type === 'hidden' && field.name === 'token') {
            return field.value;
          }
        }
      }
    }
    /* eslint-enable no-restricted-syntax */
    return null;
  },

  render() {
    const forms = ConfigStore.get('forms');
    const user = DataStore.currentUser();
    const member = DataStore.currentMember();
    const site = DataStore.currentSite();
    const routes = AppStore.routes();
    Object.keys(forms).forEach((selector) => {
      const target = document.querySelector(selector);
      if (!target) {
        logger.warn(`Form target element no longer exists: ${selector}`);
        return;
      }
      const options = _.extend({}, forms[selector]);
      if (options.load) {
        const leadForm = DataStore.leadform.get(options.load);
        if (!leadForm) {
          // looks like this form is not loaded yet
          return;
        }
        options.action = leadForm.get('action');
        options.fieldsets = leadForm.get('fieldsets');
        options.submit = leadForm.get('submit');
        options.redirect = leadForm.get('redirect');
        options.registerMe = leadForm.get('registerMe');
        options.suppressDuplicates = leadForm.get('suppress_duplicates');
      }
      if (typeof options.action === 'string' &&
        Array.isArray(options.fieldsets)) {
        const token = this._getToken(options.fieldsets);
        let form = (
          <GenericForm
            classNameModifier="cub-Form--custom"
            action={options.action}
            fieldsets={options.fieldsets}
            onSubmit={options.onSubmit}
            onSuccess={options.onSuccess}
            onError={options.onError}
            successMsgText={options.successMsgText}
            successMsgHTML={options.successMsgHTML}
            user={user}
            member={member}
            site={site}
            routes={routes}
            responsiveBreakpoint={options.responsiveBreakpoint}
            submitBtn={options.submit}
            submitColumnWidth={options.submitColumnWidth}
            registerMe={options.registerMe}
            redirect={options.redirect}
            formId={options.load}
            suppressDuplicates={options.suppressDuplicates}
          />
        );
        if (token) {
          form = (
            <GoogleReCaptchaProvider
              reCaptchaKey={token}
              scriptProps={{
                async: true,
                defer: true,
              }}
            >
              {form}
            </GoogleReCaptchaProvider>
          );
        }
        ReactDOM.render(form, target);
      }
    });
  },
};

export default FormsHandler;
