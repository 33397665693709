import AppDispatcher from '../dispatcher/AppDispatcher';
import createStore from '../mixins/createStore';
import DataStore from './DataStore';
import {
  FORM_CANCELLED,
  FORM_SUCCESS,
  MEMBER_DELETED,
  MEMBER_GOT,
  URL_CHANGED,
} from '../constants/ActionTypes';
import {
  MEMBERSHIP_ADD,
  MEMBER_POSITION,
} from '../constants/FormIds';

let recentlyAddedMember = null;

const ExperienceStore = createStore({
  recentlyAddedMember() {
    return recentlyAddedMember;
  },
});

ExperienceStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;

  AppDispatcher.waitFor([DataStore.dispatchToken]);

  switch (action.actionType) {
    case FORM_SUCCESS:
      if (action.form === MEMBERSHIP_ADD) {
        recentlyAddedMember = DataStore.member.get(action.response.id);
        ExperienceStore.emitChange();
      } else if (action.form === MEMBER_POSITION && recentlyAddedMember) {
        const memberId = action.response.member.id || action.response.member;
        if (recentlyAddedMember.id === memberId) {
          recentlyAddedMember = null;
          ExperienceStore.emitChange();
        }
      }
      break;

    case FORM_CANCELLED:
      if (action.form === MEMBER_POSITION &&
          action.props.member === recentlyAddedMember) {
        recentlyAddedMember = null;
        ExperienceStore.emitChange();
      }
      break;

    case MEMBER_GOT:
      recentlyAddedMember = DataStore.member.get(action.response.id);
      ExperienceStore.emitChange();
      break;

    case MEMBER_DELETED:
      if (action.instance === recentlyAddedMember) {
        recentlyAddedMember = null;
        ExperienceStore.emitChange();
      }
      break;

    case URL_CHANGED:
      if (recentlyAddedMember) {
        recentlyAddedMember = null;
        ExperienceStore.emitChange();
      }
      break;

    default:
  }

  return true;
});

export default ExperienceStore;
