import PropTypes from 'prop-types';
import React from 'react';
import createForm from '../../mixins/createForm';
import Password from '../fields/Password';
import UserActions from '../../actions/UserActions';
import Username from '../fields/Username';
import { CHANGE_USERNAME } from '../../constants/FormIds';
import Button from '../basic/Button';
import Form from '../basic/Form';
import Text from '../basic/Text';

const ChangeUsernameForm = createForm(CHANGE_USERNAME, {
  propTypes: {
    user: PropTypes.object,
  },

  getInitialState() {
    return {
      username: '',
      password: '',
    };
  },

  componentDidMount() {
    this.refs.username.focus();
  },

  submit() {
    UserActions.changeUsername({
      username: this.state.username,
      password: this.state.password,
    }, CHANGE_USERNAME);
  },

  render() {
    return (
      <Form
        classNameModifier="cub-Form--changeUsername"
        caption="Change username"
        error={this.state.errors.topError()}
        onSubmit={this.submit}
        isProcessing={this.state.isProcessing}
        disableWhenProcessing
      >
        <Text>
          If you&apos;d like to change your username, please enter it
          below. As a safety measure your password is required to perform
          this change.
        </Text>

        <Text>
          Current username:{' '}
          <span className="cub-Text-dataValue">
            {this.props.user.get('username')}
          </span>
        </Text>

        <Username
          ref="username"
          label="New username"
          required
          value={this.state.username}
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('username')}
        />

        <Password
          value={this.state.password}
          required
          onChange={this.onInputChange}
          error={this.state.errors.fieldError('password')}
        />

        <Button
          type="submit"
          isProcessing={this.state.isProcessing}
          text="Change username"
        />
      </Form>
    );
  },
});

export default ChangeUsernameForm;
