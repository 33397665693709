import crossroads from 'crossroads';
import hasher from 'hasher';
import Route from './Route';
import { absoluteURL, siteSentry, logger } from '../utils/Utils';

const router = {
  _initialized: false,

  routes: {},

  _activeRoutes: {},

  _router: crossroads.create(),

  _hasher: hasher,

  _site: null,

  urlLoc(url) {
    return url.split('#')[0];
  },

  urlHash(url) {
    const parts = url.split('#');
    return parts.length > 1 ? parts[parts.length - 1] : '';
  },

  currentLocation(withQueryString) {
    const loc = window.location.href.split('#')[0];
    return withQueryString ? loc : loc.split('?')[0];
  },

  /**
   * Return true if navigation to given route or url will cause page reload
   */
  pageReloadRequired(url, ...args) {
    let urlStr;
    if (url instanceof Route) {
      urlStr = url.makeUrl(...args);
    } else {
      urlStr = url;
    }
    const loc = absoluteURL(this.urlLoc(urlStr));
    return loc !== this.currentLocation(true);
  },

  _navigateInit() {
    this._navigateScheduledUrl = null;
    this._navigateScheduledPageReload = false;
  },

  encodeUrl(urlStr) {
    return String(urlStr).replace(
      /[<>"'`]/g, (s) => encodeURIComponent(s),
    ).replace(/'/g, '%27').replace(/^javascript:/, 'javascript%3A');
  },

  navigate(url, ...args) {
    let urlStr;
    if (url instanceof Route) {
      urlStr = url.makeUrl(...args);
    } else if (typeof url === 'string') {
      urlStr = url;
    } else {
      return;
    }
    logger.debug({
      message: 'navigation',
      category: 'cub.navigation',
      type: 'navigation',
      data: { from: this.currentLocation(), to: urlStr },
    });
    siteSentry.addBreadcrumb({
      message: 'navigation',
      category: 'cub.navigation',
      type: 'navigation',
      data: { from: this.currentLocation(), to: urlStr },
    });
    if (!this._navigateScheduledUrl) {
      window.setTimeout(() => {
        if (this._navigateScheduledPageReload) {
          const force = !this.pageReloadRequired(this._navigateScheduledUrl);
          window.location.href = this._navigateScheduledUrl;
          if (force) {
            window.location.reload();
          }
          return;
        }

        const navigatedTo = this._navigateScheduledUrl;
        this._hasher.setHash(this.urlHash(this._navigateScheduledUrl));
        window.scroll(0, 0);

        const scheduledUrl = this._navigateScheduledUrl;
        this._navigateInit();
        if (navigatedTo && (navigatedTo !== scheduledUrl)) {
          // during nagivation there was request for another navigation
          // so, we need to schedule another iteration to handle request
          this.navigate(scheduledUrl);
        }
      }, 50);
    }
    this._navigateScheduledUrl = this.encodeUrl(urlStr);
    this._navigateScheduledPageReload = this.pageReloadRequired(urlStr);
  },

  pageReload() {
    if (this._navigateScheduledUrl) {
      this._navigateScheduledPageReload = true;
    } else {
      window.location.reload(true);
    }
  },

  getFirstLoginRedirect() {
    const redirect = this._site.getWidgetSetting('login', 'firstLoginRedirect');
    const route = this.routes[redirect] ?
      this.routes[redirect] : this.routes.USER_PROFILE;
    return route;
  },

  destroy() {
    this._hasher.stop();
    this._router.removeAllRoutes();
  },

  _parseHash(newHash) {
    this._router.parse(newHash);
  },

  isCurrentRoute(route) {
    const currentLoc = this.currentLocation();
    const absoluteRouteUrl = absoluteURL(this.urlLoc(route.url), true);
    if (absoluteRouteUrl !== currentLoc) return false;

    const xrRoute = this._activeRoutes[route.name];
    if (!xrRoute) return false;

    return xrRoute.match(this.urlHash(window.location.href));
  },

  init(routes, site) {
    const currentLoc = this.currentLocation();
    this._router.removeAllRoutes();
    this.routes = routes;
    this._activeRoutes = {};
    this._site = site;
    Object.keys(routes).forEach((name) => {
      const route = routes[name];
      if (absoluteURL(this.urlLoc(route.url), true) === currentLoc) {
        this._activeRoutes[route.name] = this._router.addRoute(
          this.urlHash(route.url), route.handle,
        );
      }
    });
    if (!this._initialized) {
      this._navigateInit();
      this._hasher.initialized.add(this._parseHash); // parse initial hash
      this._hasher.changed.add(this._parseHash); // parse hash changes
      this._hasher.prependHash = '';
      this._hasher.init();
      this._initialized = true;
    }
  },
};

router._parseHash = router._parseHash.bind(router);

export default router;
