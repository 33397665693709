import PropTypes from 'prop-types';
import React from 'react';
import Highlight from '../basic/Highlight';

export default class OrganizationItem extends React.Component {
  render() {
    const line2 = [];
    const org = this.props.item;
    const country = org.country && org.country.name;
    const state = org.state && org.state.name;
    if (org.city) {
      line2.push(org.city);
    }
    if (state) {
      line2.push(state);
    }
    if (country) {
      if (country === 'United States') {
        line2.push('US'); // because space is limited there
      } else {
        line2.push(country);
      }
    }
    let clickHandler = null;
    if (this.props.noClick) {
      clickHandler = (e) => {
        // Prevent selection of this option
        e.preventDefault();
        e.stopPropagation();
      };
    }
    return (
      <div
        className={this.props.className}
        onClick={clickHandler}
        role="link"
        tabIndex="0"
      >
        <Highlight
          elementType="div"
          className="cub-Option-firstLine"
          text={org.name}
          highlight={this.props.highlight}
        />
        <Highlight
          elementType="div"
          className="cub-Option-secondLine"
          text={line2.join(', ')}
          highlight={this.props.highlight}
        />
      </div>
    );
  }
}

OrganizationItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  noClick: PropTypes.bool,
  highlight: PropTypes.string,
};
