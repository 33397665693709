import PropTypes from 'prop-types';
import React from 'react';
import Error from '../basic/Error';

export default class LabeledCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
  }

  validate() {
    if (this.props.required && !this.props.checked) {
      return 'This field is required.';
    }
    return null;
  }

  render() {
    const input = (
      <input
        className={'cub-FormControl cub-FormControl--checkbox' +
            ` ${this.props.classNameModifier}`}
        type="checkbox"
        checked={this.props.checked}
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
      />
    );
    const description = this.props.description && (
      <span className="cub-Label-textAdditional">
        {this.props.description}
      </span>
    );
    const image = this.props.image && (
      <div className="cub-Label-imageBlock">
        <img className="cub-Label-image" src={this.props.image} alt="" />
      </div>
    );
    const imageTitle = this.props.imageTitle && (
      <span className="cub-Label-title">
        {this.props.imageTitle}
      </span>
    );
    const imageDescription = this.props.imageDescription && (
      <span
        className="cub-Label-textAdditional"
        dangerouslySetInnerHTML={{ __html: this.props.imageDescription }}
      />
    );
    return (
      <div className="cub-FormGroup cub-FormGroup--checkbox">
        <label className="cub-Label cub-Label--checkbox">
          {image}
          {imageTitle}
          {imageDescription}
          {input}
          <span className="cub-Label-text">{this.props.label}</span>
          {this.props.required && <span className="cub-Label-asterisk">*</span>}
          {description}
        </label>
        <Error dataField={this.props.name}>
          {this.props.error}
        </Error>
      </div>
    );
  }
}

LabeledCheckbox.propTypes = {
  required: PropTypes.bool,
  classNameModifier: PropTypes.string,
  checked: PropTypes.bool,
  description: PropTypes.any,
  image: PropTypes.string,
  imageTitle: PropTypes.string,
  imageDescription: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

LabeledCheckbox.defaultProps = {
  classNameModifier: '',
  disabled: false,
};
