import React from 'react';
import AsyncComponent from '../AsyncComponent';

const editor = () => import(
  /* eslint function-paren-newline: "off" */
  /* webpackChunkName: "markdown" */ './markdown/MarkdownEditor');

export default class Markdown extends React.Component {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
  }

  validate() {
    if (this.asyncComponent && this.asyncComponent.component) {
      return this.asyncComponent.component.validate();
    }
    return null;
  }

  render() {
    const props = this.props;
    return (
      <AsyncComponent
        moduleProvider={editor}
        ref={(instance) => { this.asyncComponent = instance; }}
        {...props}
      />
    );
  }
}
