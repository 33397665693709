import PropTypes from 'prop-types';
import React from 'react';
import createForm from '../../mixins/createForm';
import { VERIFY_COUPON_FORM } from '../../constants/FormIds';
import Button from '../basic/Button';
import CouponActions from '../../actions/CouponActions';
import { Coupon } from '../../stores/DataModels';
import FormActions from '../../actions/FormActions';

const VerifyCouponForm = createForm(VERIFY_COUPON_FORM, {
  submit() {
    CouponActions.verifyCoupon(
      VERIFY_COUPON_FORM,
      this.state.couponCode,
      this.props.order,
      this.props.plan,
      null,
      this.onError,
    );
  },

  onError(...args) {
    FormActions.dispatchFormError(...args);
    if (this.props.onError) {
      this.props.onError(...args);
    }
  },

  onChange(e) {
    this.setState({ couponCode: e.target.value });
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  },

  storesSuccess(formId, response) {
    if (formId === VERIFY_COUPON_FORM) {
      this.setState({ verified: true });
      if (this.props.onSuccess) {
        this.props.onSuccess(new Coupon(response));
      }
    }
  },

  render() {
    const buttonDisabled = (
      this.state.verified ||
      !this.state.couponCode ||
      !(this.props.plan || this.props.order)
    );
    const hideIcon = this.state.verified ? '' : ' cub-Button--noIcon';
    return (
      <div className="cub-Form">
        <label className="cub-Label cub-Label--input" htmlFor="cub-coupon">
          Coupon code (optional)
        </label>
        <div className="cub-InputGroup">
          <input
            name="coupon"
            id="cub-coupon"
            className={'cub-FormControl cub-FormControl--input ' +
              'cub-InputGroup-input cub-FormControl--coupon'}
            onChange={this.onChange}
            disabled={this.state.verified}
          />
          <Button
            onClick={this.submit}
            classNameModifier={
              `cub-InputGroup-button cub-Button--resetWidth${hideIcon}`
            }
            isprocessing={this.state.isprocessing}
            text={this.state.verified ? 'Applied!' : 'Apply'}
            disabled={buttonDisabled}
          />
        </div>
      </div>
    );
  },
});

VerifyCouponForm.propTypes = {
  order: PropTypes.object,
  plan: PropTypes.object,
  onChange: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default VerifyCouponForm;
