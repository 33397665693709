import _ from 'underscore';
import AppDispatcher from '../dispatcher/AppDispatcher';
import CubApi from '../api/CubApi';
import { SITE_LOADED } from '../constants/ActionTypes';
import { logger } from '../utils/Utils';

const SiteActions = {
  getCurrentSite() {
    const expand = [
      'mailinglists__mailinglist',
      'vendor_lead_form',
      'siteplans__plan__product',
      'sitebenefits__benefit',
      'sso_settings',
    ].join(',');
    CubApi.get('sites/',
      { data: { domain: '{referer}', expand } },
      SiteActions.siteLoaded,
      SiteActions.siteLoadError);
  },

  siteLoaded(response) {
    if (Array.isArray(response) && response.length === 0) {
      logger.warn('Fatal error: configuration for ' +
          `"${window.location.host}" not found`);
    }
    AppDispatcher.handleServerAction({
      actionType: SITE_LOADED,
      response,
    });
  },

  siteLoadError(response, meta, method, url, options) {
    const errCode = parseInt(meta && meta.code, 10);
    // in case of timeout or server error make a retry in 5 seconds
    if (_.isNaN(errCode) || errCode >= 500) {
      logger.warn('Error occurred while loading site configuration, ' +
          'retry in 5 seconds...');
      window.setTimeout(() => {
        SiteActions.getCurrentSite(url, options);
      }, 5000);
    }
  },
};

export default SiteActions;
