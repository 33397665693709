import LabeledInput from './LabeledInput';

export default class PostalCode extends LabeledInput {}

PostalCode.defaultProps = {
  classNameModifier: 'cub-FormControl--postalCode',
  label: 'Postal Code',
  maxLength: 20,
  name: 'postal_code',
};
