import React from 'react';
import Loading from '../basic/Loading';
import NavLink from '../basic/NavLink';
import Text from '../basic/Text';
import Form from '../basic/Form';
import { SSO_CALLBACK } from '../../constants/FormIds';
import DataStore from '../../stores/DataStore';
import FormListener from '../FormListener';
import { absoluteURL } from '../../utils/Utils';

export default class SsoCallback extends FormListener {
  constructor(props) {
    super(props);
    this.formId = SSO_CALLBACK;
    this.state = {
      finished: false,
    };
  }

  storesSuccess(formId, response) {
    if ((formId === SSO_CALLBACK) && response.token) {
      this.setState({ finished: true });
    }
  }

  render() {
    const error = this.state.errors.firstError();
    const site = DataStore.currentSite();
    const defaultErrorGuidance = (
      <Text>
        Try {' '}
        <NavLink
          href={absoluteURL(this.props.routes.LOGIN.makeUrl(), true)}
        >
          returning to the Sign-In page
        </NavLink>
        {' '}and starting the process over.
      </Text>
    );
    const siteErrorGuidance = site.get('sso_error_guidance') && (
      <p
        className="cub-Text"
        dangerouslySetInnerHTML={{ __html: site.get('sso_error_guidance') }}
      />
    );
    return (
      <Form
        classNameModifier="cub-Form--sso-callback"
        caption={site.get('sso_callback_header') || 'Single Sign-On'}
        error={error}
        isProcessing={this.state.isProcessing}
      >
        {this.state.isProcessing && (
          <Loading />
        )}
        {this.state.finished && (
          <>
            <div className="cub-Msg cub-Msg--success">
              Success! Please wait for the redirect to finish signing in.
            </div>
            <Loading />
          </>
        )}
        {error && (siteErrorGuidance || defaultErrorGuidance)}
      </Form>
    );
  }
}
