import FormErrors from '../components/FormErrors';

export default class FormState {
  constructor(stateSetter, stateGetter) {
    if (typeof stateSetter !== 'function') {
      throw Error('stateSetter must be a function');
    }
    if (typeof stateGetter !== 'function') {
      throw Error('stateGetter must be a function');
    }

    this.stateSetter = stateSetter;
    this.stateGetter = stateGetter;

    this.onInputChange = this.onInputChange.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.submited = this.submited.bind(this);
    this.succeeded = this.succeeded.bind(this);
    this.failed = this.failed.bind(this);
    this.validationErrors = this.validationErrors.bind(this);
  }

  onInputChange(event) {
    // can accept either native input change event,
    // or {name: <name>, value: <value>} object
    const evt = event.target || event;
    const value = (evt.type === 'checkbox' && !evt.checked) ? '' : evt.value;
    this.onFieldChange(evt.name, value);
  }

  onFieldChange(name, value) {
    const errors = this.stateGetter().errors;
    if (errors.fieldError(name)) {
      errors.clearFieldError(name);
      this.stateSetter({ errors });
    }

    this.stateSetter({ [name]: value });
  }

  getInitialState() {
    return {
      isProcessing: false,
      errors: new FormErrors(),
    };
  }

  submited() {
    this.stateSetter({
      isProcessing: true,
    });
  }

  succeeded() {
    this.stateSetter(this.getInitialState());
  }

  failed(apiErrorResponse) {
    this.stateSetter({
      isProcessing: false,
      errors: new FormErrors(apiErrorResponse),
    });
  }

  validationErrors(errors) {
    // \/ emulating apiResponseError
    this.failed({ error: { params: errors } });
  }
}
