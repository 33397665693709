import LabeledInput from './LabeledInput';

export default class MiddleName extends LabeledInput {}

MiddleName.defaultProps = {
  classNameModifier: 'cub-FormControl--middleName',
  label: 'Middle Name',
  maxLength: 40,
  name: 'middle_name',
};
