import PropTypes from 'prop-types';
import React from 'react';
import NavLink from '../basic/NavLink';
import CaptionLarge from '../basic/CaptionLarge';
import Text from '../basic/Text';

export default class PasswordChangeDone extends React.Component {
  render() {
    return (
      <div className={'cub-InfoBlock cub-InfoBlock--passwordChanged' +
          ' isSuccess'}
      >
        <CaptionLarge>Password has been changed</CaptionLarge>
        <Text>
          Your password has been changed
        </Text>
        <NavLink
          className="cub-Button"
          route={this.props.routes.USER_PROFILE}
        >
          <span className="cub-Button-icon" />
          OK
        </NavLink>
      </div>
    );
  }
}

PasswordChangeDone.propTypes = {
  routes: PropTypes.object,
};
